import { Stores } from "src/stores";
import ExtendStore from "src/stores/base/extendStore";
import MessageMgStore from "../messageMgStore";
import { action, observable, runInAction } from "mobx";
import messageMgTypes from "src/types/messageMgTypes";

export default class MyMessageStore extends ExtendStore {
    @observable
    public queryListRes: messageMgTypes.myMessage.IQueryListRes = {
        pageIndex: 1,
        pageSize: 15,
        pageTotal: 30,
        recordTotal: 448,
        data: [{
            title: '素材\"web上传0425-002\"入库流程成功',
            content: '素材\"web上传0425-002\"入库成功！',
            createDate: '2022-11-03 15:30',
            isRead: true
        },{
            title: '素材\"web上传0425-002\"入库流程失败',
            content: '素材\"web上传0425-002\"入库失败！<br/>错误原因：User Canceled',
            createDate: '2022-11-03 15:30',
            isRead: false
        }]
    }
    @observable
    public selectedMessage?: messageMgTypes.myMessage.IQueryListData;
    @observable
    public isReq = false;

    private messageMgStore: MessageMgStore;

    public constructor(stores: Stores, messageMgStore: MessageMgStore) {
        super(stores);
        this.messageMgStore = messageMgStore;
        this.initSelectAllStore(stores, 'stores.messageMgStore.myMessageStore.queryListRes.data', 'selected')
    }

    @action
    public queryList(page?: number){
        this.isReq = true;
        setTimeout(()=>{
            runInAction(()=>{
                this.selectedMessage = this.queryListRes.data[0]
                this.isReq = false;
            })
        }, 1000)
    }

    @action
    public setSelectedMessage(message?: messageMgTypes.myMessage.IQueryListData){
        this.selectedMessage = message;
    }
}