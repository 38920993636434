import { css } from '@emotion/react'
import { Button, Result } from 'antd'
import * as React from 'react'
import { stores } from 'src/stores'

class NotFound extends React.Component {
    public render() {
        return (
            <div css={css`
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            `}>
                <Result
                    status="404"
                    title="404"
                    subTitle="对不起，当前地址下的页面访问不到"
                    extra={<Button type="primary" onClick={this.goBack}>回到首页</Button>}
                />
            </div>
        )
    }

    private goBack = ()=>{
        window.location.href = stores.configStore.baseConfig.siteHome;
    }
}

export default NotFound
