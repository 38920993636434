import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import workteamTypes from "../types/workteamTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace workteamApi {
    export const getTeamUsers = (groupCode: string) : AxiosPromise<IResponse<workteamTypes.ITeamUserRes[]>> => {
        return http.get<IResponse<workteamTypes.ITeamUserRes[]>>('/work-team/team-users?groupCode=' + groupCode);
    }

    export const createTeam = (req: workteamTypes.ICreateTeamReq) : AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/work-team/create-team', req)
    }

    export const updateTeam = (req: workteamTypes.ICreateTeamReq) : AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/work-team/update-team', req)
    }

    export const deleteTeam = (req: string[]) : AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/work-team/delete-team', req)
    }

    export const exitTeam = (req: string[]) : AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/work-team/exit-team', req)
    }
}
export default workteamApi