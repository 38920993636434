import { Stores } from "../index";
import { observable, action } from 'mobx';
import ManageExtendStore from "./manageExtendStore";
import manageTypes from "src/types/manageTypes";

export default class ManageStore extends ManageExtendStore {
    @observable
    public iframeInfo: manageTypes.IIframeInfo = {
        src: '',
        show: false
    };

    public constructor(stores: Stores) {
        super(stores);
        this.initMetadataMgStore();
        this.initInstanceStore();
        this.initAdvancedSearchSetStore();
        this.initFacetSetStore();
        this.initEnumerationStore();
        this.initBusinessLogStore();
        this.initOrgSetStore();
        this.initRoleSetStore();
        this.initAuthSetStore();
        this.initAllUserStore();
        this.initAuthGroupStore();
        this.initEsSearchConfigStore();
        this.initTreeSetStore();
    }

    @action
    public setIframeInfo(info: manageTypes.IIframeInfo){
        this.iframeInfo = info;
    }


    public pgShowTotalSetting = (total: number, range: number[]) => { 
        return range[0] + "-" + range[1] + " / " + total + " " + '条'.l('com.item') 
    }
}
