import { AxiosPromise } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import iSearchTypes from 'src/types/iSearchTypes';

namespace iSearchApi{
    export const queryFullSearchData = (params: iSearchTypes.IFullSearchDataReq, configOpts?: any):AxiosPromise<IResponse<iSearchTypes.IFullSearchDataRes>> => {
        return http.post<IResponse<iSearchTypes.IFullSearchDataRes>>('/smcss/v1/search', params, configOpts);
    }

    export const queryFullSearchDataWithFace = (params: iSearchTypes.IFullSearchDataReq, configOpts?: any):AxiosPromise<IResponse<iSearchTypes.IFullSearchDataRes>> => {
        return http.post<IResponse<iSearchTypes.IFullSearchDataRes>>('/smcss/v1/search/face', params, configOpts);
    }

    export const queryAggrs = (params: iSearchTypes.IFullSearchDataReq, configOpts?: any):AxiosPromise<IResponse<iSearchTypes.IFacets[]>> => {
        return http.post<IResponse<iSearchTypes.IFacets[]>>('/smcss/v1/search/facet', params, configOpts);
    }
    export const getRelativeEvents = (keyword: string):AxiosPromise<IResponse<iSearchTypes.IRelativeEventRes>> => {
        return http.get<IResponse<iSearchTypes.IRelativeEventRes>>(`/stagm/v1/tag?tagname=${keyword}&iscut=true&pagesize=5&tagType=事件`);
    }

    export const getSearchSuggest = (keyword: string): AxiosPromise<IResponse<string[]>> => {
        return http.get<IResponse<string[]>>(`/smcss/v1/search/suggest?keyword=${keyword}`, {
            headers: {hideLoading: true}
        })
    }

    export const getSearchRecord = (): AxiosPromise<IResponse<iSearchTypes.ISearchRecord[]>> => {
        return http.get<IResponse<iSearchTypes.ISearchRecord[]>>(`/smcss/v2/search/history`, {
            headers: {hideLoading: true}
        })
    }

    //删除一条历史记录
    export const deleteSearchRecord = (keywrod: string): AxiosPromise<IResponse<iSearchTypes.ISearchRecord[]>> => {
        return http.delete(`/smcss/v2/search/history?keywrod=${keywrod}`)
    }

    //清空历史记录
    export const clearSearchRecord = (): AxiosPromise<IResponse<iSearchTypes.ISearchRecord[]>> => {
        return http.delete(`/smcss/v1/search/history/clear`)
    }

    export const getSearchTemplates = ():AxiosPromise<IResponse<iSearchTypes.ISearchTemplateItem[]>> => {
        return http.get<IResponse<iSearchTypes.ISearchTemplateItem[]>>(`/smcss/v1/search/template`);
    }

    export const getSeriesRelshipState = (contentIds: string[]):AxiosPromise<IResponse<string[]>> => {
        return http.get<IResponse<string[]>>(`/scntm/v1/series/check/entity-relship-state?contentIds=` + contentIds.join('&contentIds='));
    }

    export const getCopyrightState = (contentIds: string[]):AxiosPromise<IResponse<string[]>> => {
        return http.post<IResponse<string[]>>(`/scntm/v1/series/check/copyright-relship-state`, contentIds);
    }
}

export default iSearchApi