import { message } from 'antd';
import { action, observable, runInAction } from 'mobx';
import entityApi from 'src/apis/entityApi';
import { Stores } from 'src/stores';
import ExtendStore from 'src/stores/base/extendStore';
import entityTypes from 'src/types/entityTypes';
import EntityStore from '../entityStore';
import _ from 'lodash'

export default class DocumentOpeStore extends ExtendStore {
    public parentStore: EntityStore;
    @observable
    public currentPid: string = '';
    @observable
    public checkResult: entityTypes.IHeimaCheckResultRes;
    public webOfficeInst: any;
    
    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    public setWebOfficeInst(webOfficeInst: any){
        this.webOfficeInst = webOfficeInst;
    }

    @action
    public setCurrentPid(pid: string){
        this.currentPid = pid;
    }

    @action
    public getCheckedResult(){
        if (!this.currentPid){
            return;
        }
        entityApi.getHeimaCheckResult(this.currentPid).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.checkResult = res.data.data;
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    @action
    public setCheckResultList(list: entityTypes.IHeimaCheckResultListItem[]){
        if (this.checkResult){
            this.checkResult.checkResultList = list;
        }
    }

    @action
    public removeCheckResultListItem(item: entityTypes.IHeimaCheckResultListItem){
        if (this.checkResult){
             _.remove(this.checkResult.checkResultList, (o)=>{
                return o === item
             });
        }
    }

    @action
    public removeCheckResultListByLevel(level: number){
        if (this.checkResult){
             _.remove(this.checkResult.checkResultList, (o)=>{
                return o.level === level
             });
        }
    }
}