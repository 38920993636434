import { message, Modal } from "antd";
import uploadTypes from "../../../types/uploadTypes";
import { stores } from "../../../stores";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import {l} from "mam-core-react";
import { gModal } from "src/components/extend/modal/globalModal";

class Uploader {
    public options: uploadTypes.IUploadOptions;
    private tf: any;
    // 调用groupFilesByFileName方法后，未成组的文件，会放到ungroupKey下面
    private taskInitSuccess: (task: uploadTypes.ITask) => void;
    private taskInitBefore: (task: uploadTypes.ITask) => void;
    private taskUploadProgress: (task: uploadTypes.ITask) => void;
    private taskDeleteSuccess: (task: uploadTypes.ITask) => void;
    private taskUploadSuccess: (task: uploadTypes.ITask) => void;
    private taskUploadSurplusTime: (task: uploadTypes.ITask) => void;

    constructor() {
    }

    public setTaskInitSuccess(fun: (task: uploadTypes.ITask) => void) {
        this.taskInitSuccess = fun;
    }
    public setTaskInitBefore(fun: (task: uploadTypes.ITask) => void) {
        this.taskInitBefore = fun;
    }
    public setTaskUploadProgress(fun: (task: uploadTypes.ITask) => void) {
        this.taskUploadProgress = fun;
    }
    public setTaskDeleteSuccess(fun: (task: uploadTypes.ITask) => void) {
        this.taskDeleteSuccess = fun;
    }
    public setTaskUploadSuccess(fun: (task: uploadTypes.ITask) => void) {
        this.taskUploadSuccess = fun;
    }
    public setTaskUploadSurplusTime(fun: (task: uploadTypes.ITask) => void) {
        this.taskUploadSurplusTime = fun;
    }

    public asyncLoadDependencies(){
        return new Promise((resolve, reject)=>{
            commonUtil.asyncLoadScriptArr(['/libs/mam-upload/dist/libs/oss/aliyun-oss-sdk.min.js',
            '/libs/mam-upload/dist/libs/oss-s3/aws-sdk.min.js',
            '/libs/mam-upload/dist/libs/oos/oos-sdk.min.js',
            '/libs/mam-upload/dist/libs/plupload/plupload.full.min.js',
            '/libs/mam-upload/dist/libs/ksyun/ks3jssdk.min.js',
            '/libs/mam-upload/dist/mam-upload-pure.js']).then(()=>{
                if (stores.configStore.config.isEncryptOssClientInfo){
                    commonUtil.asyncLoadScriptArr(['/libs/CryptoJS/components/core-min.js',
                    '/libs/CryptoJS/rollups/aes.js']).then(()=>{
                        commonUtil.asyncLoadScriptArr(['/libs/CryptoJS/components/mode-ecb-min.js']).then(()=>{
                            resolve(undefined);
                        })
                    })
                }
                else {
                    resolve(undefined);
                }
            });
        });
    }

    public init(options?: uploadTypes.IUploadOptions) {
        return new Promise((resolve, reject)=>{
            this.asyncLoadDependencies().then(()=>{
                if (!(window as any).mamUpload.web || !(window as any).mamUpload.vtube) {
                    let opts: any = {
                        configInst: stores.configStore.config
                    };
                    if (commonUtil.isDebug()){
                        opts.baseServerUrl = 'http://localhost:9998/proxy';
                    }
                    (window as any).mamUpload.init(opts);
                    this.initEvents();
                }
                this.options = this.getOptions(options || {});
                resolve(undefined);
            })
        })
    }

    public validSelectFile(tasks: uploadTypes.ITask[]){
        // 上传后缀名限制，白名单
        let checkExt = true;
        if (stores.configStore.config.extensionLimit 
        && stores.configStore.config.extensionLimit.type === 2
        && stores.configStore.config.extensionLimit.extensions && checkExt){
            const extensions = stores.configStore.config.extensionLimit.extensions;
            let extStr = '';
            tasks.forEach((task)=>{
                if (!task.file){
                    return;
                }
                const ext = commonUtil.getExtension((task.file as File).name).toLowerCase();
                if (_.findIndex(extensions, (o)=>{
                    return o === '.' + ext;
                }) === -1){
                    if (extStr.indexOf(`.${ext}`) === -1){
                        extStr += `.${ext}，`
                    }
                    checkExt = false;
                }
            })
            if (!checkExt){
                extStr = extStr.substring(0, extStr.length - 1);
                gModal.modal.warning({
                    title: '提示'.l('com.tips'),
                    content: `后缀名为 ${extStr} 的文件不允许上传！`,
                    onOk: ()=>{
                    },
                    onCancel: ()=>{
                    }
                });
                return false;
            }
        }
        // 黑名单
        else if (stores.configStore.config.extensionLimit 
        && stores.configStore.config.extensionLimit.type === 3
        && stores.configStore.config.extensionLimit.extensions && checkExt){
            const extensions = stores.configStore.config.extensionLimit.extensions;
            let extStr = '';
            tasks.forEach((task)=>{
                if (!task.file){
                    return;
                }
                const ext = commonUtil.getExtension((task.file as File).name).toLowerCase();
                if (_.findIndex(extensions, (o)=>{
                    return o === '.' + ext;
                }) > -1){
                    if (extStr.indexOf(`.${ext}`) === -1){
                        extStr += `.${ext}，`
                    }
                    checkExt = false;
                }
            })
            if (!checkExt){
                extStr = extStr.substring(0, extStr.length - 1);
                gModal.modal.warning({
                    title: '提示'.l('com.tips'),
                    content: `后缀名为 ${extStr} 的文件不允许上传！`,
                    onOk: ()=>{
                    },
                    onCancel: ()=>{
                    }
                });
                return false;
            }
        }
        // 文件大小限制
        if (stores.configStore.config.fileSizeLimit !== -1){
            let checkFileSize = true;
            tasks.forEach((task)=>{
                if (!task.file){
                    return;
                }
                if ((task.file as File).size > stores.configStore.config.fileSizeLimit){
                    checkFileSize = false;
                }
            })
            if (!checkFileSize){
                return false;
            }
        }
        return true;
    }

    public selectFile(options: uploadTypes.IUploadOptions) {
        this.init().then(()=>{
            this.options = this.getOptions(options);
            if (this.options.transferType === uploadTypes.TransferTypes.WEB) {
                this.getTransfer.openFileSelector((tasks: uploadTypes.ITask[]) => {
                    if (tasks.length === 0){
                        gModal.modal.warning({
                            title: '提示'.l('com.tips'),
                            content: '文件后缀不支持！'.l('webUpload.fileExtIsNotSupport')
                        });
                        return;
                    }
                    if (!this.validSelectFile(tasks)){
                        return;
                    }
                    if (this.options.writeMetadataBefore && this.options.writeMetadataBefore(this.getTasksByType(tasks), this.options) === false) {
                        return;
                    }
                    if (options.openModal) {
                        options.openModal(this.getTasksByType(tasks), this.options);
                    }
                });
            } else {
                // 客户端操作，因此要先验证客户端是否存在
                this.getTransfer.openFileSelector((tasks: uploadTypes.ITask[]) => {
                    if (this.options.writeMetadataBefore && this.options.writeMetadataBefore(this.getTasksByType(tasks), this.options) === false) {
                        return;
                    }
                    if (options.openModal) {
                        options.openModal(this.getTasksByType(tasks), this.options);
                    }
                }, () => {
                    let vtubeDownloadPath: string = (window as any).nxt.config.vtubeDownloadPath;
                    const modal = Modal.confirm(
                        {
                            title: '客户端异常',
                            content: '请确定已经正常开启客户端或重启客户端再重试。',
                            okText: '下载客户端',
                            onOk: () => {
                                message.info('正在下载客户端')
                                setTimeout(() => {
                                    window.open(vtubeDownloadPath)
                                }, 1000);
                            }
                        }
                    );
                });
            }
        });
    }

    /** 将原生files列表，转换为上传任务列表 */
    public getFileTasks(files: File[], options: uploadTypes.IUploadOptions): Promise<uploadTypes.ITask[]>{
        return new Promise((resolve, reject)=>{
            this.init().then(()=>{
                this.options = this.getOptions(options);
                // 后面两个参数解决版权书后缀和图片、文档等后缀相同导致冲突问题
                let tasks = this.getTransfer.getTasksByFiles(files, 
                    this.options.taskType === uploadTypes.TaskTypes.AGREEMENT ? 'biz_sobey_agreement' : undefined,
                    this.options.taskType !== uploadTypes.TaskTypes.AGREEMENT ? 'biz_sobey_agreement' : undefined);
                if (tasks.length === 0){
                    gModal.modal.warning({
                        title: '提示'.l('com.tips'),
                        content: '文件后缀不支持！'.l('webUpload.fileExtIsNotSupport')
                    });
                    reject();
                }
                else {
                    resolve(this.getTasksByType(tasks));
                }
            });
        })
    }

    public createTask(tasks: uploadTypes.ITask | uploadTypes.ITask[], options?: uploadTypes.IUploadOptions) {
        if (options){
            this.options = this.getOptions(options);
        }
        this.getTransfer.createTask(tasks, this.options);
    }

    public get getTransfer() {
        if (this.tf){
            return this.tf;
        }
        else {
            if (this.options.transferType === uploadTypes.TransferTypes.WEB) {
                this.tf = (window as any).mamUpload.web;
            }
            else {
                this.tf = (window as any).mamUpload.vtube;
            }
            return this.tf;
        }
    }

    /** 检查文件是否重复 */
    public checkExist(files: uploadTypes.IFile[], file: uploadTypes.IFile) {
        let isHas;
        if (this.options.transferType === uploadTypes.TransferTypes.WEB) {
            isHas = _.find(files, (item: uploadTypes.IFile) => {
                return item !== file && (item.file as File).name === (file.file as File).name &&
                    (item.file as File).size === (file.file as File).size &&
                    (item.file as File).lastModified === (file.file as File).lastModified;
            });
        } else { //vtube
            isHas = _.find(files, (item: uploadTypes.IFile) => {
                return item !== file
                    && (file.file as uploadTypes.IVtubeFile).FilePath === (item.file as uploadTypes.IVtubeFile).FilePath;
            });
        }
        if (isHas != null) {
            message.error(l('upload.fileExist', '文件 ' + file.fileName + ' 已存在', { name: file.fileName }));
            return true;
        }
        return false;
    }

    public getTasksByType(tasks: uploadTypes.ITask[]): uploadTypes.ITask[] {
        if (this.options.taskType === uploadTypes.TaskTypes.GENERAL 
            || this.options.taskType === uploadTypes.TaskTypes.AGREEMENT) {
            return tasks;
        }
        else if (this.options.taskType === uploadTypes.TaskTypes.MOCPICTUREPACKAGEUPLOAD
            || this.options.taskType === uploadTypes.TaskTypes.PICTUREPACKAGEUPLOAD) {
            return [this.getSingleGroupTask(tasks, window.location.pathname.includes('webUpload')
                ? 'biz_sobey_picture' : 'biz_sobey_picpackage')];
        }
        else if (this.options.taskType === uploadTypes.TaskTypes.GROUPUPLOAD){
            return this.getGroupTasks(tasks);
        }
        return [];
    }

    /** 获取配置参数 */
    public getOptions(options: uploadTypes.IUploadOptions){
        let opts = $.extend({}, {
            module: uploadTypes.UploadModules.YUNPAN,
            taskType: uploadTypes.TaskTypes.GENERAL,
            transferType: uploadTypes.TransferTypes.WEB,
            targetFolder: '',
            relationContentType: uploadTypes.RelationContentType.NONE,
            relationContentId: ''
        }, options);
        return opts;
    }

    public createNewTask = (tasks: uploadTypes.ITask[], fileName: string): uploadTypes.ITask=>{
        return {
            id: commonUtil.getUuid(),
            entityType: 'biz_sobey_video',
            files: tasks.map((task) => {
                return {
                    fileName: task.fileName,
                    file: task.file,
                    metadata: task.metadata
                }
            }) as uploadTypes.IFile[],
            'metadata': {
                name: fileName
            },
            targetFolder: this.options.targetFolder,
            taskType: this.options.taskType,
            transferType: this.options.transferType,
            status: 'added',
            progress: 0
        }
    }

    /** 获取成组上传任务 */
    public getGroupTasks(tasks: uploadTypes.ITask[]): uploadTypes.ITask[] {
        let newTasks: uploadTypes.ITask[] = [];
        let newTasksMap = this.groupFilesByFileName(tasks);
        Object.keys(newTasksMap).forEach((fileName)=>{
            let taskList = newTasksMap[fileName];
            if (!taskList){
                return;
            }
            newTasks.push(this.createNewTask(taskList, fileName));
        })
        return newTasks;
    }

    /**
     * @param 传入ITask数组，openFileSelector方法回调返回来的数组
     * @returns 返回一个object对象，每一个key值都是匹配成功的文件名，内容为ITask数组
    */
    public groupFilesByFileName(tasks: uploadTypes.ITask[]): Record<string, uploadTypes.ITask[] | undefined> {
        const groups: Record<string, uploadTypes.ITask[] | undefined> = {};
    
        const regexs: string[] = stores.configStore.config.config.groupMatchRegexs;
    
        let matchingTasks = tasks;
        // regexs列表当中每个正则表达式依次轮番对文件列表进行匹配，每一轮匹配后，匹配成组的文件，
        // 会从文件列表中移除，文件列表中剩余未成组文件进行下一轮正则匹配，regexs当中所有正则匹配完成后，
        // 还是未成组的文件会单独放到 ungroupKey 集合下面，做后续处理。
        regexs.forEach((regexStr)=>{
            let regex = new RegExp(regexStr);
            for (const task of matchingTasks) {
                if (!task.file){
                    break;
                }
                let filename = (task.file as File).name.substring(0, (task.file as File).name.lastIndexOf('.'));
                const matches = filename.match(regex);
        
                let matchFileName = '';
                if (matches && matches[1]) {
                    matchFileName = matches[1];
                }
                // 匹配不上正则的，直接用文件名做组名
                else {
                    matchFileName = filename;
                }
                if (matchFileName) {
                    if (groups[matchFileName]) {
                        (groups[matchFileName] as any).push(task); // 将文件添加到已存在的组
                    } else {
                        groups[matchFileName] = [task]; // 创建新的组
                    }
                }
            }

            // 单个文件一组的，表示当前正则表达式未匹配到，需要发起下一轮匹配
            matchingTasks = [];
            Object.keys(groups).forEach((fileName)=>{
                if (groups[fileName] && (groups[fileName] as any).length <= 1){
                    matchingTasks.push(...(groups[fileName] as any));
                    groups[fileName] = undefined;
                }
            })
        })
        return groups;
    }

    /** 获取图片包上传任务 */
    private getSingleGroupTask(tasks: uploadTypes.ITask[], code: string): uploadTypes.ITask {
        let type = _.find(stores.configStore.config.entityTypes, { 'code': code });

        let rettask: uploadTypes.ITask = {
            id: commonUtil.getUuid(),
            entityType: type ? type.code : '',
            files: tasks.map((task) => {
                return {
                    fileName: task.fileName,
                    file: task.file,
                    metadata: task.metadata
                }
            }) as uploadTypes.IFile[],
            'metadata': {
                name: ''
            },
            targetFolder: this.options.targetFolder,
            taskType: this.options.taskType,
            transferType: this.options.transferType,
            status: 'added',
            progress: 0
        };
        return rettask;
    }

    private initEvents() {
        (window as any).mamUpload.web.off('task-init-success');
        (window as any).mamUpload.web.on('task-init-success', (e: any, task: uploadTypes.ITask) => {
            if (this.taskInitSuccess) {
                this.taskInitSuccess(task);
            }
        });
        (window as any).mamUpload.web.on('task-init-error', (e: any, task: uploadTypes.ITask, res: any) => {
            console.error(res)
        });

        (window as any).mamUpload.web.off('task-init-before');
        (window as any).mamUpload.web.on('task-init-before', (e: any, task: uploadTypes.ITask) => {
            if (this.taskInitBefore) {
                this.taskInitBefore(task);
            }
        });
        (window as any).mamUpload.web.off('task-upload-progress');
        (window as any).mamUpload.web.on('task-upload-progress', (e: any, task: uploadTypes.ITask) => {
            if (this.taskUploadProgress) {
                this.taskUploadProgress(task);
            }
        });

        (window as any).mamUpload.web.off('task-delete-success');
        (window as any).mamUpload.web.on('task-delete-success', (e: any, task: uploadTypes.ITask) => {
            if (this.taskDeleteSuccess) {
                this.taskDeleteSuccess(task);
            }
        });

        (window as any).mamUpload.web.off('task-upload-success');
        (window as any).mamUpload.web.on('task-upload-success', (e: any, task: uploadTypes.ITask) => {
            if (this.taskUploadSuccess) {
                this.taskUploadSuccess(task);
            }
        });

        (window as any).mamUpload.web.off('task-upload-surplusTime');
        (window as any).mamUpload.web.on('task-upload-surplusTime', (e: any, task: uploadTypes.ITask) => {
            if (this.taskUploadSurplusTime) {
                this.taskUploadSurplusTime(task);
            }
        });
    }
}

export let uploader = new Uploader();
