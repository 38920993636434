import ExtendStore from 'src/stores/base/extendStore';
import _ from 'lodash'
import { Stores } from 'src/stores';
import CreateEditTemplateStore from './createEditTemplateStore';
import { action, observable, runInAction } from 'mobx';
import { message } from 'antd';
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';
import { reqCatch } from 'mam-core-react';

export default class SearchTemplateStore extends ExtendStore {
    @observable
    public searchTemplates: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateItem[] = [];
    @observable
    public isReqTemplates: boolean = false;
    @observable
    public allFields: manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[] = []
    public createEditTemplateStore: CreateEditTemplateStore

    public constructor(stores: Stores){
        super(stores)
        this.createEditTemplateStore = new CreateEditTemplateStore(stores);
    }

    @action
    public querySearchTemplate(){
        this.isReqTemplates = true;
        manageApi.esSearchConfig.searchTemplate.querySearchTemplate().then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.searchTemplates = res.data.data;
                }
            })
        }).catch(res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        }).finally(()=>{
            runInAction(()=>{
                this.isReqTemplates = false;
            })
        })
    }

    @action
    public getAllFields(){
        manageApi.esSearchConfig.advancedSearchConfig.getAllFields().then((res)=>{
            runInAction(()=>{
                if (res.data.data){
                    this.allFields = res.data.data;
                }
            })
        }).catch(reqCatch)
    }
}