import { stores } from 'src/stores';
import client from 'webpack-theme-color-replacer/client';
import { generate } from "@ant-design/colors";

namespace styleUtil {
    export const generateColors = (themeColor: string): string[]=>{
        const genColors = generate(themeColor);
        return genColors;
    }

    export const getColors4ThemeChange = (genColors: string[])=>{
        const colors = [genColors[5], genColors[4], genColors[3], genColors[2], genColors[1], genColors[0]];
        return colors.concat(genColors).concat(genColors.map(hex=>{
            return parseInt("0x" + hex.slice(1, 3)) + ', ' + parseInt("0x" + hex.slice(3, 5)) + ', ' + parseInt("0x" + hex.slice(5, 7));
        }));
    }

    export const changeThemeColor = (genColors: string[])=>{
        client.changer.changeColor({
            // new colors array, one-to-one corresponde with `matchColors`
            newColors: getColors4ThemeChange(genColors),
            changeUrl (cssUrl: string) {
                return `${cssUrl}` // while router is not `hash` mode, it needs absolute path
            }
          }, Promise).then(() => {
            console.log('Theme colors changed!')
        })
    }

    export const loadMainStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadLoginStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/login/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadCcStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/cc/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadEntityStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/entity/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadEntityPartStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/entityPart/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadShareStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/share/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadSharePageStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/sharePage/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadWapStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/wap/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadWpsStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/wps/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadSysmanageStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/sysmanage/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadManageStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/manage/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadWebUploadStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/webUpload/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadISearchStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/iSearch/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadImageSearchStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/imageSearch/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadContentMgStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/contentMg/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadBasketStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/basket/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadStatisticStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/statistic/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadDatasetDetailStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/datasetDetails/index').then(() => {
                resolve(undefined);
            })
        })
    }
    export const loadPersonalCenterStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/personalCenter/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadCopyrightStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/copyright/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadTaskSearchStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/taskSearch/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadAlbumDetailStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/albumDetail/index').then(() => {
                resolve(undefined);
            })
        })
    }

    export const loadBatchEditStyle = (): Promise<any> => {
        return new Promise((resolve, reject)=>{
            import('../styles/default/page/batchEdit/index').then(() => {
                resolve(undefined);
            })
        })
    }
}
export default styleUtil
