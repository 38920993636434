import { action, observable, runInAction } from 'mobx';
import datasetDetailsApi from 'src/apis/datasetDetailsApi';
import { Stores } from 'src/stores';
import datasetDetailsTypes from 'src/types/datasetDetailsTypes';
import DatasetDetailsStore from '../../datasetDetailsStore';
import ExtendStore from 'src/stores/base/extendStore';
import _ from 'lodash'
import { IEntityType } from 'src/types/configTypes';
import { reqCatch } from 'mam-core-react';
import { message } from 'antd';

const defaultSorts: datasetDetailsTypes.ISort[] = [
    {
        field: 'createDate_',
        name: '创建时间降序',
        isDesc: true
    },
    {
        field: 'createDate_',
        name: '创建时间升序',
        isDesc: false
    },
    {
        field: 'name_',
        name: '标题名降序',
        isDesc: true
    },
    {
        field: 'name_',
        name: '标题名升序',
        isDesc: false
    }
]

export default class EntitysDataStore extends ExtendStore {
    @observable
    public datasetDetailsStore: DatasetDetailsStore
    @observable
    public entitysData: datasetDetailsTypes.IFullSearchRes;//右侧内容区域
    @observable
    public isReqEntityData: boolean = false;
    @observable//排序方式
    public sorts: datasetDetailsTypes.ISort[] = defaultSorts;
    @observable//选中排序方式
    public selectedSort: datasetDetailsTypes.ISort = defaultSorts[0]
    @observable
    public entityTypes: IEntityType[] = []//分类
    @observable//选中的分类
    public selectedEntityTypeCodes: string[] = [];
    @observable
    public pageSize: number = 30;
    @observable
    public searchTxt: string = '';
    @observable
    public pathArray: datasetDetailsTypes.IPathArray[]=[]//面包屑路径

    public constructor(stores: Stores, datasetDetailsStore: DatasetDetailsStore) {
        super(stores);
        this.datasetDetailsStore = datasetDetailsStore;
        this.initSelectAllStore(stores, 'stores.datasetDetailsStore.entitysDataStore.entitysData.data', 'selected');
        this.initDataViewModeStore(stores);
        this.initUploadPanelStore(stores);
        this.initUploadStore(stores);
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
    }

    public initEntityTypes(){
        this.entityTypes = this.stores.configStore.config.entityTypes.filter((item: any)=>{
            return item.code !== 'rundown' && item.code !== 'sequence' && item.code !== 'dataset' 
                    && item.code !== 'scene' && item.code !== 'shot' && item.code !== 'biz_sobey_newsscript'
        })
        // .concat([{
        //     code: 'folder',
        //     name: '文件夹'
        // }])
        this.entityTypes.forEach((item)=>{
            this.selectedEntityTypeCodes.push(item.code);
        })
    }

    //查询右侧内容
    @action
    public queryEntitysData = (page: number) => {
        this.isReqEntityData = true;
        this.selectAllStore.switchAllSelected(false);
        return new Promise((resolve, reject) => {
            if(this.entitysData&&this.entitysData.data.length){
                this.entitysData.data=[]
            }
            let params: datasetDetailsTypes.IFullSearchReq = {
                folderId: this.datasetDetailsStore.treeStore.selectedFolderId,
                keyword: this.searchTxt ? [this.searchTxt] : [],
                pageIndex: page,
                pageSize: this.pageSize,
                sortFields:[
                    this.selectedSort.isDesc !== undefined ? {
                        field: this.selectedSort.field,
                        isDesc: this.selectedSort.isDesc
                    } : {
                        field: this.selectedSort.field
                    }
                ],
                facetConditions: [],
                conditions: [],
                isQueryDirectSub: this.searchTxt ? true : false
            }
            if (this.selectedEntityTypeCodes && this.selectedEntityTypeCodes.length > 0){
                let typeCondition: datasetDetailsTypes.ISearchCondition = {
                    field: "type_",
                    value: []
                };
                this.selectedEntityTypeCodes.forEach((item)=>{
                    if (typeCondition.value && typeCondition.value instanceof Array){
                        typeCondition.value.push(item)
                    }
                })
                params.facetConditions.push(typeCondition)
            }
            datasetDetailsApi.fullSearch(params).then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        res.data.data.data.forEach((item)=>{
                            item.contentId = item.contentId_;
                            item.type = item.type_;
                        })
                        this.entitysData = res.data.data

                        // 设置面包屑
                        if (res.data.data.breadcrumbs && res.data.data.breadcrumbs.length > 0){
                            this.setPathArray(res.data.data.breadcrumbs.map((item, index)=>{
                                return {
                                    showName: index === 0 ? this.datasetDetailsStore.entity.name : item.name,
                                    key: item.folderId,
                                    contentId: item.folderId
                                }
                            }))
                        }

                        resolve(true)
                    }
                })
            }, res => {
                if (res.data && res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                else {
                    console.error(res)
                }
                reject(res)
            }).finally(()=>{
                runInAction(()=>{
                    this.isReqEntityData = false;
                })
            })
        })
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
    }

    @action
    public setSelectedSort(sort: string) {
        let selectedSort = _.find(this.sorts, {name: sort})
        if (selectedSort){
            this.selectedSort = selectedSort;
        }
        this.queryEntitysData(1)
    }

    @action
    public setSelectedEntityType(codes: string[]) {
        this.selectedEntityTypeCodes = codes;
        this.queryEntitysData(1)
    }

    @action
    public setSearchTxt(txt: string){
        this.searchTxt = txt;
    }

    @action
    public setPathArray = (arr: datasetDetailsTypes.IPathArray[]) => {
        this.pathArray = arr
    }
}