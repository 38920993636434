import React from 'react';
import { observer } from 'mobx-react';
import MmsSearchInput from 'src/pages/iSearch/modules/searchWrap/module/mmsSearchInput';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { stores } from 'src/stores';
import { l } from 'mam-core-react';
import { commonUtil, routeUtil } from 'mam-common-utils';
import { UploadFile } from 'antd';

const SearchBox: React.FC<RouteComponentProps> = observer((props: RouteComponentProps) => {
    const getSearchPath = ()=>{
        return '/iSearch'
    }

    const keywordQuery = React.useCallback((keyword1: string, type?: string) => {
        stores.iSearchStore.setSearchKeyword1(keyword1);
        let keywords: string[] = [];
        if (stores.iSearchStore.searchKeyword1) {
            keywords.push(stores.iSearchStore.searchKeyword1);
        }
        if (stores.iSearchStore.searchKeyword2) {
            keywords.push(stores.iSearchStore.searchKeyword2);
        }
        if (stores.iSearchStore.searchKeyword1 || stores.iSearchStore.searchKeyword2){
            stores.iSearchStore.autoRelatedSort(true);
        }
        else {
            stores.iSearchStore.autoRelatedSort(false);
        }
        props.history.push({
            pathname: getSearchPath(),
            search: '',
            state: {
                isPublic: false,
                folder: '',
                page: 1,
                keyword: keywords,
                conditions: stores.iSearchStore.params ? commonUtil.copyObj(stores.iSearchStore.params.conditions) : [],
                facetConditions: stores.iSearchStore.params ? commonUtil.copyObj(stores.iSearchStore.params.facetConditions) : [],
                searchFaceBase64: stores.imageSearchStore.params 
                    && !routeUtil.isPath(props, getSearchPath()) ? commonUtil.copyObj(stores.imageSearchStore.params.base64) : '',
                folderId: stores.iSearchStore.params ? stores.iSearchStore.params.folderId : undefined
            }
        })
    }, [])

    const onSelectImage = React.useCallback((base64: string | UploadFile<any>, searchType: string) => {
        if(searchType === 'similar' || searchType === 'similarVideo'){
            props.history.push({
                pathname: '/imageSearch/similar',
                state: {
                    base64
                }
            })
        }else{
            props.history.push({
                pathname: getSearchPath(),
                search: '',
                state: {
                    isPublic: false,
                    folderId: stores.iSearchStore.params ? stores.iSearchStore.params.folderId : undefined,
                    page: 1,
                    keyword: stores.iSearchStore.params?.keyword ? commonUtil.copyObj(stores.iSearchStore.params.keyword) : [],
                    conditions: stores.iSearchStore.params ? commonUtil.copyObj(stores.iSearchStore.params.conditions) : [],
                    facetConditions: stores.iSearchStore.params ? commonUtil.copyObj(stores.iSearchStore.params.facetConditions) : [],
                    searchFaceBase64: base64
                }
            })
        }
    }, [])

    if (!stores.configStore.baseConfig.showHeaderSearchInput){
        return null;
    }
    const keyword = stores.iSearchStore.searchKeyword1;
    return <div className={'search-box'}>
        <MmsSearchInput keyword={keyword} keywordQuery={keywordQuery}
            onSelectImage={onSelectImage} history={true}
            placeholder={l('cc.keywordSeachPL', '标题，标签，全文关键词')}
            searchBtn={[<i className="fa fa-search" key={1}></i>,
            <span key={2}>{'搜索'.l('com.search')}</span>]}
        />
    </div>
})
export default withRouter(SearchBox)