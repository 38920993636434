import {observable, action} from 'mobx'
import {IUser} from "../types/userTypes";
import BaseStore from "./base/baseStore";
import {Stores} from "./index";
import personalCenterTypes from "../types/personalCenterTypes";

/**
 * 用户相关Store
 */
export default class UserStore extends BaseStore<Stores> {
    @observable
    public currentUser?: IUser


    @action
    public setCurrentUser(user?: IUser): void {
        this.currentUser = user;
        (window as any).nxt.user = {
            current: user
        }
    }
    @action
    public updateCurrentUser(userInfo: personalCenterTypes.IChangeUserInfo): void {
        if(this.currentUser){
            this.currentUser.nickName=userInfo.nickName
            this.currentUser.avatarUrl=userInfo.avatarUrl
        }
            
    }
}
