import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';
import { observable, action, runInAction } from 'mobx';
import iEntityTypes from 'src/types/iEntityTypes';
import iEntityApi from 'src/apis/iEntityApi';
import { message } from 'antd';
import IMetadataOpeStore, { ICustomControl } from 'mam-metadata-react/dist/store/metadataOpeStore';
import LeadingRole from 'src/pages/entity/modules/rightTabContent/baseInfo/tvSeriesInfo/leadingRole';
import EntityStore from '../entityStore';


export default class TvSeriesStore extends BaseStore<Stores> implements IMetadataOpeStore{
    public parentStore: EntityStore;
    @observable
    public tvMovieRes?: iEntityTypes.ITvMovieInfoRes;
    @observable
    public isExpand: boolean = false;
    /** 自定义组件 */
    public customControls?: ICustomControl[]

    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;

        this.setCustomControls([
            {
                fieldPath: '',
                fieldName: 'leadingRole',
                control: LeadingRole
            }
        ])
    }

    @action
    public getTvMovieInfo(name: string){
        iEntityApi.getTvMovieInfo(name).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.tvMovieRes = res.data.data;
                }
            })
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        })
    }

    @action
    public setIsExpand(isExpand: boolean){
        this.isExpand = isExpand;
    }

    public setCustomControls(customControls?: ICustomControl[]){
        this.customControls = customControls;
    }
}
