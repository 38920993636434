import BaseStore from 'src/stores/base/baseStore';
import { Stores } from "../../index";
import { SeriesInfoStore } from 'mam-biz-components'
import { action } from 'mobx';
export default class EntityOfSeriesStore extends BaseStore<Stores> {
    public contentId: string = '7f74977e1b8e45038f8b308d3da0d976'
    public seriesInfoStore: SeriesInfoStore<Stores>;

    public constructor(stores: Stores) {
        super(stores);
        this.seriesInfoStore = new SeriesInfoStore(stores, this);
    }
    @action
    public setContentId(contentId: string) {
        this.contentId = contentId
    }

    public getContentId() {
        return this.contentId
    }
}   