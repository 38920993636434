import ExtendStore from 'src/stores/base/extendStore';
import EntityStore from '../entityStore';
import { observable, computed, action, runInAction } from 'mobx';
import { Stores } from 'src/stores';
import entityTypes from 'src/types/entityTypes';
import entityApi from 'src/apis/entityApi';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { message } from 'antd';

export default class KeyframeStore extends ExtendStore {
    public parentStore: EntityStore;
    @observable
    public keyframes: entityTypes.IMarKeyframe[]
    /** 当前正在编辑的标记点 */
    @observable
    public currentKeyframe: entityTypes.IMarKeyframe | undefined;
    @observable
    public isReqKeyframe: boolean = false;
    @observable
    public currentKeyframes: any = {}
    
    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    /** 获取按入出点过滤的标记点 */
    @computed
    public get filterKeyframes(): entityTypes.IMarKeyframe[] | undefined{
        if (!this.keyframes){
            return undefined;
        }
        const currentMeta = this.parentStore.contentIndexStore.currentMeta;
        if (!currentMeta){
            return this.keyframes;
        }
        const inpoint = currentMeta.inpoint
        const outpoint = currentMeta.outpoint
        return this.keyframes.filter((keyframe)=>{
            let l100ns = keyframe.inpoint;
            return l100ns >= inpoint && l100ns <= outpoint;
        })
    }

    @action
    public getKeyframes(): Promise<entityTypes.IMarKeyframe[]>{
        return new Promise((resolve, reject)=>{
            const entity = this.parentStore.entity;
            this.isReqKeyframe = true;
            entityApi.getKeyframes(entity.contentId).then(res=>{
                runInAction(()=>{
                    if (res.data.success && res.data.data && res.data.data.data){
                        this.keyframes = res.data.data.data;
                        resolve(res.data.data.data);
                    }
                    else {
                        reject();
                    }
                })
            }).catch(res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
            }).finally(()=>{
                runInAction(()=>{
                    this.isReqKeyframe = false;
                })
            });
        })
    }

    @action
    public appendKeyframe(item: entityTypes.IMarKeyframe){
        if (this.keyframes){
            this.keyframes.push(item);
        }
    }

    @action
    public addCurrentKeyframe(inpoint:number,img:string){
        this.currentKeyframes[inpoint] = img
    }

    @action
    public setCurrentKeyframe(keyframe: entityTypes.IMarKeyframe | undefined) {
        this.currentKeyframe = keyframe;
    }

    /** 显示指定标记点界面 */
    @action
    public showKeyframeTab(keyframe: entityTypes.IMarKeyframe) {
        this.setCurrentKeyframe(keyframe);
        this.stores.entityStore.changeTopTab('keyframeMetadata')
    }
}