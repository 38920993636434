import ccTypes from "../../types/ccTypes";
import { action, observable, runInAction, computed } from "mobx";
import ccApi from "../../apis/ccApi";
import { Stores } from "../index";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ExtendStore from '../base/extendStore'
import _ from 'lodash'
import { message } from 'antd';
import { http, reqCatch } from "mam-core-react";
import { IResponse } from "mam-core-react/dist/modules/http";

export default class BasketStore extends ExtendStore {
    @observable
    public totalCount: number = 0;
    @observable
    public queryData?: ccTypes.IBasketItemRes;
    @observable
    public isAllChecked: boolean;
    @observable
    public request: ccTypes.IBasketItemsReqData
    @observable
    public requestState: boolean = false; //判断请求数据是否已经返回
    @observable
    public showModal: boolean = false;

    // // downloadStore
    // public downloadStore: DownloadStore;
    // public outstoreStore: OutstoreStore;
    // public createAlbumStore: CreateAlbumStore;
    constructor(stores: Stores) {
        super(stores)
        this.initDownloadStore(stores)
        this.initOutstoreStore(stores)
        this.initCreateDatasetStore(stores)
        this.request = {
            pageIndex: 1,
            pageSize: 50
        }
    }


    // 是否全选
    @action
    public handleChenckAllChange = (e: CheckboxChangeEvent) => {
        if (!this.queryData){
            return;
        }
        this.isAllChecked = e.target.checked
        this.queryData.data.forEach((item: ccTypes.IBasketItemData) => {
            item.selected = this.isAllChecked
        })
    }
    // 设置某一项的选中状态
    @action
    public handleCheckItemChange(index: number) {
        if (!this.queryData){
            return;
        }
        this.queryData.data[index].selected = this.queryData.data[index].selected ? !this.queryData.data[index].selected : true;
        this.checkIsAllChecked()
    }
    // 检查是否全选
    @action
    public checkIsAllChecked() {
        if (!this.queryData){
            return;
        }
        if (this.queryData.data.length === 0) {
            this.isAllChecked = false;
            return
        }
        this.isAllChecked = this.queryData.data.every(o => (o.selected))
    }

    @action
    public onChangePage = (pageIndex: number) => {
        this.request.pageIndex = pageIndex
        this.query()
    }

    @action
    public basketDeleteItems = (showMessage?: boolean, ignoreFragment?: boolean) => {
        if (!this.queryData){
            return Promise.reject();
        }
        let idArr: string[] = []
        this.queryData.data.forEach(item => {
            let isFragment = item.aiDataType !== undefined;
            if (isFragment) {
                if (ignoreFragment) {
                    // 可不移除片段
                    item.selected = false
                } else {
                    if (item.selected) {
                        idArr.push(item.id)
                    }
                }
            } else {
                if (item.selected) {
                    idArr.push(item.id)
                }
            }
        })
        return ccApi.basketDeleteItems(idArr).then((result) => {
            if (showMessage) {
                message.success('删除成功')
            }
        }).catch((err) => {
            if (showMessage) {
                message.error('删除失败')
            }
        });
    }

    @action
    public changeEnityType = (type: any) => {
        this.request.contentType = type
        this.query()
    }

    @action
    public openModal(){
        this.showModal = true;
    }

    @action
    public setShowModal(show: boolean){
        this.showModal = show;
    }

    @computed
    public get getSelectedItems(): ccTypes.IBasketItemData[] {
        return this.queryData && this.queryData.data ? this.queryData.data.filter(s => s.selected) : []
    }

    @computed
    public get getNotFragmentSelected(): ccTypes.IBasketItemData[] {
        return this.queryData && this.queryData.data ? this.queryData.data.filter(s => s.aiDataType !== undefined) : []
    }

    @action
    public getBasketCount(){
        ccApi.basketGetCount().then((res) => {
            runInAction(()=>{
                if (res.data.data){
                    this.totalCount = res.data.data;
                }
            })
        }).catch((res) => {
            if (res.data && res.data.error.title){
                message.error(res.data.error.title)
            }
            else {
                console.error(res);
            }
        });
    }

    public query = () => {
        ccApi.basketGetItems(this.request).then((result) => {
            runInAction(()=>{
                if (result.data.data){
                    this.queryData = result.data.data;
                    // 成都台积分定制，查询
                    if (this.stores.configStore.baseConfig.customEdition === 'chengdu' 
                        && this.queryData.data && this.queryData.data.length > 0){
                        http.post<IResponse<any>>(`/cd-customized/v1/score/check`, this.queryData.data.map(item=>item.contentId)).then(res=>{
                            if (res.data.data && res.data.data.checkResult){
                                res.data.data.checkResult.forEach((checkItem: any) => {
                                    runInAction(()=>{
                                        if (this.queryData && this.queryData.data){
                                            let item = _.find(this.queryData.data, { contentId: checkItem.contentId });
                                            if (item){
                                                item.cdscore = checkItem.cdscore;
                                            }
                                        }
                                    })
                                })
                            }
                        })
                    }
                }
                this.checkIsAllChecked()
            })
        }).catch(reqCatch);
    }
}

