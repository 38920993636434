import BaseStore from 'src/stores/base/baseStore';
import { observable, action, runInAction, computed } from 'mobx';
import ccTypes from 'src/types/ccTypes';
import _ from 'lodash';
import { Stores } from "../../../index";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import { message } from 'antd';
import EntityStore from '../../entityStore';
import iEntityApi from 'src/apis/iEntityApi';
import iEntityTypes from 'src/types/iEntityTypes';
import FaceProcessStore from './faceProcessStore';

export enum SmartMetadataType {
    story = 'story',
    scene = 'scene',
    shot = 'shot'//镜头层
}

/** 统计arr数组，0-n的元素当中，word出现的次数 */
function countString(arr: iEntityTypes.IWord[], word: string, n: number) {
    return arr.slice(0, n + 1).reduce((count, cur) => {
      if (cur.word === word) {
        count++;
      }
      return count;
    }, 0);
}

const DEFAULT_QUALITY_MIN = 0.5;
export default class SmartViewStore extends BaseStore<Stores> {
    /** 视频帧率 */
    public frameRate: number = 0
    @observable
    public selectedPerson: iEntityTypes.IFaceAidata | undefined
    @observable
    public selectedCaption: string

    /** 智能分析数据 */
    @observable
    public smartMetadatas: iEntityTypes.ISmartMetadatas;
    /** 关键帧以帧号为key的字典 */
    // @observable
    // public keyframeDict: { [key: number]: entityTypes.IKeyframeInfo; } = {}
    /** 智能人物列表，person.tsx顶部显示的那些头像列表 */
    @observable
    public smartPerson: iEntityTypes.IFaceAidata[] = []
    @observable
    public smartVoice?: iEntityTypes.ISpeechAidataRes
    @observable
    public smartSubtitle?: iEntityTypes.ISpeechAidataRes;
    /** 当前播放器播放到的某个词语，需要页面上高亮显示 */
    @observable
    public targetVoiceWordInfo?: {
        word: string
        index: number
    }
    // 包含此人物的视频
    @observable
    public containePerson: ccTypes.searchTypes.IFullSearchRes | undefined;
    @observable
    public selectedSegment: iEntityTypes.IFaceSequence | undefined;
    /** 是否正在加载人脸数据 */
    @observable
    public isLoadingFaces = true;
    @observable
    public isLoadingVoices = false;
    @observable
    public isLoadingSubtitles = false;

    /** 未知转已知需要的最小quality */
    @observable
    public qualityMin = DEFAULT_QUALITY_MIN;

    public subtitleLabels = ['全部', '对白字幕', '标题', '人名条', '通告文本', '其它'];
    @observable
    public selectedFilterSubtitleLabel: string = '全部'

    public parentStore: EntityStore;
    // 人脸加工
    public faceProcessStore: FaceProcessStore;

    private watchPlayerTmLock: boolean = false;

    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;
        this.faceProcessStore = new FaceProcessStore(stores, this)
    }

    //获取人脸信息
    @action
    public getFaces(inpoint?: number, outpoint?: number){
        return new Promise((resolve, reject)=>{
            this.isLoadingFaces = true;
            this.smartPerson = [];
            this.changePerson(undefined);
            this.setSelectedSegment(undefined);
            iEntityApi.getFaceAidata(this.getEntityStore().params.contentId, inpoint, outpoint).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.data.length > 0){
                        res.data.data.data = _.orderBy(res.data.data.data, ['isKnown'], ['desc']);
                        this.smartPerson = res.data.data.data;
                        this.isLoadingFaces = false;
                        resolve(undefined);
                    }
                    else {
                        this.isLoadingFaces = false;
                        resolve(undefined);
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                    reject();
                }
            });
        })
    }

    //获取语音信息
    @action
    public getVoices(page?: number, inpoint?: number, outpoint?: number, keyword?: string){
        return new Promise((resolve, reject)=>{
            if (this.isLoadingVoices){
                return;
            }
            this.isLoadingVoices = true;
            if (page){
                this.smartVoice = undefined;
            }
            let qPage = page || 1;
            if (this.smartVoice && this.smartVoice.pageIndex){
                qPage = this.smartVoice.pageIndex + 1;
                if (qPage > this.smartVoice.pageTotal){
                    return;
                }
            }
            iEntityApi.getSpeechAidata(this.getEntityStore().params.contentId, qPage, keyword ? 50 : 50, inpoint, outpoint, keyword).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.data.length > 0){
                        if (qPage > 1 && this.smartVoice){
                            this.smartVoice.data = this.smartVoice.data.concat(res.data.data.data);
                            this.smartVoice.pageIndex = res.data.data.pageIndex;
                            this.smartVoice.pageSize = res.data.data.pageSize;
                            this.smartVoice.pageTotal = res.data.data.pageTotal; 
                            this.smartVoice.recordTotal = res.data.data.recordTotal;
                        }
                        else{
                            this.smartVoice = res.data.data;
                        }
                        this.isLoadingVoices = false;
                        resolve(undefined);
                    }
                    else {
                        this.isLoadingVoices = false;
                        resolve(undefined);
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    //获取字幕信息
    @action
    public getSubtitles(page?: number, inpoint?: number, outpoint?: number, keyword?: string){
        return new Promise((resolve, reject)=>{
            if (this.isLoadingSubtitles){
                return;
            }
            this.isLoadingSubtitles = true;
            if (page){
                this.smartSubtitle = undefined;
            }
            let qPage = page || 1;
            if (this.smartSubtitle && this.smartSubtitle.pageIndex){
                qPage = this.smartSubtitle.pageIndex + 1;
                if (qPage > this.smartSubtitle.pageTotal){
                    return;
                }
            }
            let isContainsLocation: boolean | undefined = undefined;
            if (this.parentStore.entity && this.parentStore.entity.type === 'biz_sobey_picture'){
                isContainsLocation = true;
            }
            iEntityApi.getSubtitleAidata(this.getEntityStore().params.contentId, qPage, keyword ? 50 : 50, inpoint, outpoint, keyword, isContainsLocation).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.data.length > 0){
                        if (qPage > 1 && this.smartSubtitle){
                            this.smartSubtitle.data = this.smartSubtitle.data.concat(res.data.data.data);
                            this.smartSubtitle.pageIndex = res.data.data.pageIndex;
                            this.smartSubtitle.pageSize = res.data.data.pageSize;
                            this.smartSubtitle.pageTotal = res.data.data.pageTotal; 
                            this.smartSubtitle.recordTotal = res.data.data.recordTotal;
                        }
                        else{
                            this.smartSubtitle = res.data.data
                        }
                        this.isLoadingSubtitles = false;
                        resolve(undefined);
                    }
                    else {
                        this.isLoadingSubtitles = false;
                        resolve(undefined);
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public changePerson(id: string | undefined, isQDesc?: boolean) {
        return new Promise((resolve, reject)=>{
            if (id === undefined) {
                this.selectedPerson = undefined;
                this.setSelectedSegment(undefined);
                resolve(undefined);
                return;
            }
            this.selectedPerson = this.smartPerson.find(s => s.id === id);
            if (isQDesc === undefined || isQDesc === true){
                this.getPersonDesc();
            }
            if (this.selectedPerson && this.parentStore.entity.type === 'biz_sobey_video') {
                iEntityApi.getFaceSequence(this.parentStore.params.contentId, this.selectedPerson.personId, this.selectedPerson.clusterId).then(res=>{
                    runInAction(()=>{
                        if (res.data.data && this.selectedPerson){
                            this.selectedPerson.totalTime = playerUtil.second2TC(res.data.data.faceDuration / Math.pow(10, 7), 'video', res.data.data.frameRate);
                            this.selectedPerson.appearanceRate = parseFloat((res.data.data.faceDuration / res.data.data.duration * 100).toFixed(2));
                            this.selectedPerson.segments = res.data.data.data;
        
                            if (this.selectedPerson.segments && this.selectedPerson.segments.length > 0) {
                                this.setSelectedSegment(this.selectedPerson.segments[0]);
                            }
                        }
                        resolve(undefined);
                    })
                }, (res)=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                    reject();
                });
            }
            else {
                resolve(undefined);
            }
        })
    }
    @action
    public changeCaption(id: string) {
        this.selectedCaption = id
    }
    // 获取包含此人物的视频
    @action
    public getContainPerson = (name: string) => {
        // let reqData: ccTypes.searchTypes.IFullSearchReq = {
        //     conditions: [{ field: "type_", value: ["video"] }],
        //     keyword: [name],
        //     pageIndex: 1,
        //     pageSize: 6,
        //     sortBys: [{ fieldName: "default", isDesc: false }]
        // }
        // ccApi.searchApi.fullSearch(reqData).then((result) => {
        //     runInAction(() => {
        //         this.containePerson = result.data.data
        //     })
        // }).catch((err) => {
        //     console.log(err)
        // });
    }

    public watchPlayer() {
        if (this.getEntityStore().viewer && this.getEntityStore().viewer.player) {
            this.getEntityStore().viewer.player.media.addEventListener('accurateTimeUpdate', (res: any) => {
                if (this.watchPlayerTmLock){
                    return;
                }
                this.watchPlayerTmLock = true;
                setTimeout(()=>{
                    this.getSelectedCaption()
                    this.watchPlayerTmLock = false;
                }, 50)
            })
        }
    }

    @computed
    public get filterLabelSubtitle(){
        let showLabel = false;
        let items = this.smartSubtitle ? this.smartSubtitle.data : [];
        if (items && items.length > 0 && items[0].label){
            showLabel = true;
        }
        return this.smartSubtitle ? this.smartSubtitle.data.filter(item=>this.selectedFilterSubtitleLabel === '全部' 
        || !showLabel 
        || (item.label && item.label === this.selectedFilterSubtitleLabel)) : []
    }

    public getSelectedCaption() {
        let seconds = this.getEntityStore().viewer.player.getCurrentTime()
        let n100s = parseInt(seconds.toFixed(7).replace('.', ''), 10);
        let next: iEntityTypes.ISpeechAidata | undefined;
        if (this.getSelectedTab() === 'voiceInfo') {
            next = this.smartVoice ? this.smartVoice.data.find(s => s.inpoint <= n100s && s.outpoint > n100s) : undefined
        } else if (this.getSelectedTab() === 'subtitleInfo') {
            next = this.filterLabelSubtitle.find(s => s.inpoint <= n100s && s.outpoint > n100s)
        }
        this.changeCaption(next ? next.id : '')
        // 匹配当前关键词
        if (next && next.id && next.words){
            let targetWordIndex = 1;
            let targetWord = next.words.find((word, index) => {
                if (word.inpoint <= n100s && word.outpoint > n100s){
                    if (next && next.id && next.words){
                        targetWordIndex = countString(next.words, word.word, index);
                    }
                    return true;
                }
                else {
                    return false;
                }
            })
            runInAction(()=>{
                if (targetWord && next && next.text){
                    this.targetVoiceWordInfo = {
                        word: targetWord.word,
                        index: targetWordIndex, // 如果有多个相同word，标识是第几个
                    };
                }
            })
        }
        else {
            runInAction(()=>{
                this.targetVoiceWordInfo = undefined;
            })
        }
    }

    @action
    public addSmartPerson(person: iEntityTypes.IFaceAidata) {
        this.smartPerson.push(person);
    }

    @action
    public updateSmartVoiceItemName(item: iEntityTypes.ISpeechAidata, name: string) {
        item.text = name;
    }

    @action
    public updateSmartSubtitleItemName(item: iEntityTypes.ISpeechAidata, name: string) {
        item.text = name;
    }
    @action
    public setSelectedSegment(seg: iEntityTypes.IFaceSequence | undefined) {
        this.selectedSegment = seg;
        if (this.selectedSegment) {
            if (this.getEntityStore().viewer && this.getEntityStore().viewer.player && this.selectedSegment.inpoint !== undefined) {
                this.getEntityStore().viewer.player.setCurrentTime(this.selectedSegment.inpoint / Math.pow(10, 7));
            }
        }
    }

    @action
    public setQualityMin(quality: number){
        this.qualityMin = quality;
    }

    @action
    public setSelectedFilterSubtitleLabel(label: string){
        this.selectedFilterSubtitleLabel = label;
    }

    private getPersonDesc() {
        if (this.selectedPerson && this.selectedPerson.isKnown) {
            iEntityApi.getTermDetail(this.selectedPerson.personId, this.selectedPerson.name, 'NAME').then((res) => {
                runInAction(() => {
                    if (this.selectedPerson && res.data.data) {
                        this.selectedPerson.desc = {
                            text: res.data.data[0].description,
                            tags: res.data.data[0].tag || []
                        }
                    }
                });
            })
        }
    }

    private getEntityStore() {
        return this.parentStore;
    }

    private getSelectedTab() {
        return this.getEntityStore().selectedTab.top;
    }
}
