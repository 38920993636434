import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import uploadTypes from "../types/uploadTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace uploadApi {
    // 请求各种类型对应的元数据
    export const getMetadataFields = (typeCode?: string): AxiosPromise<IResponse<uploadTypes.IMetadataFieldsRes>> => {
        return http.get<IResponse<uploadTypes.IMetadataFieldsRes>>('/scntm/v1/metadata/fields/upload' + (typeCode ? `/${typeCode}` : ''))
    }

    export const getUnfinishedTask = (param: uploadTypes.IUnfinishedTaskReq): AxiosPromise<IResponse<uploadTypes.ITask[]>> => {
        return http.get<IResponse<uploadTypes.ITask[]>>('/sflud/v1/upload/unfinished-task?relationId=' + param.relationId + '&relationContentType=' + param.relationContentType + '&targetType=' + param.targetType);
    }
    // 检测客户端是否存在
    export const checkClient = (baseUrl: string): any => {
        return http.get(baseUrl + 'request/getuploadfiles?user_token=&filetype=all');
    }

    // 请求图片包类型对应的元数据及单图的元数据
    export const getPicPackageMetadataField = (): AxiosPromise<IResponse<uploadTypes.IPicPackageMetadataFieldRes>> => {
        return http.get<IResponse<uploadTypes.IPicPackageMetadataFieldRes>>('/scntm/v1/old/upload/get-picturepackage-fields')
    }
    // 获取拓展元数据
    export const getExtendMetadataField = (entityType: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/scntm/v1/old/upload/extend?entityType=' + entityType)
    }

    export const uploadUrl = (param: uploadTypes.IUploadUrl): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/sflud/v1/upload/url', param)
    }

    export const getPicExtendFields = (): AxiosPromise<IResponse<uploadTypes.IPicExtendFieldsRes>> => {
        return http.get<IResponse<uploadTypes.IPicExtendFieldsRes>>('/scntm/v1/old/upload/extend?entityType=biz_sobey_picture')
    }

    export const checkDuplicateTask = (param: uploadTypes.ICheckDuplicateTaskReq): AxiosPromise<IResponse<uploadTypes.ICheckDuplicateTaskRes[]>> => {
        return http.post<IResponse<uploadTypes.ICheckDuplicateTaskRes[]>>('/sdcfs/v1/duplicatedContent/check', param)
    }

    export const callComplete = (taskId: string): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/sflud/v1/upload/multipart/complete?taskId=${taskId}`)
    }
}
export default uploadApi;
