import ExtendStore from "../base/extendStore"
import {action, computed, observable, runInAction} from "mobx";
import entityApi from "src/apis/entityApi";
import entityTypes from "src/types/entityTypes";
import ccTypes from "src/types/ccTypes";
import ccApi from "src/apis/ccApi";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import permissionUtil from "mam-common-utils/dist/modules/permissionUtil";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import mmsTypes from "src/types/mmsTypes";
import mmsApi from "src/apis/mmsApi";
import sharePageTypes from "src/types/sharePageTypes";
import sharePageApi from "src/apis/sharePageApi";
import { Stores } from "../index"
import _ from 'lodash';
import { message } from 'antd';

export default class SharePageStore extends ExtendStore {
    //多个素材的分享下载详情页
    @observable
    public listParams: sharePageTypes.IListParam
    //多个素材列表-选择
    @observable selectedRowKeys: number[] = []
    @observable selectedRows: sharePageTypes.IQueryResDataItem[] = []
    
    //多个素材列表-数据
    @observable
    public queryRes: sharePageTypes.IShareListContentDetail
    @observable
    public isReq: boolean = false

    //列表点击进入单个素材预览
    @observable
    public entityParams: sharePageTypes.IEntityParam
    @observable
    public viewer: any;
    @observable
    public entity: entityTypes.IEntity
    /** 视频帧率 */
    @observable
    public frameRate: number = 0
    /** 素材详情报错信息 */
    @observable
    public loadError?: string

    /** 图片包相关 */
    @observable
    public seletedPicPathItem: entityTypes.IPreviewFileItem;  //图片包选中的某一个图片Path对象
    

    constructor(stores: Stores) {
        super(stores)
    }

    @action
    public setEntityParams(entityParams: sharePageTypes.IEntityParam) {
        this.entityParams = entityParams;
    }
    @action
    public setListParams(listParams: sharePageTypes.IListParam) {
        this.listParams = listParams;
    }
    
    @action
    public setViewer(viewer: any) {
        this.viewer = viewer;
    }

    /*列表选中*/
    @action
    public setListSelected = (selectedRowKeys:number[],selectedRows:sharePageTypes.IQueryResDataItem[])=>{
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
    }
    /*列表清空选中*/
    @action
    public clearListSelected=()=>{
        this.selectedRowKeys = []
        this.selectedRows = []
    }

    @action
    public query(linkUrl:string,password:string) {
        return new Promise((resolve, reject) => {
            this.isReq = true;
            sharePageApi.queryShareList(linkUrl,password).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        if(!res.data.data.needPassword && res.data.data.shareListContentDetail){
                            this.queryRes = res.data.data.shareListContentDetail
                        }
                        resolve(res.data.data);
                    }
                    this.isReq = false;
                })
                
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                runInAction(()=>{
                    this.isReq = false;
                })
                reject();
            });
        });
    }

    @action
    public getBaseData(contentId: string,linkUrl:string,password:string): Promise<entityTypes.IEntity> {
        return new Promise((resolve, reject) => {
            //entityApi.getBaseData(contentId).then((res) => { //以前的老接口的素材详情
            sharePageApi.getShareEntity(contentId,linkUrl,password).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entity = res.data.data;
                        this.entity.contentId = contentId;
                        this.entity.contentId_ = contentId;
                        //构造paths
                        let previewfile = _.find(this.entity.fileGroups, {typeCode: 'previewfile'});
                        if (!previewfile){
                            previewfile = _.find(this.entity.fileGroups, {typeCode: 'sourcefile'});
                        }
                        if (previewfile && previewfile.fileItems && previewfile.fileItems.length > 0){
                            this.entity.paths = [];
                            previewfile.fileItems.forEach((fItem)=>{
                                this.entity.paths.push({
                                    fileGuid: fItem.fileGuid,
                                    filePath: fItem.filePath,
                                    fileSize: fItem.fileSize,
                                    frameRate: fItem.frameRate,
                                    duration: fItem.duration,
                                    imageWidth: fItem.imageWidth,
                                    imageHeight: fItem.imageHeight,
                                    sourceFileGuid: fItem.sourceFileGuid || '',//图片包特殊增加这个字段
                                });
                            })
                        }
                        if (!this.frameRate){
                            this.frameRate = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].frameRate : 0;
                        }
                        //处理时长
                        this.entity.duration = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].duration : 0;
                        if (this.entity.duration && this.frameRate){
                            this.entity.durationTc = playerUtil.l100Ns2Tc(this.entity.duration, this.entity.type, this.frameRate);
                        }
                        // this.mediaStore.initKeyframeDict(this.entity.fileGroups);
                        // this.reviewInfoStore.getReviewData(this.entity.contentId);

                        //如果是图片包，需要获取所有单图的元数据
                        if(this.entity.type === 'biz_sobey_picpackage'){
                            if(this.entity.paths && this.entity.paths[0] && this.entity.paths[0].fileGuid){ //初始化设定选中第一个单图选中
                                this.seletedPicPathItem = this.entity.paths[0]
                            }
                        }

                        resolve(this.entity);
                    }
                    else {
                        reject();
                    }
                });
            }, (res) => {
                runInAction(() => {
                    if (res.data.error && res.data.error.title) {
                        this.loadError = res.data.error.title;
                    }
                    reject();
                });
            })
        });
    }

    /** 图片包相关-设置当前选中的图片 */
    @action
    public setSeletedPicPathItem(item:entityTypes.IPreviewFileItem) {
        this.seletedPicPathItem = item
    }
}
