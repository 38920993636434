import { BaseStore } from 'mam-core-react';
import { Stores } from '..';
import { observable, action, runInAction } from 'mobx';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import { message } from 'antd';
import AlbumDetailStore from './albumDetailStore';
import { getCurrentCollectFields } from 'src/apis/albumDetailApi';
import bizTypes from 'src/types/bizTypes';
import { ICollectFieldItem } from 'src/types/albumDetailTypes';
import { commonUtil } from 'mam-common-utils';

export default class ModifyAutoCollectStore extends BaseStore<Stores> {
    @observable
    public selectedCollectFields?: bizTypes.createAlbum.IAutoCollectField[]

    public albumDetailStore: AlbumDetailStore;

    public constructor(stores: Stores, albumDetailStore: AlbumDetailStore) {
        super(stores);
        this.albumDetailStore = albumDetailStore;
    }

    @action
    public getCurrentCollectFields(){
        getCurrentCollectFields(this.albumDetailStore.params.contentId).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.selectedCollectFields = res.data.data.map((item)=>{
                        return {
                            alias: item.fieldName,
                            fieldName: item.field,
                            fieldPath: '',
                            minLength: 0,
                            maxLength: 0,
                            isShow: true,
                            isReadOnly: false,
                            isMustInput: false,
                            isMultiSelect: true,
                            isArray: false,
                            value: this.transformValue(item),
                            controlType: item.controlType,
                            controlData: item.controlData,
                            fixItemId: item.fixItemId
                        }
                    });
                    console.log(commonUtil.copyObj(this.selectedCollectFields))
                }
            })
        }).catch((res)=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    @action
    public setSelectedCollectFields(fields: bizTypes.createAlbum.IAutoCollectField[]){
        this.selectedCollectFields = fields;
    }

    private transformValue(item: ICollectFieldItem){
        if (item.controlType === 8){
            return item.value ? JSON.stringify(item.value) : ''
        }
        else {
            return item.value ? item.value[0] : ''
        }
    }
}