import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import {Stores} from "../index";

export default class DeleteStore extends BaseStore<Stores>{
    @observable
    public showDeleteModal:boolean = false
    @observable
    public progressid: string

    @action
    public openModal(progressid: string){
       this.progressid = progressid;
       this.showDeleteModal = true;
    }

    @action
    public hideModal(){
        this.showDeleteModal = false;
    }
}