import OutstoreStore from "./outstoreStore";
import {action, observable} from "mobx";
import mmsTypes from "../../../types/mmsTypes";
import _ from 'lodash';

export default class EntityTableStore{
    @observable
    public allTranscodeTemplateID?: string;
    private outstoreStore: OutstoreStore;
    constructor(outstoreStore: OutstoreStore){
        this.outstoreStore = outstoreStore;
    }

    @action
    public handleAllTranscodeTemplate = (transcodeTemplateID: string) => {
        this.setAllTranscodeTemplateID(transcodeTemplateID);
        this.outstoreStore.entitys.forEach((entityInfo)=>{
            if (_.findIndex(entityInfo.entity.templates, {'transcodeTemplateID': transcodeTemplateID}) > -1)
            {
                entityInfo.entity.transcodetemplate = transcodeTemplateID;
            }
        })
    }

    @action
    public handleEntityTranscodeTemplate = (entityInfo: mmsTypes.exportTypes.IGetEntityInfo, transcodeTemplateID: string) => {
        entityInfo.entity.transcodetemplate = transcodeTemplateID;
        this.setAllTranscodeTemplateID(undefined);
    }

    @action
    public setAllTranscodeTemplateID(id?: string){
        this.allTranscodeTemplateID = id;
    }

    @action
    public showOrHideRename = (entityInfo: mmsTypes.exportTypes.IGetEntityInfo, show: boolean)=>{
        entityInfo.entity.editMode = show;
    }
    @action
    public rename = (entityInfo: mmsTypes.exportTypes.IGetEntityInfo, value: string)=>{
        entityInfo.entity.title = value;
    }
}