import {stores} from "../../index";
import {IEntityType} from "../../../types/configTypes";
import {message} from "antd";
import uploadTypes from "../../../types/uploadTypes";
import {uploader} from "../../../components/upload/core/uploader";
import {runInAction} from "mobx";
import UploadStore from "./uploadStore";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";

export default class GeneralStore{
    private uploadStore: UploadStore;
    constructor(uploadStore: UploadStore) {
        this.uploadStore = uploadStore;
    }

    /** 组装图片包数据 */
    public handleFiles(options: uploadTypes.IUploadOptions, tasks: uploadTypes.ITask[]): Promise<any> {
        let resultP: Array<Promise<any>> = [];
        if (stores.configStore.config.config.enableUploadImagePreview){
            let picType = _.find(stores.configStore.config.entityTypes, { code: 'biz_sobey_picture' });
            tasks.forEach(task=>{
                if (task.entityType === 'biz_sobey_picture' && task.file){
                    resultP.push(this.getPreviewImage(task, options, picType, task.file as File));
                }
            })
        }

        return new Promise((resolve, reject)=>{
            Promise.all(resultP).then(()=>{
                resolve(undefined);
            });
        });
    }

    private getPreviewImage(task: uploadTypes.ITask, options: uploadTypes.IUploadOptions, typeInfo: IEntityType, file: File): Promise<any> {
        let retImage = typeInfo.keyframe ? typeInfo.keyframe.replace('~', '') : '';
        if (options.transferType === uploadTypes.TransferTypes.WEB) {
            //判断大小
            // if (retImage.length >= (2 * 1024 * 1024)) {
            //     return Promise.resolve(undefined);
            // }
            //判断扩展名
            let allowExt = ['png', 'gif', 'jpg', 'bmp', 'jpeg'];
            let ext = commonUtil.getExtension(file.name).toLowerCase();
            if (_.indexOf(allowExt, ext) === -1) {
                return Promise.resolve(undefined);
            }

            return new Promise((resolve, reject)=>{
                let reader = new FileReader();
                reader.onload = () => {
                    runInAction(()=>{
                        retImage = reader.result as string;
                        runInAction(()=>{
                            task.picImage = retImage;
                        })
                        resolve(undefined);
                    })
                }
                reader.readAsDataURL(file as File);
            });
        } else { //vtube
            return Promise.resolve(undefined);
        }
    }
}
