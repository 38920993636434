import ExtendStore from "../base/extendStore"
import { Stores } from '..';
import LeftNavStore from './leftNavStore';
import { observable, action } from 'mobx';
import contentMgTypes from 'src/types/contentMgTypes';
import TaskCenterStore from './subModules/taskCenter/taskCenterStore'
import MyCollectionStore from "./subModules/myCollection/myCollectionStore";
import TencentRevertJobSchedule from "./subModules/tencentRevertJobSchedule/tencentRevertJobSchedule";
export default class ContentMgStore extends ExtendStore {
    @observable
    public iframeInfo: contentMgTypes.IIframeInfo = {
        src: '',
        show: false
    };
    public leftNavStore: LeftNavStore;
    public taskCenterStore: TaskCenterStore;
    public myCollectionStore: MyCollectionStore;
    public tencentRevertJobSchedule: TencentRevertJobSchedule;

    public constructor(stores: Stores) {
        super(stores);
        this.leftNavStore = new LeftNavStore(stores, this);
        this.taskCenterStore = new TaskCenterStore(stores, this);
        this.myCollectionStore = new MyCollectionStore(stores, this)
        this.tencentRevertJobSchedule = new TencentRevertJobSchedule(stores)
    }

    @action
    public setIframeInfo(info: contentMgTypes.IIframeInfo){
        this.iframeInfo = info;
    }
}
