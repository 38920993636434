import ExtendStore from "../base/extendStore";
import { Stores } from './../index';
import { observable, action, runInAction } from 'mobx';
import entityApi from 'src/apis/entityApi';
import entityTypes from 'src/types/entityTypes';
import _ from 'lodash'

export default class AttachmentStore extends ExtendStore {
    /** 附件预览页面记录路由 */
    @observable
    public params: entityTypes.IAttachmentParam
    /** 查询附件详情报错信息 */
    @observable
    public loadError?: string

    /** 附件详细信息 */
    @observable
    public attachment: entityTypes.IAttachmentItem | undefined

    constructor(stores: Stores) {
        super(stores)
    }

    @action
    public setParams(params: entityTypes.IAttachmentParam) {
        this.params = params;
    }

    @action
    public getAttachmentInfo(contentId: string, fileGuid: string) {
        return new Promise((resolve, reject) => {
            entityApi.getAttachment(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data && res.data.data.length > 0) {
                        this.attachment = _.find(res.data.data,{fileGuid})              
                        resolve(true)
                    }else {
                        this.loadError = '附件无法浏览'.l('newEntity.attachmentLoadFailed')
                        reject()
                    }
                })
            }, (res) => {
                runInAction(() => {
                    if (res.data.error && res.data.error.title) {
                        this.loadError = res.data.error.title
                    }else {
                        this.loadError = '附件无法浏览'.l('newEntity.attachmentLoadFailed')
                    }
                    reject()
                })
            })
        })
    }

}
