import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { controlTypeDict } from './advancedSearchConfig';
import { reqCatch } from 'mam-core-react';
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';

interface IProps{
    record: any
    index: number
    handleControlType: (index: number, value: number)=>void
    handleRefResourceField: (index: number, treeCode: string)=>void
}

const ControlType: React.FC<IProps> = observer((props) => {
    const [cateTypes, setCateTypes] = React.useState<manageTypes.esSearchConfig.advancedSearchConfig.ICateTreeTypeItem[]>([])

    React.useEffect(()=>{
        if (props.record.controlType === 14){
            manageApi.esSearchConfig.advancedSearchConfig.getCateTreeTypes().then(res=>{
                if (res.data.data){
                    setCateTypes(res.data.data)
                }
            }).catch(reqCatch)
        }
    }, [props.record.controlType]);

    if (!controlTypeDict[props.record.indexType]){
        return null;
    }
    return <div style={{display: 'flex', gap: '8px'}}>
        <Select value={props.record.controlType} onChange={props.handleControlType.bind(window, props.index)} 
            style={{flex: 1}}>
            {
                controlTypeDict[props.record.indexType].map((typeItem: any, typeIdx: number)=>{
                    return <Select.Option value={typeItem.code} key={typeIdx}>{typeItem.name}</Select.Option>;
                })
            }
        </Select>
        {
            cateTypes.length > 0 &&
            <Select style={{flex: 1}} value={props.record.refResourceField}
                onChange={props.handleRefResourceField.bind(window, props.index)}>
                {
                    cateTypes.map((item, index)=>{
                        return <Select.Option value={item.treeTypeCode} key={index}>
                            {item.treeTypeName}
                        </Select.Option>;
                    })
                }
            </Select>
        }
    </div>
})
export default ControlType