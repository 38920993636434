import {action, observable} from "mobx";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import BaseStore from "../base/baseStore";
import {Stores} from "../index";
import commonUtil from "mam-common-utils/dist/modules/commonUtil";

export interface ISelectAllStore{
    selectAllStore: SelectAllStore;

    initSelectAllStore : (stores: Stores, listSyntax: string, itemSelectKey: string, currentStore?: BaseStore<Stores>) => void;
}

export default class SelectAllStore extends BaseStore<Stores>  {
    /** 是否全选 */
    @observable
    public allSelected: boolean = false;
    private listSyntax: string;
    private itemSelectKey: string;  // 每个子项选中状态key
    private currentStore?: BaseStore<Stores>;  //宿主store

    /**
     * @param listSyntax 传入获取list的一段语句，例：stores.shareStore.shareList.data
     * @param item是否选中的key值
     * @param currentStore 可选，传入宿主store，就可以通过this访问宿主store了
     */
    constructor(stores: Stores, listSyntax: string, itemSelectKey: string, currentStore?: BaseStore<Stores>){
        super(stores);
        this.listSyntax = listSyntax;
        this.itemSelectKey = itemSelectKey;
        this.currentStore = currentStore;
    }

    /** 检查是否需要全选 */
    @action
    public checkSelectAll(){
        let selectAll: boolean = true;
        this.getList().forEach((item) => {
            if (!item[this.itemSelectKey])
            {
                selectAll = false;
            }
        });
        if (selectAll)
        {
            this.allSelected = true;
        }
    }

    /**
     * 切换子项选中状态
     *
     * @param list
     * @param linesOrItem 行数数组，可以同时设置多个，为undefined表示设置全部，如果是item对象，则设置item对象
     */
    @action
    public switchListItemSelected(linesOrItem: number[] | any | undefined, selected: boolean){
        if (linesOrItem !== undefined)
        {
            // 行数组
            if (typeof(linesOrItem) === 'object' && linesOrItem instanceof Array)
            {
                linesOrItem.forEach((line) => {
                    this.getList()[line][this.itemSelectKey] = selected;
                });
            }
            //单行对象
            else if (typeof(linesOrItem) === 'object' && !(linesOrItem instanceof Array)){
                this.getList().forEach((item) => {
                    if (item === linesOrItem)
                    {
                        item[this.itemSelectKey] = selected;
                    }
                });
            }
            // 检查是否全选或不全选
            let selectAll = true;
            this.getList().forEach((item) => {
                if (!item[this.itemSelectKey])
                {
                    selectAll = false;
                }
            });
            if (selectAll)
            {
                this.switchAllSelected(true);
            }
            else
            {
                this.switchAllSelected(false);
            }
        }
        // 所有
        else
        {
            this.getList().forEach(item => {
                item[this.itemSelectKey] = selected;
            });
            this.switchAllSelected(selected);
        }
    }

    @action
    public handleListItemSelectChanged = (linesOrItem: number[] | any | undefined, e: CheckboxChangeEvent) => {
        this.switchListItemSelected(linesOrItem, e.target.checked);
    }

    @action
    public switchAllSelected(selected: boolean){
        this.allSelected = selected;
    }
    //翻页取消全选
    @action
    public cancelAllSelected(){
        this.allSelected = false;
    }

    @action
    public handleCheckAll = (e: CheckboxChangeEvent) => {
        this.switchAllSelected(e.target.checked);
        this.switchListItemSelected(undefined, e.target.checked)
    }

    private getList(): any[]{
        let names: any[] = ['user', 'config', 'stores'];
        let values: any[] = [this.stores.userStore.currentUser, this.stores.configStore.config, this.stores];
        let list = commonUtil.evalSyntax(this.listSyntax, names, values, this.currentStore);
        if (list instanceof Array)
        {
            return list;
        }
        else
        {
            return [];
        }
    }
}