import { runInAction } from 'mobx';
import BaseStore from "../base/baseStore";
import { action, observable } from 'mobx'
import { Stores } from "../index";
import uploadTypes from "src/types/uploadTypes";
import ccApi from "src/apis/ccApi";
import { message } from "antd";
import { treeUtil } from 'mam-common-utils';
import { EventDataNode } from 'antd/lib/tree';

export default class MocSelectFolderStore extends BaseStore<Stores>{
    @observable
    public tree: any = []
    @observable
    public showModal: boolean = false
    @observable
    public expandedKeys: string[] = []
    @observable
    public selectedPath: uploadTypes.IFolderTreeItem | undefined

    @action
    public setShowModal = (value: boolean) => {
        this.showModal = value
    }

    @action
    public onExpand = (expandedKeys: string[], expanded: any) => {
        this.expandedKeys = expandedKeys
    }

    @action
    public setTreeSelectedPath = (value:uploadTypes.IFolderTreeItem | undefined)=>{
        this.selectedPath = value
    }

    @action
    public init = () => {
        this.queryMainNode().then(data => {
            data.forEach(item => {
                item.showPath = item.showName;
            });
            runInAction(() => {
                this.tree = data
            })
        });
    }

    @action
    /** 查询根节点 */
    public queryMainNode = (): Promise<uploadTypes.IFolderTreeItem[]> => {
        return new Promise((resolve, reject) => {
            ccApi.sideContentApi.initCloudFolders().then((res) => {
                if (res.data.data) {
                    resolve(res.data.data)
                }
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
        })
    }
    @action
    /** 查询子节点 */
    public queryChildren = (parentId: string, parentShowPath: string): Promise<uploadTypes.IFolderTreeItem[]> => {
        return new Promise((resolve, reject) => {
            ccApi.sideContentApi.getFolders(parentId).then((res) => {
                if (res.data.data) {
                    resolve(res.data.data)
                }
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
        })
    }
    /** 用contentId在state.tree当中获取树数据 */
    @action
    public getTreeData = (contentId: string): uploadTypes.IFolderTreeItem | undefined => {
        if (!this.tree) {
            return undefined;
        }
        return treeUtil.getTreeDataRecursively<uploadTypes.IFolderTreeItem>(this.tree, 'contentId', contentId);
    }
    /** 合并树形数据 */
    @action
    public updateTree = (list: uploadTypes.IFolderTreeItem[], key: string, children: uploadTypes.IFolderTreeItem[]): uploadTypes.IFolderTreeItem[] => {
        return list.map(node => {
            if (node.contentId === key) {
                return {
                    ...node,
                    children,
                };
            } else if (node.children) {
                return {
                    ...node,
                    children: this.updateTree(node.children, key, children),
                };
            }
            return node;
        });
    }

    /** 异步加载子节点 */
    @action
    public onLoadData = (e: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let treeItem: uploadTypes.IFolderTreeItem | undefined = this.getTreeData(e.key as string)
            this.queryChildren(e.key as string, e.title as string).then(data => {
                runInAction(() => {
                    if (data && data.length > 0) {
                        data.map((o) => {
                            o.parentId = e.key as string
                            o.showPath = treeItem ? treeItem.showPath as string + '/' + o.showName : e.title as string + '/' + o.showName
                        })
                        this.tree = this.updateTree(this.tree, e.key as string, data)
                    } else {
                        this.tree = this.updateTree(this.tree, e.key as string, [])
                    }
                    resolve(true)
                })
            })
        })
    }
}