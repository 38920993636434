import ExtendStore from "../base/extendStore"
import { Stores } from '..';
import LeftNavStore from './leftNavStore';
import { observable, action } from 'mobx';
import contentMgTypes from 'src/types/contentMgTypes';
import MyMessageStore from "./subModules/myMessageStore";
export default class MessageMgStore extends ExtendStore {
    @observable
    public iframeInfo: contentMgTypes.IIframeInfo = {
        src: '',
        show: false
    };
    public leftNavStore: LeftNavStore;
    public myMessageStore: MyMessageStore

    public constructor(stores: Stores) {
        super(stores);
        this.leftNavStore = new LeftNavStore(stores, this);
        this.myMessageStore = new MyMessageStore(stores, this);
    }

    @action
    public setIframeInfo(info: contentMgTypes.IIframeInfo){
        this.iframeInfo = info;
    }
}
