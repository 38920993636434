import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../manageStore';
import { action, observable, runInAction } from 'mobx';
import advancedSearchTypes from 'src/types/advancedSearchTypes';
import advancedSearchApi from 'src/apis/advancedSearchApi';
import { message } from 'antd';

export default class AdvancedSearchSetStore extends ExtendStore {
    @observable
    public fieldsList: advancedSearchTypes.IFeildConfigItem[] = []
    public parentStore: ManageStore;

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public getFieldList(){
        advancedSearchApi.getAdvancedSearchConfig().then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.fieldsList = res.data.data;
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }

    @action
    public setFieldsList(fieldsList: advancedSearchTypes.IFeildConfigItem[]){
        this.fieldsList = fieldsList;
    }

    @action
    public updateFieldItem(index: number, item: advancedSearchTypes.IFeildConfigItem){
        this.fieldsList.splice(index, 1, item);
        this.fieldsList = this.fieldsList.concat([]);
    }

    @action
    public removeFieldItem(index: number){
        this.fieldsList.splice(index, 1);
        this.fieldsList = this.fieldsList.concat([]);
    }
}
