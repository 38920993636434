import ExtendStore from 'src/stores/base/extendStore';
import EntityStore from '../entityStore';
import { observable, action, runInAction } from 'mobx';
import entityTypes from 'src/types/entityTypes';
import { Stores } from 'src/stores';
import entityApi from 'src/apis/entityApi';
import { reqCatch } from 'mam-core-react';

/** 片段 */
export default class ScenesStore extends ExtendStore {
    public parentStore: EntityStore;
    @observable
    public scenes: entityTypes.IEntityScenes[]
    /** 当前正在编辑的标记段 */
    @observable
    public currentScene: entityTypes.IEntityScenes;
    
    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public getScenes() {
        return new Promise((resolve, reject)=>{
            let entity = this.parentStore.entity;
            entityApi.getSegmentData(entity.contentId).then((res) => {
                runInAction(()=>{
                    if (res.data.data) {
                        if(this.currentScene){
                            res.data.data.map((item:entityTypes.IEntityScenes)=>{
                                if(item.inpoint === this.currentScene.inpoint && item.outpoint === this.currentScene.outpoint){
                                    item.keyframePath = this.currentScene.keyframePath
                                }
                                return item
                            })
                        }
                        this.scenes = res.data.data;
                    }
                    resolve(undefined);
                })
            }, ()=>{
                reject();
            }).catch(reqCatch)
        })
    }

    @action
    public setCurrentScene(scene: entityTypes.IEntityScenes) {
        this.currentScene = scene;
    }

    /**
     * 显示新增标记段界面
     */
    @action
    public showCreateScene(data: any) {
        let clip: entityTypes.IEntityScenes;

        // if (this.stores.entityStore.entity.type === 'video') {
        clip = {
            keyframePath: data.img,
            inpoint: Math.round(data.trimin * 10000000),
            outpoint: Math.round(data.trimout * 10000000),
            isNew: true
        }
        clip.duration = clip.outpoint - clip.inpoint;
        // } else {
        //     clip = {
        //         inpoint: Math.round(data.trimin * 10000000),
        //         outpoint: Math.round(data.trimout * 10000000),
        //         isNew: true
        //     }
        //     clip.duration = clip.outpoint - clip.inpoint;
        // }
        this.showSceneTab(clip);
    }

    /** 显示指定标记段界面 */
    @action
    public showSceneTab(clip: entityTypes.IEntityScenes) {
        this.setCurrentScene(clip);
        this.stores.entityStore.changeTopTab('clipMetadata')
    }

    @action
    public onCurrentSceneDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.currentScene.description = e.target.value;
    }
}