import { observable, action, runInAction } from 'mobx';
import { BaseStore, reqCatch } from 'mam-core-react';
import { Stores } from '..';
import { IBaseEntity } from 'src/types/commonTypes';
import uploadApi from 'src/apis/uploadApi';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import React from 'react';
import { MetadataForm } from 'mam-metadata-react';

export default class CreateDatasetStore extends BaseStore<Stores>{
    /** 创建资料集弹框 */
    @observable
    public showCreateDataset: boolean
    @observable
    public isCreating: boolean
    @observable
    public selectedItems: IBaseEntity[] = []
    @observable
    public fields: IFormItem[] = [];
    public form?: MetadataForm;

    @action
    public getDatasetFields(){
        uploadApi.getMetadataFields('biz_sobey_dataset').then(res=>{
            runInAction(()=>{
                if (res.data.data && res.data.data['biz_sobey_dataset'] 
                    && res.data.data['biz_sobey_dataset'].length > 0 && res.data.data['biz_sobey_dataset'][0].fields){
                    this.fields = res.data.data['biz_sobey_dataset'][0].fields;
                }
            })
        }).catch(reqCatch)
    }

    @action
    public setModalShow = (isShow: boolean) => {
        this.showCreateDataset = isShow
    }

    @action
    public setCreating = (isCreating: boolean) => {
        this.isCreating = isCreating
    }

    @action
    public open = (selectedItems: IBaseEntity[]) => {
        this.selectedItems = selectedItems
        this.isCreating = false
        this.setModalShow(true)
    }

    @action
    public setFields(fields: IFormItem[]){
        this.fields = fields;
    }

    public setForm(form: MetadataForm){
        this.form = form;
    }
}
