import BaseStore from "../base/baseStore";
import { action, observable, runInAction } from "mobx";
import {Stores} from "../index";
import ccApi from "src/apis/ccApi";
import ccTypes from "src/types/ccTypes";
import _ from 'lodash';
import { message } from 'antd';

export default class JoinGroupStore extends BaseStore<Stores>{
     /** 加入组图弹框 */
     @observable
     public showModal: boolean = false
     @observable
     public joinEntityContentIds: string[] | undefined
     @observable
     public isCreating: boolean
     @observable
     public keyword: string[] = []
     @observable
     public page: number = 1
     @observable
     public size: number = 20
     @observable
     public type: string = '0'  //默认选择全部类型
     @observable
     public searchCondition: any = []
     @observable
     public params: any
     @observable
     public selectItems: any = []
     @observable
     public groupItem: any
     @observable
     public fullSearchData: ccTypes.searchTypes.IFullSearchRes
     @observable
     public defaultKeyframe: string

     constructor(stores: Stores) {
        super(stores)
    }

    
    @action
    public setJoinEntityContentIds(contentIds:string[] | undefined){
        this.joinEntityContentIds = contentIds
    }

    @action
    public setSelectGroupItem(item:any){
        this.groupItem = item
    }
 
     @action
     public setShowModal = (isShow: boolean) => {
         this.showModal = isShow
     }
 
     @action
     public setCreating = (isCreating: boolean) => {
         this.isCreating = isCreating
     }
     // 修改选中tab
     @action
     public changeSearchCondition = (condition: any) => {
         this.searchCondition = []
         this.searchCondition.push(condition)
     }
     // 修改选中状态
     @action
     public changeSelectItem = (index: number, selected: boolean) => {
         this.fullSearchData.data[index].selected = selected;
         this.getSelectedFile()
     }
     // 获取选中的素材
     @action
     public getSelectedFile () {
         this.selectItems = _.filter(this.fullSearchData.data, 'selected')
     }
     @action
     public setKeyword(keyword:string){
         this.keyword = []
         this.keyword.push(keyword)
     }
     @action
     public setParams(params?:any){
         if(params){
             this.params.conditions = params
         }else{
             //清空，初始化请求参数
            this.params = {
                conditions: [
                    {field: "type_", nexus: "and", value: ["biz_sobey_picpackage"]}
                ],
                facetConditions: [],
                highLight: true,
                keyword: this.keyword,
                pageIndex: this.page,
                resourceName: 'entity',
                pageSize: this.size,
                sortFields: [{
                    field: "createDate_",
                    isDesc: true,
                }],
            }
         }
     }
     @action
     public queryFullSearchData(page?:number){
         this.groupItem = undefined  //初始化，一旦查询则清空单个选中项
         if(page){
            this.params.pageIndex = page
         }
         return new Promise((resolve, reject) => {
             ccApi.searchApi.fullSearch(this.params).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        if (res.data.data.data) {
                            this.resolveQueryResult(res.data.data.data);
                        }
                        this.fullSearchData = res.data.data;
                    }
                })
                resolve(res.data.data);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
         })
     }
     @action
     public saveAddFile = () => {
        //  return new Promise((resolve, reject) => {
        //      programsetApi.addProgramsetFile(req).then((res) => {
        //          runInAction(() => {
        //              this.showModal = false
        //              if (res.data && res.data.data) {
        //                  message.success('添加成功！'.l('modal.addSuccess'))
        //              }else if(res.data && res.data.error){
        //                  this.isCreating = false
        //                  message.error('添加失败！'.l('modal.addSuccess'))
        //                  reject()
        //              }else {
        //                  reject()
        //              }
        //          })
        //      }, (res)=> {
        //          runInAction(() => {
        //              console.log(res)
        //              this.showModal = false
        //              this.isCreating = false
        //          })
        //      })
        //  })
    }
    
    //同ccStore中的对数据的处理
    protected resolveQueryResult(list: any[], callback?: (item: any) => void) {
        let pictures: any[] = [];
        list.forEach((item) => {
            item.contentId = item.contentId_;
            item.type = item.type_;
            item.name = item.name_;
            item.site = item.site_;

            if (item.type === 'picture') {
                pictures.push(item);
            }

            if (callback && typeof (callback) === 'function') {
                callback(item);
            }
        });

        //是否收藏
        ccApi.getFavoriteState(list.map((item)=>{
            return item.contentId;
        })).then((res2) => {
            runInAction(() => {
                if (res2.data.data){
                    list.forEach((item) => {
                        if (res2.data.data){
                            let idx = _.findIndex(res2.data.data, {contentId: item.contentId})
                            if (idx > -1) {
                                item.isFavorite = res2.data.data[idx].isFavorite;
                            }
                        }
                    });
                }
            });
        });
    }
}
