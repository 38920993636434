import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../manageStore';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash';
import { commonUtil } from "mam-common-utils";
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';
import { IFieldTreeItem } from 'mam-components-react/dist/modules/mamSelectField/mamSelectField';

export default class FacetSetStore extends ExtendStore {
    public parentStore: ManageStore;

    @observable
    public keyword: string = ""
    @observable
    public facetList: IFieldTreeItem[]
    @observable
    public isSearching: boolean = false

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public clear(){
        this.facetList = []
    }
    @action
    public setKeyword(keyword: string){
        this.keyword = keyword
    }
    @action
    public setFacetList(facetList: IFieldTreeItem[]){
        this.facetList = facetList
    }
    @action
    public removeFacetListItem(item: IFieldTreeItem){
        let copyData: IFieldTreeItem[] = commonUtil.copyObj(this.facetList);
        _.remove(copyData, {code: item.code})
        this.setFacetList(copyData)
    }

    /* 获取层面列表 */
    @action
    public getFacetList = (): Promise<any> => {
        this.isSearching = true
        return new Promise((resolve, reject) => {
            manageApi.facetSetApi.getFacetList().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.facetList = res.data.data.map((item)=>{
                            return {
                                name: item.fieldName,
                                code: item.fieldCode,
                            }
                        })
                        this.isSearching = false
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 更新层面列表 */
    @action
    public updateFacetList = (): Promise<any> => {
        let newFacetList: manageTypes.facetSetTypes.IFacetFieldItem[] = []
        if(this.facetList){
            newFacetList = this.facetList.map((item: IFieldTreeItem)=>{
                return {
                    fieldName: item.name,
                    fieldCode: item.code,
                }
            })
        }
        return new Promise((resolve, reject) => {
            manageApi.facetSetApi.updateFacetList(newFacetList).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
}
