import { Stores } from '../index';
import * as React from "react";
import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import { MetadataForm } from 'mam-metadata-react'
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import { reqCatch } from 'mam-core-react';
import bizTypes from 'src/types/bizTypes';
import bizApi from 'src/apis/bizApi';
import { message } from 'antd';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import { commonUtil } from 'mam-common-utils';

interface ICreateSeriesRes{
    contentId_: string
    type_: string
    name_: string
}

export default class CreateSeriesStore extends ExtendStore {
    @observable
    public showCreateSeriesModal:boolean = false
    @observable
    public selectedItems: any[] = []
    @observable
    public opeMode: 'create' | 'addRelation' = 'create';
    @observable
    public posterList: bizTypes.createSeries.IUploadFile[] = [];
    @observable
    public seriesListRes: iSearchTypes.IFullSearchDataRes | undefined;
    @observable
    public isReqSeriesList: boolean = false;
    @observable
    public addRelationSelectedSeries: any[] = []; // 关联已有剧集，选中的剧集
    @observable
    public showCreateSeries = true;
    @observable
    public isMultiSelect = true;
    @observable
    public isCreating = false;
    public createSeriesMetadataRef: React.RefObject<MetadataForm> = React.createRef()
    /** 设置剧集弹窗,点击确定按钮以后,执行的回调函数,这样设计,方便很多地方使用这个弹窗 */
    public okCallback?: ()=>void

    public constructor(stores: Stores){
        super(stores);
    }

    @action
    public setOpeMode(mode: 'create' | 'addRelation'){
        this.opeMode = mode;
    }
    @action
    public openModal(selectedItems:any[]){
        this.selectedItems = selectedItems;
        this.isCreating = false;
        this.posterList = [];
        if (this.selectedItems.length === 0){//未选择素材，自动选中创建专辑tab
            this.opeMode = 'create'
        }
        else {
            this.opeMode = 'addRelation'
        }
        this.setShowCreateSeriesModal(true)
    }
    @action
    public querySeriesList = (page?: number, keyword?: string)=>{
        this.isReqSeriesList = true;
        let param: iSearchTypes.IFullSearchDataReq = {
            pageIndex: page || 1,
            pageSize: 30,
            personId: '',
            keywords: keyword ? [keyword] : [],
            facetConditions: [{
                field: 'type_',
                value: ['biz_sobey_series']
            }],
            conditions: [],
            sortFields:[{
                field: 'createDate_',
                isDesc: true
            }]
        }
        this.seriesListRes = undefined;
        iSearchApi.queryFullSearchData(param).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.seriesListRes = res.data.data;
                }
            })
        }).catch(reqCatch).finally(()=>{
            runInAction(()=>{
                this.isReqSeriesList = false;
            })
        })
    }
    @action
    public setShowCreateSeriesModal = (show:boolean) => {
        this.showCreateSeriesModal = show
    }
    @action
    public setPosterList(list: bizTypes.createSeries.IUploadFile[]){
        this.posterList = list;
    }
    @action
    public removePoster(item: bizTypes.createSeries.IUploadFile){
        _.remove(this.posterList, (o)=>o === item);
        this.posterList = this.posterList.concat([]);
    }

    /** 虚拟进度条 */
    @action
    public startVirtualProgress(){
        const minIncrement = 10; // 最小增加值
        const maxIncrement = 15; // 最大增加值
        let newPosterList = this.posterList.concat([])
        newPosterList.forEach((item) => {
            if (item.status !== 'uploading' || item.progress >= 90){
                return;
            }
            const increment = Math.floor(Math.random() * (maxIncrement - minIncrement + 1)) + minIncrement;
            item.progress += increment;
            // 虚拟到90%停止，等上传接口调用完成后再设置到100%
            if (item.progress > 90){
                item.progress = 90;
            }
        });
        this.posterList = newPosterList;
        setTimeout(()=>{
            if (_.findIndex(this.posterList, (poster)=>{
                if (poster.status === 'uploading' && poster.progress < 90){
                    return true;
                }
                return false;
            }) > -1){
                this.startVirtualProgress();
            }
        }, 500)
    }

    @action
    public uploadPoster(contentId: string, metadataItems: IFormItem[]){
        let newPosterList = this.posterList.concat([])
        _.forEach(newPosterList, (poster)=>{
            poster.status = 'uploading'
            poster.progress = 0;
        })
        this.posterList = newPosterList;
        this.startVirtualProgress();
        
        let allPromises: Array<Promise<any>> = [];
        for (let i = 0; i < this.posterList.length; i++){
            allPromises.push(new Promise((resolve, reject)=>{
                let formData = new FormData();
                formData.append('file', this.posterList[i].file)
                formData.append('type', 'biz_sobey_series')
                formData.append('fileType', 'Poster')
                let seriesName = _.find(metadataItems, {fieldName: 'name_'})
                if (seriesName){
                    formData.append('seriesName', seriesName.value as string)
                }
                formData.append('contentid', contentId)
                bizApi.createSeries.uploadPoster(formData).then(res=>{
                    runInAction(()=>{
                        if (res.data.data){
                            let newPosterList2 = this.posterList.concat([])
                            newPosterList2[i].progress = 100;
                            newPosterList2[i].status = 'success';
                            newPosterList2[i].doneFileUrl = res.data.data;
                            this.posterList = newPosterList2;
                            resolve(undefined);
                        }
                    })
                }, (res)=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    runInAction(()=>{
                        let newPosterList2 = this.posterList.concat([])
                        newPosterList2[i].status = 'error';
                        this.posterList = newPosterList2;
                    })
                    reject();
                })
            }))
        }
        return Promise.all(allPromises);
    }

    @action
    public createSeries(): Promise<ICreateSeriesRes>{
        return new Promise((resolve, reject)=>{
            if (!this.createSeriesMetadataRef.current){
                reject();
                return;
            }
            this.createSeriesMetadataRef.current.validateAll().then((ret) => {
                this.setIsCreating(true)
                let contentId = commonUtil.getUuid().replace(/\-/g, '');
                this.uploadPoster(contentId, ret.items).then(()=>{
                    const fileItems: bizTypes.createSeries.ICreateSeriesFilegroupFileItem[] = this.posterList 
                    && this.posterList.length > 0 
                    ? this.posterList.map(poster=>{
                        return {
                            fileGUID: commonUtil.getUuid().replace(/\-/g, ''),
                            filePath: poster.doneFileUrl || '',
                            fileState: 'ready'
                        }
                    }) : [];
                    if (this.stores.configStore.baseConfig.currentUser 
                        && this.stores.configStore.baseConfig.currentUser.organizations
                        && this.stores.configStore.baseConfig.currentUser.organizations.length > 0
                        && this.stores.configStore.baseConfig.currentUser.organizations[0].name !== '全部'){
                        ret.items.push({
                            alias: '',
                            fieldName: 'provider',
                            value: this.stores.configStore.baseConfig.currentUser.organizations[0].name
                        } as any)
                    }
                    let params: bizTypes.createSeries.ICreateSeriesReq = {
                        contentId,
                        entityResourceType: 'biz_sobey_series',
                        entityData: ret.items,
                        filegroups: fileItems.length > 0 ? [
                            {
                                groupName: 'keyframe_',
                                groupType: 'keyframe_',
                                fileItems: [fileItems[0]],
                                status: 'ready'
                            },
                            {
                                groupName: 'poster',
                                groupType: 'pic',
                                fileItems: fileItems,
                                status: 'ready'
                            }
                        ] : []
                    }
                    bizApi.createSeries.createSeries(params).then(res=>{
                        if (res.data.data){
                            message.success('创建系列成功！');
                            // 创建关系
                            let nameField = _.find(ret.items, {fieldName: 'name_'})
                            resolve({
                                contentId_: res.data.data,
                                type_: 'biz_sobey_series',
                                name_: nameField ? nameField.value as string : ''
                            })
                        }
                        else {
                            message.error('创建失败！')
                            reject();
                        }
                    }, res=>{
                        if (res.data.error && res.data.error.title){
                            message.error(res.data.error.title)
                        }
                        reject();
                    }).finally(()=>{
                        runInAction(()=>{
                            this.setIsCreating(false)
                        })
                    });
                }, ()=>{
                    this.setIsCreating(false)
                })
            }, (res:any)=>{
                message.error('表单验证失败！'.l('com.formValidateFailed'));
                reject();
            })
        })
    }

    @action
    public setSelectedItems(selectedItems:any[]){
        this.selectedItems = selectedItems;
    }
    @action
    public setAddRelationSelectedSeries(series: any[]){
        this.addRelationSelectedSeries = series;
        if (!this.seriesListRes){
            return;
        }
        this.seriesListRes.data = this.seriesListRes.data.concat([])
    }

    @action
    public setShowCreateSeries(show: boolean){
        this.showCreateSeries = show;
    }

    @action
    public setIsMultiSelect(multi: boolean){
        this.isMultiSelect = multi;
    }

    public setOkCallback(callback?: ()=>void){
        this.okCallback = callback;
    }

    @action
    public setIsCreating(creating: boolean){
        this.isCreating = creating;
    }
}