import * as React from 'react';
import {BaseComponent, http} from 'mam-core-react'
import {IResponse} from "mam-core-react/dist/modules/http";
import { AxiosPromise } from 'axios'
import {Avatar} from "antd";
import { UserOutlined, CaretUpOutlined } from '@ant-design/icons';
import { stores } from 'src/stores'
import { observer } from "mobx-react";

interface IProps{
    currentUser?: any
    configStore?: any
}
@observer
class mrcUserSubNav extends BaseComponent<IProps, any> {
    constructor(props: IProps) {
        super(props)
    }
    
    public getRender() {
        return <ul className='user-sub-nav'>
            <li className="arraw">
                <CaretUpOutlined />
            </li>
            {
                this.props.currentUser &&
                <li className='user'>
                    <span className="user-avatar">
                        <Avatar shape="circle" size={25} icon={<UserOutlined />}
                        src={this.props.currentUser.avatarUrl ? this.props.currentUser.avatarUrl : ''}></Avatar>
                    </span>
                    <span className='nick-name'>{this.props.currentUser.nickName}</span>
                </li>
            }
            
             {
                this.props.currentUser &&
                <li className='item-setting'>
                    <a href={'/iPersonalCenter/myInformation'}>
                        <i className='fa fa-user'></i><span>{(window as any).l('main.userNavUserCenter','个人中心')}</span>
                    </a>
                </li>
            }
            {
                this.props.currentUser && this.props.currentUser.isManager &&
                <li className='item-setting'>
                    <a href={'/manage/allUser'} target="_blank">
                        <i className='fa fa-cog'></i><span>{(window as any).l('main.userNavManage','管理中心')}</span>
                    </a>
                </li>
            }
            <li className='item-exit'>
                <a onClick={this.signout}>
                    <i className='fa fa-power-off'></i><span>{(window as any).l('main.userNavSignOut','退出登录')}</span>
                </a>
            </li>
        </ul>
    }
    private doSignout = (): AxiosPromise<IResponse<any>> => {

        if (this.props.configStore.baseConfig.oAuthLogoutUrl){
            // http.get(this.props.configStore.baseConfig.oAuthLogoutUrl);
            window.open(this.props.configStore.baseConfig.oAuthLogoutUrl);
        }
        return http.get<IResponse<any>>('/scntm/v1/account/signout')
    }

    private signout = ()=>{

        this.doSignout().then(res=>{

            if (this.props.configStore.baseConfig.loginUrl){
                window.location.href = this.props.configStore.baseConfig.loginUrl;
            }
        });
    }

}
export default mrcUserSubNav