import * as React from 'react'
import BaseComponent from "../baseComponent/baseComponent";
import LoadingComponent from "../router/loadingComponent";
import Loadable from "react-loadable";
import AuthRoute from "../router/authRoute";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter, Switch, Route } from "react-router";
import { stores } from "../stores";
import DefaultRoute from "../router/defaultRoute";
import { MocHeader } from 'mam-components-react';
import SearchBox from './header/searchBox'
import MrcUserSubNav from './header/mrcUserSubNav'
import { commonUtil } from 'mam-common-utils';
import NotFound from './NotFound';

@observer
class Home extends BaseComponent<RouteComponentProps<any, any, any>, any> {
    constructor(props: RouteComponentProps<any, any, any>) {
        super(props)
    }

    public getRender() {
        const baseConfig = stores.configStore.baseConfig;
        this.setIcon(baseConfig.themeName)
        const qShowHeader = commonUtil.getUrlQueryParam('showHeader');
        return (
            <div className={'app-body' + (process.env.BUILD_TYPE === 'mrc' ? ' mrc' : '')
                + (process.env.BUILD_TYPE === 'mah' ? ' mah' : '')}>
                {
                    baseConfig.showHeader && (!qShowHeader || qShowHeader === 'true')
                    && !this.isPath('/entity') && !this.isPath('/taskSearch') && !this.isPath('/copyright/manage') && !this.isPath('/entityPart') &&
                    <MocHeader currentUser={stores.userStore.currentUser} configStore={stores.configStore}
                        navLeftExtra={process.env.BUILD_TYPE !== 'moc' ? <SearchBox></SearchBox> : undefined}
                        userSubNav={process.env.BUILD_TYPE === 'mrc' ?
                            <MrcUserSubNav currentUser={stores.userStore.currentUser} configStore={stores.configStore}
                            ></MrcUserSubNav> : undefined} onLoad={this.onHeaderLoad} />
                }
                <div className="app-main">
                    <Switch>
                        {
                            process.env.BUILD_TYPE === 'moc' &&
                            <AuthRoute path="/cc" name="cc" component={Loadable({
                                loader: () => import('./cc/cc'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            (process.env.BUILD_TYPE === 'mah' || process.env.BUILD_TYPE === 'mrc') &&
                            <AuthRoute path="/iSearch" name="folderSearch" component={Loadable({
                                loader: () => import('./folderSearch/folderSearch'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            (process.env.BUILD_TYPE === 'mah') &&
                            <AuthRoute path="/storeSearch/:type" name="storeSearch" component={Loadable({
                                loader: () => import('./storeSearch/storeSearch'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        <AuthRoute path="/entity/:contentId" name="entity" component={Loadable({
                            loader: () => import('./entity/entity'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/entityPart" name="entityPart" component={Loadable({
                            loader: () => import('./entityPart/entityPart'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        {
                            (process.env.BUILD_TYPE === 'mah' || process.env.BUILD_TYPE === 'mrc') &&
                            <AuthRoute path="/basket" name="basket" component={Loadable({
                                loader: () => import('./basket/basketMain'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            (process.env.BUILD_TYPE === 'mah' || process.env.BUILD_TYPE === 'mrc') &&
                            <AuthRoute path="/contentMg" name="contentMg" component={Loadable({
                                loader: () => import('./contentMg/contentMg'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            (process.env.BUILD_TYPE === 'mah' || process.env.BUILD_TYPE === 'mrc') &&
                            <AuthRoute path="/datasetDetails/:contentId" name="datasetDetails" component={Loadable({
                                loader: () => import('src/pages/datasetDetails/datasetDetails'),
                                loading: LoadingComponent
                            })} exact={true}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'moc' &&
                            <AuthRoute path="/webUpload" name="webUpload" component={Loadable({
                                loader: () => import('./webUpload/webUpload'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/copyright/index" name="copyright" component={Loadable({
                                loader: () => import('./copyright/copyright/copyright'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/copyright/manage" name="manage" component={Loadable({
                                loader: () => import('./copyright/copyrightManage/copyrightManage'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/taskSearch" name="taskSearch" component={Loadable({
                                loader: () => import('./taskSearch/taskSearch'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/albumDetail/:contentId" name="albumDetail" component={Loadable({
                                loader: () => import('./albumDetail/albumDetail'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/seriesDetail/:contentId" name="seriesDetail" component={Loadable({
                                loader: () => import('./seriesDetail/seriesDetail'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        <AuthRoute path="/attachment/:contentId/:site/:fileGuid" name="attachment" component={Loadable({
                            loader: () => import('./attachment/attachment'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/iFrame" name="iFrame" component={Loadable({
                            loader: () => import('./iFrame/iFrame'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/statistic" name="statistic" component={Loadable({
                            loader: () => import('./statistic/statistic'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/iPersonalCenter" name="iPersonalCenter" component={Loadable({
                            loader: () => import('./iPersonalCenter/iPersonalCenter'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        {
                            process.env.BUILD_TYPE === 'moc' &&
                            <AuthRoute path="/mocTopicDetail/:contentId" name="mocTopicDetail" component={Loadable({
                                loader: () => import('src/pages/mocTopicDetail/mocTopicDetail'),
                                loading: LoadingComponent
                            })} exact={true}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'moc' &&
                            <AuthRoute path="/newspaperDetail/:contentId" name="newspaperDetail" component={Loadable({
                                loader: () => import('src/pages/newspaperDetail/newspaperDetail'),
                                loading: LoadingComponent
                            })} exact={true}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/contentMgPlatform" name="contentMgPlatform" component={Loadable({
                                loader: () => import('src/pages/contentMgPlatform/contentMgPlatform'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        {
                            process.env.BUILD_TYPE === 'mah' &&
                            <AuthRoute path="/newspaper" name="newspaper" component={Loadable({
                                loader: () => import('src/pages/newspaper/newspaper'),
                                loading: LoadingComponent
                            })} exact={false}></AuthRoute>
                        }
                        <AuthRoute path="/messageMg" name="messageMg" component={Loadable({
                            loader: () => import('src/pages/messageMg/messageMg'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/imageSearch" name="imageSearch" component={Loadable({
                            loader: () => import('src/pages/imageSearch/imageSearch'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/aiGc" name="aiGc" component={Loadable({
                            loader: () => import('src/pages/aiGc/aiGc'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <DefaultRoute defaultUrl={baseConfig.siteHome}></DefaultRoute>
                    </Switch>
                </div>
            </div>
        )
    }

    private setIcon = (theme: string) => {
        const shortcut = $("link[rel='shortcut icon']");
        if (shortcut && theme) {
            switch (theme) {
                case 'education':
                    shortcut.attr('href', '/education.ico');
                    break;

                default:
                    break;
            }
        }
    }

    private onHeaderLoad = () => {
        const navScroll = $('.mam-top-nav .h-slide-box .nav-scroll');
        if (navScroll.length > 0) {
            // 一级导航大于7个，检索框区域去掉flex样式，让一级导航显示更多
            if (navScroll.children('.nav-item').length > 7) {
                commonUtil.setCustomStyle(`
                    .app-moc-header .app-header-content .search-box{
                        flex: none;
                        width: auto;
                    }
                `)
            }
        }
    }
}

export default withRouter(Home)
