import ExtendStore from "../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import navApi from 'src/apis/navApi';
import { message } from 'antd';
import ContentMgStore from './contentMgStore';
import { Stores } from '..';
import { INav } from "mam-components-react/dist/modules/mocHeader/topNav";

export default class LeftNavStore extends ExtendStore {
    @observable
    public navs: INav[]
    @observable
    public openKeys: string[]
    @observable
    public current?: string
    private contentMgStore: ContentMgStore

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores);
        this.contentMgStore = contentMgStore;
    }

    @action
    public queryNavs(){
        navApi.getNavigation(undefined, 'contentMg').then(res=>{
            if (res.data.data){
                let allPromise: Array<Promise<any>> = [];
                res.data.data.forEach((data)=>{
                    allPromise.push(new Promise((resolve, reject)=>{
                        if (data){
                            this.queryNavigation(data.id).then(children=>{
                                if (children && data){
                                    data.children = children;
                                }
                                resolve(undefined);
                            }, res1=>{
                                reject();
                            })
                        }
                        else{
                            reject();
                        }
                    }))
                })
                Promise.all(allPromise).then(()=>{
                    runInAction(()=>{
                        if (res.data.data){
                            this.navs = res.data.data;
                        }
                    })
                });
            }
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }

    @action
    public setOpenKeys(openKeys: string[]){
        this.openKeys = openKeys;
    }

    @action
    public setCurrent(current?: string){
        this.current = current;
    }

    private queryNavigation(parentId?: number): Promise<INav[]>{
        return new Promise((resolve, reject)=>{
            navApi.getNavigation(parentId).then(res=>{
                if (res.data.data){
                    resolve(res.data.data);
                }
                else {
                    resolve([]);
                }
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    } 
}
