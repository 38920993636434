import BaseStore from "../../base/baseStore";
import ccTypes from "../../../types/ccTypes";
import {action, observable, runInAction} from "mobx";
import ccApi from "../../../apis/ccApi";
import {Stores, stores} from "../../index";
import moment from "moment";
import _ from "lodash";

export default class SearchTreeStore extends BaseStore<Stores>{
    @observable
    public searchTree: ccTypes.sideContentTypes.ISearchTreeRes[];

    @action
    public querySearchTree = () : Promise<any> =>{
        return new Promise((resolve, reject)=>{
            ccApi.sideContentApi.getSearchTree().then((res)=>{
                runInAction(()=>{
                    if (res.data.success === true && res.data.data){
                        res.data.data.forEach((n) => {
                            n.showCon = true;
                        });
                        this.handleData(res.data.data);
                        this.searchTree = res.data.data;
                        resolve(undefined);
                    }
                    else
                    {
                        reject();
                    }
                })
            }, ()=>{
                reject();
            });
        })
    }

    @action
    public activeConditon = (activeItem?: ccTypes.searchTypes.ISearchCondition) => {
        if (!this.searchTree) {
            return;
        }
        let searchTree = this.searchTree;
        for (let x = 0; x < searchTree.length; x++) {
            if (_.isArray(searchTree[x].subItems) && searchTree[x].subItems.length > 0) {
                for (let y = 0; y < searchTree[x].subItems.length; y++) {
                    let item = searchTree[x].subItems[y];
                    if (activeItem == null) {
                        item.active = false;
                    } else {
                        let keys = activeItem.key ? activeItem.key.split('@') : '';
                        item.active = searchTree[x].id === parseInt(keys[0], 10) && item.showName === keys[1];
                    }
                }
            } else {
                if (activeItem == null) {
                    searchTree[x].active = false;
                } else {
                    searchTree[x].active = searchTree[x].id.toString() === activeItem.key;
                }
            }
        }
    }

    /** 是否显示子节点 */
    @action
    public switchShowCon(item: ccTypes.sideContentTypes.ISearchTreeRes){
        item.showCon = !item.showCon;
    }

    // 创建时间
    private setDateCondition(val: string): string | undefined {
        switch (val) {
            case '最近十二小时':
                return '[' + moment().subtract(12, 'hours').format('YYYY-MM-DD HH:mm:00') + ' TO ' + moment().format('YYYY-MM-DD HH:mm:00') + '}';
            case '最近一天':
                return '[' + moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:00') + ' TO ' + moment().format('YYYY-MM-DD HH:mm:00') + '}';
            case '过去一周':
                return '[' + moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:00') + ' TO ' + moment().format('YYYY-MM-DD HH:mm:00') + '}';
            case '过去一月':
                return '[' + moment().subtract(1, 'months').format('YYYY-MM-DD HH:mm:00') + ' TO ' + moment().format('YYYY-MM-DD HH:mm:00') + '}';
            case '过去半年':
                return '[' + moment().subtract(6, 'months').format('YYYY-MM-DD HH:mm:00') + ' TO ' + moment().format('YYYY-MM-DD HH:mm:00') + '}';
            case '过去一年':
                return '[' + moment().subtract(12, 'months').format('YYYY-MM-DD HH:mm:00') + ' TO ' + moment().format('YYYY-MM-DD HH:mm:00') + '}';
            default: return undefined;
        }
    }
    private handleAttr(conditions: ccTypes.sideContentTypes.ISearchReqCondition[]) {
        conditions.forEach((item) => {
            if (item.field === 'createDate_') {
                let dateStr = this.setDateCondition(item.value as string)
                if (dateStr)
                {
                    item.value = dateStr;
                }
            }
        });
    }
    // 处理返回数据
    private handleData(tree: ccTypes.sideContentTypes.ISearchTreeRes[]) {
        if (!_.isArray(tree)) {
            return [];
        }
        tree.forEach((node) => {
            this.handleAttr(node.conditions);
            if (_.isArray(node.subItems)) {
                node.subItems.forEach((item) => {
                    this.handleAttr(item.conditions);
                });
            }
        });
        return tree;
    }
}