import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import { Stores } from 'src/stores'
export default class TaskSearchStore extends ExtendStore {
    @observable
    public expandedRowKeys: string[] = []
    @observable
    public selectedRows: any = []
    @observable
    public selectedRowskeys: any = []

    public constructor(stores: Stores) {
        super(stores);
    }

    @action
    public setExpandedRowKeys(expandedRowKeys: string[]) {
        this.expandedRowKeys = expandedRowKeys
    }

    @action
    public setSelected(selectedRowKeys: any, selectedRows: any) {
        this.selectedRowskeys = selectedRowKeys
        this.selectedRows = selectedRows
    }
}