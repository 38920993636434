import {AxiosPromise} from "axios";
import {http} from "../utils/http";
import ccTypes from "src/types/ccTypes";
import entityTypes from "../types/entityTypes";
import {IResponse} from "mam-core-react/dist/modules/http";
import sharePageTypes from "src/types/sharePageTypes";

namespace sharePageApi {
    //获取分享的多个素材列表
    export const queryShareList = (linkUrl:string,password:string): AxiosPromise<IResponse<sharePageTypes.IQueryRes>> => {
        return http.get<IResponse<sharePageTypes.IQueryRes>>('/scntm/v1/share/detail/list?linkUrl='+linkUrl+'&password='+password);
    }
    //获取分享的单个素材详情
    export const getShareEntity = (contentId:string,linkUrl:string,password:string): AxiosPromise<IResponse<entityTypes.IEntity>> => {
        return http.get<IResponse<entityTypes.IEntity>>('/scntm/v1/share/entity/list?linkUrl='+linkUrl+'&contentId='+contentId+'&password='+password);
    }
    //直接入库
    export const putInStorage = (req:sharePageTypes.IPutInStorageReq):AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/share/import',req)
    }
}
export default sharePageApi
