import { IFaceRecoItem, IFaceRecoReq, IImageSearchParams, ISearchBySimilarityReq } from 'src/types/imageSearchTypes'
import { observable, action, runInAction } from 'mobx'
import { Stores } from '..'
import ExtendStore from '../base/extendStore'
import imageSearchApi from 'src/apis/imageSearchApi';
import { message } from 'antd';
import _ from 'lodash';
import iSearchTypes from 'src/types/iSearchTypes';

export default class ImageSearchStore extends ExtendStore {
    @observable
    public params: IImageSearchParams;
    @observable
    public faces: IFaceRecoItem[];
    @observable
    public selectedFace?: IFaceRecoItem;
    @observable
    public fullSearchData?: iSearchTypes.IFullSearchDataRes;
    @observable
    public currentTab: string = 'biz_sobey_video';
    /** 相似检索结果 */
    @observable
    public similar: any;
    @observable
    public isSearching: boolean = false;
    @observable
    public searchDateRangeStart: string | undefined;  // 开始日期
    @observable
    public searchDateRangeEnd: string | undefined;  // 结束日期
    @observable
    public showHighlight: boolean = false;  // 是否显示高光时刻
    @observable
    public currentType: string = 'picture';  // 当前检索类型
    @observable
    public currentVideoFile: any  // 当前检索片段

    constructor(stores: Stores) {
        super(stores)
    }

    @action
    public searchFace() {
        return new Promise((resolve, reject) => {
            this.isSearching = true;
            this.fullSearchData = undefined;
            this.faces = [];
            this.selectedFace = undefined;
            let request: IFaceRecoReq = {
                imgBase64: this.params.base64.replace(/data\:image\/([a-zA-Z]+)\;base64\,/, ''),
                name: ''
            }
            imageSearchApi.searchFace(request).then(res => {
                if (res.data.success === true && res.data.data) {
                    runInAction(() => {
                        if (res.data.data) {
                            this.faces = res.data.data.recognized.map(item=>{
                                return Object.assign({}, {
                                    ...item,
                                }, {isKnown: true})
                            }).concat(res.data.data.unidentification.map(item=>{
                                return Object.assign({}, {
                                    ...item,
                                }, {isKnown: false})
                            }));
                            this.faces = _.orderBy(this.faces, ['confidence'], ['desc'])
                            if (this.faces.length > 0) {
                                this.setSelectedFace(this.faces[0]);
                            }
                            else {
                                this.setSelectedFace(undefined);
                            }
                        }
                        else {
                            this.setSelectedFace(undefined);
                        }
                    })
                }
                else {
                    this.setSelectedFace(undefined);
                }
                resolve(undefined);
            }, (res) => {
                message.error(res.data.error?.title);
                runInAction(() => {
                    this.setSelectedFace(undefined);
                })
                reject();
            })
        })
    }

    @action
    public searchBySimilarity(page: number) {
        let exc = this.currentType === 'videoToVideo' ?
            /data\:video\/([a-zA-Z0-9]+)\;base64\,/
            : /data\:image\/([a-zA-Z]+)\;base64\,/;
        this.faces = [];
        this.fullSearchData = undefined;
        this.similar = undefined;
        this.isSearching = true;
        let req: ISearchBySimilarityReq
        if (this.currentType === 'videoToVideo') {
            this.videoToBase64().then((res:string) => {
                req = {
                    videoBase64: res.replace(exc, ''),
                    page_index: page,
                    page_size: 50
                }
                this.query(req)
            })
            // this.similar = {
            //     items: [
            //         {
            //             "distance": 0.967840975522995,
            //             "framerate": 25,
            //             "inpoint": 14460000000,
            //             "name": "ss.mp4",
            //             "path": "http://172.16.152.197:30068/proxy/USER_892035b7ecce47689754675575c27089/video/2023/2/16/low/2023/2/16/f464c5885d7d41c0837ef5b002ae14a8/%E3%80%8A%E6%96%B0%E9%97%BB%E8%81%94%E6%92%AD%E3%80%8B202007232100.mp4",
            //             "vector_id": 8373637037881924,
            //             contentid: 'f464c5885d7d41c0837ef5b002ae14a8'
            //         }
            //     ],
            //     "page_count": 1,
            //     "page_index": 1,
            //     "page_size": 10,
            //     "total_count": 1
            // }
            // this.isSearching = false
        } else {
            req = {
                imgBase64: this.params.base64.replace(exc, ''),
                page_index: page,
                page_size: 50
            }
            this.query(req)
        }
    }

    @action
    public query(req: ISearchBySimilarityReq) {
        return new Promise((resolve, reject) => {
            imageSearchApi.searchBySimilarity(req, this.currentType).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.similar = res.data.data;
                    }
                    this.isSearching = false;
                })
                resolve(true);
            }, (res) => {
                runInAction(() => {
                    this.isSearching = false;
                });
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        })
    }

    @action
    public setSelectedFace(face?: IFaceRecoItem) {
        this.selectedFace = face;
    }

    @action
    public setCurrentType(currentType: string) {
        this.currentType = currentType;
    }

    @action
    public setCurrentVideoFile(currentVideoFile: any) {
        this.currentVideoFile = currentVideoFile;
    }

    @action
    public setParams(params: IImageSearchParams) {
        this.params = params;
    }

    @action
    public setIsSearching(isSearching: boolean) {
        this.isSearching = isSearching;
    }

    @action
    public setFaces(faces: IFaceRecoItem[]) {
        this.faces = faces;
        if (this.faces.length > 0) {
            this.setSelectedFace(this.faces[0]);
        }
        else {
            this.setSelectedFace(undefined);
        }
    }

    @action
    public setSearchDateRangeStart(start?: string) {
        this.searchDateRangeStart = start;
    }

    @action
    public setSearchDateRangeEnd(end?: string) {
        this.searchDateRangeEnd = end;
    }
    @action
    public setShowHighlight(show: boolean) {
        this.showHighlight = show;
    }
    @action
    public videoToBase64() {
        let newThis = this
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            if (newThis.currentVideoFile) {
                reader.readAsDataURL(newThis.currentVideoFile.originFileObj)
                reader.addEventListener('load', function (this: FileReader, ev: ProgressEvent) {
                    if (this.result && typeof this.result === 'string') {
                        resolve(this.result)
                    }
                })
            }
        })
    }
}