import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../../manageStore';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash'
import manageTypes from "src/types/manageTypes"
import manageApi from "src/apis/manageApi";
export default class RoleSetStore extends ExtendStore {
    public parentStore: ManageStore;
    @observable
    public roleKeyword: string = ''; //角色列表关键字
    @observable
    public roleUserKeyword: string = ''; //角色下用户列表关键字
    @observable
    public addRoleModal: boolean = false; //是否显示添加角色弹框
    @observable
    public operationType: string = ''; // 操作按钮type
    @observable
    public roleList: manageTypes.roleSetTypes.IRoleItem[]; // 角色列表
    @observable
    public curSelectRole?: manageTypes.roleSetTypes.IRoleItem; // 当前选中角色
    @observable
    public assignUserModal: boolean = false; // 是否显示选择人员弹框
    @observable
    public allOrgs: manageTypes.userSetTypes.IOrgTreeItem[]; // 所有部门
    @observable
    public controlList: manageTypes.roleSetTypes.IControlRes[] = []; // 权限列表
    @observable
    public currControlList: manageTypes.roleSetTypes.IControlRes[] = []; // 当前选中权限列表
    @observable
    public currControlListKeys: string[] = []; // 当前选中权限列表
    @observable
    public treeList: manageTypes.userSetTypes.IOrgTreeItem[] = []; // 树形可选择的部门列表
    @observable
    public roleUsersList: manageTypes.userSetTypes.IGetUserListRes; // 角色下用户列表
    @observable
    public roleUserPage: number = 1; // 角色下用户列表
    @observable
    public permissionModal: boolean = false; // 是否显示权限模板操作弹框
    @observable
    public templateList: manageTypes.roleSetTypes.ITemplateItem[] = [];
    @observable
    public isComplete: boolean = false;
    @observable
    public isGettingOver: boolean = false;
    @observable
    public capacityModal: boolean = false // 是否显示新增存储单元弹框
    
    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }
    // 获取角色列表
    @action
    public getRoleList(keyword: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let params: manageTypes.userSetTypes.IGetRoleListReq = {
                pageIndex: 1,
                pageSize: 50,
                keyword: keyword,
                sortFields: []
            }
            manageApi.roleSetApi.getRoleList(params).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.roleList = res.data.data.data
                    }
                })
                resolve(true);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }
    // 新增角色
    @action
    public addRole(params: manageTypes.roleSetTypes.IAddRoleReq): Promise<any> {
        return new Promise((resolve, reject) => {
            manageApi.roleSetApi.addRole(params).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        message.success("添加成功！")
                        this.setAddRoleModal(false)
                        this.getRoleList(this.roleKeyword)
                    }
                })
                resolve(true);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }
    // 编辑角色
    @action
    public editRole(params: manageTypes.roleSetTypes.IAddRoleReq): Promise<any> {
        return new Promise((resolve, reject) => {
            manageApi.roleSetApi.editRole(params).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        message.success("修改成功！")
                        this.getRoleList(this.roleKeyword)
                    }
                })
                resolve(true);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }
    // 删除角色
    @action
    public deleteRole = (roleCode: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.roleSetApi.deleteRole([roleCode]).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    // 获取部门树
    @action
    public getOrgTreeNode = (organizationCode: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.getOrgTreeNode(organizationCode).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        resolve(res.data.data)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /* 获取角色权限 */
    @action
    public getRoleControl = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (this.curSelectRole){
                manageApi.roleSetApi.getRoleControl(this.curSelectRole.roleCode).then((res) => {
                    runInAction(() => {
                        if (res.data.success && res.data.data) { 
                            this.currControlList = res.data.data
                            this.currControlListKeys =  res.data.data.length > 0 ? _.map(res.data.data, 'resource_code') : []
                            resolve(true)
                        }
                    })
                }, (res) => {
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    reject()
                })
            }
        })
    }
    // 获取权限列表
    @action
    public getControlList = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.roleSetApi.getControlList().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.controlList = res.data.data
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    // 角色设置权限
    @action
    public setRoleControl = (req: string[]): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (this.curSelectRole){
                manageApi.roleSetApi.setRoleControl(this.curSelectRole.roleCode, req).then((res) => {
                    runInAction(() => {
                        if (res.data.success && res.data.data) {
                            resolve(true)
                        }
                    })
                }, (res) => {
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    reject()
                })
            }
        })
    }
    // 角色移除权限
    @action
    public deleteRoleControl = (req: string[]): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (this.curSelectRole){
                manageApi.roleSetApi.deleteRoleControl(this.curSelectRole.roleCode, req).then((res) => {
                    runInAction(() => {
                        if (res.data.success && res.data.data) {
                            this.getRoleControl()
                            resolve(true)
                        }
                    })
                }, (res) => {
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    reject()
                })
            }
        })
    }
    // 获取角色下用户列表
    @action
    public getRoleUsers = (): Promise<any> => {

            return new Promise((resolve, reject) => {
                if(this.curSelectRole){
                    let params: manageTypes.roleSetTypes.IRoleUsersReq = {
                        pageIndex: this.roleUserPage,
                        pageSize: 50,
                        roleCode: this.curSelectRole.roleCode,
                        keyword: this.roleUserKeyword
                    }
                    manageApi.roleSetApi.getRoleUsers(params).then((res) => {
                        runInAction(() => {
                            if (res.data.success && res.data.data) {
                                this.roleUsersList = res.data.data
                                resolve(true)
                            }
                        })
                    }, (res) => {
                        if (res.data.error && res.data.error.title){
                            message.error(res.data.error.title)
                        }
                        reject()
                    })
                }
            })
        
    }
    // 移除角色下用户
    @action
    public deleteRoleUsers = (code: string[]): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (this.curSelectRole){
                manageApi.roleSetApi.deleteUserRole(this.curSelectRole.roleCode, code).then((res) => {
                    runInAction(() => {
                        if (res.data.success && res.data.data) {
                            message.success("移除成功！")
                            this.getRoleUsers()
                            resolve(true)
                        }
                    })
                }, (res) => {
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    reject()
                })
            }
            
        })
    }
    // 角色下绑定用户
    @action
    public connectRoleUsers = (code: string[]): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (this.curSelectRole){
                manageApi.roleSetApi.connectRoleUser(this.curSelectRole.roleCode, code).then((res) => {
                    runInAction(() => {
                        if (res.data.success && res.data.data) {
                            message.success("绑定成功！")
                            this.setAssignUserModal(false)
                            this.getRoleUsers()
                            resolve(true)
                        }
                    })
                }, (res) => {
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    reject()
                })
            }
            
        })
    }
    // 获取部门下用户列表
    @action
    public getOrgsUsers = (orgs: string[]): Promise<any> => {
        let params: manageTypes.userSetTypes.IGetUserListReq = {
            pageIndex: 1,
            pageSize: 50,
            isIncludeSubuser: false,
            isOnlyRootUser: false,
            isContainsDisableUser: false,
            organizationCodes: orgs
        }
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.getOrgsUserList(params).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        resolve(res.data.data)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    // 获取权限模板列表
    @action
    public getTemplateList = (): Promise<any> =>{
        this.isComplete = false
        return new Promise((resolve, reject) => {
            manageApi.roleSetApi.getTemplateList().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.templateList = res.data.data
                        _.forEach(this.templateList, item => {
                            item.isEdit = false
                        })
                        this.getTemplateAttribute()
                        resolve(res.data.data)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    // 新增权限模板
    @action
    public addTemplate = (name: string): Promise<any> =>{
        this.isComplete = false
        return new Promise((resolve, reject) => {
            manageApi.roleSetApi.addTemplate(name).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.setNewTemplate(res.data.data)
                        this.isComplete = true
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    // 移除权限模板
    @action
    public deleteTemplate = (code: string): Promise<any> =>{
        return new Promise((resolve, reject) => {
            manageApi.roleSetApi.deleteTemplate(code).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.getTemplateList()
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    // 更新模板名称
    @action
    public updateTemplateName = (code: string, name: string): Promise<any> =>{
        return new Promise((resolve, reject) => {
            manageApi.roleSetApi.updateTemplateName(code, name).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        message.success('修改成功！')
                        this.getTemplateList()
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    // 获取权限模板属性值
    @action
    public getTemplateAttribute = (): Promise<any> =>{
        return new Promise((resolve, reject) => {
            if (this.curSelectRole){
                manageApi.roleSetApi.getCurrRoleAttribute(this.curSelectRole.roleCode).then((res) => {
                    runInAction(() => {
                        if (res.data.success && res.data.data) {
                            this.templateList.forEach(item=> {
                                res.data.data.forEach((m: manageTypes.roleSetTypes.IAttributeReq) => {
                                    if(item.templateCode === m.privilegeCode) {
                                        item.canDelete = m.canDelete || false
                                        item.canExecute = m.canExecute || false
                                        item.canRead = m.canRead || false
                                        item.canWrite = m.canWrite || false
                                    }
                                });
                            })
                            resolve(res.data.data)
                        }
                        this.isComplete = true
                    })
                }, (res) => {
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    reject()
                })
            }
            
        })
    }
    // 更新权限模板属性值
    @action
    public updateTemplateAttribute = (): Promise<any> =>{
        let params: manageTypes.roleSetTypes.IAttributeReq[] = []
        this.templateList.forEach(item => {
            let obj: any = {}
            obj.privilegeCode = item.templateCode
            obj.canRead = item.canRead || false
            obj.canWrite = item.canWrite || false
            obj.canExecute = item.canExecute || false
            obj.canDelete = item.canDelete || false
            params.push(obj)
        })
        return new Promise((resolve, reject) => {
            if (this.curSelectRole){
                manageApi.roleSetApi.setCurrRoleAttribute(this.curSelectRole.roleCode, params).then((res) => {
                    runInAction(() => {
                        if (res.data.success && res.data.data) {
                            this.getTemplateList()
                            resolve(true)
                        }
                    })
                }, (res) => {
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    reject()
                })
            }
            
        })
    }
    @action
    public setRoleKeyword(payLoad: string){
        this.roleKeyword = payLoad;
    }
    @action
    public setRoleUserKeyword(payLoad: string){
        this.roleUserKeyword = payLoad;
    }
    @action
    public setAddRoleModal(payLoad: boolean){
        this.addRoleModal = payLoad;
    }
    @action
    public setOperationType(payLoad: string){
        this.operationType = payLoad;
    }
    @action
    public setCurSelectRole(payLoad: any){
        this.curSelectRole = payLoad;
    }
    @action
    public setAssignUserModal(payLoad: boolean){
        this.assignUserModal = payLoad;
    }
    @action
    public setTreeList(payLoad: manageTypes.userSetTypes.IOrgTreeItem){
        this.treeList.push(payLoad);
    }
    @action
    public clearTreeList(){
        this.treeList = [];
    }
    @action
    public setCurrControlListKeys(payLoad: string[]){
        this.currControlListKeys = payLoad;
    }
    @action
    public setRoleUserPage(payLoad: number){
        this.roleUserPage = payLoad;
    }
    @action
    public setPermissionModal(payLoad: boolean) {
        this.permissionModal = payLoad
    }
    @action
    public setTemplateAttribute(payLoad: boolean, index: number, type: string) {
        this.templateList[index][type] = payLoad
    }
    @action
    public setNewTemplate(payLoad: manageTypes.roleSetTypes.ITemplateItem) {
        payLoad.canDelete = false
        payLoad.canExecute = false
        payLoad.canRead = false
        payLoad.canWrite = false
        this.templateList.push(payLoad)
    }
    @action
    public setCapacityModal(payload: boolean) {
        this.capacityModal = payload
    }
}
