import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../manageStore';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash';
import { commonUtil } from "mam-common-utils";
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';


export default class BusinessLogStore extends ExtendStore {
    public parentStore: ManageStore;
    @observable
    public businessLogList: manageTypes.businessLogTypes.businessLogRes
    @observable
    public businessLogListReq: manageTypes.businessLogTypes.businessLogListReq = {}
    @observable
    public clientAndOperateType: manageTypes.businessLogTypes.clientAndOperateTypeRes[] = []

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public getBusinessLogList = ()=>{
        return new Promise((resolve, reject)=> {
            manageApi.businessLogApi.getBusinessLogList(this.businessLogListReq).then((res)=>{
                runInAction(()=>{
                    if(res.data && res.data.data){
                        this.businessLogList = res.data.data
                    }
                })
            }).catch((res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }
    
    @action 
    public setBusinessLogListReq = (keyName: string, keyValue: any)=> {
        this.businessLogListReq[keyName] = keyValue
    }

    @action
    public getClientAndOperateType = ()=> {
        return new Promise((resolve, reject)=> {
            manageApi.businessLogApi.getClientAndOperateType().then((res)=> {
                runInAction(()=> {
                    if(res.data.success && res.data.data){
                        this.clientAndOperateType = res.data.data
                    }
                })
            }).catch(res=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }
    
}
