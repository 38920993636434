import ccApi from 'src/apis/ccApi';
import BaseStore from "../../base/baseStore";
import { action, observable, runInAction, computed } from "mobx";
import { Stores } from "../../index";
import mmsTypes from "../../../types/mmsTypes";
import mmsApi from "../../../apis/mmsApi";
import EntityTableProStore from "./entityTableProStore";
import * as React from "react";
import _ from 'lodash';
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import MetadataForm from "mam-metadata-react/dist/metadataForm";
import { message } from 'antd';
import { commonUtil } from 'mam-common-utils';

export interface IProcessStore {
    processStore: ProcessStore;
    initProcessStore: (stores: Stores) => void;
}

export interface IOutstoreItemType {
    contentId: string
    type?: string
    type_?: string
    inpoint?: number
    outpoint?: number
    frameRate?: number
    storyid?: string
    sceneid?: string
    shotid?: string
    canEdit?: boolean
    extensions?: {
        canEdit: boolean
    }
}

export default class ProcessStore extends BaseStore<Stores> {
    public entityTableStore: EntityTableProStore;
    @observable
    public visible: boolean;
    @observable
    public infoVisible: boolean;
    @observable
    public bindCodeVisible: boolean;
    @observable
    public infoData: string = '';
    @observable
    public items: IOutstoreItemType[];
    @observable
    public externalsystemList: mmsTypes.exportTypes.IGetExternalsystem[];
    @observable
    public transcodeTemplateList: mmsTypes.exportTypes.IGetTranscodeTemplate[];
    /** 当前选中的外部系统 */
    @observable
    public selectedSystem: mmsTypes.exportTypes.IGetExternalsystem | undefined;
    @observable
    public entitys: mmsTypes.exportTypes.IGetEntityInfo[];
    @observable
    public interactionFieldsForm: React.RefObject<MetadataForm>;
    @observable
    public errorModalEnable: boolean
    @observable
    public errorModalInfo: mmsTypes.IErrorTableInfo[] = []
    @observable
    public currentCopyrightInfo: mmsTypes.exportTypes.ICopyRightInfo;
    @observable
    public applyReason?: string
    @observable
    public columnsData: any[] = []
    @observable
    public currentIndex: number = -1

    constructor(stores: Stores) {
        super(stores);
        this.entityTableStore = new EntityTableProStore(this);
        this.interactionFieldsForm = React.createRef();
    }

    @action
    public setInfoData(item: string) {
        const params = commonUtil.copyObj(this.entitys)
        params[this.currentIndex].insertPointInfo = item
        this.entitys = params
    }

    @action
    public setProgramCode(item: string) {
        const params = commonUtil.copyObj(this.entitys)
        params[this.currentIndex].programCode = item
        this.entitys = params
    }

    @action
    public openModal(items: IOutstoreItemType[]) {
        this.items = items;
        this.visible = true;
        this.getExternalsystem();
        this.getTranscodeTemplate();
    }

    @action
    public setInFoModal(value: boolean) {
        this.infoVisible = value
    }

    @action
    public setBindCodeModal(value: boolean) {
        this.bindCodeVisible = value
    }

    @action
    public hideModal() {
        this.visible = false;
        //关闭弹窗，清空列表选中
        this.stores.searchStore.selectAllStore.switchAllSelected(false);
        this.stores.searchStore.selectAllStore.switchListItemSelected(undefined, false);
    }

    @action
    public setCurrentIndex(index: number) {
        this.currentIndex = index
    }

    @action
    public getExternalsystem() {
        mmsApi.exportModal.getExternalsystem().then(res => {
            runInAction(() => {
                if (res.data.data) {
                    this.externalsystemList = res.data.data.filter((item) => {
                        return item.exportConfigureInfo.enableExport;
                    });
                    if (this.externalsystemList && this.externalsystemList.length > 0) {
                        let sSelectedSystemId = localStorage.getItem('externalSystemID');
                        if (sSelectedSystemId) {
                            this.selectedSystem = this.externalsystemList.find(item => {
                                return item.externalSystemID === sSelectedSystemId
                            })
                        }
                        if (!this.selectedSystem) {
                            this.selectedSystem = this.externalsystemList[0];
                        }

                        this.externalsystemList.forEach((system) => {
                            if (system.exportConfigureInfo.exportSupportType) {
                                system.exportConfigureInfo.exportSupportTypeArr = JSON.parse(system.exportConfigureInfo.exportSupportType);
                            }
                            if (system.exportConfigureInfo.extendParameter)//扩展参数
                            {
                                system.exportConfigureInfo.extendParameterObj = {};
                                const extendParam = JSON.parse(system.exportConfigureInfo.extendParameter);
                                extendParam.forEach((param: any) => {
                                    system.exportConfigureInfo.extendParameterObj[param.key] = param.value;
                                })
                            }
                        })
                    }
                    else {
                        message.error('外部系统列表为空！请先配置外部系统'.l('modal.outstoreSysIsNullPlzSetting'));
                    }
                }
            })
        });
    }

    @action
    public getTranscodeTemplate() {
        mmsApi.exportModal.getTranscodeTemplate().then(res => {
            runInAction(() => {
                if (res.data.data) {
                    this.transcodeTemplateList = res.data.data;
                    if (this.transcodeTemplateList && this.transcodeTemplateList.length > 0) {
                        this.entityTableStore.setAllTranscodeTemplateID(this.transcodeTemplateList[0].transcodeTemplateID);
                    }
                    this.getEntityInfo();
                }
            })
        })
    }

    @action
    public getEntityInfo() {
        const request = {
            "contentIds": new Array<string>()
        }

        // 用于获取权限
        const itemDict = {}
        this.items.forEach(s => {
            s.canEdit = s.canEdit || (s.extensions && s.extensions.canEdit)
            itemDict[s.contentId] = s
            request.contentIds.push(s.contentId)
        })
        mmsApi.exportModal.getEntityInfo(request).then(res => {
            runInAction(() => {
                if (res.data.success) {
                    if (res.data.data && res.data.data.exportItems) {
                        res.data.data.exportItems.forEach((item, index) => {
                            const currentType = item.entityType;
                            const currentTemplates = this.loopMatchTemplate(currentType);

                            //获取多音轨信息
                            const videoGroup = _.find(item.groupInfos, { groupName: 'videogroup' });

                            // 同一个视频，多个不同片段同时出库，用上面方式获取originalItem有问题
                            let originalItem: IOutstoreItemType = this.items[index];

                            item.entity = {
                                contentId: item.contentId,
                                title: item.entityName,
                                type: item.entityType,
                                typeName: item.entityType,
                                templates: currentTemplates,
                                transcodetemplate: (currentTemplates[0]) ? currentTemplates[0].transcodeTemplateID : "",
                                oldTitle: item.entityName,
                                timeCode: item.timeCode,
                                frameRate: this.items[index].frameRate,
                                storyid: this.items[index].storyid,
                                sceneid: this.items[index].sceneid,
                                shotid: this.items[index].shotid,
                                partType: this.items[index].type,
                                canWrite: itemDict[item.contentId] && itemDict[item.contentId].canWrite
                            }
                            //如果是片段导出，包含type_字段，表示是检索页发起的出库
                            if ((!originalItem.type_ || originalItem.type_.indexOf('biz_sobey') !== 0)
                                && originalItem.inpoint !== undefined && originalItem.outpoint !== undefined && originalItem.inpoint !== -1 && originalItem.outpoint !== -1) {
                                item.entity.inpoint = originalItem.inpoint;
                                item.entity.outpoint = originalItem.outpoint;
                                item.entity.duration = originalItem.outpoint - originalItem.inpoint;
                            }
                            item.audioTracks = videoGroup ? videoGroup.fileItems.filter((fitem) => {
                                return fitem.track && fitem.track.startsWith('A');
                            }).map((fitem1) => {
                                return fitem1.track;
                            }) : undefined
                        })
                        this.entitys = res.data.data.exportItems
                    }

                }
            })
        })
    }

    @action
    public changeExternalSystem(externalSystemID: string) {
        localStorage.setItem('externalSystemID', externalSystemID);
        this.selectedSystem = _.find(this.externalsystemList, { 'externalSystemID': externalSystemID });
        //切换外部系统后，转码模板下拉重新生成
        if (this.entitys && this.entitys.length > 0) {
            this.entitys.forEach((item, index) => {
                const currentType = item.entityType;
                const currentTemplates = this.loopMatchTemplate(currentType);
                item.entity.templates = currentTemplates;
                item.entity.transcodetemplate = (currentTemplates[0]) ? currentTemplates[0].transcodeTemplateID : ""
            })
        }
    }

    /** 判断素材是否支持选择的外部系统 */
    @action
    public isEntitySupportSystem(entityInfo: mmsTypes.exportTypes.IGetEntityInfo) {
        let support = false;
        if (this.selectedSystem) {
            this.selectedSystem.exportConfigureInfo.exportSupportTypeArr?.forEach((type) => {
                if (entityInfo.entity.type === type.code) {
                    support = true;
                }
            })
        }
        return support;
    }

    @action
    public selectTracks(entityInfo: mmsTypes.exportTypes.IGetEntityInfo, vals: string[]) {
        entityInfo.selectedTracks = vals;
    }

    @action
    public showErrorModal(show: boolean) {
        this.errorModalEnable = show
    }

    @action
    public openErrorModal(errorList: Array<{ contentId: string, entityName: string, message: string }>) {
        const datas: Array<{ name: string, reason: string, contentId: string }> = [];
        errorList.forEach((s: { contentId: string, entityName: string, message: string }, index: number) => {
            const message = s.message.split(':').join('包含敏感信息"'.l('modal.outstoreSensitiveJoin')) + '"';
            let data = datas.find(o => o.contentId === s.contentId);
            if (data) {
                data.reason += '，' + message;
            } else {
                data = {
                    name: s.entityName,
                    reason: message,
                    contentId: s.contentId
                };
                datas.push(data);
            }
            if (index === errorList.length - 1) {
                data.reason += '。';
            }
        });

        if (datas.length > 1) {
            const errorInfos = [{
                tip: '以下素材检测到敏感信息，出库失败'.l('modal.outstoreSensitiveTitle'),
                tableTitle: '素材名'.l('entity.name'),
                tableReason: '敏感信息'.l('entity.sensitive'),
                datas
            }];
            this.errorModalInfo = errorInfos;
            this.showErrorModal(true)
        }
        return datas
    }

    @action
    public openCopyright = (item: mmsTypes.exportTypes.IGetEntityInfo) => {
        mmsApi.CopyrightModal.getFields(item.contentId).then(res => {
            runInAction(() => {
                if (res.data && res.data.data) {
                    this.currentCopyrightInfo = {
                        name: item.entityName,
                        contentId: item.contentId,
                        createDate: item.createDate,
                        createUser: item.createUser,
                        metadata: res.data.data
                    }
                }
            })
        })
    }

    @action
    public getColumns = () => {
        ccApi.processApi.getColumns().then(res => {
            runInAction(() => {
                if (res.data && res.data.data) {
                    this.columnsData = JSON.parse(res.data.data)
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }
    /** 申请原因 */
    @action
    public setApplyReason = (applyReason: string) => {
        this.applyReason = applyReason;
    }

    /** 
     * 获取多码率信息，拼接成字符串，分号分割
     * 
     * @param type id:获取id；name:获取name
     */
    public getMultiTransTemplateInfo = (type: string) => {
        let str = '';
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate) {
            const exportTranscodeTemplate = JSON.parse(this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate);
            exportTranscodeTemplate.forEach((template: any) => {
                let obj = _.find(this.transcodeTemplateList, { transcodeTemplateName: template.transcodeTemplateName });
                if (obj) {
                    if (type === 'id') {
                        str += obj.transcodeTemplateID + ';';
                    }
                    else {
                        str += obj.transcodeTemplateName + ';';
                    }
                }
            });
            if (str.length > 0) {
                str = str.substring(0, str.length - 1);
            }
        }
        return str;
    }

    private loopMatchTemplate(type: string): mmsTypes.exportTypes.IGetTranscodeTemplate[] {
        let configTransTmpList: mmsTypes.exportTypes.IGetTranscodeTemplate[] | undefined;//外部系统配置支持的转码模板
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo
            && this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate) {
            configTransTmpList = JSON.parse(this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate);
        }
        let ts: any[] = []
        for (let i = 0; i < this.transcodeTemplateList.length; i++) {
            for (let j = 0; j < this.transcodeTemplateList[i].supportType.length; j++) {
                if (this.transcodeTemplateList[i].supportType[j] === type) {
                    if (configTransTmpList && configTransTmpList.length > 0) {
                        if (_.findIndex(configTransTmpList, { transcodeTemplateID: this.transcodeTemplateList[i].transcodeTemplateID }) > -1) {
                            ts.push(this.transcodeTemplateList[i])
                            break
                        }
                    }
                }
            }
        }
        if (!ts || ts.length === 0) {//为空默认添加原格式
            ts = [
                {
                    transcodeTemplateID: '1',
                    transcodeTemplateName: '原格式',
                    supportType: [type]
                }
            ]
        }
        return ts
    }
}
