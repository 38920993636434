import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import shareDetailTypes from 'src/types/shareDetailTypes';
import {IResponse} from "mam-core-react/dist/modules/http";

namespace shareDetailApi{
    export const linkDetail = (id:string,extractCode:string) :AxiosPromise<IResponse<shareDetailTypes.ILinkDetailRes>> => {
        return http.get<IResponse<shareDetailTypes.ILinkDetailRes>>(`/share/link-detail?shortUrl=${id}&extractCode=${extractCode}`)
    }
}

export default shareDetailApi