import ExtendStore from 'src/stores/base/extendStore';
import EntityStore from '../entityStore';
import { observable, computed, action, runInAction } from 'mobx';
import { Stores } from 'src/stores';
import entityTypes from 'src/types/entityTypes';
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import entityApi from 'src/apis/entityApi';
import { reqCatch } from 'mam-core-react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { message } from 'antd';
import _ from 'lodash';

export default class PaperlayoutStore extends ExtendStore {
    public parentStore: EntityStore;
    //当前选中的版面
    @observable
    public currentPaperlayout: iSearchTypes.IFullSearchData | undefined;
    //当前选中版面的流媒体信息
    @observable
    public previewInfo: entityTypes.IPreviewInfo[] | undefined
    //版面列表
    @observable
    public paperlayoutList: iSearchTypes.IFullSearchData[] | undefined
    @observable
    public isReq: boolean = false;
    @observable
    public showPaperarticleList: boolean = true;
    
    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public getPaperlayoutList(parentId: string){
        this.isReq = true
        this.paperlayoutList = undefined
        this.parentStore.queryFullSearchData(parentId).then(res=>{
            runInAction(() => {
                this.paperlayoutList = _.orderBy(res, ['layoutnum'], ['asc']);
                if(this.parentStore.params?.paperlayoutId){
                    this.setCurrentPaperlayout(_.find(res, {contentId: this.parentStore.params.paperlayoutId}))
                }else if(!this.currentPaperlayout && this.paperlayoutList.length > 0){
                    this.setCurrentPaperlayout(this.paperlayoutList[0])
                }
            })
        }).finally(()=>{
            runInAction(() => {
                this.isReq = false
            })
        })
    }

    @action
    public setCurrentPaperlayout(currentPaperlayout: iSearchTypes.IFullSearchData | undefined) {
        if(currentPaperlayout && this.currentPaperlayout && this.currentPaperlayout.contentId === currentPaperlayout.contentId){
            return
        }
        this.currentPaperlayout = currentPaperlayout;
        if(currentPaperlayout){
            this.getPreviewInfo(currentPaperlayout.contentId).then(res=>{
                runInAction(() => {
                    this.previewInfo = res
                })
            })
            this.parentStore.paperarticleStore.getPaperarticleList(currentPaperlayout.contentId)
        }
        
    }

    @action
    public getPreviewInfo(contentId: string): Promise<entityTypes.IPreviewInfo[]>{
        return new Promise((resolve, reject) => {
            entityApi.getPreviewInfo(contentId).then(res=>{
                runInAction(() => {
                    if (res.data.data){
                        resolve(res.data.data)
                    }
                })
            }).catch(reqCatch)
        })
    }

    @action
    public setShowPaperarticleList(showPaperarticleList: boolean) {
        this.showPaperarticleList = showPaperarticleList
        
    }

    
    
}
