import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import advancedSearchTypes from 'src/types/advancedSearchTypes';
import advancedSearchApi from 'src/apis/advancedSearchApi';
import { message } from 'antd';
import SysmanageStore from '../sysmanageStore';
import sysmanageApi from 'src/apis/sysmanageApi';

export default class OptoolConfigurationStore extends ExtendStore {
    public parentStore: SysmanageStore;
    @observable
    public dataSource: any = []

    public constructor(stores: Stores, parentStore: SysmanageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setDataSource() {
        return new Promise((resolve,reject)=> {
            sysmanageApi.OptoolConfigurationApi.getOptoolList().then((res)=> {
                runInAction(()=> {
                    if(res.data.data && res.data.success){
                        this.dataSource = res.data.data
                    }
                })
            })
        })
        
    }
}
