import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import ccTypes from "../types/ccTypes";
import { IResponse } from "mam-core-react/dist/modules/http";
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import entityTypes from 'src/types/entityTypes';
import { stores } from "src/stores";
import iEntityTypes from "src/types/iEntityTypes";

namespace entityApi {
    export const addDownload = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/api/thirdparty/frame/download/task', contentId);
    }
    export const getBaseData = (contentId: string, keys?: string): AxiosPromise<IResponse<entityTypes.IEntity>> => {
        return http.get<IResponse<entityTypes.IEntity>>(`/scntm/v2/entity/base/${contentId}?needExtend=true&needEntityData=true` + (keys ? `&${keys}` : ''));
    }

    export const getEntityData = (contentId: string): AxiosPromise<IResponse<entityTypes.IEntityDataItem[]>> => {
        return http.get<IResponse<entityTypes.IEntityDataItem[]>>('/scntm/v1/entity/entitydata/' + contentId);
    }

    export const getIntelligentEntityData = (contentId: string): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.get<IResponse<IFormItem[]>>('/scntm/v1/entity/entitydata/intelligent/' + contentId);
    }

    export const getCatalog = (contentId: string, entityType: string, frameRate: number, programform: string): AxiosPromise<IResponse<entityTypes.ICatalog[]>> => {
        return http.get<IResponse<entityTypes.ICatalog[]>>('/entity/get-catalog?pathtype=1&entityType=' + entityType +
            '&contentId=' + contentId + '&frameRate=' + frameRate + '&programform=' + programform);
    }

    export const updateEntityData = (contentId: string, request: entityTypes.IUpdateEntityReq): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>('/scntm/v1/entity/entitydata/' + contentId, request);
    }

    export const addKeyframe = (contentId: string, request: entityTypes.IKeyframeReq): AxiosPromise<IResponse<entityTypes.IMarKeyframe>> => {
        return http.post<IResponse<entityTypes.IMarKeyframe>>(`/scntm/v1/entity/mark-keyframe/${contentId}`, request);
    }

    export const updateKeyframe = (contentId: string, request: entityTypes.IKeyframeReq): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/scntm/v1/entity/mark-keyframe/${contentId}`, request);
    }

    /** 后台接口返回的keyFrameNo为百纳秒 */
    export const getKeyframes = (contentId: string): AxiosPromise<IResponse<entityTypes.IGetMarKeyframeRes>> => {
        return http.get<IResponse<entityTypes.IGetMarKeyframeRes>>('/scntm/v1/entity/mark-keyframe/' + contentId);
    }

    export const deleteKeyframes = (contentId: string, req: entityTypes.IKeyframeReq) => {
        return http.delete(`/scntm/v1/entity/mark-keyframe/${contentId}?guids=${req.guid}`);
    }

    /** 获取关键帧元数据 */
    export const getKeyframeMetadata = (contentId: string, id: string): AxiosPromise<IResponse<entityTypes.IKeyframeMetadata>> => {
        return http.get<IResponse<entityTypes.IKeyframeMetadata>>(`/scntm/v1/entity/mark-keyframe/metadata/${contentId}/${id}`);
    }

    export const updateKeyframeMetadata = (contentId: string, id: string, keyframeMetadata: entityTypes.IKeyframeMetadata): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/scntm/v1/entity/mark-keyframe/metadata/${contentId}/${id}`, keyframeMetadata);
    }

    export const updateKeyframeExtendData = (contentId: string, id: string, data: any): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.patch<IResponse<IFormItem[]>>(`/scntm/v1/aidata/markkeyframe/extend-metadata/${contentId}/${id}`, data);
    }

    export const updateTag = (request: entityTypes.IUpdateTagReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-tag', request);
    }

    export const getHistory = (contentId: string): AxiosPromise<IResponse<entityTypes.IHistory[]>> => {
        return http.get<IResponse<entityTypes.IHistory[]>>('/entity/get-log-by-contentId?contentId=' + contentId + '&queryAdress=');
    }

    export const addClip = (request: entityTypes.IAddSceneReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/entity/snippet', request);
    }

    export const updateClip = (contentId: string, request: entityTypes.IUpdateSceneReq): AxiosPromise<IResponse<any>> => {
        return http.put<IResponse<any>>(`/scntm/v1/entity/snippet/${contentId}`, request);
    }

    export const getScenes = (contentId: string): AxiosPromise<IResponse<entityTypes.IEntityScenes[]>> => {
        return http.get<IResponse<entityTypes.IEntityScenes[]>>(`/scntm/v1/entity/snippet/${contentId}`);
    }

    export const deleteClip = (contentId: string, guid: string): AxiosPromise<IResponse<any>> => {
        return http.delete(`/scntm/v1/entity/snippet/${contentId}/${guid}`);
    }

    export const updateCatalog = (request: entityTypes.IUpdateCatalogReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-catalog', request);
    }

    export const getExternalsystemTemplate = (): AxiosPromise<IResponse<entityTypes.IExternalsystemTemplate[]>> => {
        return http.get<IResponse<entityTypes.IExternalsystemTemplate[]>>('/policy/get-externalsystem-template');
    }
    // 查询当前的封面
    export const getCoverpages = (contentId: string, site: string, fileType: string, frameRate: string): AxiosPromise<IResponse<any>> => {
        return http.get('/entity/get-frontcover?contentId=' + contentId + '&queryAdress=' + site + '&entityType=' + fileType + '&frameRate=' + frameRate)
    }
    // 上传封面的接口
    export const uploadCoverpage = (data: entityTypes.IUploadCoverpage): AxiosPromise<IResponse<any>> => {
        return http.post('/entity/add-frontcover', data)
    }
    // 更新封面的接口，设置某一项为封面
    export const updateCoverpage = (data: entityTypes.IUpdateCoverpage): AxiosPromise<IResponse<any>> => {
        return http.post('/entity/update-frontcover', data)
    }
    // 删除封面
    export const deleteCoverpage = (data: entityTypes.IUpdateCoverpage): AxiosPromise<IResponse<any>> => {
        return http.post('/entity/delete-frontcover', data)
    }

    // 片段导出
    export const exportSection = (data: entityTypes.IExportSectionReq): AxiosPromise<IResponse<any>> => {
        return http.post('/export/exporttask', data)
    }

    // 片段另存为:获取转换模板,保存格式
    // export const getTransCodeTemplate = (): AxiosPromise<IResponse<entityTypes.ITransCodeTemplateTemplate[]>> => {
    //     return http.get('/export/get-transcodetemplate')
    // }

    // 片段另存为:获取转换模板,保存格式
    export const getTransCodeTemplate = (): AxiosPromise<IResponse<entityTypes.ITransCodeTemplateTemplate[]>> => {
        return http.get('/smegw/v1/export/get-transcodetemplate')
    }

    // 验证素材的版权信息
    // 传参为素材的contentId数组
    export const validCopyrights = (data: string[]): AxiosPromise<IResponse<any>> => {
        return http.post('/copyright/valid-copyrights', data)
    }

    // // 视频片段另存为
    // export const videoSaveSection = (data: entityTypes.ISaveSectionReq): AxiosPromise<IResponse<any>> => {
    //     return http.post('/v1/export/savevideoclip', data)
    // }

    // 视频片段另存为
    export const videoSaveSection = (data: entityTypes.ISaveSectionReqNew): AxiosPromise<IResponse<any>> => {
        return http.post('/smegw/v1/export/savevideoclip', data)
    }

    /** 获取关联素材 */
    export const getLinkedEntity = (contentId: string): AxiosPromise<IResponse<entityTypes.ILinkedEntity[]>> => {
        return http.get<IResponse<entityTypes.ILinkedEntity[]>>('/scntm/v1/entity/relation/entity/' + contentId)
    }

    /** 获取流媒体 */
    export const getPreviewInfo = (contentId: string): AxiosPromise<IResponse<entityTypes.IPreviewInfo[]>> => {
        return http.get<IResponse<entityTypes.IPreviewInfo[]>>(`/scntm/v1/entity/preview-info/${contentId}`)
    }

    /** 获取人物描述 */
    export const getPersonDesc = (key: string): AxiosPromise<IResponse<string[]>> => {
        return http.get<IResponse<string[]>>('/intelligentProcess/description?term=' + key);
    }

    /** 查询附件信息 */
    export const getAttachment = (contentId: string): AxiosPromise<IResponse<entityTypes.IAttachmentItem[]>> => {
        return http.get<IResponse<entityTypes.IAttachmentItem[]>>(`/scntm/v1/entity/attachment/fileinfo?contentId=${contentId}`);
    }
    /** 删除附件 */
    export const deleteAttachment = (contentId: string, fileGuid: string): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/delete-attachment', {
            basicInfo: {
                'contentId': contentId
            },
            updateInfo: [
                fileGuid
            ]
        });
    }

    /** 查询版权信息 */
    export const getCopyrightInfo = (contentId: string): AxiosPromise<IResponse<entityTypes.ICopyrightInfoItem[]>> => {
        return http.get<IResponse<entityTypes.ICopyrightInfoItem[]>>('/entity/get-entity-certificate?contentid=' + contentId);
    }

    /** 查询授权书信息 */
    export const getAgreementInfo = (contentId: string): AxiosPromise<IResponse<entityTypes.IAgreementInfo>> => {
        return http.get<IResponse<entityTypes.IAgreementInfo>>(`/scntm/v1/copyright/search-byvideoid/${contentId}`);
    }

    /** 查询授权书信息 */
    export const getAgreementInfoInSeries = (contentId: string): AxiosPromise<IResponse<entityTypes.IAgreementInfo>> => {
        return http.get<IResponse<entityTypes.IAgreementInfo>>(`/scntm/v1/copyright/search-byseriesid/${contentId}`);
    }

    /** 查询标签关联片段 */
    export const searchTagRelevantPart = (tag: string, guid: string): AxiosPromise<IResponse<ccTypes.searchTypes.ISmartQueryResultData[]>> => {
        return http.post<IResponse<ccTypes.searchTypes.ISmartQueryResultData[]>>('/search/search-tag-relevant', {
            tag,
            size: 5,
            guid
        });
    }

    /** 导入语音SRT信息 */
    export const importVoiceSrt = (formData: FormData): AxiosPromise<IResponse<ccTypes.searchTypes.ISmartQueryResultData[]>> => {
        return http.post<IResponse<ccTypes.searchTypes.ISmartQueryResultData[]>>('/api/entity/upload-srt?siteCode=', formData, {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
        });
    }

    /** 查询词库列表 */
    export const queryLexiconList = (req: entityTypes.IQLexiconReq): AxiosPromise<IResponse<entityTypes.IQLexiconRes>> => {
        return http.post<IResponse<entityTypes.IQLexiconRes>>('/business/lexicon/lexicon-sets-search', req);
    }

    /** 修改未知人脸 */
    export const modifyUnknowFace = (req: entityTypes.IModifyUnknowFaceReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/skldg/v1/entity/people/unknowface', req);
    }

    export const getPersonCategorys = (): AxiosPromise<IResponse<entityTypes.IPersonCategory[]>> => {
        return http.get<IResponse<entityTypes.IPersonCategory[]>>('/skldg/v1/entity/people/categorys');
    }
    /** 附件预览页面获取附件信息 */
    // export const getAttachmentInfo = (contentId: string, fileGuid: string): AxiosPromise<IResponse<entityTypes.IAttachmentRes>> => {
    //     return http.get<IResponse<entityTypes.IAttachmentRes>>('/entity/get-entity-attachment-preview?contentId=' + contentId + '&fileGuid=' + fileGuid);
    // }
    /** 获取相关任务 */
    export const getRelativeTask = (contentId: string): AxiosPromise<IResponse<entityTypes.IRelativeTaskRes[]>> => {
        return http.get<IResponse<entityTypes.IRelativeTaskRes[]>>('/scntm/v1/entity/relation/form-task/' + contentId);
    }
    /** 获取相关素材 */
    export const getRelativeEntity = (contentId: string): AxiosPromise<IResponse<any[]>> => {
        return http.get<IResponse<any[]>>('/scntm/v1/entity/relation/' + contentId);
    }
    /** 查询操作历史 */
    export const getOperationRecord = (contentId: string): AxiosPromise<IResponse<entityTypes.IDownloadRecordRes>> => {
        return http.get<IResponse<entityTypes.IDownloadRecordRes>>('/scntm/v1/business-log/' + contentId);
    }
    export const getPicExif = (contentId: string): AxiosPromise<IResponse<entityTypes.IPicExifRes[]>> => {
        return http.get<IResponse<entityTypes.IPicExifRes[]>>(`/scntm/v1/entity/picture/${contentId}/exif`);
    }
    /** 开启黑马校对 */
    export const startHeimaCheck = (req: entityTypes.IStartHeimaCheckReq): AxiosPromise<IResponse<string>> => {
        return http.post<IResponse<string>>(`/scntm/v1/heima/check`, req);
    }

    /** 查询黑马校对进度 */
    export const getHeimaCheckProgress = (pid: string): AxiosPromise<IResponse<number>> => {
        return http.get<IResponse<number>>(`/scntm/v1/heima/check/progress/${pid}`, {
            headers: {
                hideLoading: true
            }
        });
    }

    /** 查询黑马校对结果 */
    export const getHeimaCheckResult = (pid: string): AxiosPromise<IResponse<entityTypes.IHeimaCheckResultRes>> => {
        return http.get<IResponse<entityTypes.IHeimaCheckResultRes>>(`/scntm/v1/heima/check/${pid}`);
    }

    /** 查询标签详情 */
    export const getTagDetail = (tagName: string): AxiosPromise<IResponse<entityTypes.ITagDetail[]>> => {
        return http.get<IResponse<entityTypes.ITagDetail[]>>(`/skldg/v1/spider/people?name=${tagName}&count=1`);
    }

    /** 查询标签坐标 */
    export const getTagGeo = (tagName: string): AxiosPromise<IResponse<entityTypes.ITagGeo>> => {
        return http.get<IResponse<entityTypes.ITagGeo>>(`/skldg/v1/other/geo?loc=${tagName}`);
    }

    /** 图片包-获取所有单图的元数据 */
    export const getPicPackageAllPicMetadata = (picPackageId: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/entity/picture/package/metadata?contentId=${picPackageId}`);
    }
    /** 图片包-更新单图的元数据,传图片包id即可，因为单图id已存在于元数据属性中 */
    export const updateSinglePicMetadata = (picPackageId: string, request: entityTypes.IUpdateEntityReq): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/scntm/v1/entity/picture/package/metadata/${picPackageId}`, request);
    }

    export const getFileGroups = (contentId: string): AxiosPromise<IResponse<entityTypes.IFileGroup[]>> => {
        return http.get<IResponse<entityTypes.IFileGroup[]>>(`/scntm/v1/entity/file-group?contentId=${contentId}`);
    }
    //打片段获取
    export const getSegmentData = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/entity/segment/${contentId}`);
    }

    //新增片段
    export const saveSegmentData = (contentId: string,params:any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/scntm/v1/entity/segment/${contentId}`,params);
    }

    //更新片段
    export const updateSegmentData = (contentId: string,params:any): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/scntm/v1/entity/segment/${contentId}`,params);
    }

    //删除
    export const deleteSegmentData = (contentId: string,params:string): AxiosPromise<IResponse<any>> => {
        return http.delete(`/scntm/v1/entity/segment/${contentId}?guids=${params}`);
    }

    //智能拆条
    export const getSmartCutInfo = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/entity/metadata/${contentId}?metadataType=model_sobey_smart_cut_`);
    }

    //获取片段详情
    export const getClipDetail = (contentId: string,guid:string|undefined): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/entity/segment/metadata/${contentId}/${guid}`);
    }

    //更新片段详情
    export const updateClipDetail = (contentId: string,guid:string|undefined,params:IFormItem[]): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/scntm/v1/entity/segment/metadata/${contentId}/${guid}`,params);
    }

    export const getKeyframesData = (contentId:string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/entity/keyframe/${contentId}` )
    }

    export const getCategoryTypes = (): AxiosPromise<IResponse<entityTypes.ICategoryType[]>> => {
        return http.get<IResponse<entityTypes.ICategoryType[]>>('/skldg/v1/entity/people/categorys');
    }
    //版权书创建剧集关联关系
    export const createRelation = (params:iEntityTypes.IEpisodeRelation): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/entity/relation/parent-children',params);
    }

    //封面
    export namespace coverPage{ //todo: 待新接口提供
        //封面: 上传
        export const addCoverPage = (req: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/add-frontcover', req)
        }
        //封面: 设为封面
        export const settingFace = (req: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/update-frontcover', req)
        }
        //封面: 删除图片
        export const deleteImage = (req: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/delete-frontcover', req)
        }
        //封面: 刷新
        export const refreshFun = (req : entityTypes.coverPage.IRefreshReq): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/entity/get-frontcover?contentId=${req.contentId}&queryAdress=${req.site}&entityType=${req.fileType}&frameRate=${req.frameRate}`)
        }
    }

    export const updateMetadataField = (contentId: string, data: any): AxiosPromise<IResponse<any>> => {
        return http.patch(`/scntm/v1/entity/entitydata-original/${contentId}`, data)
    }
}
export default entityApi;
