import React from 'react';
import { observer } from 'mobx-react';
import { Table, Space, Tooltip, message, Popconfirm, Select } from 'antd';
import { DeleteOutlined, MenuOutlined, EditOutlined } from '@ant-design/icons';
import { stores } from 'src/stores';
import { controlTypeDict } from './advancedSearchConfig';
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';
import { commonUtil } from 'mam-common-utils';
import ControlType from './controlType';

const TableList: React.FC = observer(() => {
    const advancedSearchConfigStore = stores.manageStore.esSearchConfigStore.advancedSearchConfigStore;

    React.useEffect(()=>{
      advancedSearchConfigStore.queryFieldList();
    }, []);

    const remove = React.useCallback((record: manageTypes.esSearchConfig.advancedSearchConfig.IAdvancedSearchField)=>{
        if (record.id){
            advancedSearchConfigStore.removeFieldItem(record.id);
        }
    }, []);

    const handleControlType = React.useCallback((index: number, value: number)=>{
        const req = commonUtil.copyObj(advancedSearchConfigStore.fieldList[index]);
        req.controlType = value;
        manageApi.esSearchConfig.advancedSearchConfig.updateAdvancedSearchField(req).then(res=>{
            message.success('修改成功！'.l('com.modifySuccess'))
            advancedSearchConfigStore.queryFieldList();
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        });
    }, []);

    const handleRefResourceField = (index: number, treeCode: string)=>{
        const req = commonUtil.copyObj(advancedSearchConfigStore.fieldList[index]);
        req.refResourceField = treeCode;
        manageApi.esSearchConfig.advancedSearchConfig.updateAdvancedSearchField(req).then(res=>{
            message.success('修改成功！'.l('com.modifySuccess'))
            advancedSearchConfigStore.queryFieldList();
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        });
    };

    const columns = [
        {
          title: '字段名'.l('manage.fieldName'),
          dataIndex: 'fieldName',
          key: 'fieldName',
        },
        {
          title: '字段代码'.l('manage.indexCode'),
          dataIndex: 'indexCode',
          key: 'indexCode',
          align: 'center'
        },
        {
            title: '控件类型'.l('manage.controlType'),
            width: 200,
            key: 'controlType',
            align: 'center',
            render: (text: any, record: any, index: number) => {
                return <ControlType record={record} index={index} handleControlType={handleControlType}
                    handleRefResourceField={handleRefResourceField}></ControlType>
            },
        },
        {
            title: '操作'.l('manage.operation'),
            width: 80,
            key: 'action',
            align: 'center',
            render: (text: any, record: any, index: number) => (
              <Space size="middle">
                  <Tooltip title={'删除'.l('com.delete')}>
                      <Popconfirm title={'确定要删除当前字段？'.l('searchConfig.deleteThisFieldTip')}
                            onConfirm={remove.bind(window, record)}>
                        <a
                            style={{color: '#ff4d4f'}}>
                            <DeleteOutlined />
                        </a>
                      </Popconfirm>
                  </Tooltip>
              </Space>
            ),
          }
      ];
    return <>
        <Table columns={columns as any} dataSource={advancedSearchConfigStore.fieldList} pagination={false}
            rowKey={'indexCode'} loading={advancedSearchConfigStore.isReqFieldList}
            scroll={{ x: '100%', y: 'calc(100vh - 260px)' }}
        />
    </>
})
export default TableList