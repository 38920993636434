import ccTypes from "../../types/ccTypes";
import {action, observable, runInAction} from "mobx";
import ccApi from "../../apis/ccApi";
import { Stores } from "../index";
import ExtendStore from "../base/extendStore";
import _ from 'lodash';
import mmsApi from "src/apis/mmsApi";
import { reqCatch } from "mam-core-react";

export default abstract class CcStore extends ExtendStore {
    /** 是否显示层面过滤 */
    @observable
    public showFacetFilter: boolean = true;
    @observable
    public sortItems: ccTypes.searchTypes.ISortItem[] = []
    /** 当前选中排序类型 */
    @observable
    public currentSort: ccTypes.searchTypes.ISortItem;
    public abstract params: ccTypes.IParams;


    constructor(stores: Stores) {
        super(stores);
        this.initDownloadStore(stores)
        this.initFavoriteStore(stores)
        this.initDataViewModeStore(stores);
        this.initHypermediaPreviewBoxStore(stores);
        this.initOutstoreStore(stores);
        this.initSearchFilterStore(stores);
        this.initBatchSmartProcess(stores);
        this.initAdvancedSearchStore(stores);

        let sCurrentSort = localStorage.getItem('horizon_search_currentSort');
        if (sCurrentSort) {
            this.currentSort = JSON.parse(sCurrentSort);
        }
    }

    public abstract setParams(searchParams: ccTypes.IParams): void;

    @action
    public setShowFacetFilter(show: boolean) {
        this.showFacetFilter = show;
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: ccTypes.searchTypes.ISortItem[] = []
        if (config && config.sortFields && config.length > 0) {
            sortItems = config.sortFields.map((item: any) => {
                return {
                    text: item.name,
                    field: item.field,
                    desc: item.desc ? 'desc' : 'asc',
                    hideDirection: true
                }
            })
            sortItems.push({
                text: '相关度'.l('search.relatedRate'), 
                field: 'default', 
                desc: 'desc', 
                hideDirection: true
            })
        } else {
            sortItems = [
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' },
                { text: '相关度'.l('search.relatedRate'), field: 'default', desc: 'desc', hideDirection: true },
            ]
        }
        this.setSortItems(sortItems)
        if(!this.currentSort){
            this.setCurrentSort(sortItems[0])
        }
    }

    @action
    public setSortItems = (sortItems: ccTypes.searchTypes.ISortItem[]) => {
        this.sortItems = sortItems
    }

    @action
    public setCurrentSort(sort: ccTypes.searchTypes.ISortItem) {
        this.currentSort = sort;
        localStorage.setItem('horizon_search_currentSort', JSON.stringify(this.currentSort));
    }
    
    //默认入库时间和相关度排序自动切换
    @action
    public autoRelatedSort(isToRelated: boolean) {
        if(isToRelated){
            this.currentSort = { text: '相关度'.l('search.relatedRate'), field: 'default', desc: 'desc', hideDirection: true }
        }else{
            this.currentSort = { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' }
        };
    }

    /** 记录用户操作习惯 */
    public recordOperationHabit(id: string, entityType: string){
        let param: ccTypes.searchTypes.IRecordOperationHabitReq = {
            id,
            entityType
        }
        // ccApi.searchApi.recordOperationHabit(param);
    }

    public resolveQueryResult(list: ccTypes.searchTypes.IFullSearchData[], callback?: (item: any) => void) {
        list.forEach((item) => {
            item.contentId = item.contentId_;
            item.type = item.type_;

            if (callback && typeof (callback) === 'function') {
                callback(item);
            }
        });

        //租户菜单的没有收藏，需要判断取消这个收藏处理
        if(window.location.pathname.indexOf('/cc/tenant') === -1){
            //是否收藏
            this.favoriteCheck(list)
        }
        
    }

    @action
    public favoriteCheck(items: ccTypes.searchTypes.IFullSearchData[]){
        if (items) {
            let contentIds: string[] = items.map((item) => {
                return item.contentId;
            })
            mmsApi.collection.getCollectionStatus(contentIds).then(res=>{
                if (res.data.data){
                    runInAction(() => {
                        if (res.data.data) {
                            res.data.data.forEach((favoriteItem) => {
                                let item = _.find((this as any).fullSearchData.data, { contentId: favoriteItem.rescontentid });
                                if (item){
                                    item.isFavorite = true;
                                    item.favoriteObjId = favoriteItem.contentId_;
                                }
                            })
                        }
                    })
                }
            })
        }
    }

    protected getCustomFacets(facets: ccTypes.searchTypes.IFacets[] | ccTypes.cloudTypes.IFullSearchFacets[]){
        if (this.stores.configStore.baseConfig.customEdition === 'junhao'){
            let newFacets = facets.concat([]);
            let mediumTypeFacet = _.find(newFacets, {facetFieldName: 'mediumType'})
            if (mediumTypeFacet && mediumTypeFacet.facetValue){
                let shunxu = ['报纸', '广电', '全媒体'];
                let newFacetValue = shunxu.filter(sx=>{
                    return _.findIndex(mediumTypeFacet?.facetValue, {value: sx}) > -1;
                }).map(sx=>{
                    return _.find(mediumTypeFacet?.facetValue, {value: sx});
                })
                if (newFacetValue){
                    mediumTypeFacet.facetValue = newFacetValue as any
                }
            }
            let typeFacet = _.find(newFacets, {facetFieldName: 'type_'})
            if (typeFacet && typeFacet.facetValue){
                let hypermediaFv = _.find(typeFacet.facetValue, {value: 'biz_sobey_hypermedia'});
                if (hypermediaFv){
                    _.remove(typeFacet.facetValue, (fv)=>{
                        return fv.value === 'biz_sobey_hypermedia'
                    })
                    typeFacet.facetValue.unshift(hypermediaFv)
                }
            }
            return newFacets;
        }
        else {
            return facets;
        }
    }

    /** 初始化层面数据 */
    protected initFacets(facets: ccTypes.searchTypes.IFacets[] | ccTypes.cloudTypes.IFullSearchFacets[]) {
        facets.forEach(item => {
            if (item.facetFieldName === 'createDate_') {
                item.startDate = '';
                item.endDate = '';
            }
            item.showMore = false;
            if (item.facetValue) {
                item.facetValue.forEach((o: ccTypes.searchTypes.IFacetsFacetValue | ccTypes.cloudTypes.IFullSearchFacetsFacetValue) => {
                    o.isShow = true;
                    o.isSelected = false;
                });
            }
            item.checkboxIsOpen = false;
        });
    }
}
