import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import React from 'react';
import ManageStore from '../../manageStore';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import manageApi from 'src/apis/manageApi'
import manageTypes from "src/types/manageTypes";

export default class OrgSetStore extends ExtendStore {
    public parentStore: ManageStore;

    @observable
    public allOrgs: manageTypes.userSetTypes.IOrgTreeItem[]; //所有部门
    @observable
    public currentOrg: manageTypes.userSetTypes.IOrgTreeItem | undefined  //当前选中的部门
    @observable
    public isEdit: boolean = false; //当前选中的部门是否处于编辑状态

    @observable
    public userListPage: number = 1;
    @observable
    public userListSize: number = 50;
    //是否正在检索用户列表
    @observable
    public isSearchingUser: boolean = false;
    //当前选中部门的用户列表
    @observable
    public userListRes: manageTypes.userSetTypes.IGetUserListRes | undefined ; 
    
    //当前选中的待编辑的用户
    @observable
    public currentUser: manageTypes.userSetTypes.IUserListItem | undefined 

    @observable
    public roleListRes: manageTypes.userSetTypes.IGetRoleListRes | undefined ; //角色列表
   
    @observable
    public showAddUserModal: boolean = false;
    @observable
    public showAddOrgModal: boolean = false;

    orgTree: any = React.createRef();

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public clear(){
        this.isEdit = false
        this.currentOrg = undefined
        this.userListRes = undefined
        this.currentUser = undefined
    }
    @action
    public setIsEdit(isEdit: boolean){
        this.isEdit = isEdit
    }
    @action
    public setShowAddUserModal(show: boolean){
        this.showAddUserModal = show
        if(!show){  //关闭弹窗时进行清空当前选中的用户
            this.currentUser = undefined
        }
    }
    @action
    public setShowAddOrgModal(show: boolean){
        this.showAddOrgModal = show
    }

    /* 当前选中部门 */
    @action
    public setCurrentOrg(currentOrg: manageTypes.userSetTypes.IOrgTreeItem  | undefined){
        this.currentOrg = currentOrg
        this.isEdit = false;
        if(this.currentOrg && this.currentOrg.organizationCode){
            this.getCurUserList(this.currentOrg.organizationCode)
        }else{
            this.userListRes = undefined
        }
    }
    /* 当前选中的用户 */
    @action
    public setCurrentUser(currentUser: manageTypes.userSetTypes.IUserListItem | undefined){
        this.currentUser = currentUser
    }
    /* 当前用户列表的页码 */
    @action
    public setUserListPage(userListPage: number){
        this.userListPage = userListPage
    }

    /* 部门相关 */
    @action
    public getOrgTreeNode = (organizationCode: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.getOrgTreeNode(organizationCode).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        resolve(res.data.data)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public addOrg = (req: manageTypes.userSetTypes.IAddEditOrgReq): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.addOrg(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.data)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public editOrg = (req: manageTypes.userSetTypes.IAddEditOrgReq): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.editOrg(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public deleteOrg = (organizationCode: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.deleteOrg(organizationCode).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    
    /* 用户相关 */
    @action
    public getCurUserList = (organizationCode?: string): Promise<any> => {
        this.isSearchingUser = true
        let req: manageTypes.userSetTypes.IGetUserListReq = {
            organizationCode: organizationCode || (this.currentOrg ? this.currentOrg.organizationCode : ''),
            //organizationCodes: [organizationCode],
            isIncludeSubuser: false,
            isOnlyRootUser: false,
            isContainsDisableUser: false,
            pageIndex: this.userListPage,
            pageSize: this.userListSize,
            //keyword: '',
            //sortFields: [],
        }
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.getCurUserList(req).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.userListRes = res.data.data
                        this.isSearchingUser = false
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public addUser = (req: manageTypes.userSetTypes.IAddUserReq): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.addUser(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public editUser = (req: manageTypes.userSetTypes.IEditUserReq): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.editUser(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public deleteUser = (userCodes: string[]): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.deleteUser(userCodes).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /* 获取所有角色 */
    @action
    public getRoleList = (): Promise<any> => {
        let req: manageTypes.userSetTypes.IGetRoleListReq = {
            pageIndex: 1,
            pageSize: 100,
            keyword: '',
            sortFields: [],
        }
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.getRoleList(req).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.roleListRes = res.data.data
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    @action
    public getOrgExtendData = (organizationCode: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.getOrgExtendData(organizationCode).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(res.data.data)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public saveOrgExtendData = (extendData: manageTypes.userSetTypes.IOrgExtendData, file?: File) => {
        let form = new FormData()
        form.append('orgCode', extendData.orgCode)
        form.append('providerId', extendData.providerId)
        form.append('orgType', extendData.orgType)
        if(file){
            form.append('fileName', file.name)
            form.append('fileStream', file)
        }
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.saveOrgExtendData(form).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        // message.success('上传成功'.l('manage.uploadSuccess'))
                        resolve(res.data.success)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }



}
