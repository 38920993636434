import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import copyrightApi from 'src/apis/copyrightApi'
import _ from 'lodash'
import { Stores } from 'src/stores'
import copyrightType from 'src/types/copyrightTypes'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import { message } from 'antd'
export default class CopyrightStore extends ExtendStore {
    @observable
    public leftCondition: copyrightType.ILeftConditionTypes[] = [];  //条件过滤
    @observable
    public leftInitData: copyrightType.ILeftConditionTypes[] = [];
    @observable
    public facets: copyrightType.IFacetsType[];
    @observable
    public fieldsData: copyrightType.IFieldsDataType[] = [];
    @observable
    public selectFieldsData: string[] = [];
    @observable
    public topConditions: copyrightType.ITopConditionType[] = [] //精确查找
    @observable
    public rightTableData: copyrightType.IRightTableDataType
    @observable
    public rightSortDatas: copyrightType.ISortDataType[] = [] = [
        {
            key: '入库时间降序',
            value: 'createDate_|true'
        }, {
            key: '入库时间升序',
            value: 'createDate_|false'
        }, 
        // {
        //     key: '授权时间升序',
        //     value: 'copyright.begindate|true'
        // }, {
        //     key: '授权时间降序',
        //     value: 'copyright.begindate|false'
        // }, 
        {
            key: '截止日期降序',
            value: 'copyright.enddate|true'
        }, {
            key: '截止日期升序',
            value: 'copyright.enddate|false'
        }
    ]  //全部的排序数据
    @observable
    public rightSortData: string = 'createDate_|true'  //页面排序数据
    @observable
    public fieldAndValues: string //左侧条件过滤选择数据
    @observable
    public leftTreeData: copyrightType.ILeftTreeDataType //左侧机构树形结构数据
    @observable
    public leftPersonData: copyrightType.ILeftPersonData //左侧入库者数据
    @observable
    public rightMetadata: IFormItem[] //右侧弹出框元数据
    @observable
    public selectedRowKeys: string[] = [] //右侧表格多选
    @observable
    public selectedRowValue: copyrightType.IDataType[] = [] //右侧表格多选每行的数据
    @observable
    public currentPage: number = 1 //右侧表格分页数据
    @observable
    public rightSort: copyrightType.IRightSortType = { fieldName: this.rightSortData.split('|')[0], isDesc: true }//右侧排序数据
    @observable
    public selectConditions: copyrightType.ISelectConditionsType[] = [] //左侧筛选数据
    @observable
    public keyword: string = '' //入库者筛选
    @observable
    public isLoading: boolean = true 

    public constructor(stores: Stores) {
        super(stores);
    }

    // 分页
    @action.bound
    public setCurrentPage(value: number) {
        runInAction(() => {
            this.currentPage = value
        })
    }

    //列表多选
    @action.bound
    public setSelectedRowkeys(value: string[]) {
        runInAction(() => {
            this.selectedRowKeys = value
        })
    }

    //列表多选
    @action.bound
    public setSelectedRowValue(value: any) {
        runInAction(() => {
            this.selectedRowValue = value
        })
    }

    //列表多选
    @action.bound
    public setKeyword(value: string) {
        runInAction(() => {
            this.keyword = value
        })
    }

    //刷新右侧列表
    @action.bound
    public setRightTableData(value: any) {
        runInAction(() => {
            this.rightTableData = value
        })
    }

    //列表多选
    @action.bound
    public setDelSelectedRowkeys(index: number) {
        runInAction(() => {
            if (this.selectedRowKeys.length > 0) {
                _.remove(this.selectedRowKeys, (value, indexs, array) => {
                    return indexs === index
                });
            }
        })
    }

    //列表多选
    @action.bound
    public setDelSelectedRowValue(index: number) {
        runInAction(() => {
            if (this.selectedRowValue.length > 0) {
                _.remove(this.selectedRowValue, (value, indexs, array) => {
                    return indexs === index
                });
            }
        })
    }

    //右侧排序方式
    @action.bound
    public setRightSort(value: string) {
        runInAction(() => {
            this.rightSortData = value
        })
    }

    //添加左侧条件筛选条件
    @action.bound
    public setSelectConditions(value: copyrightType.ISelectConditionsType) {
        runInAction(() => {
            this.selectConditions.push(value)
        })
    }

    //删除左侧条件筛选已选条件
    @action.bound
    public setDelSelectConditions(index: number) {
        runInAction(() => {
            _.remove(this.selectConditions, (value, indexs, array) => {
                return indexs === index
            });
            this.getTableData()
        })
    }

    //增加精确查找条件
    @action
    public setAddCondition = () => {
        const value: copyrightType.ITopConditionType = {
            key: this.fieldsData[0].fieldName,
            fieldCode: this.fieldsData[0].fieldCode,
            value: [''],
            isMetadataField: this.fieldsData[0].isMetadataField
        }
        if (this.fieldsData[0].controlType === 8) {
            value.controlType = this.fieldsData[0].controlType
        }
        this.topConditions.push(value)
    }

    //移除精确查找条件
    @action.bound
    public setRemoveCondition(index: number) {
        if (this.topConditions.length > 1) {
            runInAction(() => {
                _.remove(this.topConditions, (value, indexs, array) => {
                    return indexs === index
                });
            })
        }
    }

    //修改精确查找的数据type
    @action.bound
    public setTypeTopCondition(index: number, key: string) {
        runInAction(() => {
            this.topConditions[index].key = key
            this.topConditions[index].fieldCode = (_.find(this.fieldsData, { fieldName: key }) as copyrightType.IFieldsDataType)?.fieldCode
            this.topConditions[index].isMetadataField = (_.find(this.fieldsData, { fieldName: key }) as copyrightType.IFieldsDataType)?.isMetadataField
        })
        this.setValueTopCondition(index, [''])
    }

    //修改精确查找的数据value
    @action.bound
    public setValueTopCondition(index: number, value: any[]) {
        runInAction(() => {
            this.topConditions[index].value = value
        })
    }

    //清空筛选条件
    @action.bound
    public setClearCondition() {
        runInAction(() => {
            this.topConditions = []
            this.setAddCondition()
        })
    }

    //初始化数据
    @action
    public initSort = (facets: copyrightType.ILeftConditionTypes[]) => {
        const leftConditions: copyrightType.ILeftConditionTypes[] = []
        this.facets.forEach((item: copyrightType.IFacetsType) => {
            const hasFacets = _.find(facets, facet => facet.facetFieldName === item.field)
            if (hasFacets) {
                if (item.sort.length !== 0) {
                    const newFacetValue: any[] = []
                    item.sort.forEach((items: any) => {
                        const newSort = _.find(hasFacets.facetValue, o => o.value.replace("\"", "").replace("\"", "") === items.replace("\"", "").replace("\"", ""))
                        if (newSort) {
                            newFacetValue.push(newSort)
                        }
                    })
                    hasFacets.facetValue = newFacetValue
                }
                leftConditions.push(hasFacets)
            }
        })
        leftConditions.forEach(m => {
            m.showSub = false
        })
        return leftConditions
    }

    // 左侧条件过滤数据处理
    @action
    public getFacets() {
        copyrightApi.getFacetsData().then(res => {
            if (res.data.success && res.data.data) {
                runInAction(() => {
                    this.facets = res.data.data
                    this.leftCondition = this.initSort(this.leftInitData)
                })
            }
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        });
    }

    //左侧精确查找数据
    @action
    public getFields() {
        copyrightApi.getFieldsData().then(res => {
            if (res.data.success && res.data.data) {
                runInAction(() => {
                    this.fieldsData = res.data.data
                    this.setAddCondition()
                })
            }
        }).catch(res => {
            // if (res.data.error && res.data.error.title) {
            //     message.error(res.data.error.title)
            // }
        });
    }

    // 获取排序数据
    @action.bound
    public getSortData() {
        copyrightApi.getConfigSort().then(res => {
            if (res.data.success && res.data.data) {
                runInAction(() => {
                    res.data.data.sortFields.forEach((item: { value: string; key: any }) => {
                        this.rightSortDatas.push({
                            key: item.value + '降序',
                            value: `${item.key}|${true}`
                        })
                        this.rightSortDatas.push({
                            key: item.value + '升序',
                            value: `${item.key}|${false}`
                        })
                    })
                    this.rightSortData = this.rightSortDatas[0].value
                    const obj = { fieldName: this.rightSortData.split('|')[0], isDesc: true }
                    this.rightSort = obj
                    this.setCurrentPage(1)
                    this.getTableData()
                })
            }
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        });
    }

    // 获取列表数据
    @action.bound
    public getTableData() {
        this.isLoading = true
        this.selectedRowKeys = []
        this.selectedRowValue = []
        const arr: any = []
        this.topConditions.map((item: any) => {
            if (item.key && item.value[0] !== "") {
                arr.push({ field: item.fieldCode, value: item.value, searchRelation: 'eq', isMetadataField: item.isMetadataField })
            }
        })
        const obj:any = {
            conditions: arr,
            facetConditions: this.selectConditions.map((item: any) => { return { field: item.key, value: item.value } }),
            highLight: true,
            keyword: [],
            pageIndex: this.currentPage,
            pageSize: 20,
            sortFields: []
        }
        obj.sortFields.push({
            field: this.rightSortData.split('|')[0],
            isDesc:this.rightSortData.split('|')[1] === 'true' 
        })
        copyrightApi.getFullSearchTableData(obj).then(res => {
            if (res.data.success && res.data.data) {
                runInAction(() => {
                    this.rightTableData = res.data.data
                    this.leftCondition = res.data.data.facets
                    this.isLoading = false
                })
            }
        }).catch(res => {
            this.isLoading = false
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })

    }

    // 获取机构数据
    @action.bound
    public getTreeData() {
        copyrightApi.getLeftTreeData().then(res => {
            if (res.data.success && res.data.data) {
                runInAction(() => {
                    this.leftTreeData = res.data.data
                    this.currentPage = 1
                    this.getPersonData(res.data.data.organizationCode)
                })
            }
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    // 获取入库者数据
    @action.bound
    public getPersonData(value: string) {
        const obj = {
            keyword: this.keyword,
            organizationCode: value,
            organizationCodes: value,
            pageIndex: this.currentPage,
            pageSize: 36,
        }
        copyrightApi.getLeftPersonData(obj).then(res => {
            if (res.data.success && res.data.data) {
                runInAction(() => {
                    this.leftPersonData = res.data.data
                })
            }
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    //获取右侧编辑元数据
    @action.bound
    public getRightModalMetadata(contentId: string) {
        this.rightMetadata = []
        copyrightApi.getModalMetadata(contentId).then(res => {
            if (res.data.success && res.data.data) {
                runInAction(() => {
                    if (this.selectedRowKeys.length > 1) {
                        res.data.data.map((item: any) => {
                            Reflect.deleteProperty(item, 'value')
                            return item
                        })
                        this.rightMetadata = res.data.data
                    } else {
                        this.rightMetadata = res.data.data
                    }
                })
            }
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    //获取右侧批量编辑元数据
    @action.bound
    public getRightModalMetadataMult() {
        copyrightApi.getModalMetadataMult().then(res => {
            if (res.data.success && res.data.data) {
                runInAction(() => {
                    this.rightMetadata = res.data.data
                })
            }
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }
}