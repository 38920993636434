
import ExtendStore from 'src/stores/base/extendStore';
import {action, observable} from "mobx";
import shareDetailTypes from 'src/types/shareDetailTypes';
import shareDetailApi from 'src/apis/shareDetailApi';
import { message } from 'antd';
import { Stores } from './../index';
import {l} from "mam-core-react";

class ShareDetailStore extends ExtendStore{
    @observable
    public params:shareDetailTypes.IParams

    @observable
    public passwordIsPass:boolean = true

    @observable
    public model:shareDetailTypes.ILinkDetailRes

    @observable
    public password:string

    constructor(stores:Stores){
        super(stores)
        this.initDataViewModeStore(stores);
        this.initSelectAllStore(stores,'stores.shareDetailStore.model.resources', 'selected')
        this.initDownloadStore(stores)
        this.initCopyStore(stores)
        this.initFavoriteStore(stores)
        this.initCreatePlanningStore(stores)
    }

    @action
    public setParams(params:shareDetailTypes.IParams){
        this.params = params
    }

    @action
    public setPasswordIsPass(passwordIsPass:boolean){
        this.passwordIsPass = passwordIsPass
    }

    @action 
    public setModel(model:shareDetailTypes.ILinkDetailRes){
        this.model = model
    }

    @action
    public setPassword(password:string){
        this.password = password
    }

    @action
    public query(key?:string){
        if(!this.params.key){
            this.setPasswordIsPass(false)
        }else{
            shareDetailApi.linkDetail(this.params.id,key || this.params.key)
                .then(res=>{
                    if(res.data.success && res.data.data){
                        if(res.data.data.errNo === 0){
                            // 没有问题时候的操作
                            const params = this.params
                            params.key = key || this.params.key
                            this.setParams(params)
                            if(res.data.data.resources){
                                res.data.data.resources.forEach(item=>{
                                    item.siteCode = this.params.opsite
                                })
                            }
                        }else{
                            if(res.data.data.errNo === 1){
                                message.error(l('share.pwdError', '密码错误'))
                            }else{
                                message.error(res.data.data.errMsg)
                            }
                            this.setPasswordIsPass(false)
                        }
                        this.setModel(res.data.data)
                    }else{
                        this.setPasswordIsPass(false)
                    }
                })
        }
    }

    @action
    public pass(event:React.FormEvent<HTMLFormElement>){
        event.preventDefault()
        this.query(this.password)
    }
}

export default ShareDetailStore