import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import copyrightApi from 'src/apis/copyrightApi'
import _ from 'lodash'
import { Stores } from 'src/stores'
import copyrightType from 'src/types/copyrightTypes'
import { message } from 'antd'
import { commonUtil } from 'mam-common-utils'
import manageTypes from 'src/types/manageTypes'
import { IFieldTreeItem } from 'mam-components-react/dist/modules/mamSelectField/mamSelectField'
export default class CopyrightManageStore extends ExtendStore {
    @observable
    public currentTab: string = 'config' //当前tab
    @observable
    public showModal: boolean = false //modal
    @observable
    public feactFieldList: IFieldTreeItem[] = [] //所有层面条件
    @observable
    public fieldsList: any = [] //所有高级检索字段
    @observable
    public isLoading: boolean = false //是否加载状态
    @observable
    public isLoadingFullSearchData: boolean = true
    @observable
    public metadataSource: manageTypes.metadata.IMetadataSource[] = []
    @observable
    public selectedRows: manageTypes.metadata.IMetadataSource[] = []
    public inputType = { //元数据控件对应的汉字名称
        1: '日期+时间',
        2: '日期',
        4: '数字',
        9: '帧显示时码',
        11: '百纳秒显示时码',
        10: '存储容量',
        19: '小数类型',
        25: '评分',
        5: '单行文本',
        6: '多行文本',
        3: '时间',
        12: '标签输入框',
        14: '树形结构',
        16: '日期范围',
        20: '超链接',
        22: '富文本编辑框',
        24: '检索查询',
        28: '素材计价',
        31: '智能标签',
        7: 'bool值',
        8: '下拉框',
        15: '表格',
    }
    @observable
    public showEditModal: boolean = false;
    @observable
    public editField?: manageTypes.metadata.IMetadataSource;
    @observable
    public metadataTypeTab: manageTypes.metadata.IMetadataTypeRes[]
    @observable
    public currentMetadataTypeId?: number = undefined
    @observable
    public isLoadingFullEntityData: boolean = true
    @observable
    public addMetadataTypeModalVisible: boolean = false
    @observable
    public copyrightConfig: copyrightType.ICopyrightConfigType

    public constructor(stores: Stores) {
        super(stores);
    }

    // tab切换
    @action
    public setCurrentTab(tab: string) {
        this.currentTab = tab
    }

    @action
    public openModal(editField: manageTypes.metadata.IMetadataSource) {
        this.editField = editField;
        this.showEditModal = true;
    }

    @action
    public closeModal() {
        this.showEditModal = false;
        this.editField = undefined;
    }

    @action
    public changeEditField(fieldCode: string, value: any) {
        if (this.editField) {
            this.editField[fieldCode] = value;
        }
    }

    @action
    public setShowModal(tab: string) {
        this.currentTab = tab
    }

    @action
    public setFieldsList(fieldsList: any) {
        this.fieldsList = fieldsList;
    }

    @action
    public removeFieldItem(index: number) {
        this.fieldsList.splice(index, 1);
        this.fieldsList = this.fieldsList.concat([]);
    }

    @action
    public updateFieldItem(index: number, item: any) {
        this.fieldsList.splice(index, 1, item);
        this.fieldsList = this.fieldsList.concat([]);
    }

    @action
    public setFacetList(facetList: any) {
        this.feactFieldList = facetList
    }

    @action
    public removeFacetListItem(item: any) {
        let copyData: any[] = commonUtil.copyObj(this.feactFieldList);
        _.remove(copyData, { code: item.code })
        this.setFacetList(copyData)
    }

    @action
    public setFieldList(fieldsList: any) {
        this.fieldsList = fieldsList
    }

    @action
    public setSelectedRows = (selectedRows: any) => {
        this.selectedRows = selectedRows
    }

    @action
    public setNewDataSource = (metadataSource: manageTypes.metadata.IMetadataSource[]) => {
        this.metadataSource = metadataSource
    }

    @action
    public setCurrentMetadataTypeId(Id?: number) {
        this.currentMetadataTypeId = Id
    }

    @action
    public setAddMetadataTypeModalVisible = (visible: boolean) => {
        this.addMetadataTypeModalVisible = visible
    }

    @action
    public setCopyrightConfig = (value: boolean | number, type: string) => {
        this.copyrightConfig[type] = value
    }

    //获取
    @action
    public getAllFeacts() {
        this.isLoading = true
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.getAllFeacts().then(res => {
                if (res.data.success && res.data.data) {
                    runInAction(() => {
                        this.feactFieldList = res.data.data.map((item: any) => {
                            return {
                                name: item.fieldName,
                                code: item.fieldCode,
                            }
                        })
                        this.isLoading = false
                    })
                }
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                    this.isLoading = false
                }
            });
        })
    }
    // 更新
    @action
    public addOrEditFeacts(params: any) {
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.addOrEditFeacts(params).then(res => {
                if (res.data.success && res.data.data) {
                    runInAction(() => {
                        resolve(true)
                    })
                }
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            });
        })
    }

    @action
    public getAllConditions() {
        this.isLoading = true
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.getAllConditions().then(res => {
                if (res.data.success && res.data.data) {
                    runInAction(() => {
                        this.fieldsList = res.data.data
                        this.isLoading = false
                    })
                }
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                    this.isLoading = false
                }
            });
        })
    }

    // 更新
    @action
    public addOrEditConditions(params: any) {
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.addOrEditConditions(params).then(res => {
                if (res.data.success && res.data.data) {
                    runInAction(() => {
                        resolve(true)
                    })
                }
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            });
        })
    }

    //获取元数据字段
    @action
    public getEntityMetadata = () => {
        return new Promise((resolve, reject) => {
            this.isLoadingFullSearchData = true
            copyrightApi.manageApi.getMetadataField().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.metadataSource = _.sortBy(res.data.data, ['order'])
                        this.metadataSource.map((item: manageTypes.metadata.IMetadataSource, index: number) => {
                            item.dataIndex = index
                        })
                        this.isLoadingFullSearchData = false
                    }
                    resolve(undefined);
                })
            }).catch((res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject();
            })
        })
    }

    //字段新增
    @action
    public addMetadataField = (params: any) => {
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.addMetadataField(params).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                    reject()
                }
            })
        })
    }

    //字段更新
    @action
    public updateMetadataField = (params: manageTypes.metadata.IMetadataSource) => {
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.updateMetadataField(params).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        message.success('修改成功！')
                        resolve(true)
                        this.getEntityMetadata()
                    }
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }

    @action
    public sortList(fieldCodes: any[]) {
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.orderMetadataField(fieldCodes).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {

                    }
                    resolve(undefined);
                })
            }).catch((res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject();
            })
        })
    }

    //字段删除
    @action
    public deleteItem = (fieldIds: number[]) => {
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.deleteMetadataField(fieldIds).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                    reject()
                }
            })
        })
    }

    //获取版权限制
    @action
    public getCopyrightConfig = () => {
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.getCopyrightConfig().then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        this.copyrightConfig = res.data.data
                    }
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                    reject()
                }
            })
        })
    }

    //更新版权限制
    @action
    public updateCopyrightConfig = () => {
        return new Promise((resolve, reject) => {
            copyrightApi.manageApi.updateCopyrightConfig(this.copyrightConfig).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        message.success('保存成功！')
                        resolve(true)
                    }
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                    reject()
                }
            })
        })
    }

}