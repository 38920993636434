import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import {Stores} from "../index";

export default class CreatePlanningStore extends BaseStore<Stores>{
    // 显示创建选题modal
    @observable
    public showCreatePlanningModal:boolean = false

    @observable
    public items:any[]

    // 富文本编辑器弹窗
    @observable
    public showSimditor:boolean = false

    @observable
    public htmlContent:string

    @observable
    public htmlText:string

    @action
    public setHtmlContent(htmlContent:string){
        this.htmlContent = htmlContent
    }

    @action
    public setHtmlText(htmlText:string){
        this.htmlText = htmlText
    }

    @action
    public setShowSimditor(show:boolean){
        this.showSimditor = show
    }

    @action
    public setShowCreatePlanningModal(show:boolean){
        this.showCreatePlanningModal = show
    }

    @action
    public setItems(items:any[]){
        this.items = items
    }
}