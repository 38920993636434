import { BaseStore, http } from 'mam-core-react';
import { Stores } from 'src/stores';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash';
import EntityStore from '../entityStore';
import iEntityApi from 'src/apis/iEntityApi';
import iEntityTypes from 'src/types/iEntityTypes';

export default class SensitiveParts4PlayerStore extends BaseStore<Stores> {
    /** 敏感片段列表，用于控制播放器标识敏感信息 */
    @observable
    public isReqSensitiveParts: boolean = true;
    public sensitiveParts: iEntityTypes.ISensitiveSequenceItem[] = [];
    public parentStore: EntityStore;

    private watchPlayerSensitivePartLock: boolean = false;

    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public getSensitiveParts(){
        this.isReqSensitiveParts = true;
       iEntityApi.getSensitiveSequence([this.parentStore.params.contentId]).then(res=>{
            if (res.data.data && res.data.data[this.parentStore.params.contentId]){
                this.sensitiveParts = res.data.data[this.parentStore.params.contentId];
            }
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        }).finally(()=>{
            runInAction(()=>{
                this.isReqSensitiveParts = false;
            })
        })
    }

    /** 监听播放器敏感片段，然后屏蔽 */
    public watchPlayerSensitivePart(viewer: any){
        if (this.sensitiveParts.length === 0){
            return;
        }
        const playerSensitiveMask = $('.layer-overlay-html-inject').children('.container').children('.player-sensitive-mask');
        const playerSensitiveShow = $('.layer-overlay-html-inject').children('.container').children('.player-sensitive-show');
        // const highLevelParts = _.find(this.sensitiveParts, {level: 'High'});
        // const lowLevelParts = _.find(this.sensitiveParts, {level: 'Low'});
        this.matchPlayerSensitivePart(viewer, playerSensitiveMask, playerSensitiveShow);
        viewer.player.media.addEventListener('accurateTimeUpdate', (res: any) => {
            if (this.watchPlayerSensitivePartLock){
                return;
            }
            this.watchPlayerSensitivePartLock = true;
            this.matchPlayerSensitivePart(viewer, playerSensitiveMask, playerSensitiveShow);
            setTimeout(()=>{
                this.matchPlayerSensitivePart(viewer, playerSensitiveMask, playerSensitiveShow);
                this.watchPlayerSensitivePartLock = false;
            }, 500)
        })
    }

    private matchPlayerSensitivePart(viewer: any, playerSensitiveMask: JQuery<HTMLElement>, playerSensitiveShow: JQuery<HTMLElement>){
        const currentTime = parseInt(viewer.player.getCurrentTime().toFixed(7).replace('.', ''), 10);
        let isFind = false;
        for (let i = 0, j = this.sensitiveParts.length; i < j; i++){
            if (this.sensitiveParts[i].inpoint <= currentTime 
                && this.sensitiveParts[i].outpoint >= currentTime){
                isFind = true;
                break;
            }
        }
        if (isFind){
            playerSensitiveMask.css('display', 'flex');
        }
        else {
            playerSensitiveMask.css('display', 'none');
        }
        // if (highLevelParts){ // 限制为高的片段
        //     let isFind = false;
        //     for (let i = 0, j = highLevelParts.points.length; i < j; i++){
        //         if (highLevelParts.points[i].inpoint <= currentTime 
        //             && highLevelParts.points[i].outpoint >= currentTime){
        //             isFind = true;
        //             break;
        //         }
        //     }
        //     if (isFind){
        //         playerSensitiveShow.css('display', 'flex');
        //     }
        //     else {
        //         playerSensitiveShow.css('display', 'none');
        //     }
        // }
        // if (lowLevelParts){ // 限制为低的片段
        //     let isFind = false;
        //     for (let i = 0, j = lowLevelParts.points.length; i < j; i++){
        //         if (lowLevelParts.points[i].inpoint <= currentTime 
        //             && lowLevelParts.points[i].outpoint >= currentTime){
        //             isFind = true;
        //             break;
        //         }
        //     }
        //     if (isFind){
        //         playerSensitiveShow.css('display', 'flex');
        //     }
        //     else {
        //         playerSensitiveShow.css('display', 'none');
        //     }
        // }
    }
}