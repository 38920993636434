import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import { Stores } from 'src/stores'
import copyrightType from 'src/types/copyrightTypes'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import ccApi from 'src/apis/ccApi'
import copyrightApi from 'src/apis/copyrightApi'
import iSearchTypes from 'src/types/iSearchTypes'
import ccTypes from 'src/types/ccTypes'
import { message } from 'antd'
import entityApi from 'src/apis/entityApi'
import { commonUtil } from 'mam-common-utils'
export default class CopyrightManageStore extends ExtendStore {
    @observable
    public showModal: boolean = false //删除提示剧集弹窗
    @observable
    public metadatas: IFormItem[] = [] //版权元数据
    @observable
    public editMetadata: boolean = false //元数据编辑
    @observable
    public selectedBooks: copyrightType.ICopyrightSelectedBooksType = {//选中的版权书
        selectedBooksKeys: [],
        selectedBooks: []
    }
    @observable
    public isLoadingArgeement: boolean = false
    @observable
    public queryResult: iSearchTypes.IFullSearchDataRes
    @observable
    public entitiesRes?: ccTypes.searchTypes.IFullSearchRes
    @observable
    public currentContentId: string = ''
    @observable
    public queryParams: copyrightType.IAgreementPramas = {
        "pageIndex": 1,
        "pageSize": 30,
        "personId": "",
        "keywords": [],
        "facetConditions": [
            { field: "type_", value: ["biz_sobey_agreement"] }
        ],
        "conditions": [],
        "sortFields": [{ "field": "createDate_", "isDesc": false }]
    }
    @observable
    public showExcelExportModal: boolean = false //Excel导出弹窗
    public excelExportPageSize: number = 100 //Excel导出分页大小

    public constructor(stores: Stores) {
        super(stores);
    }

    @action
    public setShowModal(showModal: boolean) {
        this.showModal = showModal
    }

    @action
    public setEditMetadata(editMetadata: boolean) {
        this.editMetadata = editMetadata
    }

    @action
    public setSelectedBooks(selectedRowKeys: string[], selectedRows: any[]) {
        this.selectedBooks = {
            selectedBooksKeys: selectedRowKeys,
            selectedBooks: selectedRows
        }
    }

    //列表多选
    @action.bound
    public setDelSelectedRowValue(index: number) {
        runInAction(() => {
            if (this.selectedBooks.selectedBooks.length > 0) {
                _.remove(this.selectedBooks.selectedBooks, (value, indexs, array) => {
                    return indexs === index
                });
            }
        })
    }

    //列表多选
    @action.bound
    public setDelSelectedRowkeys(index: number) {
        runInAction(() => {
            if (this.selectedBooks.selectedBooksKeys.length > 0) {
                _.remove(this.selectedBooks.selectedBooksKeys, (value, indexs, array) => {
                    return indexs === index
                });
            }
        })
    }

    @action
    public setPageSize(pageSize: number) {
        this.queryParams = Object.assign({}, this.queryParams, { pageSize })
    }

    @action
    public setCurrentContentId(currentContentId: string) {
        this.currentContentId = currentContentId
    }

    //根据版权书获取剧集信息
    @action
    public getEpisodeByArgreement(contentId: string, page?: number, keyword?: string) {
        let req: ccTypes.searchTypes.IFullSearchReq = {
            pageIndex: page || 1,
            pageSize: 45,
            keyword: keyword ? [keyword] : [],
            sortFields: [{
                field: 'createDate_',
                isDesc: true
            }],
            conditions: [{
                field: 'model_sobey_parent_relation.contentid',
                value: [contentId],
                isMetadataField: true,
                searchRelation: 'Eq'
            }],
            facetConditions: [],
            highLight: true
        };
        return new Promise((resolve, reject) => {
            ccApi.searchApi.fullSearch(req).then((res) => {
                runInAction(() => {
                    if (res.data.data && res.data.data.data) {
                        if (!res.data.data.facets) {
                            res.data.data.facets = []
                        }
                        this.entitiesRes = res.data.data;
                        resolve(this.entitiesRes)
                    } else {
                        reject(false)
                    }
                })
            }).finally(() => {
                runInAction(() => {
                    reject(false)
                })
            })
        })

    }

    //获取所有版权书
    @action
    public getAgreement(pageIndex?: number, keywords?: string[]) {
        this.isLoadingArgeement = true
        if (pageIndex) {
            this.queryParams = { ...this.queryParams, pageIndex }
        }
        if(keywords){
            this.queryParams = { ...this.queryParams, keywords }
        }
        copyrightApi.agreementApi.getAgreement(this.queryParams).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    this.queryResult = res.data.data;
                    this.isLoadingArgeement = false
                }
            })
        }).finally(() => {
            runInAction(() => {
                this.isLoadingArgeement = false;
            })
        })
    }

    //删除版权书
    @action
    public delAgreement(contentIds: string[]) {
        copyrightApi.agreementApi.delAgreement(contentIds).then(res => {
            runInAction(() => {
                if (res.data.data) {
                    message.success('删除成功！')
                    this.getAgreement(1)
                }
            })
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        });
    }

    //获取版权元数据
    @action
    public getEntityData(contentId: string) {
        return new Promise((resolve, reject) => {
            entityApi.getEntityData(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data && _.find(res.data.data, { groupCode: 'default_group' })) {
                        this.metadatas = _.find(res.data.data, { groupCode: 'default_group' })?.fields as IFormItem[];
                        this.metadatas = this.metadatas.filter(i => i.fieldName !== 'name_')
                        if (this.selectedBooks.selectedBooksKeys.length > 1) {
                            this.metadatas = this.metadatas.map(item => {
                                return {
                                    ...item,
                                    value: ''
                                }
                            })
                        }
                    }
                    resolve(true)
                });
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject(false)
            });
        })
    }

    @action
    public setShowExcelExportModal(showExcelExportModal: boolean) {
        this.showExcelExportModal = showExcelExportModal
    }


}
