import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import shareTypes from "../types/shareTypes";
import {IResponse} from "mam-core-react/dist/modules/http";

namespace shareApi {
    export const getLinkShare = (param: shareTypes.IShareListReq) : AxiosPromise<IResponse<shareTypes.IShareList>> => {
        return http.post<IResponse<shareTypes.IShareList>>('/scntm/v1/share/list', param);
    }

    export const extendLinkshare = (shareUrl:string): AxiosPromise<IResponse<boolean>> => {
        return http.get<IResponse<boolean>>(`/share/extend-linkshare?shareUrl=${shareUrl}$linkType=${6}`)
    }

    export const quitShareGroup = (ids:string[]):AxiosPromise<IResponse<boolean>> => {
        return http.post<IResponse<boolean>>('/share/quit-share-group',ids)
    }

    export const getShareStatus = (linkUrl: string):AxiosPromise<IResponse<shareTypes.IShareStatusRes>>=>{
        return http.get<IResponse<shareTypes.IShareStatusRes>>(`/scntm/v1/share/${linkUrl}/status`);
    }
}
export default shareApi
