import * as React from 'react';
import {observer} from "mobx-react";
import BaseComponent from "../baseComponent/baseComponent";
import {Redirect, Route, RouteComponentProps, withRouter} from "react-router";
import NotFound from 'src/pages/NotFound';

interface IProps extends RouteComponentProps<any, any, any>{
    defaultUrl: string
}

@observer
class DefaultRoute extends BaseComponent<IProps, any> {
    constructor(props: IProps) {
        super(props)
        if(props.defaultUrl.includes('tpp')){
            this.toTpp(props.defaultUrl)
        }
    }

    public getRender() {
        if (this.props.history.location.pathname === '/'){
            return <Route path='/' exact
                component={() => { window.location.href = `${this.props.defaultUrl}`; return null;} }/>
        }
        else {
            return <NotFound></NotFound>
        }
    }

    private toTpp(href:string){
        window.location.href = href
    }

}

export default withRouter(DefaultRoute)