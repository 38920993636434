import { Stores } from 'src/stores'
import ExtendStore from 'src/stores/base/extendStore';
import ContentMgStore from '../../contentMgStore';
import { action, observable, runInAction } from "mobx"
import _ from 'lodash'
import { message } from 'antd'
import { l } from 'mam-core-react'
import contentMgTypes from 'src/types/contentMgTypes'
import contentMgApi from "src/apis/contentMgApi"
import sysmanageTypes from 'src/types/sysmanageTypes'

/** 任务中心模块store */
export default class TaskCenterStore extends ExtendStore {
    //任务状态数据
    public taskStatusList = [
        {show: true,key: "0", title: l('taskCenter.allTask', '所有任务'), name: l('com.all', '全部')},
        {show: true,key: "1", title: l('taskCenter.doingTask', '执行中的任务'), name: l('com.doing', '进行中')},
        {show: true,key: "2", title: l('taskCenter.successedTask', '成功的任务'), name: l('com.success', '成功')},
        {show: true,key: "3", title: l('taskCenter.failedTask', '失败的任务'), name: l('com.fail', '失败')},
    ]
    /*列表*/
    @observable
    public taskStatus: string = '0'  //'0'所有、'1'执行中、'2'成功、'3'失败
    @observable
    public listSelectedRowKeys: any = []
    @observable
    public listSelectedRows: any = []
    @observable
    public keyword: string = '' //任务名称
    @observable
    public page: number = 1
    @observable
    public size: number = 20
    @observable
    public queryListRes: contentMgTypes.taskCenter.IQueryRes
    @observable
    public baseConfig: sysmanageTypes.taskCenterConfig.IBaseCfg = {
        taskCenterPageSizes: ['20','30','50', '100'],
        taskCenterTableTemplate: '',
    }
    @observable
    public isSearching: boolean = false
    @observable
    public currentSort: string = "createtime|desc" 
    @observable
    public showSearch: boolean = false
    @observable
    public hasAdvancedConditions: boolean = false
    @observable
    public externalSystem: contentMgTypes.taskCenter.IExternalSystemItem[] = [] 
    @observable
    public taskTypeList: contentMgTypes.taskCenter.ITaskTypeItem[] = []
    @observable
    public sortFields: contentMgTypes.taskCenter.ISortFieldItem[] = []
    @observable
    public fields: contentMgTypes.taskCenter.IFieldItem[] = [
        {
            alias: l('taskCenter.taskType','任务类型'),
            controlData: JSON.stringify({"":"全部"}),
            controlType: 8,
            fieldName: "taskType",  
            value: "",  
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('taskCenter.entityType','素材类型'),
            controlData: JSON.stringify({"":"全部"}),
            controlType: 8,
            fieldName: "clipType",  
            value: "",  //'video'
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('taskCenter.targetInfo','分发渠道'),
            controlData: JSON.stringify({"":"全部"}),
            controlType: 8,
            fieldName: "targetInfoCode",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('taskCenter.createUser','发起者'),
            controlType: 5,
            fieldName: "createUserName",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('taskCenter.createTime','创建时间'),
            controlType: 16,
            fieldName: "createTime",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
        {
            alias: l('taskCenter.finishTime','完成时间'),
            controlType: 16,
            fieldName: "updateTime",
            value: "",
            displayValue: "",
            nexus:"AND",
        },
    ]
    @observable
    public showExcelExportModal: boolean = false

    //循环刷新时间：5、10、15、30分钟
    // public refreshTimeMap: number[] = [5, 10, 15, 30]
    // @observable
    // public refreshTime: number = 5
    // @observable
    // public taskCenterRefreshTimer: NodeJS.Timeout | undefined 

    /*详情*/
    @observable
    public taskDetail: contentMgTypes.taskCenter.ITaskDetail | undefined
    @observable
    public isSearchingDetail: boolean = false

    private contentMgStore: ContentMgStore;

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores);
        this.contentMgStore = contentMgStore;
    }

    // @action
    // public intervalFn = () => {
    //     if(this.taskCenterRefreshTimer){
    //         clearInterval(this.taskCenterRefreshTimer)
    //     }
    //     this.taskCenterRefreshTimer = setInterval(()=>{
    //         this.queryList()
    //     }, this.refreshTime * 1000 * 60)
    // }

    @action
    public setNewListData(listData: contentMgTypes.taskCenter.ITaskListItem[]) {
        this.queryListRes.data = listData
    }

    @action
    public setShowExcelExportModal(showExcelExportModal: boolean) {
        this.showExcelExportModal = showExcelExportModal
    }

    @action
    public clearSearch() {
        this.fields.map(o=>{
            o.value = ""
            o.displayValue = ""
            o.nexus = "AND"
        })
    }
    @action
    public setShowSearch(showSearch: boolean) {
        this.showSearch = showSearch
    }
    
    @action
    public setFields(fields: contentMgTypes.taskCenter.IFieldItem[]) {
        this.fields = fields
    }
    @action
    public setFieldItemValue(index: number, value: string, displayValue?: string) {
        this.fields[index].value = value
        this.fields[index].displayValue = displayValue || value
    }
    @action
    public setFieldItemNexus(index: number, value: string) {
        this.fields[index].nexus = value
    }

    @action
    public setListSelectedRowKeys = (selectedRowKeys: any, selectedRows: any[]) => {
        this.listSelectedRowKeys = selectedRowKeys
        this.listSelectedRows = selectedRows
    }
    @action
    public clearListSelected = () => {
        this.listSelectedRowKeys = []
        this.listSelectedRows = []
    }

    @action
    public setTaskStatus(taskStatus: string) {
        this.taskStatus = taskStatus
    }
    // @action
    // public setRefreshTime(refreshTime: number) {
    //     this.refreshTime = refreshTime
    //     localStorage.setItem('taskCenterRefreshTime', refreshTime.toString())
    // }
    @action
    public setCurrentSort(currentSort: string) {
        if(currentSort !== this.currentSort){
            this.currentSort = currentSort
        }
    }
    @action
    public setKeyword(keyword: string) {
        this.keyword = keyword
    }
    
    @action
    public setPage(page: number) {
        this.page = page
    }
    @action
    public setSize(size: number) {
        this.size = size
        localStorage.setItem('taskCenterPageSize', size.toString())
        this.queryList(1)
    }
    @action
    public getAdvancedSearchFieldValue(fieldName: string): string {
        let fieldItem: contentMgTypes.taskCenter.IFieldItem | undefined = _.find(this.fields, {fieldName})
        if(!fieldItem){
            return ''
        }
        if(fieldItem.controlType === 16){
            let dateArr = fieldItem.value.split(',')
            if(dateArr[0] && dateArr[1]){
                return dateArr[0]+' 00:00:00'+'#'+dateArr[1]+' 23:59:59'
            }else{
                return ''
            }
        }else if(fieldItem.controlType === 8){
            let newValue = fieldItem.value ? JSON.parse(fieldItem.value) : ''
            if(newValue && newValue.length > 0){
                return newValue[0]
            }else{
                return ''
            }
        }else{
            return fieldItem.value || ''
        }
    }

    @action
    public getQueryReq(page?: number): contentMgTypes.taskCenter.IQueryReq {
        if(page){
            this.page = page
        }
        let req: contentMgTypes.taskCenter.IQueryReq = {
            pageIndex: this.page,
            pageSize: this.size,
            userCode: '', 
            keyword: this.keyword,
            conditions: {
                taskStatus: this.taskStatus === '0' ? '' : Number(this.taskStatus),
                taskType: this.getAdvancedSearchFieldValue("taskType"),
                clipType: this.getAdvancedSearchFieldValue("clipType"),
                createUserName: this.getAdvancedSearchFieldValue("createUserName"),
                targetInfoCode: this.getAdvancedSearchFieldValue("targetInfoCode"),
                createTime: this.getAdvancedSearchFieldValue("createTime"),
                updateTime: this.getAdvancedSearchFieldValue("updateTime"),
            }
        }
        if(this.currentSort){
            req.sort = this.currentSort.split('|')[0]
            req.order = this.currentSort.split('|')[1]
        }
        if(req.conditions.taskType || req.conditions.clipType || req.conditions.createUserName || req.conditions.targetInfoCode || req.conditions.createTime || req.conditions.updateTime){
            this.hasAdvancedConditions = true
        }else{
            this.hasAdvancedConditions = false
        }
        return req
    }

    @action
    public getBaseCfg(){
        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.getBaseCfg().then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.baseConfig.taskCenterTableTemplate = res.data.data.taskCenterTableTemplate
                        if(res.data.data.taskCenterPageSizes?.length > 0){
                            this.baseConfig.taskCenterPageSizes = res.data.data.taskCenterPageSizes
                        }
                        const pageSizeStorage = localStorage.getItem('taskCenterPageSize');
                        if (pageSizeStorage && this.baseConfig.taskCenterPageSizes.indexOf(''+parseInt(pageSizeStorage, 10)) > -1){
                            this.size = parseInt(pageSizeStorage, 10)
                        }else{
                            if(this.baseConfig.taskCenterPageSizes?.length > 0){
                                this.size = Number(this.baseConfig.taskCenterPageSizes[0])
                            }
                        }
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public queryList(page?: number) {
        this.clearListSelected()
        this.isSearching = true

        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.queryList(this.getQueryReq(page)).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        if(res.data.data.data){
                            res.data.data.data.map((o)=>o.isEdit = false)
                        }
                        this.getTaskStepsAndPriority(res.data.data)
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /*列表继续查询流程步骤和优先级 */
    @action
    public getTaskStepsAndPriority(listRes: contentMgTypes.taskCenter.IQueryRes) {
        if (listRes && listRes.data && listRes.data.length > 0) {
            let flowIds: string[] = _.map(listRes.data, 'flowId')
            flowIds = Array.from(new Set(flowIds))
            contentMgApi.taskCenter.getTaskFlow(flowIds).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        if (res.data.data.length > 0) {
                            res.data.data.map((o: any) => {
                                listRes.data.map((item) => {
                                    if (item.flowId === o.flowId) {
                                        item.taskSteps = o.taskSteps
                                    }
                                })
                            })
                        }
                        this.getPriority(listRes)
                    } else {
                        this.queryListRes = listRes
                        this.isSearching = false
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                this.queryListRes = listRes
                this.isSearching = false
            })
        } else {
            this.queryListRes = listRes
            this.isSearching = false
        }
    }
    /*列表继续查询优先级 */
    @action
    public getPriority(listRes: contentMgTypes.taskCenter.IQueryRes) {
        if (listRes && listRes.data && listRes.data.length > 0) {
            let flowIds: string[] = _.map(listRes.data, 'flowId')
            flowIds = Array.from(new Set(flowIds))
            contentMgApi.taskCenter.getPriority(flowIds).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        _.keys(res.data.data).map((o)=>{
                            listRes.data.map((item) => {
                                if (item.flowId === o) {
                                    item.priority = res.data.data[o]
                                }
                            })
                        })
                        this.queryListRes = listRes
                        this.isSearching = false
                    } else {
                        this.queryListRes = listRes
                        this.isSearching = false
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                this.queryListRes = listRes
                this.isSearching = false
            })
        } else {
            this.queryListRes = listRes
            this.isSearching = false
        }
    }
    @action
    public redoTask(flowIds: string[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.redoTask(flowIds).then((res) => {
                if(res.data.success){
                    resolve(true)
                }else{
                    reject()
                }
            }, (res) => {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public stopTask(flowIds: string[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.stopTask(flowIds).then((res) => {
                if(res.data.success){
                    resolve(true)
                }else{
                    reject()
                }
            }, (res) => {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public deleteTask(flowIds: string[]) {
        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.deleteTask(flowIds).then((res) => {
                if(res.data.success){
                    resolve(true)
                }else{
                    reject()
                }
            }, (res) => {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public setPriority(flowIds: string[], priority: number) {
        let req = {}
        flowIds.map((o)=>{
            req[o] = priority
        })
        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.setPriority(req).then((res) => {
                if(res.data.success){
                    resolve(true)
                }else{
                    reject()
                }
            }, (res) => {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 获取查询条件项： 任务类型、排序项 */
    @action
    public getConditionConfig(){
        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.getConditionConfig().then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.sortFields = res.data.data.sortFields || []
                        this.taskTypeList = res.data.data.taskTypes || []
                        this.handleTaskTypeFields()
                        resolve(true)
                    }else {
                        reject()
                    }
                })
            }, (res) => {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 获取全部分发渠道 */
    @action
    public getExternalSystem(){
        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.getExternalSystem().then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.externalSystem = res.data.data
                        let externalSystemControlData = {
                            "": "全部"
                        }
                        if(this.externalSystem){
                            this.externalSystem.map((o)=>{
                                externalSystemControlData[o.externalSystemIdentity] = o.externalSystemName
                            })
                        }
                        let externalSystemIndex = _.findIndex(this.fields,{fieldName:"targetInfoCode"})
                        this.initFieldsControlData(externalSystemIndex,externalSystemControlData)
                        resolve(true)
                    }else {
                        reject()
                    }
                })
            }, (res) => {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /**条件筛选项 */
    @action
    public initFieldsControlData(index: number, controlData: any){
        this.fields[index].controlData = JSON.stringify(controlData)
    }
    /**处理条件筛选项-默认 */
    @action
    public handleDefaultFields(){
        let typeControlData = {
            "": "全部"
        }
        if(this.stores.configStore.config && this.stores.configStore.config.entityTypes){
            this.stores.configStore.config.entityTypes.map((o: any)=>{
                typeControlData[o.code] = o.name
            })
        }
        let typeIndex = _.findIndex(this.fields,{fieldName:"clipType"})
        this.initFieldsControlData(typeIndex,typeControlData)
    }
    /**处理任务类型配置项 */
    @action
    public handleTaskTypeFields(){
        let taskTypeControlData = {
            "": "全部"
        }
        if(this.taskTypeList){
            this.taskTypeList.map((o: any)=>{
                taskTypeControlData[o.value] = o.key
            })
        }
        let typeIndex = _.findIndex(this.fields,{fieldName:"taskType"})
        this.initFieldsControlData(typeIndex,taskTypeControlData)
    }

    @action
    public getTaskDetail(flowId: string) {
        this.isSearchingDetail = true
        this.taskDetail = undefined
        return new Promise((resolve, reject) => {
            contentMgApi.taskCenter.getTaskDetail(flowId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        if(res.data.data.taskStepHistroy && res.data.data.taskStepHistroy.length > 0){
                            _.reverse(res.data.data.taskStepHistroy)  //历史步骤逆序
                        }
                        this.taskDetail = res.data.data
                        this.isSearchingDetail = false
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    public formatTaskTypeName(taskType: number): string{
        let item: contentMgTypes.taskCenter.ITaskTypeItem | undefined = _.find(this.taskTypeList, {value: taskType})
        return  item ? item.key : ''
    }

    public formatTaskStatusName(taskStatus: number): string{
        switch(taskStatus){
            case 0: return '等待'.l('taskCenter.wait');
            case 1: return '执行中'.l('taskCenter.excuting');
            case 2: return '完成'.l('taskCenter.finish');
            case 3: return '失败'.l('taskCenter.fail');
            default: return'';
        }
    }

}
