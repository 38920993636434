import { Stores } from 'src/stores'
import ExtendStore from "src/stores/base/extendStore"
import { observable, action, runInAction } from 'mobx'
import { message } from 'antd'
import _ from 'lodash'
import manageTypes from 'src/types/manageTypes'
import manageApi from 'src/apis/manageApi'
import EsSearchConfigStore from './esSearchConfigStore'

export default class IndexFieldConfigStore extends ExtendStore{
    public parentStore: EsSearchConfigStore;
    /* 索引字段配置 */
    @observable 
    public fieldNav: manageTypes.esSearchConfig.indexFieldConfig.IFieldNavItem[] = [
        {
            key: '0',
            title: '节目层',
            show: true,
        },
        {
            key: '1',
            title: '片段层',
            show: true,
        },
        {
            key: '2',
            title: '场景层',
            show: true,
        },
        {
            key: '3',
            title: '镜头层',
            show: true,
        },
    ]
    @observable 
    public currentType: string = "0"
    @observable 
    public fieldList: manageTypes.esSearchConfig.indexFieldConfig.IFieldListItem[] = []

    public indexTypeMap: manageTypes.esSearchConfig.indexFieldConfig.IIndexTypeItem[] = [
        {
            indexType: 0,
            name: '字符串',
            dataType: 'string',
            options: [
                {
                    indexType: 0,
                    name: '字符串',
                    dataType: 'string',
                },
                {
                    indexType: 3,
                    name: '枚举',
                    dataType: 'enum',
                },
                {
                    indexType: 6,
                    name: '路径',
                    dataType: 'treePath',
                },
                {
                    indexType: 7,
                    name: '人脸',
                    dataType: 'face',
                },
                {
                    indexType: 8,
                    name: '关键字',
                    dataType: 'keyword',
                },
            ]
        },
        {
            indexType: 1,
            name: '整数',
            dataType: 'long',
        },
        {
            indexType: 2,
            name: '小数',
            dataType: 'float',
        },
        {
            indexType: 3,
            name: '枚举',
            dataType: 'enum',
        },
        {
            indexType: 4,
            name: '布尔',
            dataType: 'boolean',
        },
        {
            indexType: 5,
            name: '时间',
            dataType: 'date',
        },
        {
            indexType: 6,
            name: '路径',
            dataType: 'treePath',
        },
        {
            indexType: 7,
            name: '人脸',
            dataType: 'face',
        },
        {
            indexType: 8,
            name: '关键字',
            dataType: 'keyword',
        },
    ]

    public constructor(stores: Stores, parentStore: EsSearchConfigStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    @action
    public setCurrentType = (currentType: string)=> {
        this.currentType = currentType
    }

    @action
    public getIndexFieldList(type?: string) {
        return new Promise((resolve, reject) => {
            manageApi.esSearchConfig.indexFieldConfig.getIndexFieldList(type || this.currentType).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.fieldList = res.data.data
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public addIndexField(req: manageTypes.esSearchConfig.indexFieldConfig.IAddFieldItem[]) {
        return new Promise((resolve, reject) => {
            manageApi.esSearchConfig.indexFieldConfig.addIndexField(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        message.success('添加成功'.l('com.addSuccess'))
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    
}
