import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../../manageStore';
import AppsMgStore from './appsMgStore';
import EntityTypeStore from './entityTypeStore';
import { action, observable, runInAction } from 'mobx';
import manageApi from 'src/apis/manageApi';
import manageTypes from 'src/types/manageTypes';
import { message } from 'antd';
import EditMetaFieldStore from './editMetaFieldStore';

export default class MetadataMgStore extends ExtendStore {
    public parentStore: ManageStore;
    public appsMgStore: AppsMgStore;
    public entityTypeStore: EntityTypeStore;
    public editMetaFieldStore: EditMetaFieldStore
    
    public inputType = { //元数据控件对应的汉字名称
        date: {
            1: '日期+时间',
            2: '日期'
        },
        integer: {
            4: '数字'
        },
        float: {
            19: '数字'
        },
        long: {
            4: '数字',
            9: '帧显示时码',
            11: '百纳秒显示时码',
            10: '存储容量'
        },
        double: {
            19: '小数类型',
            25: '评分'
        },
        string: {
            5: '单行文本',
            6: '多行文本',
            3: '时间',
            11: '百纳秒显示时码',
            12: '标签类型',
            14: '树形结构',
            16: '日期范围',
            20: '超链接',
            22: '富文本编辑框',
            24: '检索查询',
            28: '目录树',
            29: '分类树',
            31: 'CV标签',
            32: '智能标签',
            33: '字符串数组',
            34: '平铺选择',
            35: '分类标签选择',
            37: '地区选择'
        },
        boolean: { 7: 'bool值' },
        enum: { 8: '下拉框' },
        complex: { 15: '表格', 24: '检索查询', 31: 'CV标签', 38: '主色调展示'}
    };
    @observable
    public metadataSource: manageTypes.metadata.IMetadataSource[] = []
    @observable
    public allMetadataType: manageTypes.metadata.IAllMetadataTypeRes[]
    @observable
    public currentMetadataTypeId?: number = undefined
    @observable
    public currentGroupId?: number = undefined
    @observable
    public metadataTypeTab: manageTypes.metadata.IMetadataTypeRes[]
    @observable
    public metadataGroupTab: manageTypes.metadata.IMetadataGroupRes[]
    @observable
    public selectedRows: manageTypes.metadata.IMetadataSource[] = []
    @observable
    public isLoadingFullSearchData: boolean = true
    @observable
    public isLoadingTab: boolean = true
    @observable
    public addGroupModalVisible: boolean = false
    @observable
    public addMetadataTypeModalVisible: boolean = false


    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
        this.appsMgStore = new AppsMgStore(stores, this);
        this.entityTypeStore = new EntityTypeStore(stores, this);
        this.editMetaFieldStore = new EditMetaFieldStore(stores, this)
    }

    @action
    public setNewDataSource = (metadataSource:manageTypes.metadata.IMetadataSource[])=> {
        this.metadataSource = metadataSource
    }

    @action
    public setSelectedRows = (selectedRows:any)=> {
        this.selectedRows = selectedRows
    }

    @action
    public setAddGroupModalVisible = (visible: boolean)=> {
        this.addGroupModalVisible = visible
    }

    @action
    public setAddMetadataTypeModalVisible = (visible: boolean)=> {
        this.addMetadataTypeModalVisible = visible
    }

    @action 
    public checkedChangeMedataType = (code: string ,checked: boolean)=> {
        this.allMetadataType.forEach((item)=> {
            if(code === item.code){
                item.checked = checked
            }
        })
    }

    //字段删除
    @action
    public deleteItem = (fieldIds: number[])=> {
        return new Promise((resolve, reject)=> {
            manageApi.metadata.deleteMetadataFields(this.currentGroupId as number, fieldIds).then((res)=> {
                runInAction(()=> {
                    if(res.data.success){
                        message.success('删除成功')
                        this.getEntityMetadata()
                    }
                })
            }).catch(res=> {
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }

    //获取第一层tab
    @action
    public getMetadataType = ()=> {
        return new Promise((resolve, reject)=>{
            this.isLoadingTab = true
            manageApi.metadata.getMetadataType(this.appsMgStore.currentAppCode, this.entityTypeStore.currentEntityCode).then((res)=> {
                runInAction(()=>{
                    if(res.data.data){
                        this.metadataTypeTab = res.data.data
                        this.isLoadingTab = false
                        if (this.metadataTypeTab && this.metadataTypeTab.length > 0){
                            this.currentMetadataTypeId = this.metadataTypeTab[0].id;
                        }
                    }
                })
            }).catch((res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }

    //获取所有的元数据层
    @action
    public getAllMetadataType = ()=> {
        return new Promise(()=> {
            manageApi.metadata.getAllMetadataType(this.entityTypeStore.currentEntityCode).then((res)=> {
                runInAction(()=> {
                    if(res.data.success && res.data.data){
                        this.allMetadataType = res.data.data
                        this.allMetadataType.forEach((item: manageTypes.metadata.IAllMetadataTypeRes)=> {
                            if(this.metadataTypeTab.some((o: manageTypes.metadata.IMetadataTypeRes)=> {
                                return  item.code === o.metadataCode
                            })){
                                item.checked = true
                            }else{
                                item.checked = false
                            }
                        })
                    }
                })
            }).catch((res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
    }

    //获取第二层tab
    @action
    public getMetadataGroup = ()=> {
        return new Promise((resolve, reject)=>{
            if (!this.currentMetadataTypeId){
                reject();
                return;
            }
            manageApi.metadata.getMetadataGroup(this.currentMetadataTypeId).then((res)=>{
                runInAction(()=>{
                    if(res.data.success && res.data.data){
                        this.metadataGroupTab = res.data.data
                        if (this.metadataGroupTab && this.metadataGroupTab.length > 0){
                            this.currentGroupId = this.metadataGroupTab[0].id
                        }
                    }
                    resolve(undefined);
                })
            }).catch((res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject();
            })
        })
    }

    //获取元数据字段
    @action
    public getEntityMetadata = ()=> {
        return new Promise((resolve, reject)=>{
            if (!this.currentGroupId){
                reject();
                return;
            }
            this.isLoadingFullSearchData = true
            manageApi.metadata.getEntityMetadata(this.currentGroupId, this.entityTypeStore.programFormCode).then((res)=> {
                runInAction(()=>{
                    if(res.data.success && res.data.data){
                        this.metadataSource = res.data.data
                        this.metadataSource.forEach((item: manageTypes.metadata.IMetadataSource, index: number)=>{
                            item.dataIndex = index
                            if (item.controlData && item.controlType === 34){
                                let ctrlData = JSON.parse(item.controlData);
                                item.isNeedCustom = ctrlData.isNeedCustom;
                            }
                        })
                        this.isLoadingFullSearchData = false
                    }
                    resolve(undefined);
                })
            }).catch((res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject();
            })
        })
    }

    @action
    public setCurrentMetadataTypeId(Id?: number){
        this.currentMetadataTypeId = Id
    }

    @action
    public setCurrentGroupId(id?: number){
        this.currentGroupId = id;
    }

    @action
    public sortList(fieldCodes:string[]){
        return new Promise((resolve, reject)=> {
            if (!this.currentGroupId){
                reject();
                return;
            }
            manageApi.metadata.sortList(this.currentGroupId,fieldCodes).then((res)=> {
                runInAction(()=>{
                    if(res.data.success && res.data.data){
                    }
                    resolve(undefined);
                })
            }).catch((res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject();
            })
        })
    }
}
