import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import folderPermissionTypes, { folderOperate } from 'src/types/folderPermissionTypes';
import ccTypes from 'src/types/ccTypes';
import {Stores} from "../index";

export enum taskStatus {
    done = 'done',
    waiting = 'waiting',
    doing = 'doing'
}

export enum statusReason {
    interrupt = '中断',
    unknown = '未知错误',
    success = '成功',
    failure = '失败',
    not_exists = '不存在',
    busy = '繁忙(所在文件夹)',
    no_permission = '当前用户对文件夹无全部权限'
}

export default class CreateFolderStore extends BaseStore<Stores> {
    @observable
    public showCreateFolderModal: boolean = false
    @observable
    public showUpdateOwnerModal: boolean = false

    @observable
    public operateType: folderOperate
    @observable
    public folder: folderPermissionTypes.IOpenModalInfo
    @observable
    public task: folderPermissionTypes.IUpdatePermissionTask | undefined

    @action
    public setShowCreateFolderModal(show: boolean) {
        this.showCreateFolderModal = show
    }

    @action
    public setshowUpdateOwnerModal(show: boolean) {
        this.showUpdateOwnerModal = show
        if (!show) {
            this.task = undefined
        }
    }

    @action
    public setTask = (task: folderPermissionTypes.IUpdatePermissionTask) => {
        if (!this.task) {
            this.task = task
        } else if (task) {
            if (this.task.taskId !== task.taskId) {
                this.task.current += task.current
                this.task.total += task.total
                this.task.taskId = task.taskId
                this.task.status = task.status
                if (task.current === task.total) {
                    task.errorList.forEach(s => {
                        if (this.task) {
                            this.task.errorList.push(s)
                        }
                    })
                }
            } else {
                this.task = task
            }
            this.task.progress = this.task.current / this.task.total * 100
        }
        if (this.task && !this.showUpdateOwnerModal) {
            this.setshowUpdateOwnerModal(true)
        }
    }

    @action
    public open(request: folderPermissionTypes.IOpenModalInfo) {
        this.operateType = request.operateType
        this.folder = request
        if (this.operateType === folderOperate.create) {
            this.folder.name = '新建文件夹'.l('cloud.create')
        } else {
            this.folder.oldName = this.folder.name
        }
        //传入type
        this.setShowCreateFolderModal(true)
    }

    public openFolderPermissionEditor = (operateType: folderOperate, item: any, parent?: ccTypes.cloudTypes.IFullSearchParent[]) => {
        // 公共文件夹根目录this.state.parent.length === 1，就不传parentId了
        let request = {
            operateType,
            contentId: '',
            parentId: parent && parent.length > 1 ? parent[parent.length - 1].contentId : '',
            name: '',
            isCreate: true
        }
        if (operateType !== folderOperate.create) {
            request.contentId = item.contentId
            request.name = item.name
            request.isCreate = false;
        }

        this.open(request)
    }
}