import { action, observable, runInAction } from 'mobx';
import datasetDetailsApi from 'src/apis/datasetDetailsApi';
import { message } from 'antd'
import { Stores } from 'src/stores';
import DatasetDetailsStore from '../../datasetDetailsStore';
import datasetDetailsTypes from 'src/types/datasetDetailsTypes';
import ExtendStore from 'src/stores/base/extendStore';
import { reqCatch } from 'mam-core-react';
import _ from 'lodash'
import { treeUtil } from 'mam-common-utils';

export default class TreeStore extends ExtendStore {
    public datasetDetailsStore: DatasetDetailsStore
    @observable
    public treeDataArr: datasetDetailsTypes.ITreeItem[] = []//左侧树菜单
    @observable
    public expandedKeys: string[] = []//左侧树菜单展开key
    @observable
    public loadedKeys: string[] = []
    @observable
    public selectedFolderId: string = ""//左侧选中文件id

    @observable
    public addEditName: string = ""//添加修改文件名
    @observable
    public isEditInAddEditModal: boolean = false;
    @observable
    public showAddEditModal: boolean = false//添加编辑的模态框
    @observable
    public currentTreeNodeOrder: number = 0;
    //保存当前分页信息
    @observable
    public currentPageInfo: any = { pageIndex: 1, pageTotal: 1 };

    public constructor(stores: Stores, datasetDetailsStore: DatasetDetailsStore) {
        super(stores);
        this.datasetDetailsStore = datasetDetailsStore;
    }
    @action
    public setTreeDataArr = (arr: datasetDetailsTypes.ITreeItem[]) => {
        this.treeDataArr = arr
    }
    @action
    public setExpandedKeys = (expandedKeys: string[]) => {
        this.expandedKeys = expandedKeys
    }
    @action
    public setLoadedKeys = (loadedKeys: string[]) => {
        this.loadedKeys = loadedKeys
    }
    @action
    public pushExpandedKeys = (keyword: string) => {
        this.expandedKeys = [...this.expandedKeys, keyword]
    }
    @action
    public setCurrentTreeNodeOrder(currentTreeNodeOrder: number) {
        this.currentTreeNodeOrder = currentTreeNodeOrder;
    }
    //查询左侧树
    @action
    public getTreeChildren = (folderId: string, pageIndex?: number) => {
        const params = {
            folderId,
            pageSize: 50,
            pageIndex: pageIndex ? pageIndex : 1
        }
        return new Promise((resolve, reject) => {
            datasetDetailsApi.getTreeChildrenV2(params).then(res => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        res.data.data.data.map((v: any, i: number) => {
                            v.key = v.contentId;
                            v.title = v.showName;
                            v.parentId = folderId;
                        })
                        this.currentPageInfo = {
                            pageIndex: res.data.data.pageIndex,
                            pageTotal: res.data.data.pageTotal
                        }
                        resolve(res.data.data)
                    } else {
                        reject(false)
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject(false)
            })
        })
    }

    //初始化左侧树
    @action
    public initializationTreeData = () => {
        return new Promise((resolve, reject) => {
            this.setExpandedKeys([]);
            this.setLoadedKeys([]);
            datasetDetailsApi.getTreeRoot(this.datasetDetailsStore.contentId).then(res => {
                if (res.data.data) {
                    res.data.data.isRoot = true;
                    res.data.data.key = res.data.data.contentId;
                    res.data.data.title = res.data.data.showName;
                    res.data.data.children = [];
                    this.setTreeDataArr([res.data.data])
                    this.changeSelectedFolderId(res.data.data.contentId);
                    resolve([res.data.data])
                }
            }, () => {
                reject();
                this.setTreeDataArr([]);
            }).catch(reqCatch)
        })
    }

    //初始化左侧树 //新调接口避免数据污染
    @action
    public initializationTreeDataModal = () => {
        return new Promise((resolve, reject) => {
            datasetDetailsApi.getTreeRoot(this.datasetDetailsStore.contentId).then(res => {
                if (res.data.data) {
                    res.data.data.isRoot = true;
                    res.data.data.key = res.data.data.contentId;
                    res.data.data.title = res.data.data.showName;
                    res.data.data.children = [];
                    this.changeSelectedFolderId(res.data.data.contentId)
                    resolve([res.data.data])
                }
            }, () => {
                reject();
                this.setTreeDataArr([]);
            }).catch(reqCatch)
        })
    }

    //根据id返回id所在位置路径
    @action
    public findIndexArray(data: datasetDetailsTypes.ITreeItem[], contentId: string, indexArray: any[]): datasetDetailsTypes.ITreeItem[] {
        let arr = Array.from(indexArray)
        for (let i = 0, len = data.length; i < len; i++) {
            arr.push(data[i]);
            if (data[i].contentId === contentId) {
                return arr
            }
            let children = data[i].children;
            if (children && children.length) {
                let result = this.findIndexArray(children, contentId, arr);
                if (result) {
                    return result
                }
            }
            arr.pop()
        }
        return []
    }

    @action
    public addTreeNode(newNode: datasetDetailsTypes.ITreeItem) {
        if (this.selectedFolderId && this.treeDataArr.length > 0) {
            let newTree = this.treeDataArr.concat([]);
            let selectedNode = treeUtil.getTreeDataRecursively(newTree, 'key', this.selectedFolderId);
            if (selectedNode) {
                newNode.key = newNode.contentId;
                newNode.title = newNode.showName;
                newNode.parentId = selectedNode.key;
                if (!selectedNode.children) {
                    selectedNode.children = [newNode];
                }
                else {
                    selectedNode.children.push(newNode);
                }
                this.setTreeDataArr(newTree);
                setTimeout(() => {
                    this.datasetDetailsStore.entitysDataStore.queryEntitysData(1)
                }, 1000)
            }
        }
    }

    @action
    public removeTreeNode(node: datasetDetailsTypes.ITreeItem) {
        if (this.treeDataArr.length > 0 && node.parentId) {
            let newTree = this.treeDataArr.concat([]);
            let parentNode = treeUtil.getTreeDataRecursively(newTree, 'key', node.parentId);
            let removeNode = treeUtil.getTreeDataRecursively(newTree, 'key', node.key);
            if (parentNode && removeNode) {
                _.remove(parentNode.children, { key: removeNode.key });
                this.setTreeDataArr(newTree);
                if (newTree.length > 0) {
                    this.changeSelectedFolderId(parentNode.key);
                    setTimeout(() => {
                        this.datasetDetailsStore.entitysDataStore.queryEntitysData(1)
                    }, 1000)
                }
            }
        }
    }

    @action
    public changeSelectedFolderId = (id: string) => {
        this.selectedFolderId = id
    }

    @action
    public openAddEditModal(isEdit: boolean) {
        this.isEditInAddEditModal = isEdit;
        if (this.isEditInAddEditModal) {
            let selectedNode = treeUtil.getTreeDataRecursively(this.treeDataArr, 'key', this.selectedFolderId);
            if (selectedNode) {
                this.addEditName = selectedNode?.showName;
            }
        }
        this.showAddEditModal = true;
    }

    @action
    public handleAddEditName = (e: any) => {
        this.addEditName = e.target.value
    }

    @action
    public editTreeItemName = (folderId: string, name: string) => {
        let newArr = this.treeDataArr.concat([])
        let targetNode = treeUtil.getTreeDataRecursively(this.treeDataArr, 'key', folderId);
        if (targetNode) {
            targetNode.name = name
            targetNode.showName = name
        }
        this.treeDataArr = newArr;
    }

    @action
    public closeAddEditModal() {
        this.addEditName = '';
        this.isEditInAddEditModal = false;
        this.showAddEditModal = false;
    }
}