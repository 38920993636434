import {stores} from "../../index";
import {IEntityType} from "../../../types/configTypes";
import {message} from "antd";
import uploadTypes from "../../../types/uploadTypes";
import {uploader} from "../../../components/upload/core/uploader";
import {action, observable, runInAction} from "mobx";
import UploadStore from "./uploadStore";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import uploadApi from "src/apis/uploadApi";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";

export default class PicPkgHandler{
    @observable
    public selectedImageFile?: uploadTypes.IFile;
    @observable
    public activeTab: string;
    private uploadStore: UploadStore;
    constructor(uploadStore: UploadStore) {
        this.uploadStore = uploadStore;
    }

    @action
    public setSelectedImageFile(file?: uploadTypes.IFile){
        this.selectedImageFile = file;
    }

    @action
    public setActiveTab(tab: string){
        this.activeTab = tab;
        if (tab === 'singlePicMetadata'){
            // 切换到单图属性，如果未选择图片，则自动选中第一个
            if (!this.selectedImageFile 
                && this.uploadStore.readyTasks.length > 0 && this.uploadStore.readyTasks[0].files
                && this.uploadStore.readyTasks[0].files.length > 0){
                    this.selectedImageFile = this.uploadStore.readyTasks[0].files[0];
                }

            if (this.uploadStore.readyTasks.length > 0 
                && !this.uploadStore.readyTasks[0].metadata.extendMetadataInfo){
                uploadApi.getPicExtendFields().then(res=>{
                    runInAction(()=>{
                        // 初始化单图属性
                        if (res.data.data 
                            && this.uploadStore.readyTasks.length > 0
                            && this.uploadStore.readyTasks[0].files){
                            this.uploadStore.readyTasks[0].metadata.extendMetadataInfo = {
                                metadataType: 'biz_sobey_picpackage',
                                metadatas: this.uploadStore.readyTasks[0].files.map(file=>{
                                    const metaFields = commonUtil.copyObj(this.uploadStore.allMetadataFields.biz_sobey_picture[0].fields || []);
                                    // 将文件名赋给标题字段
                                    const titleField = _.find(metaFields, {fieldName: 'name_'});
                                    if (titleField){
                                        titleField.value = file.fileName;
                                    }
                                    return {
                                        guid: file.fileId,
                                        field: metaFields
                                    }
                                })
                            }
                        }
                    })
                });
            }
        }
    }

    @action
    public setSinglePicMetaField(items: IFormItem[], index: number, value: string){
        if (this.selectedImageFile && this.uploadStore.selectedTask
            && this.uploadStore.selectedTask.metadata.extendMetadataInfo)
        {
            const fileMetadata = _.find(this.uploadStore.selectedTask.metadata.extendMetadataInfo.metadatas, {guid: this.selectedImageFile.fileId})
            if (fileMetadata){
                fileMetadata.field = items;
            }
        }
    }

    /** 组装图片包数据 */
    public handleFiles(options: uploadTypes.IUploadOptions, tasks: uploadTypes.ITask[], files: uploadTypes.IFile[]): Promise<uploadTypes.IFile[]> {
        let result: uploadTypes.IFile[] = [];
        let resultP: Array<Promise<any>> = [];
        let picType = _.find(stores.configStore.config.entityTypes, { code: 'biz_sobey_picture' });
        let extensions :any;
        if (picType && picType.extensions){
            extensions = picType.extensions.split(',');
        }
        let ext: string[] = [];
        let fileName: string[] = [];
        let flg = false;
        _.forEach(files, (file: uploadTypes.IFile, index: number) => {
            file.id = commonUtil.getUuid().replace(/\-/g, '');
            file.fileId = file.id;
            if ((tasks && tasks.length > 0 && tasks[0].files
                    && uploader.checkExist(tasks[0].files, file))) {
                return;
            }
            if (file.metadata.ext && picType && extensions && _.indexOf(extensions, file.metadata.ext.toLowerCase()) > -1) { //检查是否为允许的格式

                //允许格式下，且是图片包上传时，给单图赋元数据
                if(this.uploadStore.picPackageMetadataField && this.uploadStore.picPackageMetadataField.metadata){
                    file.metadata.field = commonUtil.copyObj(this.uploadStore.picPackageMetadataField.metadata)
                    if(file.metadata.field){
                        //注意： 只有单图的名称对应的是fieldName: 'name'，其他的均对应fieldName: 'name_'
                        let metaField = _.find(file.metadata.field, { fieldName: 'name_' });
                        if (metaField){
                            metaField.value = file.metadata.name;
                        }
                    }
                }

                //允许格式下，且是图片包上传时，给单图赋元数据 webUpload
                if(this.uploadStore.allMetadataFields && this.uploadStore.allMetadataFields.biz_sobey_picture[0].fields){
                    file.metadata.field = commonUtil.copyObj(this.uploadStore.allMetadataFields.biz_sobey_picture[0].fields)
                    if(file.metadata.field){
                        //注意： 只有单图的名称对应的是fieldName: 'name'，其他的均对应fieldName: 'name_'
                        let metaField = _.find(file.metadata.field, { fieldName: 'name_' });
                        if (metaField){
                            metaField.value = file.fileName;
                        }
                    }
                }

                // 第一张图的名字赋给图片包
                if (index === 0 && (tasks[0].metadata.name == null || tasks[0].metadata.name === '')) {
                    tasks[0].metadata.name = file.metadata.name;
                    if (tasks[0].metadata.field)
                    {
                        let metaField = _.find(tasks[0].metadata.field, { fieldName: 'name_' });
                        if (metaField){
                            metaField.value = file.metadata.name;
                        }
                    }
                }
                result.push(file);
                resultP.push(this.getPreviewImage(options, picType, file));
            } else {
                flg = true;
                let temp = _.filter(ext, (item: string) => {
                    return item === file.metadata.ext;
                });
                if (!temp || temp.length === 0) {
                    ext.push(file.metadata.ext as string);
                }
                fileName.push((file.file as File).name);
            }
        });
        if (flg) {
            let a = ext.join("、");
            let b = fileName.join("、");
            message.error('对不起，图片包不支持 ' + a + ' 格式的文件，' + b + ' 添加失败')
        }
        return new Promise((resolve, reject)=>{
            Promise.all(resultP).then(()=>{
                resolve(result);
            });
        });
    }

    private getPreviewImage(options: uploadTypes.IUploadOptions, typeInfo: IEntityType, file: uploadTypes.IFile): Promise<any> {
        if (typeInfo.keyframe){
            file.image = typeInfo.keyframe.replace('~', '');
        }
        if (options.transferType === uploadTypes.TransferTypes.WEB) {
            //判断大小
            // if (file.image.length >= (2 * 1024 * 1024)) {
            //     return Promise.resolve();
            // }
            //判断扩展名
            let allowExt = ['png', 'gif', 'jpg', 'bmp', 'jpeg'];
            let ext = commonUtil.getExtension((file.file as File).name).toLowerCase();
            if (_.indexOf(allowExt, ext) === -1) {
                return Promise.resolve();
            }

            return new Promise((resolve, reject)=>{
                let reader = new FileReader();
                reader.onload = () => {
                    runInAction(()=>{
                        file.image = reader.result as string;
                        resolve(true);
                    })
                }
                reader.readAsDataURL(file.file as File);
            });
        } else { //vtube
            return Promise.resolve();
        }
    }
}
