import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import EntityStore from '../entityStore';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import { observable } from 'mobx';

export default class CopyrightStore extends ExtendStore {
    public parentStore: EntityStore;
    @observable
    public metadatas: IFormItem[]
    
    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;
    }
}