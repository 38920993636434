import { action, observable, runInAction } from "mobx";
import ccTypes from "../../types/ccTypes";
import CcStore from "./ccStore";
import { Stores } from "../index";
import ccApi from "src/apis/ccApi";
import _ from 'lodash';
import { message } from 'antd';

export default class TenantStore extends CcStore {
    @observable
    public currentSiteType: string
    @observable
    public tenantNav: ccTypes.sideContentTypes.ITenantNavItem[]
    
    @observable
    public params: ccTypes.searchTypes.ISearchParams

    @observable
    public fullSearchData?: ccTypes.searchTypes.IFullSearchRes;

    @observable
    public previewEntity: any;
    @observable
    public selectedPreviewEntity: ccTypes.searchTypes.IEntityPathData | undefined;
   
    @observable
    public searchKeyword1: string = '';  // 主搜索框值
    @observable
    public searchKeyword2: string = '';  // 次检索框值

    @observable
    public pageSize: number = 30;
    public pageSizes: number[] = [30,60,100]

    constructor(stores: Stores) {
        super(stores)
        this.initOutstoreStore(stores);
        this.initDeleteStore(stores);
        this.initCreatePlanningStore(stores);
        this.initOutputExcelStore(stores);
        this.initExportExcelStore(stores);
        this.initUnlockStore(stores);
        this.initShareStore(stores);
        this.initSelectAllStore(stores, 'stores.tenantStore.fullSearchData.data', 'selected');
        this.initAdvancedSearchStore(stores)
    }

    @action
    public setCurrentSiteType(siteType: string) {
        this.currentSiteType = siteType;
    }

    @action
    public queryFullSearchData(page?: number): Promise<any> {
        this.selectAllStore.switchAllSelected(false);
        this.fullSearchData = undefined;
        return new Promise((resolve, reject) => {
            this.getSortItems();
            if (this.stores.configStore.config.searchResultNum){
                this.pageSize = this.stores.configStore.config.searchResultNum;
            }
            const pageSizeStorage = localStorage.getItem('searchStore.pageSize');
            if (pageSizeStorage !== null){
                this.pageSize = parseInt(pageSizeStorage, 10)
            }
            if(!this.params.site){
                this.initTenantNavFirstSite()
            }
            let req: ccTypes.searchTypes.IFullSearchReq = {
                pageIndex: page || this.params.page,
                pageSize: this.pageSize,
                keyword: this.params.keyword,
                sortFields: [{
                    field: this.currentSort.field,
                    isDesc: this.currentSort.desc === 'desc'
                }],
                conditions: [],
                facetConditions: [],
                highLight: true
            };
            if (this.params.facetConditions) {
                this.params.facetConditions.forEach((condition) => {
                    if (condition.items) {
                        condition.items.forEach((n) => {
                            let item = _.find(req.facetConditions, { field: n.field });
                            if (item == null) {
                                req.facetConditions.push(n);
                            } else {
                                if (_.isArray(item.value)) {
                                    if (!_.includes(item.value, n.value)) {
                                        item.value.push(n.value as string);
                                    }
                                } else {
                                    if (item.value !== n.value) {
                                        item.value = [item.value, n.value as string];
                                    }
                                }
                            }
                        });
                    } else {
                        if (condition.field && condition.value) {
                            req.facetConditions.push({ field: condition.field, value: condition.value })
                        }
                    }
                });
            }
            if (this.params.resourceName) {
                req.resourceName = this.params.resourceName
            }

            ccApi.searchApi.fullSearch(req, this.params.site || "").then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.initFacets(res.data.data.facets);
                        if (res.data.data.data) {
                            this.resolveQueryResult(res.data.data.data);
                        }
                        this.fullSearchData = res.data.data;
                    }
                })
                resolve(true);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    @action
    public setParams(searchParams: ccTypes.searchTypes.ISearchParams) {
        this.params = searchParams;
        if (searchParams.keyword){
            if (searchParams.keyword[0]){
                this.setSearchKeyword1(searchParams.keyword[0]);
            }
            if (searchParams.keyword[1]){
                this.setSearchKeyword2(searchParams.keyword[1]);
            }
        }
    }

    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }
    @action
    public setSearchKeyword2(str: string) {
        this.searchKeyword2 = str;
    }

    /**
     * 重命名
     */
    @action
    public switchRenameStatus(index: number, editMode: boolean) {
        if (this.fullSearchData) {
            if (editMode) {
                this.fullSearchData.data[index].oldName = this.fullSearchData.data[index].name_
            }
            this.fullSearchData.data[index].editMode = editMode
        }
    }

    @action
    public updateShowName(index: number, newName: string) {
        if (this.fullSearchData) {
            this.fullSearchData.data[index].name_ = newName
        }
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
        localStorage.setItem('searchStore.pageSize', size.toString());
        this.queryFullSearchData(1).then(()=>{});
    }

    /** 设置预览素材 */
    @action 
    public setPreviewEntity(entity: any){
        this.previewEntity = entity;
        this.getFilePathByContentId(entity.contentId);
    }

    /** 根据素材id获取地址 */
    @action 
    public getFilePathByContentId(contentId:string){
        ccApi.searchApi.getEntityPath(contentId).then(res=>{
            runInAction(() => {
                if(res.data.success&&res.data.data&&res.data.data[0]){
                    this.selectedPreviewEntity = res.data.data[0];
                }else{
                    this.selectedPreviewEntity = undefined
                }
            })
        },res=>{
            this.selectedPreviewEntity = undefined;
        })
    }

    //查询租户的主菜单数
    @action
    public getTenantNav(){
        return new Promise((resolve, reject) => {
            ccApi.sideContentApi.getTenantNav().then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.tenantNav = res.data.data;
                        if(this.tenantNav.length > 0){
                            this.tenantNav.map((item: ccTypes.sideContentTypes.ITenantNavItem)=>{
                                this.getTenantNavTree(item)
                            })
                        }
                    }
                })
                resolve(true);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    //查询租户的某一主菜单数下租户列
    @action
    public getTenantNavTree(item: ccTypes.sideContentTypes.ITenantNavItem){
        ccApi.sideContentApi.getTenantNavTree(item.siteTypeCode).then((res) => {
            runInAction(() => {
                if (res.data.success === true && res.data.data) {
                    item.tree = res.data.data
                }else{
                    item.tree = []
                }
            })
        }, (res) => {
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }

    //获取租户菜单下的第一个租户列
    @action
    public initTenantNavFirstSite(){
        if(this.tenantNav && this.tenantNav.length > 0){
            this.tenantNav.map((item)=>{
                if(item.siteTypeCode === this.currentSiteType && item.tree && item.tree.length > 0){
                    this.params.site = item.tree[0].siteCode
                }
            })
        }
    }

    //设置主菜单打开状态
    @action
    public setTenantNavOpen(siteTypeCode: string, isFromChild?: boolean){
        if(this.tenantNav && this.tenantNav.length > 0){
            this.tenantNav.map((navItem)=>{
                if(navItem.siteTypeCode === siteTypeCode){
                    if(isFromChild){
                        navItem.showSubs = true
                    }else{
                        navItem.showSubs = !navItem.showSubs;
                    }
                }
            })
        }
    }

}
