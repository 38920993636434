import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import { IResponse } from "mam-core-react/dist/modules/http";
import manageTypes from "../types/manageTypes";
import EntityPart from "src/pages/entityPart/entityPart";
import { param } from "jquery";

namespace manageApi {

    // 实例对象相关
    export namespace instanceSetApi {
        // 获取实例对象列表
        export const getInstanceList = (): AxiosPromise<IResponse<manageTypes.instanceSetTypes.IInstanceItem[]>> => {
            return http.get<IResponse<manageTypes.instanceSetTypes.IInstanceItem[]>>(`/scntm/v1/configuration-center/manager/resource`)
        }
        // 新增实例对象
        export const addInstance = (req: manageTypes.instanceSetTypes.IInstanceItem): AxiosPromise<IResponse<manageTypes.instanceSetTypes.IInstanceItem>> => {
            return http.post<IResponse<manageTypes.instanceSetTypes.IInstanceItem>>('/scntm/v1/configuration-center/manager/resource', req)
        }
        // 更新实例对象
        export const updateInstance = (req: manageTypes.instanceSetTypes.IInstanceItem): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>('/scntm/v1/configuration-center/manager/resource', req)
        }
        // 删除实例对象
        export const deleteInstance = (namespace: string, resourceId: string | undefined): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/resource?namespace=${namespace}&resourceId=${resourceId}`)
        }
        // 获取实例对象配置
        export const getInstanceDetail = (entityType: string): AxiosPromise<IResponse<manageTypes.instanceSetTypes.IConfiguration>> => {
            return http.get<IResponse<manageTypes.instanceSetTypes.IConfiguration>>(`/scntm/v1/configuration-center/manager/resource/${entityType}/extension`)
        }
        // 更新实例对象配置
        export const updateInstanceDetail = (entityType: string, req: manageTypes.instanceSetTypes.IConfiguration): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/${entityType}/extension`, req)
        }
        // 获取实例对象想基本元数据列表 /v1/configuration-center/manager/resource/{type}/entity-fields
        export const getBaseMetaList = (type: string): AxiosPromise<IResponse<manageTypes.instanceSetTypes.IBaseMeta[]>> => {
            return http.get<IResponse<manageTypes.instanceSetTypes.IBaseMeta[]>>(`/scntm/v1/configuration-center/manager/resource/${type}/entity-fields`)
        }
        // 实例对象下新增基本元数据
        export const addBaseMeta = (type: string, req: manageTypes.instanceSetTypes.IBaseMeta[]): AxiosPromise<IResponse<any>> => {
            const applyAll = req[0].applyAll ? req[0].applyAll : false
            Reflect.deleteProperty(req[0], 'applyAll')
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/${type}/entity-fields?applyAll=${applyAll}`, req)
        }
        // 实例对象下更新基本元数据
        export const updateBaseMeta = (type: string, req: manageTypes.instanceSetTypes.IBaseMeta): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/${type}/entity-fields`, req)
        }
        // 实例对象下删除基本元数据
        export const deleteBaseMeta = (type: string, fieldIds: string[]): AxiosPromise<IResponse<any>> => {
            let ids = '';
            fieldIds.forEach((id) => {
                ids += 'fieldIds=' + id + '&'
            })
            ids = ids.substring(0, ids.length - 1)
            return http.delete(`/scntm/v1/configuration-center/manager/resource/${type}/entity-fields?` + ids)
        }
        // 实例对象下获取拓展元数据业务对象
        export const getExpandMetaObjList = (type: string): AxiosPromise<IResponse<manageTypes.instanceSetTypes.IInstanceItem[]>> => {
            return http.get<IResponse<manageTypes.instanceSetTypes.IInstanceItem[]>>(`/scntm/v1/configuration-center/manager/resource/${type}/metadata`)
        }
        // 实例对象下新增拓展元数据业务对象
        export const addExpandInstance = (type: string, req: manageTypes.instanceSetTypes.IInstanceItem): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/${type}/metadata`, req)
        }
        // 实例对象下修改拓展元数据业务对象
        export const editExpandInstance = (type: string, req: manageTypes.instanceSetTypes.IInstanceItem): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/${type}/metadata`, req)
        }
        // 实例对象下新增拓展元数据业务对象
        export const deleteExpandInstance = (type: string, id: string): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/resource/${type}/metadata/${id}`)
        }
        // 实例对象下选中业务对象获取业务对象下元数据
        export const getExpandMetaList = (type: string, instanceType: string | undefined): AxiosPromise<IResponse<manageTypes.instanceSetTypes.IBaseMeta[]>> => {
            return http.get<IResponse<manageTypes.instanceSetTypes.IBaseMeta[]>>(`/scntm/v1/configuration-center/manager/resource/${type}/metadata-fields/${instanceType}`)
        }
        // 实例对象下选中业务对象新增业务对象下元数据
        export const addExpandMeta = (type: string, instanceType: string | undefined, req: manageTypes.instanceSetTypes.IBaseMeta[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/${type}/metadata-fields/${instanceType}`, req)
        }
        // 实例对象下选中业务对象更新业务对象下元数据
        export const updateExpandMeta = (type: string, instanceType: string | undefined, req: manageTypes.instanceSetTypes.IBaseMeta): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/${type}/metadata-fields/${instanceType}`, req)
        }
        // 实例对象下选中业务对象删除业务对象下元数据
        export const deleteExpandMeta = (type: string, instanceType: string | undefined, id: string[]): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/resource/${type}/metadata-fields/${instanceType}?fieldIds=${id}`)
        }
        // 实例对象下获取文件组列表
        export const getFileGroupList = (type: string): AxiosPromise<IResponse<manageTypes.instanceSetTypes.IFileGroupItem[]>> => {
            return http.get<IResponse<manageTypes.instanceSetTypes.IFileGroupItem[]>>(`/scntm/v1/configuration-center/manager/resource/${type}/filegroup`)
        }
        // 实例对象下新增文件组
        export const addFileGroup = (type: string, req: manageTypes.instanceSetTypes.IFileGroupItem): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/${type}/filegroup`, req)
        }
    }

    //层面配置相关
    export namespace facetSetApi {
        //获取层面列表
        export const getFacetList = (): AxiosPromise<IResponse<manageTypes.facetSetTypes.IFacetFieldItem[]>> => {
            return http.get<IResponse<manageTypes.facetSetTypes.IFacetFieldItem[]>>(`/scntm/v1/configuration-center/manager/search/facet`)
        }
        //更新层面列表
        export const updateFacetList = (list: manageTypes.facetSetTypes.IFacetFieldItem[]): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/search/facet`, list)
        }
    }

    export namespace metadata {
        // 获取entityType层数据
        export const getEntityTypeList = (): AxiosPromise<IResponse<manageTypes.instanceSetTypes.IInstanceItem[]>> => {
            return http.get<IResponse<manageTypes.instanceSetTypes.IInstanceItem[]>>(`/scntm/v1/configuration-center/manager/resource`)
        }
        export const getProgramFormList = (apllicationCode: string): AxiosPromise<IResponse<manageTypes.metadata.IProgramFormItem[]>> => {
            return http.get<IResponse<manageTypes.metadata.IProgramFormItem[]>>(`/scntm/v1/configuration-center/manager/metadata-field/programform/${apllicationCode}`)
        }
        export const getProgramFormEnums = (entityTypeCode: string): AxiosPromise<IResponse<manageTypes.metadata.IProgramFormEnumsRes[]>> => {
            return http.get<IResponse<manageTypes.metadata.IProgramFormEnumsRes[]>>(`/scntm/v1/configuration-center/manager/metadata-field/programform/${entityTypeCode}/enums`)
        }
        export const saveProgramForm = (apllicationCode: string, programFormItem: manageTypes.metadata.IProgramFormItem): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/metadata-field/programform/${apllicationCode}`, programFormItem)
        }
        export const deleteProgramForm = (apllicationCode: string, entityTypeCode: string): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/metadata-field/programform/${apllicationCode}/${entityTypeCode}`)
        }
        //增加app层
        export const addApplication = (params: manageTypes.metadata.IAddApplication): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/metadata-field/application`, params)
        }
        //获取app层数据
        export const getApplication = (): AxiosPromise<IResponse<manageTypes.metadata.IApplicationRes[]>> => {
            return http.get<IResponse<manageTypes.metadata.IApplicationRes[]>>(`/scntm/v1/configuration-center/manager/metadata-field/application`)
        }
        //更新app层
        export const updateApplication = (params: manageTypes.metadata.IAddApplication): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/metadata-field/application`, params)
        }
        //获取要显示元数据层
        export const getMetadataType = (applicationCode: string, entityTypeCode: string): AxiosPromise<IResponse<manageTypes.metadata.IMetadataTypeRes[]>> => {
            return http.get<IResponse<manageTypes.metadata.IMetadataTypeRes[]>>(`/scntm/v1/configuration-center/manager/metadata-field/${applicationCode}/${entityTypeCode}/metadata-type`)
        }
        //添加元数据层
        export const addMetadataType = (params: manageTypes.metadata.IAddMetadataType): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/configuration-center/manager/metadata-field/metadata-type', params)
        }
        //删除元数据层
        export const deleteMetadataType = (id: number): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/metadata-field/metadata-type/${id}`)
        }
        //获取所有元数据层
        export const getAllMetadataType = (entityTypeCode: string): AxiosPromise<IResponse<manageTypes.metadata.IAllMetadataTypeRes[]>> => {
            return http.get<IResponse<manageTypes.metadata.IAllMetadataTypeRes[]>>(`/scntm/v1/configuration-center/manager/resource/${entityTypeCode}/metadata`)
        }
        //获取元数据层下的分组
        export const getMetadataGroup = (metadataTypeId: number): AxiosPromise<IResponse<manageTypes.metadata.IMetadataGroupRes[]>> => {
            return http.get<IResponse<manageTypes.metadata.IMetadataGroupRes[]>>(`/scntm/v1/configuration-center/manager/metadata-field/${metadataTypeId}/group`)
        }
        // 获取元数据分组下的字段
        export const getEntityMetadata = (groupId: number, programform?: string): AxiosPromise<IResponse<manageTypes.metadata.IMetadataSource[]>> => {
            return http.get<IResponse<manageTypes.metadata.IMetadataSource[]>>(`/scntm/v1/configuration-center/manager/metadata-field/${groupId}` + (programform ? `?programform=${programform}` : ''))
        }
        //字段配置 增加元数据字段
        export const addMetadataFields = (groupId: number, params: manageTypes.metadata.IAddMetadataFeild[], programform?: string): AxiosPromise<IResponse<manageTypes.metadata.IAddMetadataFeild[]>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/metadata-field/${groupId}` + (programform ? `?programform=${programform}` : ''), params)
        }
        //增加app层
        export const updateEntityMetadata = (groupId: number, fieldId: number, params: manageTypes.metadata.IMetadataSource): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/metadata-field/${groupId}/${fieldId}`, params)
        }
        //元数据下新增分组
        export const addGroup = (params: manageTypes.metadata.IAddGroup): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/metadata-field/group`, params)
        }
        export const deleteGroup = (groupId: number): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/metadata-field/group/${groupId}`)
        }
        //获取所有的元数据字段
        export const getOriginEntityMetadata = (metadataTypeId: number): AxiosPromise<IResponse<manageTypes.metadata.IOriginFieldRes[]>> => {
            return http.get<IResponse<manageTypes.metadata.IOriginFieldRes[]>>(`/scntm/v1/configuration-center/manager/metadata-field/${metadataTypeId}/origin-fields`)
        }
        //字段配置 删除字段
        export const deleteMetadataFields = (groupId: number, fieldIds: number[], programform?: string): AxiosPromise<IResponse<any>> => {
            let ids = '';
            fieldIds.forEach((id) => {
                ids += 'fieldIds=' + id + '&'
            })
            ids = ids.substring(0, ids.length - 1)
            return http.delete(`/scntm/v1/configuration-center/manager/metadata-field/${groupId}?` + ids + (programform ? `&programform=${programform}` : ''))
        }
        //增加拖拽排序
        export const sortList = (groupId: number, fieldCodes: string[], programform?: string): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/metadata-field/order/${groupId}` + (programform ? `?programform=${programform}` : ''), fieldCodes)
        }
        //查询分类树
        export const getCategoryTree = (): AxiosPromise<IResponse<manageTypes.metadata.ICategoryTreeItem[]>> => {
            return http.get<IResponse<manageTypes.metadata.ICategoryTreeItem[]>>(`/scntm/v1/configuration-center/manager/category-tree`)
        }
    }
    // 枚举配置
    export namespace enumerationApi {
        export const getList = (parentId: string | number): AxiosPromise<IResponse<manageTypes.enumerationTypes.IEnumListItem[]>> => {
            return http.get<IResponse<manageTypes.enumerationTypes.IEnumListItem[]>>(`/scntm/v1/configuration-center/manager/resource/fixitem/${parentId}`)
        }
        //新增整个枚举项(含多个子项)
        export const addEnumAll = (req: manageTypes.enumerationTypes.IAddEditEnumReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/fixitem/entirety`, req)
        }
        //新增单个枚举项/子项
        export const addEnum = (req: manageTypes.enumerationTypes.IAddEditEnumReq[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/fixitem`, req)
        }
        //修改单个枚举项/子项
        export const editEnum = (req: manageTypes.enumerationTypes.IAddEditEnumReq): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/resource/fixitem`, req)
        }
        //删除单个枚举项或子项
        export const deleteEnum = (id: string) => {
            return http.delete(`/scntm/v1/configuration-center/manager/resource/fixitem/${id}`)
        }
    }

    export namespace businessLogApi {
        export const getBusinessLogList = (listReq: manageTypes.businessLogTypes.businessLogListReq): AxiosPromise<IResponse<manageTypes.businessLogTypes.businessLogRes>> => {
            return http.get<IResponse<manageTypes.businessLogTypes.businessLogRes>>(`/scntm/v1/configuration-center/manager/business-log?pageSize=30${listReq?.pageIndex ? '&pageIndex=' + listReq?.pageIndex : ''}${listReq?.operateType ? '&operateType=' + listReq?.operateType : ''}${listReq?.operateStartTime ? '&operateStartTime=' + listReq?.operateStartTime : ''}${listReq?.operateEndTime ? '&operateEndTime=' + listReq?.operateEndTime : ''}`)
        }

        // export const getLogType = ():AxiosPromise<IResponse<any>> =>{
        //     return http.get<IResponse<any>>('/sboic/v1/business/business-log/get-log-type')
        // }

        export const getClientAndOperateType = (): AxiosPromise<IResponse<manageTypes.businessLogTypes.clientAndOperateTypeRes[]>> => {
            return http.get<IResponse<manageTypes.businessLogTypes.clientAndOperateTypeRes[]>>('/scntm/v1/configuration-center/manager/business-log/filters')
        }
    }

    //容量配置相关接口
    export namespace storageApi {
        // 获取存储列表
        export const getStorageList = (): AxiosPromise<IResponse<manageTypes.storageTypes.IStorageListItem[]>> => {
            return http.get<IResponse<manageTypes.storageTypes.IStorageListItem[]>>(`/scntm/v1/configuration-center/manager/storage`)
        }
        // 获取存储下已分配的容量: type: user、org、role
        export const getAllotStorage = (type: string, code: string): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/scntm/v1/configuration-center/manager/storage/space/${type}/${code}`)
        }
        // 清空存储下分配的存储空间
        export const clearStorage = (spaceId: string): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/storage/space/${spaceId}`)
        }
        // 新增分配空间
        export const addAllotStorage = (req: manageTypes.storageTypes.IAddAllotStorageReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/storage/space`, req)
        }
        // 更新分配空间
        export const editAllotStorage = (req: manageTypes.storageTypes.IEditAllotStorageReq): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/storage/space`, req)
        }
    }
    //权限组管理
    export namespace authGroupApi {
        export const getList = (req: manageTypes.authGroupTypes.IGetGroupListReq): AxiosPromise<IResponse<manageTypes.authGroupTypes.IGetGroupListRes>> => {
            return http.get<IResponse<manageTypes.authGroupTypes.IGetGroupListRes>>(`/scntm/v1/configuration-center/manager/usergroup?pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&keyword=${req.keyword}`)
        }
        export const addGroup = (req: manageTypes.authGroupTypes.IAddEditGroupReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/usergroup`, req)
        }
        export const editGroup = (req: manageTypes.authGroupTypes.IAddEditGroupReq): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/usergroup`, req)
        }
        export const deleteGroup = (groupCode: string) => {
            return http.delete(`/scntm/v1/configuration-center/manager/usergroup/${groupCode}`)
        }
        //获取基本信息
        export const getGroupInfo = (groupCode: string): AxiosPromise<IResponse<manageTypes.authGroupTypes.IGroupListItem>> => {
            return http.get<IResponse<manageTypes.authGroupTypes.IGroupListItem>>(`/scntm/v1/configuration-center/manager/usergroup/${groupCode}`)
        }
        //获取已分配用户
        export const getGroupUsers = (groupCode: string, req: manageTypes.userSetTypes.IGetAllUserListReq): AxiosPromise<IResponse<manageTypes.authGroupTypes.IGetAuthGroupUsersRes>> => {
            return http.get<IResponse<manageTypes.authGroupTypes.IGetAuthGroupUsersRes>>(`/scntm/v1/configuration-center/manager/usergroup/${groupCode}/users?pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&keyword=${req.keyword}`, { headers: { hideLoading: true } })
        }
        //权限组添加分配用户
        export const addGroupUsers = (groupCode: string, users: manageTypes.authGroupTypes.IAuthGroupUsersItem[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/usergroup/${groupCode}/users`, users)
        }
        //权限组修改分配用户
        export const editGroupUsers = (groupCode: string, users: manageTypes.authGroupTypes.IAuthGroupUsersItem[]): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/usergroup/${groupCode}/users`, users)
        }
        //删除分配用户
        export const deleteGroupUsers = (groupCode: string, userCodes: string[]) => {
            return http.delete(`/scntm/v1/configuration-center/manager/usergroup/${groupCode}/users`, { data: userCodes })
        }
    }
    //全部用户管理
    export namespace userSetApi {
        export const getAllUserList = (req: manageTypes.userSetTypes.IGetAllUserListReq): AxiosPromise<IResponse<manageTypes.userSetTypes.IGetUserListRes>> => {
            return http.get<IResponse<manageTypes.userSetTypes.IGetUserListRes>>(`/scntm/v1/configuration-center/manager/user?pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&keyword=${req.keyword}&isDisable=${req.isDisable}`)
        }
    }

    //功能权限相关
    export namespace authSetApi {
        //用户相关： 获取功能权限列表
        export const getAuthList = (): AxiosPromise<IResponse<manageTypes.authSetTypes.IAuthListItem[]>> => {
            return http.get<IResponse<manageTypes.authSetTypes.IAuthListItem[]>>(`/scntm/v1/configuration-center/manager/function-permission`)
        }
        export const addAuth = (req: manageTypes.authSetTypes.IAddEditAuthReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/function-permission`, req)
        }
        export const editAuth = (req: manageTypes.authSetTypes.IAuthListItem): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/function-permission`, req)
        }
        export const deleteAuth = (code: string) => {
            return http.delete(`/scntm/v1/configuration-center/manager/function-permission/${code}`)
        }
    }

    //部门管理相关
    export namespace orgSetApi {
        // 部门相关： 获取部门树节点
        export const getOrgTreeNode = (organizationCode: string): AxiosPromise<IResponse<manageTypes.userSetTypes.IOrgTreeItem[]>> => {
            return http.get<IResponse<manageTypes.userSetTypes.IOrgTreeItem[]>>(`/scntm/v1/configuration-center/manager/organization?organizationCode=${organizationCode}`)
        }
        export const addOrg = (req: manageTypes.userSetTypes.IAddEditOrgReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/organization`, req)
        }
        export const editOrg = (req: manageTypes.userSetTypes.IAddEditOrgReq): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/organization`, req)
        }
        export const deleteOrg = (organizationCode: string) => {
            return http.delete(`/scntm/v1/configuration-center/manager/organization/${organizationCode}`)
        }
        //查询部门的扩展信息
        export const getOrgExtendData = (organizationCode: string): AxiosPromise<IResponse<manageTypes.userSetTypes.IOrgExtendData>> => {
            return http.get<IResponse<manageTypes.userSetTypes.IOrgExtendData>>(`/customized/v1/organization?orgCode=${organizationCode}`)
        }
        //保存部门的扩展信息
        export const saveOrgExtendData = (formData: FormData): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/customized/v1/organization`, formData, {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            })
        }

        //用户相关： 查询当前部门的用户列表
        export const getCurUserList = (req: manageTypes.userSetTypes.IGetUserListReq): AxiosPromise<IResponse<manageTypes.userSetTypes.IGetUserListRes>> => {
            return http.get<IResponse<manageTypes.userSetTypes.IGetUserListRes>>(`/scntm/v1/configuration-center/manager/organization/${req.organizationCode}/user?isIncludeSubuser=${req.isIncludeSubuser}&isOnlyRootUser=${req.isOnlyRootUser}&isContainsDisableUser=${req.isContainsDisableUser}&pageIndex=${req.pageIndex}&pageSize=${req.pageSize}`)
        }
        // 查多个部门下的用户
        export const getOrgsUserList = (req: manageTypes.userSetTypes.IGetUserListReq): AxiosPromise<IResponse<manageTypes.userSetTypes.IGetUserListRes>> => {
            let paramStr = '';
            if (req.organizationCodes) {
                req.organizationCodes.forEach((code) => {
                    paramStr += '&organizationCodes=' + code;
                })
            }
            return http.get<IResponse<manageTypes.userSetTypes.IGetUserListRes>>(`/scntm/v1/configuration-center/manager/organization/user?isIncludeSubuser=${req.isIncludeSubuser}&isOnlyRootUser=${req.isOnlyRootUser}&isContainsDisableUser=${req.isContainsDisableUser}&pageIndex=${req.pageIndex}&pageSize=${req.pageSize}${paramStr}`)
        }

        export const addUser = (req: manageTypes.userSetTypes.IAddUserReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/user`, req)
        }
        export const editUser = (req: manageTypes.userSetTypes.IEditUserReq): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/user`, req)
        }
        export const deleteUser = (userCodes: string[]) => {
            return http.delete(`/scntm/v1/configuration-center/manager/user?userCodes=${userCodes}`)
        }
        //给用户分配角色
        export const assignRoleToUser = (userCode: string, roleCodes: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/user/${userCode}/roles`, roleCodes)
        }

        //获取所有角色
        export const getRoleList = (req: manageTypes.userSetTypes.IGetRoleListReq): AxiosPromise<IResponse<manageTypes.userSetTypes.IGetRoleListRes>> => {
            return http.get<IResponse<manageTypes.userSetTypes.IGetRoleListRes>>(`/scntm/v1/configuration-center/manager/role?pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&keyword=${req.keyword}&sortFields=${req.sortFields}`)
        }

    }

    //角色定义相关
    export namespace roleSetApi {
        // 获取角色列表
        export const getRoleList = (req: manageTypes.userSetTypes.IGetRoleListReq): AxiosPromise<IResponse<manageTypes.roleSetTypes.IRoles>> => {
            return http.get<IResponse<manageTypes.roleSetTypes.IRoles>>(`/scntm/v1/configuration-center/manager/role?pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&keyword=${req.keyword}&sortFields=${req.sortFields}`)
        }
        // 添加角色
        export const addRole = (req: manageTypes.roleSetTypes.IAddRoleReq): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/scntm/v1/configuration-center/manager/role', req)
        }
        export const editRole = (req: manageTypes.roleSetTypes.IAddRoleReq): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/role`, req)
        }
        // 删除角色
        export const deleteRole = (roleCodes: string[]): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/role?roleCodes=${roleCodes}`)
        }
        // 获取权限列表
        export const getControlList = (): AxiosPromise<IResponse<manageTypes.roleSetTypes.IControlRes[]>> => {
            return http.get<IResponse<manageTypes.roleSetTypes.IControlRes[]>>(`/scntm/v1/configuration-center/manager/function-permission`)
        }
        // 获取角色权限
        export const getRoleControl = (req: string): AxiosPromise<IResponse<manageTypes.roleSetTypes.IControlRes[]>> => {
            return http.get<IResponse<manageTypes.roleSetTypes.IControlRes[]>>(`/scntm/v1/configuration-center/manager/role/${req}/function-permission`)
        }
        // 取消角色权限
        export const deleteRoleControl = (roleCode: string, codes: string[]): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/role/${roleCode}/function-permission?fpCodes=${codes.join('&fpCodes=')}`)
        }
        // 角色设置权限
        export const setRoleControl = (roleCode: string, req: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>(`/scntm/v1/configuration-center/manager/role/${roleCode}/function-permission`, req)
        }
        // 获取角色下用户列表
        export const getRoleUsers = (req: manageTypes.roleSetTypes.IRoleUsersReq): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/scntm/v1/configuration-center/manager/role/${req.roleCode}/users?pageIndex=${req.pageIndex}&pageSize=${req.pageSize}&keyword=${req.keyword}`)
        }
        // 移除角色下用户
        export const deleteUserRole = (roleCode: string, codes: string[]): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/role/${roleCode}/users?userCodes=${codes}`)
        }
        // 角色下添加用户
        export const connectRoleUser = (roleCode: string, userCode: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/role/${roleCode}/users`, userCode)
        }
        // 角色下获取权限模板
        export const getTemplateList = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>('/scntm/v1/configuration-center/manager/permission-template?pageIndex=1&pageSize=50')
        }
        // 角色下更新模板名称
        export const updateTemplateName = (code: string, name: string): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/configuration-center/manager/permission-template`, { templateCode: code, templateName: name })
        }
        // 角色下新增模板
        export const addTemplate = (name: string): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/permission-template`, { templateName: name })
        }
        // 角色下移除模板
        export const deleteTemplate = (code: string): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/manager/permission-template/${code}`)
        }
        // 角色下获取权限模板属性值
        export const getCurrRoleAttribute = (roleCode: string): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/scntm/v1/configuration-center/manager/role/${roleCode}/permission-template`)
        }
        // 角色下更新权限模板属性值
        export const setCurrRoleAttribute = (roleCode: string, req: manageTypes.roleSetTypes.IAttributeReq[]): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/role/${roleCode}/permission-template`, req)
        }
    }

    export namespace esSearchConfig {
        // 选择字段-获取字段
        export const getFields = (typeName?: string): AxiosPromise<IResponse<manageTypes.esSearchConfig.IFieldTreeItem[]>> => {
            return http.get<IResponse<manageTypes.esSearchConfig.IFieldTreeItem[]>>(`/smcss/v1/configuration-center/manager/resource/fields/${typeName}`)
        }
        // 检索配置-检索开通
        export const tenantAction = (): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/smcss/v1/tenant/action?action-id=open`)
        }
        // 检索配置-重建索引
        export const rebuildIndex = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/rebuild-index`)
        }
        export namespace facetConfig {
            export const getAllFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.facetConfig.IFieldItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.facetConfig.IFieldItem[]>>(`/smcss/v1/configuration-center/manager/esindex/facet/fields`)
            }

            export const getFacetFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.facetConfig.IFieldItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.facetConfig.IFieldItem[]>>(`/smcss/v1/configuration-center/manager/esindex/facet`)
            }

            export const updateFacetField = (item: manageTypes.esSearchConfig.facetConfig.IFieldItem): AxiosPromise<IResponse<any>> => {
                return http.patch<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/facet`, item)
            }

            export const updateFacetFieldsOrder = (items: manageTypes.esSearchConfig.facetConfig.IFieldItem[]): AxiosPromise<IResponse<any>> => {
                return http.patch<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/facet/order`, items)
            }

            export const addFacetFields = (items: manageTypes.esSearchConfig.facetConfig.IFieldItem[]): AxiosPromise<IResponse<any>> => {
                return http.post<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/facet`, items)
            }

            export const deleteFacetFields = (id: number): AxiosPromise<IResponse<any>> => {
                return http.delete(`/smcss/v1/configuration-center/manager/esindex/facet/${id}`)
            }
        }
        export namespace advancedSearchConfig {
            export const getAllFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[]>>(`/smcss/v1/configuration-center/manager/esindex/advanced-search-condition/fields`)
            }

            export const getAdvancedSearchFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.advancedSearchConfig.IAdvancedSearchField[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.advancedSearchConfig.IAdvancedSearchField[]>>(`/smcss/v1/configuration-center/manager/esindex/advanced-search-condition`)
            }

            export const updateAdvancedSearchField = (item: manageTypes.esSearchConfig.advancedSearchConfig.IAdvancedSearchField): AxiosPromise<IResponse<any>> => {
                return http.patch<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/advanced-search-condition`, item)
            }

            export const addAdvancedSearchFields = (items: manageTypes.esSearchConfig.advancedSearchConfig.IAdvancedSearchField[]): AxiosPromise<IResponse<any>> => {
                return http.post<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/advanced-search-condition`, items)
            }

            export const deleteAdvancedSearchField = (id: number): AxiosPromise<IResponse<any>> => {
                return http.delete(`/smcss/v1/configuration-center/manager/esindex/advanced-search-condition/${id}`)
            }
            export const getCateTreeTypes = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.advancedSearchConfig.ICateTreeTypeItem[]>> => {
                return http.get(`/scntm/v1/configuration-center/manager/category-tree`)
            }
        }
        export namespace excelExportFieldsConfig {
            export const getAllFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[]>>(`/smcss/v1/configuration-center/manager/esindex/advanced-search-condition/fields`)
            }

            export const getExportFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.excelExportFieldsConfig.IExportExcelFieldItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.excelExportFieldsConfig.IExportExcelFieldItem[]>>(`/smcss/v1/configuration-center/manager/esindex/export-field`)
            }

            export const updateExportFields = (item: manageTypes.esSearchConfig.excelExportFieldsConfig.IExportExcelFieldItemReq): AxiosPromise<IResponse<any>> => {
                return http.patch<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/export-field`, item)
            }

            export const addExportFields = (items: manageTypes.esSearchConfig.excelExportFieldsConfig.IExportExcelFieldItemReq[]): AxiosPromise<IResponse<any>> => {
                return http.post<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/export-field`, items)
            }

            export const deleteExportFields = (ids: number[]): AxiosPromise<IResponse<any>> => {
                let reqUrl = '/smcss/v1/configuration-center/manager/esindex/export-field'
                ids.forEach((id, index) => {
                    if (index === 0) {
                        reqUrl += `?`
                    } else {
                        reqUrl += `&`
                    }
                    reqUrl += `ids=${id}`
                })
                return http.delete(reqUrl)
            }

            export const updateExcelFieldsOrder = (items: manageTypes.esSearchConfig.excelExportFieldsConfig.IExportExcelFieldItem[]): AxiosPromise<IResponse<any>> => {
                return http.patch<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/export-field/order`, items)
            }
        }
        export namespace excelBasic {
            export const getCfg = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.excelBasic.ICfg>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.excelBasic.ICfg>>(`/v1/manage/config/search/excel`)
            }

            export const updateCfg = (req: manageTypes.esSearchConfig.excelBasic.ICfg): AxiosPromise<IResponse<any>> => {
                return http.put<IResponse<any>>(`/v1/manage/config/search/excel`, req)
            }
        }
        export namespace searchResult {
            export const getBaseCfg = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.searchResult.IBaseCfg>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.searchResult.IBaseCfg>>(`/v1/manage/config/search/result/base`)
            }
            export const updateBaseCfg = (param: manageTypes.esSearchConfig.searchResult.IBaseCfg): AxiosPromise<IResponse<any>> => {
                return http.put<IResponse<any>>(`/v1/manage/config/search/result/base`, param)
            }

            export const getDetailCfg = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.searchResult.IDetailCfg>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.searchResult.IDetailCfg>>(`/v1/manage/config/search/result/detail`)
            }
            export const updateDetailCfg = (param: manageTypes.esSearchConfig.searchResult.IDetailCfg): AxiosPromise<IResponse<any>> => {
                return http.put<IResponse<any>>(`/v1/manage/config/search/result/detail`, param)
            }

            export const getTableCfg = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.searchResult.ITableCfg>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.searchResult.ITableCfg>>(`/v1/manage/config/search/result/table`)
            }
            export const updateTableCfg = (param: manageTypes.esSearchConfig.searchResult.ITableCfg): AxiosPromise<IResponse<any>> => {
                return http.put<IResponse<any>>(`/v1/manage/config/search/result/table`, param)
            }
        }
        export namespace searchTemplate {
            export const querySearchTemplate = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.searchTemplate.ISearchTemplateItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.searchTemplate.ISearchTemplateItem[]>>(`/smcss/v1/configuration-center/manager/esindex/condition-template`)
            }
            export const createSearchTemplate = (param: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateItem): AxiosPromise<IResponse<any>> => {
                return http.post<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/condition-template`, param)
            }
            export const updateSearchTemplate = (param: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateItem): AxiosPromise<IResponse<any>> => {
                return http.patch<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/condition-template`, param)
            }
            export const deleteSearchTemplate = (ids: string[]): AxiosPromise<IResponse<any>> => {
                let reqUrl = '/smcss/v1/configuration-center/manager/esindex/condition-template'
                ids.forEach((id, index) => {
                    if (index === 0) {
                        reqUrl += `?`
                    } else {
                        reqUrl += `&`
                    }
                    reqUrl += `ids=${id}`
                })
                return http.delete(reqUrl)
            }
        }
        export namespace indexFieldConfig {
            // 索引字段配置-获取字段列表
            export const getIndexFieldList = (type: string): AxiosPromise<IResponse<manageTypes.esSearchConfig.indexFieldConfig.IFieldListItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.indexFieldConfig.IFieldListItem[]>>(`/smcss/v1/configuration-center/manager/esindex/list/${type}`)
            }
            // 索引字段配置-获取字段详情
            export const getIndexFieldInfo = (id: number): AxiosPromise<IResponse<manageTypes.esSearchConfig.indexFieldConfig.IFieldListItem>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.indexFieldConfig.IFieldListItem>>(`/smcss/v1/configuration-center/manager/esindex/${id}`)
            }
            // 索引字段配置-添加字段
            export const addIndexField = (req: manageTypes.esSearchConfig.indexFieldConfig.IAddFieldItem[]): AxiosPromise<IResponse<any>> => {
                return http.post<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex`, req)
            }
            // 索引字段配置-修改字段详情
            export const editIndexFieldInfo = (req: manageTypes.esSearchConfig.indexFieldConfig.IFieldListItem | any): AxiosPromise<IResponse<any>> => {
                return http.patch<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/${req.id}`, req)
            }
            // 索引字段配置-删除字段
            export const deleteIndexField = (id: number): AxiosPromise<IResponse<any>> => {
                return http.delete(`/smcss/v1/configuration-center/manager/esindex/${id}`)
            }
        }
        export namespace defaultSearchConfig {
            // 默认检索配置-获取字段列表
            export const getDefaultFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.defaultSearchConfig.IFieldItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.defaultSearchConfig.IFieldItem[]>>(`/smcss/v1/configuration-center/manager/esindex/default-condition/fields`)
            }
            // 默认检索配置-获取字段详情
            export const getDefaultList = (type: string): AxiosPromise<IResponse<manageTypes.esSearchConfig.defaultSearchConfig.IDefaultSearchField[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.defaultSearchConfig.IDefaultSearchField[]>>(`/smcss/v1/configuration-center/manager/esindex/default-condition/${type}`)
            }

            // 默认检索配置-更新字段详情
            export const updateDefaultFields = (type: number, items: manageTypes.esSearchConfig.defaultSearchConfig.IDefaultSearchField[]): AxiosPromise<IResponse<any>> => {
                return http.put<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/default-condition/${type}`, items)
            }
        }
        export namespace searchMatchConfig {
            export const getAllFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.searchMatchConfig.IFieldItem[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.searchMatchConfig.IFieldItem[]>>(`/smcss/v1/configuration-center/manager/esindex/full-serach-field/fields`)
            }

            export const getSearchMatchFields = (): AxiosPromise<IResponse<manageTypes.esSearchConfig.searchMatchConfig.ISearchMatchField[]>> => {
                return http.get<IResponse<manageTypes.esSearchConfig.searchMatchConfig.ISearchMatchField[]>>(`/smcss/v1/configuration-center/manager/esindex/full-serach-field`)
            }

            export const updateSearchMatchField = (item: manageTypes.esSearchConfig.searchMatchConfig.ISearchMatchField): AxiosPromise<IResponse<any>> => {
                return http.patch<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/full-serach-field`, item)
            }

            export const addSearchMatchFields = (items: manageTypes.esSearchConfig.searchMatchConfig.ISearchMatchField[]): AxiosPromise<IResponse<any>> => {
                return http.post<IResponse<any>>(`/smcss/v1/configuration-center/manager/esindex/full-serach-field`, items)
            }

            export const deleteSearchMatchField = (id: number): AxiosPromise<IResponse<any>> => {
                return http.delete(`/smcss/v1/configuration-center/manager/esindex/full-serach-field`, { data: [id] })
            }
        }
    }

    export namespace mocDefaultSearchConfig {
        // 默认检索配置-获取字段详情
        export const getDefaultList = (type: string): AxiosPromise<IResponse<manageTypes.mocDefaultSearchConfig.IDefaultSearchField[]>> => {
            return http.get<IResponse<manageTypes.mocDefaultSearchConfig.IDefaultSearchField[]>>(`/scntm/v1/configuration-center/manager/search/default-condition/${type}`)
        }

        // 默认检索配置-更新字段详情
        export const updateDefaultFields = (type: number, items: manageTypes.mocDefaultSearchConfig.IDefaultSearchField[]): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/search/default-condition/${type}`, items)
        }
    }

    export namespace lexiconMg{
        export const getLexiconRoots = (): AxiosPromise<IResponse<manageTypes.lexiconMg.ILexiconItem[]>> => {
            return http.get<IResponse<manageTypes.lexiconMg.ILexiconItem[]>>(`/scntm/v1/configuration-center/manager/lexicon`)
        }
        export const getLexiconChildren = (lexiconId: number): AxiosPromise<IResponse<manageTypes.lexiconMg.ILexiconItem[]>> => {
            return http.get<IResponse<manageTypes.lexiconMg.ILexiconItem[]>>(`/scntm/v1/configuration-center/manager/lexicon/${lexiconId}`)
        }
        export const addLexiconNode = (param: manageTypes.lexiconMg.addLexiconNodeReq) => {
            return http.post<IResponse<manageTypes.lexiconMg.ILexiconItem>>(`/scntm/v1/configuration-center/manager/lexicon`, param)
        }
    }

    export namespace treeSet{
        //分类树列表
        export const getCategoryList = (): AxiosPromise<IResponse<manageTypes.treeSet.ICategoryListItem[]>> => {
            return http.get<IResponse<manageTypes.treeSet.ICategoryListItem[]>>(`/scntm/v1/configuration-center/manager/category-tree`)
        }
        export const addCategory = (treeTypeCode: string,treeTypeName: string): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/category-tree`, {treeTypeCode, treeTypeName})
        }
        export const editCategory = (treeTypeCode: string,treeTypeName: string): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/scntm/v1/configuration-center/manager/category-tree`, {treeTypeCode, treeTypeName})
        }
        export const deleteCategory = (id: number): AxiosPromise<IResponse<any>> => {
            return http.delete<IResponse<any>>(`/scntm/v1/configuration-center/manager/category-tree/${id}`)
        }
        //分类树内容树
        export const getCategoryTree = (treeTypeCode: string, categoryCode?: string): AxiosPromise<IResponse<manageTypes.treeSet.ITreeItem[]>> => {
            return http.get<IResponse<manageTypes.treeSet.ITreeItem[]>>(`/scntm/v1/configuration-center/manager/category-tree/${treeTypeCode}/node`+(categoryCode? `/${categoryCode}` : ``))
        }
        export const addCategoryTreeNode = (req: manageTypes.treeSet.IAddTreeItem): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/configuration-center/manager/category-tree/${req.categoryType}/node`,req)
        }
        export const deleteCategoryTreeNode = (treeTypeCode: string, id: number): AxiosPromise<IResponse<any>> => {
            return http.delete<IResponse<any>>(`/scntm/v1/configuration-center/manager/category-tree/${treeTypeCode}/node/${id}`)
        }
    }
}

export default manageApi
