import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import ccTypes from "../types/ccTypes";
import folderPermissionTypes from 'src/types/folderPermissionTypes';
import { IResponse } from "mam-core-react/dist/modules/http";
import entityTypes from 'src/types/entityTypes';

namespace ccApi {
    export namespace sideContentApi {
        /**
         * 左侧树
         */
        export const getSearchTree = (): AxiosPromise<IResponse<ccTypes.sideContentTypes.ISearchTreeRes[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.ISearchTreeRes[]>>('/scntm/v1/search/config/fix-condition')
        }

        /**
         * 查询文件夹
         */
        export const getFolders = (foldercode: string): AxiosPromise<IResponse<ccTypes.sideContentTypes.IFolder[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.IFolder[]>>(`/scntm/v1/folder/children?folderId=${foldercode}`)
        }

        /**
         * 初始化文件夹
         */
        export const initCloudFolders = (): AxiosPromise<IResponse<ccTypes.sideContentTypes.IFolder[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.IFolder[]>>(`/scntm/v1/folder/init`)
        }

        /**
         * 查询工作组
         */
        export const getWorkteam = (): AxiosPromise<IResponse<ccTypes.sideContentTypes.IWorkteamRes[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.IWorkteamRes[]>>('/scntm/v1/share/usergroups')
        }

        export const getPublicTree = (param: ccTypes.sideContentTypes.IFolderReq): AxiosPromise<IResponse<ccTypes.cloudTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.cloudTypes.IFullSearchRes>>('/public-tree/query', param)
        }

        /**
         * 查询workspace(工作空间)
         */

        export const getWorkSpace = (params: ccTypes.workSpaceTypes.IWorkSpaceReq): AxiosPromise<IResponse<ccTypes.cloudTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.cloudTypes.IFullSearchRes>>('/workspace/query', params)
        }

        /**
         * 查询租户的主菜单数
         */
        export const getTenantNav = (): AxiosPromise<IResponse<ccTypes.sideContentTypes.ITenantNavItem[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.ITenantNavItem[]>>('/scntm/v1/site/type')
        }
        /**
         * 查询租户的某一主菜单数下租户列
         */
        export const getTenantNavTree = (siteType: string): AxiosPromise<IResponse<ccTypes.sideContentTypes.ITenantTreeItem[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.ITenantTreeItem[]>>('/scntm/v1/site?siteType='+siteType)
        }
    }

    export namespace searchApi {
        export const fullSearch = (req: ccTypes.searchTypes.IFullSearchReq, site?: string): AxiosPromise<IResponse<ccTypes.searchTypes.IFullSearchRes>> => {
            if(window.location.pathname.indexOf('/cc/tenant') > -1 && site){
                return http.post<IResponse<ccTypes.searchTypes.IFullSearchRes>>('/scntm/v1/search/entity', req,{headers:{"sobeycloud-op-site":site}})
            }else{
                return http.post<IResponse<ccTypes.searchTypes.IFullSearchRes>>('/scntm/v1/search/entity', req)
            }
        }
        //检索的相关推荐检索（当主检索结果个数小于5个时才显示推荐）
        export const getRecommendSearchData = (req: ccTypes.searchTypes.IFullSearchReq): AxiosPromise<IResponse<ccTypes.searchTypes.IRecommendSearchRes>> => {
            return http.post<IResponse<ccTypes.searchTypes.IRecommendSearchRes>>('/search/recommendSearch', req)
        }

        export const recordOperationHabit = (param: ccTypes.searchTypes.IRecordOperationHabitReq) => {
            http.post('/search/record/keyword', param)
        }

        /**  根据素材id返回路径 */
        export const getEntityPath = (contentId: string): AxiosPromise<IResponse<ccTypes.searchTypes.IEntityPath>> => {
            return http.get<IResponse<ccTypes.searchTypes.IEntityPath>>('/entity/get-entity-path?contentId=' + contentId);
        }

        /**  任务中心面板返回数据 */
        export const getTaskCenterData = (req: ccTypes.searchTypes.taskCenterReq): AxiosPromise<IResponse<ccTypes.searchTypes.taskCenterRes>> => {
            return http.get<IResponse<ccTypes.searchTypes.taskCenterRes>>(`/scntm/v1/flow/task?FlowName=${req.FlowName}&PageIndex=${req.PageIndex}&PageSize=${req.PageSize}`);
        }
    }

    export namespace cloudApi {
        export const fullSearch = (req: ccTypes.cloudTypes.IFullSearchReq): AxiosPromise<IResponse<ccTypes.searchTypes.IFullSearchRes>> => {
            return http.post<IResponse<ccTypes.searchTypes.IFullSearchRes>>('/scntm/v1/search/folder', req)
        }
        /** 获取公共文件夹的权限信息 */
        export const getFolderProperty = (queryId: string): AxiosPromise<IResponse<folderPermissionTypes.IRelatedPermission>> => {
            return http.get<IResponse<folderPermissionTypes.IRelatedPermission>>('/scntm/v1/folder/property/' + queryId)
        }
        /** 保存文件夹权限 */
        export const saveFolderPermission = (req: folderPermissionTypes.ISaveFolderPermissionRequest): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/folder/public', req)
        }

        /** 修改文件夹权限 */
        export const updateFolderPermission = (req: folderPermissionTypes.ISaveFolderPermissionRequest): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>('/scntm/v1/folder/public', req)
        }

        export const getUpdatePermissionResult = (params: string): AxiosPromise<IResponse<folderPermissionTypes.IUpdatePermissionTask>> => {
            return http.get<IResponse<folderPermissionTypes.IUpdatePermissionTask>>(`/resource/get-update-permission-result?taskId=${params}`)
        }

        // 创建子文件夹时获取父文件夹信息
        export const getParentsInfo = (params: string): AxiosPromise<IResponse<folderPermissionTypes.IParentsInfo>> => {
            return http.get<IResponse<folderPermissionTypes.IParentsInfo>>(`/scntm/v1/folder/public/create-extend?contentId=${params}`)
        }
    }

    export namespace relativeInfoApi {
        //相关信息
        export const getRelativeInfo = (keyword: string): AxiosPromise<IResponse<ccTypes.relativeInfo.IGetRelativeInfoRes[]>> => {
            return http.post<IResponse<ccTypes.relativeInfo.IGetRelativeInfoRes[]>>('/search/recommend/relevant', {
                keyword
            })
        }
        //实体关系
        export const getEntityRelation = (keyword: string, type: string): AxiosPromise<IResponse<ccTypes.relativeInfo.IGetEntityRelationRes>> => {
            return http.post<IResponse<ccTypes.relativeInfo.IGetEntityRelationRes>>('/search/recommend/relation', {
                keyword,
                type
            })
        }
        //相关事件
        export const getRelativeEvents = (keyword: string): AxiosPromise<IResponse<ccTypes.relativeInfo.IGetRelativeEventsRes[]>> => {
            return http.post<IResponse<ccTypes.relativeInfo.IGetRelativeEventsRes[]>>('/search/recommend/event', {
                keyword,
                size: 10
            })
        }
        //近似推荐
        export const getSimilarRecommendation = (keyword: string): AxiosPromise<IResponse<string[]>> => {
            return http.post<IResponse<string[]>>('/search/recommend/approximate', {
                keyword
            })
        }
        //相关专辑
        export const getRelativeAlbums = (keyword: string): AxiosPromise<IResponse<ccTypes.relativeInfo.IGetRelativeAlbums[]>> => {
            return http.post<IResponse<ccTypes.relativeInfo.IGetRelativeAlbums[]>>('/search/recommend/album', {
                keyword,
                size: "2"
            })
        }
    }

    /**
     * check-name(查重)
     */
    export const checkName = (url: string, params: ccTypes.CheckName.ICheckNameReq[]): AxiosPromise<IResponse<ccTypes.CheckName.ICheckNameRes>> => {
        return http.post<IResponse<ccTypes.CheckName.ICheckNameRes>>(`${url}check-name`, params)
    }

    /**
     * rename(重命名)
     */
    export const rename = (params: ccTypes.CheckName.IReNameReq): AxiosPromise<IResponse<string>> => {
        return http.post<IResponse<string>>(`/scntm/v1/entity/rename`, params)
    }

    /**
     * 新建文件夹
     */
    export const createPrivateFolder = (params: ccTypes.createFolder.ICreateFolderReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/folder/private', params);
    }

    /** 获取检索模板 */
    export const getUserHabit = (key: string): AxiosPromise<IResponse<ccTypes.IUserHabit[]>> => {
        return http.get('/user/get-user-habit?key=' + key);
    }

    /** 保存检索模板 */
    export const saveUserHabit = (req: ccTypes.ISaveUserHabitReq): AxiosPromise<IResponse<any>> => {
        return http.post('/user/save-user-habit', req);
    }
    
    /** 挑选篮获取素材的数量 获取挑选篮的列表 */
    export const basketGetItems = (data: ccTypes.IBasketItemsReqData): AxiosPromise<IResponse<ccTypes.IBasketItemRes>> => {
        return http.get<IResponse<ccTypes.IBasketItemRes>>(`/scntm/v1/pick-basket?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}` + (data.contentType ? `&contentType=${data.contentType}`: ''))
    }
    /** 挑选篮获取素材的数量 获取挑选篮的列表 */
    export const basketGetCount = (): AxiosPromise<IResponse<number>> => {
        return http.get<IResponse<number>>(`/scntm/v1/pick-basket/current-user/pick-count`)
    }
    /** 添加到挑选篮 */
    export const basketAddItems = (data: ccTypes.IBasketAddItems[]): AxiosPromise<any> => {
        return http.post<any>('/scntm/v1/pick-basket', data)
    }
    /** 从挑选篮删除素材 */
    export const basketDeleteItems = (ids: string[]): AxiosPromise<any> => {
        let paramIds = '';
        ids.forEach((id)=>{
            paramIds += 'ids=' + id + '&';
        })
        paramIds = paramIds.substring(0, paramIds.length - 1);
        return http.delete(`/scntm/v1/pick-basket?` + paramIds);
    }
    /** 从挑选篮清空素材 */
    export const basketClear = (): AxiosPromise<IResponse<boolean>> => {
        return http.delete('/scntm/v1/pick-basket/clear')
    }

    /** 以图搜图 */
    export const getimageinfos = (imageBase64: string, siteCode: string): AxiosPromise<IResponse<string[]>> => {
        return http.post<IResponse<string[]>>('/search/getimageinfos?siteCode=' + siteCode, { imageBase64 })
    }
    /** 发起编目任务 */
    export const createCatalogueTask = (contentId: string[]): AxiosPromise<IResponse<ccTypes.ICreateCatalogueRes[]>> => {
        return http.post<IResponse<ccTypes.ICreateCatalogueRes[]>>('/catalog/task/add', contentId);
    }

    export namespace dataset {
        /** 只用标题创建资料集 */
        export const create = (param: ccTypes.Dataset.ICreateDatasetReq): AxiosPromise<IResponse<entityTypes.IEntity>> => {
            return http.post<IResponse<entityTypes.IEntity>>('/scntm/v1/data-set', param)
        }

        /** 修改素材和资料集的关系 */
        export const changeEntityRelation = (param: ccTypes.Dataset.IChangeEntityRelationReq): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>('/scntm/v1/data-set/relations', param)
        }
    }

    export const getFavoriteState = (contentIds: string[]): AxiosPromise<IResponse<ccTypes.IFavoriteStateRes[]>> => {
        return http.post<IResponse<ccTypes.IFavoriteStateRes[]>>('/scntm/v1/favorite/state', contentIds);
    }

    export namespace processApi{

        export const getColumns = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>('/smegw/v1/programcode/get-column');
        }

        export const getProgramData = (params:any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/smegw/v1/programcode/get',params);
        }

        export const bindProgramCode = (params:any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/smegw/v1/programcode/bind',params);
        }

        export const unbindProgramCode = (params:any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/smegw/v1/programcode/unbind',params);
        }
    }

    /** 获取用户存储空间大小 */
    export const getUserStorage = (): AxiosPromise<IResponse<ccTypes.IUserStorageRes>> => {
        return http.get<IResponse<ccTypes.IUserStorageRes>>(`/scntm/v1/user/storage-usage-info/current`)
    }
}

export default ccApi
