import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import {IResponse} from "mam-core-react/dist/modules/http";
import iSearchTypes from 'src/types/iSearchTypes';
import newspaperTypes from "src/types/newspaperTypes";

namespace newspaperApi {
    
    //检索
    export const search = (req: newspaperTypes.IFullSearchReq): AxiosPromise<IResponse<iSearchTypes.IFullSearchDataRes>> => {
        return http.post<IResponse<iSearchTypes.IFullSearchDataRes>>(`/smcss/v1/search`, req)
    }
    //报纸包
    export namespace paperPackage {
        

        export const save = (contentId: string, req: any): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>(`/scntm/v1/entity/entitydata-original/${contentId}`, req)
        }

        export const uploadCommonImg = (formData: FormData, hideLoading?: boolean): AxiosPromise<IResponse<string>> => {
            return http.post(`/scntm/v1/common/upload-image`, formData, {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    hideLoading: hideLoading !== undefined ? hideLoading : false
                },
            })
        }

        export const updateEntityKeyframe = (req: newspaperTypes.IUpdateEntityKeyframeReq): AxiosPromise<IResponse<string>> => {
            return http.patch(`/scntm/v1/entity/keyframe`, req)
        }
    }
}

export default newspaperApi
