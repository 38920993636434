import ExtendStore from 'src/stores/base/extendStore';
import { action, observable, runInAction } from 'mobx';
import SysManageStore from '../sysmanageStore';
import { Stores } from 'src/stores';
import sysmanageTypes from 'src/types/sysmanageTypes';
import sysmanageApi from 'src/apis/sysmanageApi';
import { message } from 'antd';
import { reqCatch } from 'mam-core-react';

export default class NavConfigStore extends ExtendStore {
    public parentStore: SysManageStore;
    @observable 
    public dragKey = "00000000000000000000000000000000"
    @observable 
    public selectedNav?:sysmanageTypes.navConfig.INav;
    @observable 
    public editMode: boolean = false
    @observable
    public navTypes: sysmanageTypes.navConfig.INavType[] = [];

    public constructor(stores: Stores, parentStore: SysManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action.bound
    public setDragKey(value:string) {
        this.dragKey = value
    }

    @action.bound
    public queryNavigation=(parentId?: string): Promise<sysmanageTypes.navConfig.INav[]>=>{
        this.selectedNav = undefined;
        return new Promise((resolve, reject)=>{
            sysmanageApi.navConfig.queryNavigation(parentId).then(datas => {
                runInAction(()=>{
                    if (datas.data.data) {
                        datas.data.data.forEach(item=>{
                            item.key = item.id + '';
                        })
                        resolve(datas.data.data)
                    }else{
                        reject()
                    }
                })
            }).catch(res=>{
                if (res.data && res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                else {
                    console.error(res)
                }
            });
        })
    }

    @action.bound
    public setSelectedNav(value:sysmanageTypes.navConfig.INav) {
        this.selectedNav = value
    }

    @action.bound
    public changeSelectedNavOpenNewPage(type:boolean) {
        if (this.selectedNav){
            this.selectedNav.openNewPage = type
        }
    }

    @action.bound
    public changeEditMode(edit:boolean) {
        this.editMode = edit
    }

    @action
    public getNavTypes(){
        sysmanageApi.navConfig.getNavType().then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.navTypes = res.data.data;
                }
            })
        }).catch(reqCatch)
    }
}