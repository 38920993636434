import { observable, action, runInAction } from 'mobx'
import { Stores } from '..'
import ExtendStore from '../base/extendStore'
import _ from 'lodash';
import { IBasicInfo, IMocTopicDetailParam, ISearchEntitiesReq, ISearchEntitiesRes } from 'src/types/mocTopicDetailTypes';
import entityTypes from 'src/types/entityTypes';
import entityApi from 'src/apis/entityApi';
import { message } from 'antd';
import { searchEntities } from 'src/apis/mocTopicDetailApi';
import { reqCatch } from 'mam-core-react';

export default class MocTopicDetailStore extends ExtendStore {
    @observable
    public params: IMocTopicDetailParam;
    @observable
    public keyword: string = ''
    @observable
    public baseData?: entityTypes.IEntity;
    @observable
    public basicInfo: IBasicInfo = {};
    @observable
    public entitiesRes?: ISearchEntitiesRes;
    @observable
    public isReqEntities: boolean = false;

    constructor(stores: Stores) {
        super(stores)
        this.initSelectAllStore(stores, 'stores.mocTopicDetailStore.entitiesRes.data', 'selected');
        this.initDataViewModeStore(stores);
        this.initDownloadStore(stores)
        this.initOutstoreStore(stores)
        this.initShareStore(stores)
    }

    @action
    public setParams(params: IMocTopicDetailParam){
        this.params = params;
    }

    @action
    public getBasicDatas(){
        entityApi.getBaseData(this.params.contentId, `keys=description_`).then((res) => {
            runInAction(() => {
                if (res.data.data) {
                    this.baseData = res.data.data;
                    if (res.data.data.keyframe && res.data.data.keyframe.filePath){
                        this.basicInfo.keyframePath = res.data.data.keyframe.filePath
                    }
                    if (res.data.data.create_date){
                        this.basicInfo.createDate = res.data.data.create_date;
                    }
                    if (res.data.data.name){
                        this.basicInfo.title = res.data.data.name;
                    }
                    if (res.data.data.entityData.description_){
                        this.basicInfo.description = res.data.data.entityData.description_;
                    }
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    @action
    public setKeyword(kw: string){
        this.keyword = kw;
    }

    @action
    public searchEntities(page?: number){
        this.entitiesRes = undefined;
        this.isReqEntities = true;
        let req: ISearchEntitiesReq = {
            pageIndex: page || 1,
            pageSize: 30,
            keyword: [this.keyword],
            sortFields: [{
                field: 'createDate_',
                isDesc: true
            }],
            conditions: [],
            facetConditions: [],
            albumId: this.params.contentId
        };
        searchEntities(req).then(res=>{
            runInAction(()=>{
                if (res.data.data && res.data.data.data.length > 0){
                    res.data.data.data.forEach((item) => {
                        item.contentId = item.contentId_;
                        item.type = item.type_;
                    });
                    this.entitiesRes = res.data.data;
                }
            })
        }).catch(reqCatch).finally(()=>{
            runInAction(()=>{
                this.isReqEntities = false;
            })
        })
    }

    @action
    public setKeyframe(url: string){
        runInAction(()=>{
            this.basicInfo.keyframePath = url;
        })
    }
}