import React from 'react';
import { Stores } from "../index";
import ExtendStore from "../base/extendStore";
import { action, computed, observable, runInAction } from "mobx";
import uploadTypes from "src/types/uploadTypes";
import _ from 'lodash'
import { commonUtil } from "mam-common-utils";
import { uploader } from 'src/components/upload/core/uploader';
import { IEntityType } from "src/types/configTypes";
import { gModal } from 'src/components/extend/modal/globalModal';

export enum ShowModule{
    SELECTFILE = 1,
    TASKLIST = 2
}

/** web上传模块store */
export default class WebUploadStore extends ExtendStore {
    public isInit: boolean = false;
    @observable
    public showModule: ShowModule = ShowModule.SELECTFILE;
    @observable
    public showEditMetaModal: boolean = false;
    @observable
    public showAttachmentModal: boolean = false;
    @observable
    public filterEntityTypes: uploadTypes.IFilterEntityTypes[] = [];

    public constructor(stores: Stores) {
        super(stores);
        this.initUploadStore(stores);
        this.initUploadPanelStore(stores);
    }

    @computed
    public get getFilterEntityTypes(){
        if (!this.uploadStore.readyTasks || this.uploadStore.readyTasks.length === 0){
            return [];
        }
        return this.filterEntityTypes.filter((item)=>{
            return _.findIndex(this.uploadStore.readyTasks, {entityType: item.code}) > -1;
        })
    }

    @computed
    public get getCheckedFilterEntityTypes(){
        return this.getFilterEntityTypes.filter(item=>!item.unchecked);
    }

    @action
    public init(){
        this.filterEntityTypes = this.stores.configStore.config.entityTypes.filter((item: IEntityType)=>{
            return item.code !== 'biz_sobey_rundown' && item.code !== 'biz_sobey_mhtml' 
                    && item.code !== 'biz_sobey_picpackage' && item.code !== 'biz_sobey_program' 
                    && item.code !== 'biz_sobey_materialpackage'
        })
    }

    @action
    public setShowModule(module: ShowModule){
        runInAction(()=>{
            this.showModule = module;
        })
    }

    /** 选择文件以后，对选择的文件做一系列校验，然后转换为task对象返回 */
    public beforeUpload(fileList: File[], taskType: uploadTypes.TaskTypes): Promise<uploadTypes.ITask[]>{
        let checkExt = true;
        // 上传后缀名限制，白名单
        if (this.stores.configStore.config.extensionLimit 
        && this.stores.configStore.config.extensionLimit.type === 2
        && this.stores.configStore.config.extensionLimit.extensions){
            const extensions = this.stores.configStore.config.extensionLimit.extensions;
            let extStr = '';
            fileList.forEach((file)=>{
                const ext = commonUtil.getExtension(file.name).toLowerCase();
                if (_.findIndex(extensions, (o)=>{
                    return o === '.' + ext;
                }) === -1){
                    if (extStr.indexOf(`.${ext}`) === -1){
                        extStr += `.${ext}，`
                    }
                    checkExt = false;
                }
            })
            if (!checkExt){
                extStr = extStr.substring(0, extStr.length - 1);
                gModal.modal.warning({
                    title: '提示'.l('com.tips'),
                    content: `后缀名为 ${extStr} 的文件不允许上传！`,
                    onOk: ()=>{
                        checkExt = true;
                    },
                    onCancel: ()=>{
                        checkExt = true;
                    }
                });
                return Promise.reject();
            }
        }
        // 黑名单
        else if (this.stores.configStore.config.extensionLimit 
        && this.stores.configStore.config.extensionLimit.type === 3
        && this.stores.configStore.config.extensionLimit.extensions && checkExt){
            const extensions = this.stores.configStore.config.extensionLimit.extensions;
            let extStr = '';
            fileList.forEach((file: File)=>{
                const ext = commonUtil.getExtension(file.name).toLowerCase();
                if (_.findIndex(extensions, (o)=>{
                    return o === '.' + ext;
                }) > -1){
                    if (extStr.indexOf(`.${ext}`) === -1){
                        extStr += `.${ext}，`
                    }
                    checkExt = false;
                }
            })
            if (!checkExt){
                extStr = extStr.substring(0, extStr.length - 1);
                gModal.modal.warning({
                    title: '提示'.l('com.tips'),
                    content: `后缀名为 ${extStr} 的文件不允许上传！`
                });
                return Promise.reject();
            }
        }
        // 文件大小限制
        if (this.stores.configStore.config.fileSizeLimit !== -1){
            let checkFileSize = true;
            fileList.forEach((file: File)=>{
                if (file.size > this.stores.configStore.config.fileSizeLimit){
                    checkFileSize = false;
                }
            })
            if (!checkFileSize){
                return Promise.reject();
            }
        }
        return new Promise((resolve, reject)=>{
            uploader.getFileTasks(fileList, {
                taskType,
                transferType: this.uploadStore.transferType
            }).then((tasks)=>{
                if (tasks.length === 0){
                    reject();
                    return;
                }
                resolve(tasks)
            }).catch((e)=>{
                console.error(e)
                reject();
            })
        })
    }

    @action
    public setFilterEntityTypeStatus(item: uploadTypes.IFilterEntityTypes, unchecked: boolean){
        item.unchecked = unchecked;
    }

    @action
    public openEditMetaModal(task?: uploadTypes.ITask){
        if (task){
            this.uploadStore.setSelectedTask(task);
        }
        this.showEditMetaModal = true;
    }

    @action
    public closeEditMetaModal(){
        this.showEditMetaModal = false;
    }

    @action
    public openAttachmentModal(task?: uploadTypes.ITask){
        if (task){
            this.uploadStore.setSelectedTask(task);
            this.uploadStore.setSelectedTaskAttachmentFiles(task.attachmentFiles && task.attachmentFiles.length > 0 ? task.attachmentFiles : undefined);
            this.showAttachmentModal = true;
        }
    }

    @action
    public closeAttachmentModal(){
        this.showAttachmentModal = false;
    }
    
    @action
    public removeTask(task: uploadTypes.ITask){
        let idx = _.findIndex(this.uploadStore.readyTasks, {taskId: task.taskId});
        this.uploadStore.readyTasks.splice(idx, 1);
    }

    @action
    public removeTaskById(task: uploadTypes.ITask){
        let idx = _.findIndex(this.uploadStore.readyTasks, {id: task.id});
        this.uploadStore.readyTasks.splice(idx, 1);
    }
}