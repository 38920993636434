import ExtendStore from '../../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash'
import { message } from 'antd';
import { Stores } from 'src/stores'
import PersonalCenterStore from '../personalCenterStore'
import personalCenterTypes from 'src/types/personalCenterTypes'
import personalCenterApi from 'src/apis/personalCenterApi'
import { reqCatch } from 'mam-core-react';

export default class InformationStore extends ExtendStore {
    public personalCenterStore: PersonalCenterStore

    @observable
    public userInfo: personalCenterTypes.IUserInfo; 

    @observable
    public changePasswordModal: boolean = false;
    @observable
    public avatarMaxSize: number;
    @observable
    public avatarBase64: string;

    constructor(stores: Stores, personalCenterStore: PersonalCenterStore) {
        super(stores)
        this.personalCenterStore = personalCenterStore
    }

    @action
    public setUserInfo(obj: personalCenterTypes.IUserInfo) {
        this.userInfo = obj
    }

    @action
    public setChangePasswordModal(flag: boolean) {
        this.changePasswordModal = flag
    }

    @action
    public queryUserInfo=() => {
        return new Promise((resolve, reject) => {
            personalCenterApi.getUserInfo().then((res) => {
                runInAction(() => {
                    this.setUserInfo(res.data.data)
                    resolve(true)
                })
            }, err => {
                if (err.data.error && err.data.error.title){
                    message.error(err.data.error.title);
                }
                reject();
            }).catch(reqCatch);
        })
    }
}