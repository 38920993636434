import { observable, action, runInAction } from 'mobx'
import { message } from 'antd'
import _ from 'lodash'
import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import EsSearchConfigStore from './esSearchConfigStore';
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';
import { reqCatch } from 'mam-core-react';

export default class searchMatchConfigStore extends ExtendStore{
    public parentStore: EsSearchConfigStore
    @observable
    public fieldList: manageTypes.esSearchConfig.searchMatchConfig.ISearchMatchField[] = []
    @observable
    public isReqFieldList: boolean = false

    public constructor(stores: Stores, parentStore: EsSearchConfigStore) {
        super(stores)
        this.parentStore = parentStore
    }

    @action
    public setFieldList(fieldList: manageTypes.esSearchConfig.searchMatchConfig.ISearchMatchField[]){
        this.fieldList = fieldList
    }

    @action
    public queryFieldList(){
        this.isReqFieldList = true
        manageApi.esSearchConfig.searchMatchConfig.getSearchMatchFields().then((res)=>{
            runInAction(()=>{
                if (res.data.data){
                    this.fieldList = res.data.data
                }
            })
        }).catch(reqCatch).finally(()=>{
            runInAction(()=>{
                this.isReqFieldList = false
            })
        })
    }

    @action
    public deleteFieldItem(id: number){
        manageApi.esSearchConfig.searchMatchConfig.deleteSearchMatchField(id).then(()=>{
            message.success('删除成功！'.l('com.deleteSuccess'))
            this.queryFieldList()
        })
    }
}
