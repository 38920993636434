import BaseStore from 'src/stores/base/baseStore';
import { observable, action, runInAction, computed } from 'mobx';
import { Stores } from "../../index";
import { message } from 'antd';
import ccTypes from 'src/types/ccTypes';
import ccApi from 'src/apis/ccApi';
import iEntityTypes from 'src/types/iEntityTypes';
import entityApi from 'src/apis/entityApi';

export default class SeriesStore extends BaseStore<Stores> {
    @observable
    public entitiesRes?: ccTypes.searchTypes.IFullSearchRes | undefined
    @observable
    public isLoading = false;

    @action
    public getEpisodeByArgreement(contentId: string, page?: number, keyword?: string) {
        this.isLoading = true
        let req: ccTypes.searchTypes.IFullSearchReq = {
            pageIndex: page || 1,
            pageSize: 45,
            keyword: keyword ? [keyword] : [],
            sortFields: [{
                field: 'createDate_',
                isDesc: true
            }],
            conditions: [{
                field: 'model_sobey_parent_relation.contentid',
                value: [contentId],
                isMetadataField: true,
                searchRelation: 'Eq'
            }],
            facetConditions: [],
            highLight: true
        };
        if(req.pageIndex === 1){
            this.entitiesRes = undefined
        }
        return new Promise((resolve, reject) => {
            ccApi.searchApi.fullSearch(req).then((res) => {
                runInAction(() => {
                    if (res.data.data && res.data.data.data) {
                        if (!res.data.data.facets) {
                            res.data.data.facets = []
                        }
                        this.entitiesRes = res.data.data;
                        resolve(this.entitiesRes)
                        this.isLoading = false
                    } else {
                        reject(false)
                        this.isLoading = false
                    }
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject(false)
                this.isLoading = false
            });
        })
    }

    @action
    public createRelation(params:iEntityTypes.IEpisodeRelation) {
        return new Promise((resolve, reject) => {
            entityApi.createRelation(params).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject(false)
            });
        })
    }
}   