import BaseStore from "../../base/baseStore";
import ccTypes from "../../../types/ccTypes";
import ccApi from "../../../apis/ccApi";
import {action, observable, runInAction} from "mobx";
import {Stores} from "../../index";
import { message } from 'antd';
import _ from 'lodash'

export default class CloudEntityStore extends BaseStore<Stores>{
    @observable
    public rootFolders: ccTypes.sideContentTypes.IFolder[];

    @action
    public initTree(){
        return new Promise((resolve, reject)=>{
            ccApi.sideContentApi.initCloudFolders().then((res)=>{
                runInAction(()=>{
                    if (res.data.data){
                        this.rootFolders = res.data.data;
                        this.rootFolders.forEach((folder)=>{
                            this.getFolders(folder.contentId);
                            if (this.stores.cloudStore.params 
                                && this.stores.cloudStore.params.folderCode === folder.contentId){
                                folder.showSub = true;
                            }
                        })
                        resolve(undefined);
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                    reject();
                }
            })
        })
    }

    @action
    public getFolders(parentCode: string){
        ccApi.sideContentApi.getFolders(parentCode).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    let folder: ccTypes.sideContentTypes.IFolder | undefined;
                    this.rootFolders.forEach(rFolder=>{
                        if (!folder){
                            folder = this.getFolderByCode(rFolder, parentCode)
                        }
                    })
                    if (folder){
                        res.data.data.forEach(child=>{
                            child.parentCode = folder?.contentId;
                            if (this.stores.cloudStore.params 
                                && this.stores.cloudStore.params.folderCode === child.contentId){
                                if (folder){
                                    folder.showSub = true;
                                }
                            }
                        })
                        folder.children = res.data.data;
                    }
                }
            });
        }, (res)=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title)
            }
        });
    }

    @action
    public setFolderShowSub(folder: ccTypes.sideContentTypes.IFolder, showSub: boolean){
        folder.showSub = showSub;
    }

    public isRoot(code: string){
        return _.findIndex(this.rootFolders, {contentId: code}) > -1;
    }

    private getFolderByCode(folder: ccTypes.sideContentTypes.IFolder, targetCode: string): ccTypes.sideContentTypes.IFolder | undefined{
        if (folder.contentId === targetCode)
        {
            return folder;
        }
        if (!folder.children)
        {
            return undefined;
        }
        var retval;
        var item;
        for (var i = 0, j = folder.children.length; i < j; i++)
        {
            item = folder.children[i];
            if (item.contentId === targetCode)
            {
                return item;
            }
            if (item.children && item.children.length > 0)
            {
                retval = this.getFolderByCode(item, targetCode);
                if (retval)
                {
                    return retval;
                }
            }
            if (i == j - 1)
            {
                return undefined;
            }
        }
        return undefined;
    };
}