import { Stores } from "../index";
import { observable, action } from 'mobx';
import SysmanageExtendStore from "./sysmanageExtendStore";
import sysmanageTypes from "src/types/sysmanageTypes";

export default class SysmanageStore extends SysmanageExtendStore {
    @observable
    public iframeInfo: sysmanageTypes.IIframeInfo = {
        src: '',
        show: false
    };

    public constructor(stores: Stores) {
        super(stores);
        this.initOptoolConfigurationStore()
        this.initNavConfigStore();
        this.initDetailPageConfigurationStore();
        this.initTaskCenterConfigStore();
    }

    @action
    public setIframeInfo(info: sysmanageTypes.IIframeInfo){
        this.iframeInfo = info;
    }


    public pgShowTotalSetting = (total: number, range: number[]) => { 
        return range[0] + "-" + range[1] + " / " + total + " " + '条'.l('com.item') 
    }

}
