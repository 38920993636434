import { AxiosPromise } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import contentMgTypes from 'src/types/contentMgTypes'
import iSearchTypes from 'src/types/iSearchTypes'
import sysmanageTypes from "src/types/sysmanageTypes"

namespace contentMgApi {
    export namespace taskCenter {
        //获取新的任务中心配置
        export const getBaseCfg = (): AxiosPromise<IResponse<sysmanageTypes.taskCenterConfig.IBaseCfg>> => {
            return http.get<IResponse<sysmanageTypes.taskCenterConfig.IBaseCfg>>(`/v1/config/taskCenter`)
        }
        //获取全部外部系统
        export const getExternalSystem = (): AxiosPromise<IResponse<contentMgTypes.taskCenter.IExternalSystemItem[]>> => {
            return http.get<IResponse<contentMgTypes.taskCenter.IExternalSystemItem[]>>(`/smegw/v1/external-system`)
        }
        //获取检索条件：排序配置、任务类型筛选项
        export const getConditionConfig = (): AxiosPromise<IResponse<contentMgTypes.taskCenter.IConditionConfig>> => {
            return http.get<IResponse<contentMgTypes.taskCenter.IConditionConfig>>(`/swfbb/v1/taskcenter/mytask/get-query-conditions`)
        }
        //查询列表
        export const queryList = (req: contentMgTypes.taskCenter.IQueryReq): AxiosPromise<IResponse<contentMgTypes.taskCenter.IQueryRes>> => {
            return http.post<IResponse<contentMgTypes.taskCenter.IQueryRes>>('/swfbb/v1/taskcenter/mytask/query', req)
        }
        //导出表格: 按查询条件导出
        export const exportTask = (req: contentMgTypes.taskCenter.IQueryReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/swfbb/v1/taskcenter/mytask/exportexcel', req)
        }
        //重做
        export const redoTask = (flowIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/swfbb/v1/taskcenter/mytask/redo', flowIds)
        }
        //停止
        export const stopTask = (flowIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/swfbb/v1/taskcenter/mytask/stop', flowIds)
        }
        //删除
        export const deleteTask = (flowIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.delete('/swfbb/v1/taskcenter/mytask/delete', { data: flowIds })
        }
        //查询任务详情
        export const getTaskDetail = (flowId: string): AxiosPromise<IResponse<contentMgTypes.taskCenter.ITaskDetail>> => {
            return http.get<IResponse<contentMgTypes.taskCenter.ITaskDetail>>(`/swfbb/v1/taskcenter/mytask/get-stepdetail?flowId=${flowId}`)
        }
        //设置优先级 {flowId:priorityNumber, flowId:priorityNumber}
        export const setPriority = (req: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/swfbb/v1/taskcenter/mytask/priority/batch', req)
        }
        //批量查询优先级
        export const getPriority = (flowIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/swfbb/v1/taskcenter/mytask/priority', flowIds)
        }
        //批量查询流程步骤
        export const getTaskFlow = (flowIds: string[]): AxiosPromise<IResponse<contentMgTypes.taskCenter.IGetTaskFlowRes[]>> => {
            return http.post<IResponse<contentMgTypes.taskCenter.IGetTaskFlowRes[]>>('/swfbb/v1/taskcenter/mytask/get-stepdetail/batch', flowIds)
        }

    }

    export namespace myCollection {
        export const getMyCollectionList = (params: contentMgTypes.myCollection.IMyCollectionParams): AxiosPromise<IResponse<contentMgTypes.myCollection.IGetMyCollectionListResponse>> => {
            return http.get(`/scntm/v1/favorite?pageIndex=${params.pageIndex}&pageSize=${params.pageSize}&keyword=${params.keyword}&isDesc=${params.isDesc}`);
        }

        export const cancelCollection = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
            let paramIds = '';
            contentIds.forEach((id) => {
                paramIds += 'contentIds=' + id + '&';
            })
            paramIds = paramIds.substring(0, paramIds.length - 1);
            return http.delete(`/scntm/v1/favorite?` + paramIds)
        }
    }

    export namespace tencentRevertJobSchedule {
        export const queryTask = (param: contentMgTypes.tencentRevertJobSchedule.IQueryTaskReq): AxiosPromise<IResponse<contentMgTypes.tencentRevertJobSchedule.IQueryRes>> => {
            return http.post<IResponse<contentMgTypes.tencentRevertJobSchedule.IQueryRes>>(`/skldg/v1/task/person/revert/search`, param)
        }

        export const retry = (req: contentMgTypes.tencentRevertJobSchedule.IRetryReq): AxiosPromise<IResponse<any>> => {
            return http.put('/slink_manager/v1/jobschedule/task/retry', req)
        }

        export const deleteTask = (req: contentMgTypes.tencentRevertJobSchedule.IDeleteReq): AxiosPromise<IResponse<any>> => {
            return http.delete('/slink_manager/v1/jobschedule/task', { data: req})
        }
    }
}

export default contentMgApi
