import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../../manageStore';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import manageApi from 'src/apis/manageApi'
import manageTypes from "src/types/manageTypes";

export default class AuthSetStore extends ExtendStore {
    public parentStore: ManageStore;

    @observable
    public authList: manageTypes.authSetTypes.IAuthListItem[]
    @observable
    public addEditAuthType: string = 'addAuthNav'  //添加权限菜单addAuthNav 修改权限菜单editAuthNav 添加权限addAuth 修改权限editAuth 
    @observable
    public currentAuthNav: manageTypes.authSetTypes.IAuthListItem | undefined  //当前选中的权限菜单
    @observable
    public currentAuth: manageTypes.authSetTypes.IAuthListItem | undefined  //当前选中的子权限
    @observable
    public selectedAuthCodes: string[] = []
    @observable
    public showAddEditAuthModal: boolean = false;

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public clear(){
        this.authList = []
        this.currentAuth = undefined
    }
    @action
    public setShowAddEditAuthModal(show: boolean){
        this.showAddEditAuthModal = show
        if(!show){  //关闭弹窗时进行清空当前选中的权限
            this.currentAuth = undefined
        }
    }
    
    @action
    public setSelectedAuthCodes(selectedAuthCodes: string[]){
        this.selectedAuthCodes = selectedAuthCodes
    }

    /* 当前选中的一个权限菜单 */
    @action
    public setCurrentAuthNav(currentAuthNav: manageTypes.authSetTypes.IAuthListItem | undefined){
        this.currentAuthNav = currentAuthNav
    }
    @action
    public setAddEditAuthType(addEditAuthType: string){
        this.addEditAuthType = addEditAuthType
    }
    /* 当前选中的一个权限 */
    @action
    public setCurrentAuth(currentAuth: manageTypes.authSetTypes.IAuthListItem | undefined){
        this.currentAuth = currentAuth
    }
    /* 用户相关 */
    @action
    public getAuthList = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.authSetApi.getAuthList().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.authList = res.data.data
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public addAuth = (req: manageTypes.authSetTypes.IAddEditAuthReq): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.authSetApi.addAuth(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public editAuth = (req: manageTypes.authSetTypes.IAuthListItem): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.authSetApi.editAuth(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public deleteAuth = (code: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.authSetApi.deleteAuth(code).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

}
