import {Stores} from "../index";
import _ from 'lodash';
import LinkShareStore from './linkShareStore';
import { BaseStore } from 'mam-core-react';
import PersonShareStore from './personShareStore';
import { observable, action } from 'mobx';

export default class ShareStore extends BaseStore<Stores>{
    public linkShareStore: LinkShareStore;
    public personShareStore: PersonShareStore;
    @observable
    public keyword: string = ''

    constructor(stores: Stores){
        super(stores);
        this.linkShareStore = new LinkShareStore(stores, this);
        this.personShareStore = new PersonShareStore(stores, this);
    }

    @action
    public setKeyword(keyword: string){
        this.keyword = keyword;
    }
}