import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import ccTypes from '../../types/ccTypes';
import mmsApi from 'src/apis/mmsApi';
import { message } from 'antd';
import {Stores} from "../index";

export default class ExportExcelFieledSettingStore extends BaseStore<Stores>{
    @observable
    public showExportExcelModal:boolean = false

    @observable
    public exportFileds:ccTypes.IExportFileds[] = [];

    @observable
    public selectedItems:ccTypes.IExportFileds[] = [];

    @observable
    public allSelected:boolean = true;

    @observable
    public req:ccTypes.searchTypes.IFullSearchReq

    @action
    public setShowExportExcelModal(show:boolean){
        this.showExportExcelModal = show
    }

    @action
    public setReq(req:ccTypes.searchTypes.IFullSearchReq){
        this.req =req
    }
    //初始化字段配置
    @action
    public initExportExcelConfig(){
        mmsApi.exportExcel.getExportFields().then(res => {
            if(res&&res.data.success&&this.exportFileds.length===0){
                res.data.data.find((item:ccTypes.IExportFileds,index:number) => {
                    let e:ccTypes.IExportFileds = {
                        key:index,
                        selected:item.pitchOn===0?true:false,
                        resourceFieldName:item.resourceFieldName,
                        resourceDesc: item.resourceDesc
                    }
                    this.exportFileds.push(e);
                });
            }
        },res=>{
            if(res.data.error&&res.data.error.title){
                message.error(res.data.error.title)
            }
        })
    }
    //设置全选
    @action
    public selectAllExportFields(){
        this.checkSelectAll();
        this.exportFileds.find((item:ccTypes.IExportFileds) => {
            item.selected = this.allSelected?false:true;
        })
        this.checkSelectAll();
    }
    // 设置单项的选中状态
    @action
    public selectExportField(index: number, value: boolean){
        this.checkSelectAll();
        this.exportFileds.find((item:ccTypes.IExportFileds,exportFiledsIndex:number)=>{
            item.selected = index === exportFiledsIndex?value:item.selected;
        })
        this.checkSelectAll();
    }
    //判断是否全部选中
    @action
    public checkSelectAll(){
        this.selectedItems= [];
        this.exportFileds.find((item:ccTypes.IExportFileds)=>{
            if(item.selected){
                this.selectedItems.push(item);
            }
        })
        this.allSelected = this.selectedItems.length === this.exportFileds.length?true:false;
    }
    //设置导出Excel
    @action
    public exportExcel(){
        if(this.exportFileds.length===0){
            message.error('没有选中');
        }else{
            this.setExportFieldConfig();
        }
    }
    //设置导出字段配置
    @action
    public setExportFieldConfig(){
        let newExportFileds:ccTypes.IExportFileds[] = [];
        this.exportFileds.find((item:ccTypes.IExportFileds)=>{
            let e:ccTypes.IExportFileds = {
                resourceFieldName:item.resourceFieldName,
                pitchOn:item.selected?0:1
            }
            newExportFileds.push(e);
        })
        this.saveExportFields(newExportFileds);
    }
    //导出字段配置
    @action
    public saveExportFields(exportFileds:ccTypes.IExportFileds[]){
        mmsApi.exportExcel.saveExportFields(exportFileds).then((res:any)=>{
            if(res.data.data){
                message.success('导出字段配置成功');
            }
        },(res:any)=>{
            if(res.data.error&&!res.data.success){
                message.error(res.data.error.code);
            }
        })
    }
}
