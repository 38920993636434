import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import entityApi from 'src/apis/entityApi'
import { IAlbumEntitieReq, IParams} from 'src/types/albumDetailTypes'
import _ from 'lodash'
import { Stores } from '..'
import { message } from 'antd'
import ModifyAutoCollectStore from './modifyAutoCollectStore'
import iSearchTypes from 'src/types/iSearchTypes'
import entityTypes from 'src/types/entityTypes'
import iSearchApi from 'src/apis/iSearchApi'
import { queryAlbumEntities } from 'src/apis/albumDetailApi'

export default class AlbumDetailStore extends ExtendStore {
    @observable
    public params: IParams
    @observable
    public baseData?: entityTypes.IEntity;
    @observable
    public metadatas: IFormItem[] = []
    @observable
    public keyframePath: string = ''
    @observable
    public isMetaEdit: boolean
    @observable
    public entitiesRes?: iSearchTypes.IFullSearchDataRes
    @observable
    public isLoadingEntities = true;
    @observable
    public isReqAlbums = true;
    // @observable
    // public relatedAlbumsRes: iIndexTypes.secList.ISearchAlbumsRes | undefined;
    // @observable
    // public topAlbumsRes: iIndexTypes.secList.ISearchAlbumsRes | undefined;
    public modifyAutoCollectStore: ModifyAutoCollectStore;
    @observable
    public sortItems: iSearchTypes.ISortItem[] = []
    /** 当前选中排序类型 */
    @observable
    public currentSort: iSearchTypes.ISortItem;

    public constructor(stores: Stores) {
        super(stores);
        this.initSelectAllStore(stores, 'stores.albumDetailStore.entitiesRes.data', 'selected');
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
        this.initDataViewModeStore(stores);
        this.modifyAutoCollectStore = new ModifyAutoCollectStore(stores, this)
    }

    @action
    public setParams(params: IParams){
        this.params = params;
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: iSearchTypes.ISortItem[] = []
        // if (config && config.sortFields && config.sortFields.length > 0) {
        //     sortItems = config.sortFields.map((item: any) => {
        //         return {
        //             text: item.name || '',
        //             field: item.field,
        //             desc: item.desc ? 'desc' : 'asc',
        //             hideDirection: true
        //         }
        //     })
        // } else {
            sortItems = [
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' },
                { text: '相关度'.l('search.relatedRate'), field: '_score', hideDirection: true }
            ]
        // }
        this.sortItems = sortItems;
        if(!this.currentSort){
            this.setCurrentSort(sortItems[0]);
        }
    }

    @action
    public setCurrentSort(sort: iSearchTypes.ISortItem) {
        this.currentSort = sort;
    }
    @action
    public getBasicDatas(){
        this.metadatas = [];
        this.keyframePath = '';
        entityApi.getBaseData(this.params.contentId, `keys=albumtype`).then((res) => {
            runInAction(() => {
                if (res.data.data) {
                    this.baseData = res.data.data;
                    if (res.data.data.keyframe && res.data.data.keyframe.filePath){
                        this.keyframePath = res.data.data.keyframe.filePath
                    }
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
        entityApi.getEntityData(this.params.contentId).then((res) => {
            runInAction(() => {
                if (res.data.data) {
                    let defaultGroup = _.find(res.data.data, {groupCode: 'default_group'})
                    if (defaultGroup){
                        this.metadatas = defaultGroup.fields;
                    }
                }
            });
        })
    }

    @action
    public getEntitys(page?: number, keyword?: string){
        this.isLoadingEntities = true;
        let param: IAlbumEntitieReq = {
            pageIndex: page || 1,
            pageSize: 45,
            personId: '',
            keywords: keyword ? [keyword] : [],
            facetConditions: [],
            conditions: [],
            sortFields:[{
                field: this.currentSort.field,
                isDesc: this.currentSort.desc === 'desc'
            }],
            albumId: this.params.contentId
        }
        queryAlbumEntities(param).then(res=>{
            runInAction(()=>{
                if (res.data.data && res.data.data.data){
                    res.data.data.data.forEach((item)=>{
                        item.contentId = item.contentId_;
                        item.type = item.type_;
                    })
                    if (!res.data.data.aggrs){
                        res.data.data.aggrs = []
                    }
                    this.entitiesRes = res.data.data;
                }
            })
        }).finally(()=>{
            runInAction(()=>{
                this.isLoadingEntities = false;
            })
        })
    }
    // 查询相关专辑和热门专辑
    @action
    public getRelativeOrHotAlbums(name?: any){
        return new Promise((resolve, reject)=>{
            resolve(undefined)
            // let param: iIndexTypes.secList.ISearchAlbumsReq = {
            //     pageIndex: 1,
            //     pageSize: 3,
            //     keyword: name? [name] : [],
            //     conditions: [
            //         {
            //             field: "contentId_",
            //             nexus: "not",
            //             value: [this.params.contentId]
            //         }
            //     ],
            //     sorts:[
            //         {
            //             field: 'createDate_',
            //             desc: true
            //         }
            //     ]
            // }
            // if(name) {
            //     this.relatedAlbumsRes = undefined
            // } else {
            //     this.topAlbumsRes = undefined
            // }
            // this.isReqAlbums = true;
            // iIndexApi.searchAlbums(param).then(res=>{
            //     runInAction(()=>{
            //         if (res.data.data){
            //             res.data.data.data.forEach((item)=>{
            //                 item.name = item.name_;
            //                 item.duration = item.duration_;
            //                 item.contentId = item.contentId_;
            //                 item.framerate = item.framerate_;
            //             })
            //             if(name) {
            //                 this.relatedAlbumsRes = res.data.data
            //             } else {
            //                 this.topAlbumsRes = res.data.data
            //             }
            //         }
            //         this.isReqAlbums = false;
            //         resolve(true);
            //     })
            // }, res=>{
            //     if (res.data.error && res.data.error.title){
            //         message.error(res.data.error.title);
            //     }
            //     this.isReqAlbums = false;
            //     reject();
            // });
        })
    }

    @action
    public setMetaEdit(isEdit: boolean){
        this.isMetaEdit = isEdit;
    }
}