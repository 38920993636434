import React, { Component } from 'react'
import { Input, Dropdown, Menu, message, Divider, UploadFile } from 'antd'
import { observer } from 'mobx-react';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import { stores } from 'src/stores';
import { SearchOutlined } from '@ant-design/icons';
import iSearchApi from 'src/apis/iSearchApi';
import iSearchTypes from 'src/types/iSearchTypes';
import SelectAudioWithPanel from './selectAudioWithPanel/selectAudioWithPanel';
import RecoSongByAudioPanel from './recoSongByAudio/recoSongByAudioPanel';
import SelectImageWithPanel from './selectImageWithPanel';
const Search = Input.Search;
const { ItemGroup, Item } = Menu

interface IMmsSearchInputProps {
    keyword: string
    keywordQuery: (keywords: string) => void
    onSelectImage?: (base64: string | UploadFile<any>, searchType: string) => void
    placeholder?: string
    history?: boolean
    searchBtn?: JSX.Element[] | string
}

interface IMmsSearchInputState {
    keyword: string
    searchSuggest: string[]
    historyData: iSearchTypes.ISearchRecord[]
}

@observer
class MmsSearchInput extends Component<IMmsSearchInputProps, IMmsSearchInputState>{
    private qSearchSuggestTimeout: NodeJS.Timeout;

    constructor(props: IMmsSearchInputProps) {
        super(props)
        this.state = {
            keyword: '',
            searchSuggest: [],
            historyData: []
        }
    }

    public render() {
        let input: JSX.Element;
        input = <Search
            value={this.state.keyword}
            onChange={this.changeKeywords}
            placeholder={this.props.placeholder}
            enterButton={
                this.props.searchBtn ||
                <SearchOutlined style={{ fontSize: '26px' }} />
            }
            size="large"
            allowClear
            onSearch={this.submit}
            prefix={
                stores.configStore.baseConfig 
                && stores.configStore.baseConfig.functionSwitch.imageSearch
                && stores.configStore.baseConfig.functionSwitch.imageSearch.enableImageSearch &&
                <SelectImageWithPanel onSelectImage={this.onSelectImage}></SelectImageWithPanel>
            }
        />
        return (
            <div id="new-main-search-wrap">
                {
                    !this.state.keyword ?
                        <Dropdown overlay={this.buildHistoryData}
                            overlayClassName="new-main-search-history-box" trigger={['click']}
                            overlayStyle={{ maxWidth: 800, overflowX: 'hidden' }}
                            getPopupContainer={this.getPopupContainer}
                            onOpenChange={this.onHistoryOpenChange}>
                            {input}
                        </Dropdown> :
                        (this.state.searchSuggest ?
                            <Dropdown overlay={this.buildSearchSuggestData}
                                overlayClassName="new-main-search-history-box" trigger={['click']}
                                overlayStyle={{ maxWidth: 800, overflowX: 'hidden' }}
                                getPopupContainer={this.getPopupContainer}>
                                {input}
                            </Dropdown> :
                            input)
                }
            </div>
        )
    }

    public componentDidMount() {
        this.setState({
            keyword: commonUtil.copyObj(!this.props.keyword ? '' : this.props.keyword)
        }, () => {
            if (this.state.keyword) {
                this.getSearchSuggest(this.state.keyword);
            }
        })
    }

    public componentDidUpdate(prevProps: IMmsSearchInputProps) {
        if (prevProps.keyword !== this.props.keyword) {
            this.setState({
                keyword: commonUtil.copyObj(this.props.keyword || '')
            }, () => {
                if (this.state.keyword) {
                    this.getSearchSuggest(this.state.keyword);
                }
            })
        }
    }

    private onHistoryOpenChange = (open: boolean) => {
        if (open && this.props.history) {
            this.getHistory()
        }
    }

    private submit = (keyword: string) => {
        this.props.keywordQuery(keyword)
    }

    private changeKeywords = (event: React.ChangeEvent<HTMLInputElement>) => {
        //检索去掉特殊符号
        let val: string = event.target.value//.replace(/[@#&+\-\"\'\_￥]/g, '');
        if (val === this.state.keyword) {
            return
        }
        if (val.length > 50) {
            val = val.substring(0, 50);
        }
        this.setState({
            keyword: val
        }, () => {
            stores.iSearchStore.setSearchKeyword1(val);
            if (this.props.history) {
                this.getHistory()
            }
            if (this.state.keyword) {
                this.getSearchSuggest(this.state.keyword);
            }
            else {
                this.setState({
                    searchSuggest: []
                })
            }
        })
    }

    private buildHistoryData = (): JSX.Element => {
        if (!this.state.historyData || this.state.historyData.length === 0) {
            return <></>;
        }
        return (
            <Menu>
                <ItemGroup title={'历史搜索'.l('cc.historySearch')}>
                    <a onClick={this.clearAllHistory} className="empty-history-a">
                        <i className="fa fa-trash"></i>{'清除记录'.l('cc.emptyRecords')}
                    </a>
                    <Divider />
                    {
                        this.state.historyData.map((item, index) => {
                            return (
                                <Item key={index}>
                                    <a className="history-item" onClick={this.goSearch.bind(this, item.key)} title={item.key}>
                                        <span className="search-item">{item.key}</span>
                                        <i className="fa fa-trash history-item-remove-a" onClick={this.deleteHistory.bind(this, index, item.key)}></i>
                                    </a>
                                </Item>
                            )
                        })
                    }
                </ItemGroup>
            </Menu>
        )
    }

    private buildSearchSuggestData = (): JSX.Element => {
        return (
            <Menu>
                {
                    this.state.searchSuggest && this.state.searchSuggest.map((item, index) => {
                        return <Item key={index}>
                            <a className="item" onClick={this.goSearch.bind(this, item)} title={item}>
                                <span className="search-item">{item}</span>
                            </a>
                        </Item>
                    })
                }
            </Menu>
        )
    }

    private getPopupContainer = (triggerNode: HTMLElement): HTMLElement => {
        return document.getElementById('new-main-search-wrap') || document.body
    }

    private goSearch = (keyword: string) => {
        this.setState({
            keyword
        }, () => {
            this.submit(this.state.keyword)
        })
    }

    private deleteHistory = (index: number, value: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        const historyData = commonUtil.copyObj(this.state.historyData)
        iSearchApi.deleteSearchRecord(value)
            .then((res) => {
                historyData.splice(index, 1)
                this.setState({ historyData })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
    }

    private getSearchSuggest = (keyword: string) => {
        if (this.qSearchSuggestTimeout) {
            clearTimeout(this.qSearchSuggestTimeout);
        }
        this.qSearchSuggestTimeout = setTimeout(() => {
            iSearchApi.getSearchSuggest(keyword).then(res => {
                if (res.data.data) {
                    this.setState({
                        searchSuggest: res.data.data
                    });
                }
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
        }, 600)
    }

    private getHistory = () => {
        iSearchApi.getSearchRecord()
            .then(res => {
                if (res.data.data) {
                    this.setState({
                        historyData: res.data.data
                    })
                }
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
    }

    private clearAllHistory = () => {
        iSearchApi.clearSearchRecord()
            .then((res) => {
                this.setState({ historyData: [] })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
            })
    }

    private onSelectImage = (base64: string | UploadFile<any>, searchType: string) => {
        if (this.props.onSelectImage) {
            this.props.onSelectImage(base64, searchType);
        }
    }
}

export default MmsSearchInput