import {action, observable, runInAction} from "mobx";
import EntityExtendStore from "./entityExtendStore";
import { Stores } from "../../../index";
import _ from 'lodash';
import * as React from "react";
import permissionUtil from "mam-common-utils/dist/modules/permissionUtil";
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import { ITabs } from "src/pages/entity/modules/topTabs/topTabs";
import mmsApi from "src/apis/mmsApi";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import { PosterStore } from "mam-biz-components";
import { reqCatch } from 'mam-core-react';
import NewspaperStore from "../../newspaperStore";
import MetadataOpeStore from "src/stores/entity/base/metadataOpeStore/metadataOpeStore";
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import entityApi from "src/apis/entityApi";
import entityTypes from "src/types/entityTypes";
import newspaperApi from "src/apis/newspaperApi";
import newspaperTypes from "src/types/newspaperTypes";

export default class EntityStore extends EntityExtendStore {
    private parentStore: NewspaperStore;
    @observable
    public params: entityTypes.IEntityParam
    @observable
    public viewer: any;
    @observable
    public entity: entityTypes.IEntity;
    @observable
    public entityData: entityTypes.IEntityDataItem[];
    /** 查询素材详情报错信息 */
    @observable
    public loadError?: string
    /** 错误码 */
    @observable
    public loadErrorCode?: string
    /** 选中的tab code */
    @observable
    public selectedTab: entityTypes.ISelectedTab = { top: 'baseInfo', bottom: 'keyframe' }
    @observable
    public partMetaEditable: boolean = false;
    @observable
    public smartCutInfo: any
    @observable
    public metadataClip: any
    /** 视频帧率 */
    public frameRate: number = 0
    /** 关键帧字典，可通过帧号寻找关键帧 */
    @observable
    public keyframeDict: any;
    /** 元数据是否为编辑状态 */
    @observable
    public metaEditable: boolean = false;
    // 报纸文章详情需要查出父级来显示路径
    @observable
    public routePath: entityTypes.IPaperRoutePath[] = [];
    
    public baseInfo: React.RefObject<any>;

    /** 元数据相关扩展操作 */
    public metadataOpeStore: MetadataOpeStore;
    public constructor(stores: Stores, parentStore: NewspaperStore) {
        super(stores)
        this.parentStore = parentStore;
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
        this.initPaperlayoutStore();
        this.initPaperarticleStore();
        this.metadataOpeStore = new MetadataOpeStore(stores, this);
        this.baseInfo = React.createRef();
    }

    @action
    public setParams(params: any) {
        if (!this.params){
            this.params = params;
        }
        else {
            this.params = Object.assign({}, this.params, params);
        }
    }

    @action
    public setViewer(viewer: any) {
        this.viewer = viewer;
    }

    @action
    public getBaseData(contentId: string): Promise<entityTypes.IEntity> {
        this.loadError = '';
        this.loadErrorCode = '';
        return new Promise((resolve, reject) => {
            entityApi.getBaseData(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entity = res.data.data;
                        this.entity.contentId = contentId;
                        this.entity.contentId_ = contentId;
                        this.entity.paths = this.entity.previewFile || [];
                        if (!this.frameRate){
                            this.frameRate = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].frameRate : 0;
                        }
                        //处理时长
                        this.entity.duration = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].duration : 0;
                        if (this.entity.duration && this.frameRate){
                            this.entity.durationTc = playerUtil.l100Ns2Tc(this.entity.duration, this.entity.type, this.frameRate);
                        }

                        //是否收藏
                        // mmsApi.collection.getCollectionStatus([this.entity.contentId]).then((res2) => {
                        //     runInAction(() => {
                        //         if (res2.data.data){
                        //             let favoriteItem = _.find(res2.data.data, (favoriteItem)=>{
                        //                 return favoriteItem.rescontentid === this.entity.contentId
                        //             })
                        //             if (favoriteItem) {
                        //                 this.entity.isFavorite = true;
                        //                 this.entity.favoriteObjId = favoriteItem.contentId_;
                        //             }
                        //         }
                        //     });
                        // });
                        //素材是报纸文章时，其父级版面id已在路由中，需要再查版面的父级id
                        if((this.entity.type === 'biz_sobey_paperarticle' || this.entity.type === 'biz_paperarticle')){
                            let routePath = [
                                {
                                    name: this.entity.name, 
                                    contentId: this.entity.contentId, 
                                    type: this.entity.type
                                }
                            ]
                            if(this.params.paperlayoutId){
                                //获取全路径
                                routePath.unshift({
                                    name: this.entity.entityData.layoutname, 
                                    contentId: this.params.paperlayoutId, 
                                    type: 'biz_sobey_paperlayout',
                                })
                                this.queryParentContentId(this.params.paperlayoutId, routePath)
                            }else{
                                this.routePath = routePath
                            }
                            
                            //获取该文章的图片列表
                            this.getFileGroups(this.entity.contentId).then((list)=>{
                                if(list){
                                    let group = _.find(list,{typeCode: 'paperarticlegroup'})
                                    if(group && group.fileItems && group.fileItems.length > 0){
                                        group.fileItems.map(f=>{
                                            f.parentId = this.entity.contentId
                                            f.parentName = this.entity.name
                                        })

                                        this.paperarticleStore.setPictureList(group.fileItems)
                                    }
                                }
                            })
                        }
                        resolve(this.entity);
                    }
                    else {
                        reject();
                    }
                });
            }, (res) => {
                runInAction(() => {
                    if (res.data.error && res.data.error.title){
                        if (res.data.error.code){
                            this.loadErrorCode = res.data.error.code;
                            if (res.data.error.code === 'B1066') {
                                this.loadError = '系统未定义该类型，无法浏览。';
                            }
                        }
                        this.loadError = res.data.error.title;
                    }
                    reject();
                });
            })
        });
    }

    @action
    public getEntityData(contentId: string): Promise<entityTypes.IEntityDataItem[]> {
        return new Promise((resolve, reject) => {
            entityApi.getEntityData(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entityData = res.data.data;
                        this.paperlayoutStore.getPaperlayoutList(contentId);
                        resolve(this.entityData);
                    }
                    else {
                        reject();
                    }
                });
            })
        });
    }

    @action
    public changeTopTab(name: string) {
        return new Promise((resolve, reject)=>{
            this.selectedTab.top = name;
            // if (name !== 'person'){
            //     this.previewImageStore.setHideBBox()
            // }
            // else if (name === 'section'){
            //     this.scenesStore.getScenes().finally(()=>{
            //         resolve(undefined);
            //     });
            // }
            // else {
            //     resolve(undefined);
            // }
    
            // 处理外部tab，iframe链接
            const topTabs = this.getTopTabs();
            const currentTopTab = _.find(topTabs, {key: name});
            if (currentTopTab && currentTopTab.outerFrameUrl){
                this.selectedTab.topTabFrameUrl = currentTopTab.outerFrameUrl;
            }
            else {
                this.selectedTab.topTabFrameUrl = undefined;
            }
        })
    }

    @action
    public changeBottomTab(name: string) {
        this.selectedTab.bottom = name;
    }

    public setPlayerPosition = (item: entityTypes.ICut) => {
        this.viewer.player.setCurrentTime(playerUtil.frame2Second(item.in, this.frameRate));
        this.viewer.player.setTrimin(playerUtil.frame2Second(item.in, this.frameRate));
        this.viewer.player.setTrimout(playerUtil.frame2Second(item.out, this.frameRate));
    }
    /**
     * trimIn,trimOut seconds
     */
    public setPlayerPositionBySecond = (trimIn: number, trimOut: number) => {
        this.viewer.player.setCurrentTime(trimIn);
        this.viewer.player.setTrimin(trimIn);
        this.viewer.player.setTrimout(trimOut);
    }

    /** 能否使用历史 */
    public canUseHistory() {
        if (!_.get(this.stores.configStore, 'config.entity.history.enable', false)) {
            return false;
        }
        if (permissionUtil.judge('Default_ActionLogView')) {
            return true;
        }
        return false;
    }

    @action
    public setPartMetaEditable(editable: boolean){
        this.partMetaEditable = editable;
    }

    @action
    public getSmartCutInfo(contentId: string){
        return new Promise((resolve, reject) => {
            entityApi.getSmartCutInfo(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.smartCutInfo = res.data.data;
                        resolve(res.data.data);
                    }
                    else {
                        reject();
                    }
                });
            })
        })
    }

    @action
    public initKeyframeDict(fileGroups: any[]) {
        this.keyframeDict = {};
        if (fileGroups && fileGroups.length > 0) {
            fileGroups.forEach(groupItem => {
                this.keyframeDict[groupItem.keyFrameNo] = {
                    keyframePath: groupItem.filePath
                };
            });
        }
    }

    @action
    public getKeyframesData(contentId: string){
        return new Promise((resolve, reject) => {
            entityApi.getKeyframesData(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.initKeyframeDict(res.data.data)
                        resolve(true);
                    }
                    else {
                        reject();
                    }
                });
            })
        })
    }

    @action
    public getClipDetail(contentId: string,guid:string|undefined){
        return new Promise((resolve, reject) => {
            entityApi.getClipDetail(contentId,guid).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.metadataClip = res.data.data;
                        resolve(res.data.data);
                    }
                    else {
                        reject();
                    }
                });
            })
        })
    }

    @action
    public setFavoriteStatus(status: boolean){
        this.entity.isFavorite = status;
    }

    @action
    public updateEntityDataGroup(group: entityTypes.IEntityDataItem, field: string, val: any){
        group[field] = val;
    }

    @action
    public setEntityDataGroupFields(groupCode: string, fields: IFormItem[]){
        let group = _.find(this.entityData, {groupCode})
        if (group){
            group.fields = fields;
        }
    }

    @action
    public setMetaEditable(editable: boolean) {
        this.metaEditable = editable;
    }

    @action
    public queryFullSearchData(parentId: string): Promise<iSearchTypes.IFullSearchData[]> {
        let req: newspaperTypes.IFullSearchReq = {
            pageIndex: 1,
            pageSize: 60,
            conditions: [],
            facetConditions: [],
            keywords: [],
            parentId,
            sortFields:[]
        }

        return new Promise((resolve, reject) => {
            newspaperApi.search(req).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        if (res.data.data.data) {
                            this.parentStore.resolveQueryResult(res.data.data.data);
                        }
                        resolve(res.data.data.data)
                    }else {
                        reject()
                    }
                })
            }).catch(reqCatch)
        })
    }

    @action
    public queryParentContentId(contentId: string, routePath: entityTypes.IPaperRoutePath[]): Promise<iSearchTypes.IFullSearchData[]> {
        let req: newspaperTypes.IFullSearchReq = {
            pageIndex: 1,
            pageSize: 1,
            conditions: [
                {field: 'contentId_',value: [contentId]}
            ],
            facetConditions: [],
            keywords: [],
            sortFields:[]
        }

        return new Promise((resolve, reject) => {
            newspaperApi.search(req).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        if (res.data.data.data) {
                            this.parentStore.resolveQueryResult(res.data.data.data);
                        }
                        if(res.data.data.data.length > 0){
                            let paperlayoutItem: any = res.data.data.data[0]
                            if(paperlayoutItem.parent_){
                                routePath.unshift({
                                    name: this.entity.entityData.papername,
                                    contentId: paperlayoutItem.parent_[0].contentid,
                                    type: 'biz_sobey_paper',
                                })
                                this.routePath = routePath
                            }
                        }
                        resolve(res.data.data.data)
                    }else {
                        reject()
                    }
                })
            }).catch(reqCatch)
        })
    }

    public getEntityBaseData(contentId: string): Promise<entityTypes.IEntity>{
        return new Promise((resolve, reject) => {
            entityApi.getBaseData(contentId).then((res) => {
                if (res.data.data) {
                    res.data.data.contentId = contentId;
                    res.data.data.contentId_ = contentId;
                    res.data.data.paths = res.data.data.previewFile || [];
                    resolve(res.data.data)
                }else {
                    reject()
                }
            })
        })
    }

    public getFileGroups(contentId: string): Promise<entityTypes.IFileGroup[]>{
        return new Promise((resolve, reject) => {
            entityApi.getFileGroups(contentId).then(res=>{
                if (res.data.data){
                    resolve(res.data.data)
                }
            }).catch(reqCatch)
        })
    }

    public getTopTabs(): ITabs[]{
        if (!this.stores.configStore.config){
            return [];
        }
        const defaultTabs = this.stores.configStore.config.config.defaultTopTabs;
        if (!this.entity){
            return defaultTabs;
        }
        const topTabs = _.find(this.stores.configStore.config.config.topTabs, {entityType: this.entity.type});
        if (!topTabs || !topTabs.tabs){
            return defaultTabs;
        }
        return topTabs.tabs;
    }

    public getContentId(){
        return this.params.contentId;
    }

    public toPaperlayout(paperlayoutId?: string, paperarticleId?: string) {
        if(paperlayoutId){
            let href = `/newspaper/entity/${this.params.contentId}?paperlayout=${paperlayoutId}`
            if(paperarticleId){
                href += `&paperarticle=${paperarticleId}`
            }
            window.open(href)
        }
    }

    public toPaperarticle(paperlayoutId: string, paperarticleId: string) {
        let href = `/newspaper/entity/${paperarticleId}?paperlayout=${paperlayoutId}`
        window.open(href)
    }


}
