import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import mmsTypes from 'src/types/mmsTypes';
import {Stores} from "../index";

class CopyStore extends BaseStore<Stores> {
    @observable
    public showCopyModal: boolean = false

    @observable
    public options: mmsTypes.copyTypes.IOptions

    @observable
    public items: any[]

    @observable
    public selectItem: mmsTypes.selectDirectoryModalTypes.ITreeItem

    @observable
    public isOk: boolean = false

    @observable
    public tree:any = []

    @action
    public setShowCopyModal(show: boolean) {
        this.showCopyModal = show
    }

    @action
    public setOptions(options: mmsTypes.copyTypes.IOptions) {
        this.options = options
        this.options.operationType = this.options.operationType || 'copy'
        this.options.extraRoot = this.getSecondRoot(this.options,this.options.operationType)
    }

    @action
    public setItems(items: any[]) {
        this.items = items
    }

    @action
    public setSelectItem(item: mmsTypes.selectDirectoryModalTypes.ITreeItem) {
        this.selectItem = item
    }

    @action
    public setIsOk(ok: boolean) {
        this.isOk = ok
    }
    
    @action
    public setTree(tree:any){
        this.tree = tree
    }

    @action
    private getSecondRoot(options: mmsTypes.copyTypes.IOptions,type?:string) {
        if (options.treeType === 'private') {
            options.extraRoot = [{ treeType: 1 }]
        }
        if(options.treeType === 'public'){
            options.extraRoot = [{ treeType: 0 }]
        }
        if(options.treeType === 'doha'){
            options.extraRoot = [{ treeType: 0 }, { treeType: 1 }]
            if(type !== 'move'){
                options.extraRoot.push({ treeType: 6 }) 
            }
        }
        if(options.treeType === 'ml'){
            options.extraRoot = [{ treeType: 3 }]
        }
        return options.extraRoot
    }

}

export default CopyStore