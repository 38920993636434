import { AxiosPromise } from "axios";
import { http } from "mam-core-react";
import { IResponse } from "mam-core-react/dist/modules/http";
import { ITranslatePromptReq, ITranslatePromptRes, ITxt2ImgProgressRes, ITxt2ImgReq, ITxt2ImgRes } from "src/types/aiGcTypes";

export const txt2Img = (req: ITxt2ImgReq):AxiosPromise<IResponse<ITxt2ImgRes>> => {
    return http.post<IResponse<ITxt2ImgRes>>(`/saims/v1/diffusion/txt2img`, req, {
        headers: {hideLoading: true}
    });
}

export const getTxt2ImgProgress = ():AxiosPromise<IResponse<ITxt2ImgProgressRes>> => {
    return http.get<IResponse<ITxt2ImgProgressRes>>(`/saims/v1/diffusion/progress`);
}

export const translatePrompt = (req: ITranslatePromptReq):AxiosPromise<IResponse<ITranslatePromptRes>> => {
    return http.post<IResponse<ITranslatePromptRes>>(`/saims/v1/diffusion/translate`, req, {
        headers: {hideLoading: true}
    });
}