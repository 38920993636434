import { BaseStore } from "mam-core-react";
import { Stores } from "src/stores";
import SmartViewStore from "./smartViewStore";
import { action, observable } from "mobx";
import iEntityTypes from "src/types/iEntityTypes";
import iEntityApi from "src/apis/iEntityApi";

export default class FaceProcessStore extends BaseStore<Stores> {
    @observable
    public showModal: boolean = false;
    @observable
    public processType?: iEntityTypes.ProcessType
    @observable
    public resultImgUrl: string = ''
    @observable
    public vagueValue: number = 0;
    @observable
    public selectedDefaultFaceUrl?: string;
    @observable
    public customImgUrl?: string;
    @observable
    public currentFrameImage: string = '';
    @observable
    public facePosition?: number;
    public parentStore: SmartViewStore;

    public constructor(stores: Stores, parentStore: SmartViewStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public openModal(){
        this.showModal = true;
        this.processType = undefined;
        this.currentFrameImage = this.parentStore.parentStore.viewer.player.getCurrentKeyframe()
        this.resultImgUrl = this.parentStore.parentStore.viewer.player.getCurrentKeyframe()
    }

    @action
    public closeModal(){
        this.showModal = false;
    }

    @action
    public setFacePosition(pos: number){
        this.facePosition = pos;
    }

    @action
    public setResultImgUrl(url: string){
        this.resultImgUrl = url;
    }

    @action
    public setProcessType(type: iEntityTypes.ProcessType){
        this.processType = type;
    }

    @action
    public setVagueValue(value: number){
        this.vagueValue = value;
    }

    @action
    public setSelectedDefaultFaceUrl(url?: string){
        this.selectedDefaultFaceUrl = url;
    }

    @action
    public setCustomImgUrl(url?: string){
        this.customImgUrl = url;
    }
}
