import { observable, action, runInAction } from 'mobx';
import BaseStore from "../base/baseStore";
import {Stores} from "../index";
import { message } from 'antd';
import mmsTypes from 'src/types/mmsTypes';
import mmsApi from 'src/apis/mmsApi';

export default class BatchSmartProcessStore extends BaseStore<Stores>{
    @observable
    public showBatchSmartProcessModal:boolean = false

    @observable
    public items: any[]

    @observable
    public smartConfigBtns:mmsTypes.smartProcessTypes.ISmartProcessIntelligentAnalysisBtnItem[]

    @action
    public setShowBatchSmartProcessModal(show:boolean){
        this.showBatchSmartProcessModal = show
    }

    @action
    public setItems(items:any[]){
        this.items = items
    }

    @action
    public getSmartConfigBtns = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            mmsApi.SmartProcessModal.getSmartConfigBtns().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.smartConfigBtns = res.data.data
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
}
