import BaseStore from "../base/baseStore";
import { action, observable } from 'mobx'
import {Stores} from "../index";

export default class MoveStore extends BaseStore<Stores>{
    @observable
    public showMoveModal:boolean = false

    @observable
    public items:any

    @observable
    public isOk:boolean = false

    @observable
    public selectedItems:any

    @observable
    public itemsInfo:any

    @observable
    public disable:boolean = true

    @observable
    public selectedItem:any

    @action
    public setShowMoveModal(show:boolean){
        this.showMoveModal = show
    }

    @action
    public setItems(items:any){
        this.items = items
    }

    @action
    public setIsOk(ok:boolean){
        this.isOk = ok
    }

    @action
    public setSelectedItems(items:any){
        this.selectedItems = items
    }

    @action
    public setItemsInfo(itemsInfo:any){
        this.itemsInfo = itemsInfo
    }

    @action 
    public setDisable(disable:boolean){
        this.disable = disable
    }

    @action
    public setSelectedItem(selectedItem:any){
        this.selectedItem = selectedItem
    }
}