import { commonUtil } from 'mam-common-utils';

import ExtendStore from 'src/stores/base/extendStore';
import { action, observable, runInAction } from "mobx";
import { message } from 'antd';
import { Stores } from '../index'
import { l } from "mam-core-react";
import _ from 'lodash';
import contentMgTypes from 'src/types/contentMgTypes';
import navApi from 'src/apis/navApi';
import { INav } from 'mam-components-react/dist/modules/mocHeader/topNav';
class StatisticStore extends ExtendStore {
    @observable
    public navs: INav[]
    @observable
    public iframeInfo: contentMgTypes.IIframeInfo = {
        src: '',
        show: false
    };
    @observable
    public openKeys: string[]
    @observable
    public current?: string

    constructor(stores: Stores) {
        super(stores)
    }

    @action
    public queryNav() {
        navApi.getNavigation(-1, 'top').then(res => {
            if (res.data.data) {
                let allPromise: Array<Promise<any>> = [];
                res.data.data.forEach((data) => {
                    if (data.name === '数据统计') {
                        allPromise.push(new Promise((resolve, reject) => {
                            if (data) {
                                navApi.getNavigation(data.id).then(res1 => {
                                    runInAction(() => {
                                        if (res1.data.data && data) {
                                            data.children = res1.data.data;
                                        }
                                    })
                                    resolve(true);
                                }, res1 => {
                                    reject();
                                })
                            }
                            else {
                                reject();
                            }
                        }))
                    }
                })
                Promise.all(allPromise).then(() => {
                    if (res.data.data) {
                        if (_.find(res.data.data, { name: '数据统计' })) {
                            let children = _.find(res.data.data, { name: '数据统计' })?.children
                            let allPromiseChildren: Array<Promise<any>> = [];
                            if (children && children.length > 0) {
                                children.forEach((data) => {
                                    allPromiseChildren.push(new Promise((resolve, reject) => {
                                        if (data) {
                                            navApi.getNavigation(data.id).then(res1 => {
                                                if (res1.data.data && data) {
                                                    data.children = res1.data.data;
                                                }
                                                resolve(true);
                                            }, res1 => {
                                                reject();
                                            })
                                        }
                                        else {
                                            reject();
                                        }
                                    }))
                                })
                            }
                            Promise.all(allPromiseChildren).then(() => {
                                runInAction(() => {
                                    if (children && children.length > 0) {
                                        this.navs = children
                                    }
                                })
                            })
                        }
                    }
                });
            }
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
        });
    }

    @action
    public setOpenKeys(openKeys: string[]) {
        this.openKeys = openKeys;
    }

    @action
    public setCurrent(current?: string) {
        this.current = current;
    }
    @action
    public setIframeInfo(info: contentMgTypes.IIframeInfo) {
        this.iframeInfo = info;
    }

}

export default StatisticStore