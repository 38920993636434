import BaseStore from "../base/baseStore"
import { action, observable } from 'mobx'
import {Stores} from "../index";

export default class ShareStore extends BaseStore<Stores>{
    @observable
    public showShareModal:boolean = false

    @observable
    public contentIds:string[]

    @action
    public setShowShareModal(show:boolean){
        this.showShareModal = show
    }

    @action
    public share(contentIds:string[]){
        this.contentIds = contentIds
        this.setShowShareModal(true)
    }
}