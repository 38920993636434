import React from "react";
import mmsTypes from "../../../types/mmsTypes";
import _ from 'lodash';
import {ExclamationCircleFilled} from '@ant-design/icons'
import { gModal } from "src/components/extend/modal/globalModal";

const nameStyle: React.CSSProperties = {
    flex: '1', 
    width: '1%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}
const dateStyle = {
    width: '180px'
}
const getContent = (copyright: mmsTypes.downloadTypes.IFileDownloadInfoCopyright)=>{
    return <div className="mam-flex-table">
        <div className="flex-head">
            <div style={nameStyle}>素材名</div>
            <div style={dateStyle}>版权截至日期</div>
        </div>
        <div className="flex-body">
            {
                copyright.expires && copyright.expires.map((item, index)=>{
                    return <div className="flex-item" key={index}>
                        <div style={nameStyle} title={item.name}><span>{item.name}</span></div>
                        <div style={Object.assign({}, dateStyle, {color: '#ff4d4f'})}>{item.endTime}</div>
                    </div>
                })
            }
            {
                copyright.warns && copyright.warns.map((item, index)=>{
                    return <div className="flex-item" key={index}>
                        <div style={nameStyle} title={item.name}><span>{item.name}</span></div>
                        <div style={Object.assign({}, dateStyle)}>{item.endTime}</div>
                    </div>
                })
            }
        </div>
    </div>;
}
export const confirmTipCopyrightModel = (tip: string, copyright: mmsTypes.downloadTypes.IFileDownloadInfoCopyright)=>{
    return new Promise((resolve, reject)=>{
        gModal.modal.confirm({
            title: tip,
            icon: <ExclamationCircleFilled />,
            content: getContent(copyright),
            onOk() {
                resolve(undefined);
            },
            onCancel() {
                reject();
            },
            width: 600,
            zIndex: 1001
        });
    })
}
export const warningTipCopyrightModel = (tip: string, copyright: mmsTypes.downloadTypes.IFileDownloadInfoCopyright)=>{
    return new Promise((resolve, reject)=>{
        gModal.modal.warning({
            title: tip,
            icon: <ExclamationCircleFilled />,
            content: getContent(copyright),
            onOk() {
                resolve(undefined);
            },
            onCancel() {
                reject();
            },
            width: 600,
            zIndex: 1001
        });
    })
}