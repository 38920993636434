import { AxiosPromise } from "axios"
import { IResponse } from "mam-core-react/dist/modules/http"
import sysmanageTypes from "src/types/sysmanageTypes";
import {http} from "../utils/http";

namespace sysmanageApi {
    export namespace OptoolConfigurationApi {
        export const getOptoolList = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/scntm/v1/configuration-center/admin/tool`)
        }

        export const getOptoolItem = (id: number): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/scntm/v1/configuration-center/admin/tool/${id}`)
        }

        export const addOptoolItem = (params: any): AxiosPromise<IResponse<any>> => {
            return http.post(`/scntm/v1/configuration-center/admin/tool`,params)
        }

        export const updateOptoolItem = (id: number,params: any): AxiosPromise<IResponse<any>> => {
            return http.patch(`/scntm/v1/configuration-center/admin/tool/${id}`,params)
        }

        export const delOptoolItem = (id: number): AxiosPromise<IResponse<any>> => {
            return http.delete(`/scntm/v1/configuration-center/admin/tool/${id}`)
        }
    }

    // 智能配置
    export namespace IntelligentSetApi {
        // 获取智能配置
        export const getIntelligentDetail = (): AxiosPromise<IResponse<sysmanageTypes.IntelligentSetTypes.IIntelligentReq>> => {
            return http.get<IResponse<sysmanageTypes.IntelligentSetTypes.IIntelligentReq>>(`/scntm/v1/configuration-center/admin/intelligent`)
        }
        // 更新智能配置
        export const updateIntelligentDetail = (req: sysmanageTypes.IntelligentSetTypes.IIntelligentReq): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>('/scntm/v1/configuration-center/admin/intelligent', req)
        }

        // mah获取智能流程配置
        export const getSmartFlowConfig = (): AxiosPromise<IResponse<sysmanageTypes.IntelligentSetTypes.ISmartFlowConfigItem[]>> => {
            return http.get<IResponse<sysmanageTypes.IntelligentSetTypes.ISmartFlowConfigItem[]>>(`/swfbb/v1/manage/intelligent/smart-flow-config`)
        }
        // mah更新智能流程配置
        export const updateSmartFlowConfig = (params: sysmanageTypes.IntelligentSetTypes.ISmartFlowConfigItem[]): AxiosPromise<IResponse<sysmanageTypes.IntelligentSetTypes.ISmartFlowConfigItem[]>> => {
            return http.post<IResponse<sysmanageTypes.IntelligentSetTypes.ISmartFlowConfigItem[]>>(`/swfbb/v1/manage/intelligent/smart-flow-config`,params)
        }
    }

    export namespace detailPage{
        export const getEntityConfig = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>('/v1/config/entity')
        }

        export const getDetailPageCfg = (): AxiosPromise<IResponse<sysmanageTypes.detailPage.IDetailPageCfg>> => {
            return http.get<IResponse<sysmanageTypes.detailPage.IDetailPageCfg>>(`/v1/manage/config/entity`)
        }

        export const updateDetailPageCfg = (req: sysmanageTypes.detailPage.IDetailPageCfg): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/v1/manage/config/entity`, req)
        }
    }

    export namespace importOutstore{
        export const getConfigObj = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/scmas/v1/config/get-scmas-config-obj`)
        }

        export const getConfig = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/scmas/v1/config/get-scmas-config`)
        }

        export const updateConfig = (req: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scmas/v1/config/set-scmas-config`, req)
        }
    }

    export namespace upload{
        export const getCfg = (): AxiosPromise<IResponse<sysmanageTypes.upload.IUploadCfg>> => {
            return http.get<IResponse<sysmanageTypes.upload.IUploadCfg>>(`/v1/manage/config/upload`)
        }

        export const updateCfg = (req: sysmanageTypes.upload.IUploadCfg): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/v1/manage/config/upload`, req)
        }
    } 

    export namespace baseSetting{
        export const getCfg = (): AxiosPromise<IResponse<sysmanageTypes.baseSetting.ICfg>> => {
            return http.get<IResponse<sysmanageTypes.baseSetting.ICfg>>(`/v1/manage/config/base`)
        }

        export const saveCfg = (cfg: sysmanageTypes.baseSetting.ICfg): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/v1/manage/config/base`, cfg)
        }

        export const getLoginCfg = (): AxiosPromise<IResponse<sysmanageTypes.baseSetting.ILoginCfg>> => {
            return http.get<IResponse<sysmanageTypes.baseSetting.ILoginCfg>>(`/v1/manage/config/login`)
        }

        export const saveLoginCfg = (cfg: sysmanageTypes.baseSetting.ILoginCfg): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/v1/manage/config/login`, cfg)
        }
    }

    export namespace navConfig{
        export const getNavType = (): AxiosPromise<IResponse<sysmanageTypes.navConfig.INavType[]>> => {
            return http.get<IResponse<sysmanageTypes.navConfig.INavType[]>>(`/scntm/v1/configuration-center/admin/navigation/type`)
        }
        export const queryNavigation = (parentId?: string): AxiosPromise<IResponse<sysmanageTypes.navConfig.INav[]>> => {
            return http.get(`/scntm/v1/configuration-center/admin/navigation` + (parentId ? `/${parentId}` : ''))
        }
        export const addNavigation = (params: sysmanageTypes.navConfig.INav): AxiosPromise<IResponse<string>> => {
            return http.post(`/scntm/v1/configuration-center/admin/navigation`, params)
        }
        export const editNavigation = (params: sysmanageTypes.navConfig.INav): AxiosPromise<IResponse<string>> => {
            return http.patch(`/scntm/v1/configuration-center/admin/navigation`,params)
        }
        export const deleteNavigation = (id: number): AxiosPromise<IResponse<string>> => {
            return http.delete(`/scntm/v1/configuration-center/admin/navigation/${id}`)
        }
        export const orderNavigation = (params: sysmanageTypes.navConfig.INav): AxiosPromise<IResponse<boolean>> => {
            return http.patch(`/scntm/v1/configuration-center/admin/navigation/order`,params)
        }
    }
    export namespace SystemRegistry{
        export const getSysRegInfo = (PageIndex:number): AxiosPromise<IResponse<sysmanageTypes.SystemRegistry.ISysRegPage>> => {
            return http.get<IResponse<sysmanageTypes.SystemRegistry.ISysRegPage>>(`/scntm/v1/configuration-center/admin/hive-system-registry/?PageIndex=${PageIndex}`)
        }
        export const addSysRegInfo = (params: sysmanageTypes.SystemRegistry.ISysRegInfo): AxiosPromise<IResponse<sysmanageTypes.SystemRegistry.ISysRegInfo>> => {
            return http.post(`/scntm/v1/configuration-center/admin/hive-system-registry`, params)
        }
        export const editSysRegInfo = (params: sysmanageTypes.SystemRegistry.ISysRegInfo): AxiosPromise<IResponse<sysmanageTypes.SystemRegistry.ISysRegInfo>> => {
            return http.patch(`/scntm/v1/configuration-center/admin/hive-system-registry`,params)
        }
        export const deleteSysRegInfo = (id: number): AxiosPromise<IResponse<string>> => {
            return http.delete(`/scntm/v1/configuration-center/admin/hive-system-registry/${id}`)
        }
    }

    export namespace taskCenterConfig{
        export const getBaseCfg = (): AxiosPromise<IResponse<sysmanageTypes.taskCenterConfig.IBaseCfg>> => {
            return http.get<IResponse<sysmanageTypes.taskCenterConfig.IBaseCfg>>(`/v1/manage/config/taskCenter`)
        }
        export const updateBaseCfg = (req: sysmanageTypes.taskCenterConfig.IBaseCfg): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>(`/v1/manage/config/taskCenter`, req)
        }
    }
}

export default sysmanageApi
