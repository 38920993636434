import { Stores } from "../index";
import { BaseStore } from 'mam-core-react';
import PaperPackageStore from "./modules/paperPackage/paperPackageStore";
import EntityStore from "./modules/entity/entityStore";

export default class NewspaperStore extends BaseStore<Stores>{
    public paperRatio: number = 320/447;
    public paperPackageStore: PaperPackageStore;
    public entityStore: EntityStore;

    public constructor(stores: Stores) {
        super(stores);
        this.paperPackageStore = new PaperPackageStore(stores, this)
        this.entityStore = new EntityStore(stores, this)
    }

    public resolveQueryResult(list: any[], callback?: (item: any) => void) {
        list.forEach((item) => {
            item.contentId = item.contentId_
            item.type = item.type_

            if (callback && typeof (callback) === 'function') {
                callback(item)
            }
        })
    }

}
