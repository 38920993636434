import {action, observable, runInAction} from "mobx";
import {IBaseConfig, IUiModule, ICatalogConfig} from "../types/configTypes";
import BaseStore from "./base/baseStore";
import {Stores} from "./index";
import configApi from 'src/apis/configApi';
import { message } from "antd";
import _ from 'lodash'
import { commonUtil } from "mam-common-utils";

export default class ConfigStore extends BaseStore<Stores> {
    @observable
    public config: any;
    @observable
    public baseConfig: IBaseConfig
    @observable
    public catalogConfig: ICatalogConfig
    @observable
    public isGetSearchConfig: boolean = false;
    /**
     * 是否显示请求加载动画
     */
    @observable
    public showRequestLoading: boolean = false;
    /**
     * 调用uiModulesEnable方法之后uiModulesSwitch里面才会有值！
     */
    @observable
    public uiModulesSwitch: any = {};
    private uiModules: IUiModule[] = [];
    /** 避免同一个key值同时发起多次查询，在请求结束前发起同样的请求，就用第一次发起的那个Promise */
    private queryUiModulesQueue: any = {};

    @action
    public setBaseConfig(baseConfig?: IBaseConfig): void {
        if (baseConfig)
        {
            this.baseConfig = baseConfig;
            (window as any).nxt.config = this.baseConfig;
            (window as any).nxt.config.server = process.env.DEV_SERVER as string;
            (window as any).nxt.config.webUploadThreads = 6;
        }
    }

    @action
    public setBaseConfigFadeColors(fadeColors: string[]){
        this.baseConfig.fadeColors = fadeColors;
    }

    @action
    public setShowRequestLoading(show: boolean){
        this.showRequestLoading = show;
    }

    /** 追加配置项 */
    @action
    public appendConfig(key: string, value: string){
        this.config[key] = value;
        (window as any).nxt.config = Object.assign({}, (window as any).nxt.config, this.config);
    }

    @action
    public getLoginConfig(){
        return new Promise(((resolve, reject) => {
            configApi.getLoginConfig()
                .then(res => {
                    runInAction(()=>{
                        if (res.data.data){
                            this.config = res.data.data;
                            (window as any).nxt.config = Object.assign({}, (window as any).nxt.config, this.config);
                            resolve(undefined);
                        }
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                })
        }))
    }

    @action
    public getSearchConfig(){
        return new Promise(((resolve, reject) => {
            configApi.getSearchConfig()
                .then(res => {
                    runInAction(()=>{
                        if (res.data.data){
                            this.config = res.data.data;

                            (window as any).nxt.config = Object.assign({}, (window as any).nxt.config, this.config);
                            this.isGetSearchConfig = true;
                            resolve(undefined);
                        }
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                })
        }))
    }

    @action
    public getSearchResultFieldsConfig(){
        return new Promise(((resolve, reject) => {
            configApi.getSearchResultFieldsConfig()
                .then(res => {
                    runInAction(()=>{
                        if (res.data.data){
                            if (!this.config){
                                this.config = {
                                    config: {}
                                };
                            }
                            if (this.config.config){
                                this.config.config = Object.assign({}, this.config.config, res.data.data);
                            }
                            console.log(this.config);
                            (window as any).nxt.config = Object.assign({}, (window as any).nxt.config, this.config);
                            resolve(undefined);
                        }
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                })
        }))
    }

    @action
    public getEntityConfig(){
        return new Promise(((resolve, reject) => {
            configApi.getEntityConfig()
                .then(res => {
                    runInAction(()=>{
                        if (res.data.data){
                            this.config = res.data.data;
                            
                            (window as any).nxt.config = Object.assign({}, (window as any).nxt.config, this.config);
                            resolve(undefined);
                        }
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                })
        }))
    }

    @action
    public getWebUploadConfig(){
        return new Promise(((resolve, reject) => {
            configApi.getWebUploadConfig()
                .then(res => {
                    runInAction(()=>{
                        if (res.data.data){
                            if (!this.config){
                                this.config = res.data.data;
                            }
                            else {
                                // uploadConfig返回的entityTypes没有viewUrl！，所以删除避免bug
                                if (this.config.entityTypes){ 
                                    delete res.data.data.entityTypes;
                                }
                                this.config = commonUtil.deepAssign({}, this.config, res.data.data)
                            }

                            (window as any).nxt.config = Object.assign({}, (window as any).nxt.config, this.config);
                            resolve(undefined);
                        }
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                })
        }))
    }

    @action
    public getFavoriteConfig(){
        return new Promise(((resolve, reject) => {
            configApi.getFavoriteConfig()
                .then(res => {
                    runInAction(()=>{
                        if (res.data.data){
                            this.config = res.data.data;

                            (window as any).nxt.config = Object.assign({}, (window as any).nxt.config, this.config);
                            this.isGetSearchConfig = true;
                            resolve(undefined);
                        }
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                })
        }))
    }

    // 获取编目配置
    @action
    public getCatalogConfig(){
        return new Promise(((resolve, reject) => {
            configApi.getCatalogConfig()
                .then(res => {
                    runInAction(()=>{
                        if (res.data.data){
                            this.catalogConfig = res.data.data;
                            (window as any).nxt.config.catalogConfig = this.catalogConfig;
                        }
                    })
                }, res=>{
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                })
        }))
    }
    
    /** 开关是否全部开启 */
    public getUiModulesEnable(keys: string): Promise<any>{
        return new Promise((resolve, reject)=>{
            let enable = true;
            this.queryUiModules(keys).then(()=>{
                runInAction(()=>{
                    keys.split(',').forEach((key)=>{
                        if (this.uiModulesSwitch[key] === false){
                            enable = false;
                        }
                        else if (this.uiModulesSwitch[key] === undefined) {
                            let module = _.find(this.uiModules, {key});
                            if (!module || !module.value || module.value.toLowerCase() === 'false'){
                                enable = false;
                                this.uiModulesSwitch[key] = false;
                            }
                            else {
                                this.uiModulesSwitch[key] = true;
                            }
                        }
                    });
                })
                if (enable){
                    resolve(undefined);
                }
                else {
                    reject();
                }
            }, ()=>{
                reject();
            })
        })
    }

    /** 获取配置，非开关 */
    public getUiModulesConfig(key: string): Promise<any>{
        return new Promise((resolve, reject)=>{
            this.queryUiModules(key).then(()=>{
                let module = _.find(this.uiModules, {key})
                if (module){
                    resolve(module.value);
                }
                else {
                    resolve(undefined);
                }
            }, ()=>{
                reject();
            })
        })
    }

    public queryUiModules(keys: string): Promise<any>{
        if (this.queryUiModulesQueue[keys]){
            return this.queryUiModulesQueue[keys];
        }
        const qPromise = new Promise((resolve, reject)=>{
            configApi.getUiModules(keys).then(res=>{
                if (res.data.data){
                    res.data.data.forEach((item)=>{
                        if (_.findIndex(this.uiModules, {key: item.key}) === -1){
                            this.uiModules.push(item);
                        }
                    })
                }
                resolve(undefined);
            }).catch(res=>{
                if (res.data && res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            }).finally(()=>{
                this.queryUiModulesQueue[keys] = undefined;
            })
        })
        this.queryUiModulesQueue[keys] = qPromise;
        return qPromise;
    }
    
    /** 根据key值获取当前配置，确保已经调用过queryUiModules接口以后，可获取到配置 */
    public getUiModule(key: string){
        return _.find(this.uiModules, {key});
    }
}
