import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Radio, Upload, UploadFile, message, Modal } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { InboxOutlined, CloseCircleFilled, CameraOutlined, PictureOutlined } from '@ant-design/icons';
import { stores } from 'src/stores'
import { commonUtil } from 'mam-common-utils';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';
import { css } from '@emotion/react';
import { UploadChangeParam } from 'antd/es/upload';
const { Dragger } = Upload;

interface IProps extends RouteComponentProps {
    onSelectImage: (base64: string | UploadFile<any>, searchType: string) => void
}

const SelectImageWithPanel: React.FC<IProps> = observer((props: IProps) => {
    const [searchType, setSearchType] = React.useState('face');   //face
    const [showPanel, setShowPanel] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const dragSelect: React.RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.removeEventListener('click', clickDom, false);
        document.addEventListener('click', clickDom, false);
        return () => {
            document.removeEventListener('click', clickDom, false);
        }
    }, [])

    useEffect(() => {
        //input file触发click的时候不会冒泡调用stopP，因为react的所有事件在document上，所以弹窗下必须用这种方式来实现文件选择
        if (dragSelect.current) {
            $(dragSelect.current)[0].removeEventListener('click', openFileSelect, false)
            $(dragSelect.current)[0].addEventListener('click', openFileSelect, false)
        }
        return () => {
            if (dragSelect.current) {
                $(dragSelect.current)[0].removeEventListener('click', openFileSelect, false)
            }
        }
    }, [dragSelect.current])

    const openFileSelect = React.useCallback((e: any) => {
        if (dragSelect.current) {
            $(dragSelect.current).find('input[type="file"]').click();
        }
        e.stopPropagation();
    }, [dragSelect.current])

    const onChange = React.useCallback((e: RadioChangeEvent) => {
        setSearchType(e.target.value)
    }, [])

    const customRequest = React.useCallback(() => {
    }, [])

    const onSelectChange = React.useCallback((info: any) => {
        const reader = new FileReader()
        if (info.file.originFileObj) {
            reader.readAsDataURL(info.file.originFileObj)
            // tslint:disable-next-line:only-arrow-functions
            reader.addEventListener('load', function (this: FileReader, ev: ProgressEvent) {
                if (this.result && typeof this.result === 'string') {
                    props.onSelectImage(this.result, searchType);
                    setShowPanel(false);
                }
            })
        }
    }, [searchType])

    const openPanel = React.useCallback((e: React.MouseEvent) => {
        setShowPanel(true);
        e.nativeEvent.stopImmediatePropagation();
    }, [])

    const clickDom = React.useCallback(() => {
        setShowPanel(false);
    }, [])

    const stopP = React.useCallback((e: React.MouseEvent) => {
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
    }, [])

    const removeFaceBtn = React.useCallback(() => {
        stores.imageSearchStore.setParams({
            base64: ''
        })
        stores.imageSearchStore.setFaces([]);
        props.history.push({
            pathname: '/iSearch',
            search: '',
            state: {
                isPublic: false,
                folderId: stores.iSearchStore.params.folderId,
                page: 1,
                keyword: stores.iSearchStore.params?.keyword ? commonUtil.copyObj(stores.iSearchStore.params.keyword) : [],
                conditions: stores.iSearchStore.params ? commonUtil.copyObj(stores.iSearchStore.params.conditions) : [],
                facetConditions: stores.iSearchStore.params ? commonUtil.copyObj(stores.iSearchStore.params.facetConditions) : [],
                searchFaceBase64: ''
            }
        })
    }, []);

    const onCropFinished = (file: File) => {
        const reader = new FileReader()
        if (file) {
            reader.readAsDataURL(file)
            // tslint:disable-next-line:only-arrow-functions
            reader.addEventListener('load', function (this: FileReader, ev: ProgressEvent) {
                if (this.result && typeof this.result === 'string') {
                    props.onSelectImage(this.result, searchType);
                    setShowPanel(false);
                }
            })
        }
    }

    const onOpenCamera = () => {
        setVisible(true)
        let navigator: any = window.navigator;
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = (constraints: any) =>{
                // 首先，如果有getUserMedia的话，就获得它
                let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

                // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                // 否则，为老的navigator.getUserMedia方法包裹一个Promise
                return new Promise((resolve, reject) =>{
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }
        const newConstraints = {
            video: true,
            audio: false
        };
        let promise = navigator.mediaDevices.getUserMedia(newConstraints);
        promise.then((stream: any) => {
            let videoDom: any = document.getElementById('camera-search-video')
            if(videoDom){
                // 旧的浏览器可能没有srcObject
                if ("srcObject" in videoDom) {
                    videoDom.srcObject = stream;
                } 
                else {
                    // 防止再新的浏览器里使用它，应为它已经不再支持了
                    videoDom.src = window.URL.createObjectURL(stream)
                }
                videoDom.onloadedmetadata = (e: any) => {
                    videoDom.play()
                }
            }
        }).catch((err: any) => {
            console.log("失败")
        })
    }

    const onCloseCamera = () => {
        let videoDom: any = document.getElementById('camera-search-video')
        if (videoDom && videoDom.srcObject){
            let stream = videoDom.srcObject
            let tracks = stream.getTracks();
            tracks.forEach((track: any) => {
                track.stop()
            })
        }
        
    }

    const handleOk = () => {
        let videoDom: any = document.getElementById('camera-search-video')
        const canvas = document.createElement('canvas')
        canvas.width = videoDom.offsetWidth;
        canvas.height = videoDom.offsetHeight;
        canvas.style.height = `${videoDom.offsetWidth}px`;
        canvas.style.height = `${videoDom.offsetHeight}px`;
        const ctx = canvas.getContext('2d')
        if(ctx){
            ctx.drawImage(videoDom, 0, 0, canvas.width, canvas.height)
            let imgSrc = canvas.toDataURL("image/jpeg")
            onCameraDone(imgSrc)
        }
        handleCancel()
    }

    const onCameraDone = React.useCallback((base64: string)=>{
        props.onSelectImage(base64, searchType)
        setShowPanel(false)
    }, [searchType])

    const handleCancel = () => {
        onCloseCamera()
        setVisible(false)
    }

    const getDraggerProps = React.useMemo(() => {
        return {
            name: 'file',
            multiple: false,
            showUploadList: false,
            customRequest,
            accept: searchType === 'similarVideo' ? "video/*" : 'image/jpg,image/png,image/bmp,image/jpeg',
            openFileDialogOnClick: false,
            onChange: (info: UploadChangeParam<UploadFile<any>>) => {
                if (searchType === 'similarVideo') {
                    if (info.file.size && (info.file.size as number) / 1024 / 1024 > 50) {
                        message.warning('请选择50M以下的视频片段！')
                    } else {
                        props.onSelectImage(info.file, searchType);
                        setShowPanel(false);
                    }
                } else {
                    const reader = new FileReader()
                    if (info.file && info.file.originFileObj) {
                        reader.readAsDataURL(info.file.originFileObj)
                        // tslint:disable-next-line:only-arrow-functions
                        reader.addEventListener('load', function (this: FileReader, ev: ProgressEvent) {
                            if (this.result && typeof this.result === 'string') {
                                props.onSelectImage(this.result, searchType);
                                setShowPanel(false);
                            }
                        })
                    }
                }
            }
        };
    }, [searchType])
    return <div className="select-image-with-panel">
        <div className="select-btn">
            <a title={'以图搜图'.l('cc.pictureSearchPicture')} onClick={openPanel}>
                <i className="iconfont icon-tianjiatupian ant-input-clear-icon"></i>
            </a>
            {
                process.env.BUILD_TYPE === 'mah' &&
                stores.imageSearchStore.params && stores.imageSearchStore.params.base64 &&
                <span className="search-img"
                    style={{ backgroundImage: 'url("' + (stores.imageSearchStore.params.base64) + '")' }}>
                    <a className="remove-face-btn" onClick={removeFaceBtn}><CloseCircleFilled /></a>
                </span>
            }
        </div>
        {
            <div className="select-panel" onClick={stopP} style={{ display: showPanel ? ' block' : ' none' }}>
                <div css={css`
                    .ant-radio-wrapper{
                        margin-right: 18px !important;
                    }
                `}>
                    <Radio.Group onChange={onChange} value={searchType}>
                        <Radio value={'face'}>{'人脸搜索'.l('cc.faceSearch')}</Radio>
                        {
                            stores.configStore.baseConfig.functionSwitch.imageSearch.enableSimilarSearch && <Radio value={'similar'}>{'相似搜索'}</Radio>
                        }
                        {
                            stores.configStore.baseConfig.functionSwitch.imageSearch.enableVideoSearch && <Radio value={'similarVideo'}>{'片段搜索'}</Radio>
                        }
                    </Radio.Group>
                </div>
                <div css={css`display: flex;`}>
                    <div className="drag-select" ref={dragSelect} css={css`flex: 1; width: 1%;`}>
                        {
                            searchType !== 'face' ?
                            <Dragger {...getDraggerProps}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{
                                    searchType === 'similarVideo' ? '点击添加或者拖拽视频片段到此' : '点击添加或者拖拽图片到此'
                                }</p>
                                {/* <p className="ant-upload-text">{'点击添加或者拖拽视频片段到此'}</p> */}
                            </Dragger>: 
                            <Dragger {...getDraggerProps}>
                                <p className="ant-upload-drag-icon">
                                    <PictureOutlined />
                                </p>
                                <p className="ant-upload-text">{
                                    '点击添加或者拖拽带有人脸的图片到此'.l('cc.clickOrDragPicToHere')
                                }</p>
                            </Dragger>
                        }
                    </div>
                    {
                        searchType === 'face' &&
                        stores.configStore.baseConfig.functionSwitch.imageSearch?.enablePhotoSearch &&
                        <div className='drag-select camera-search' onClick={onOpenCamera} css={css`width: 48%; margin-left: 4%;`}>
                            <span className="ant-upload-wrapper" css={css`
                                box-sizing: border-box;
                                margin: 0;
                                padding: 0;
                                color: rgba(0, 0, 0, 0.88);
                                font-size: 14px;
                            `}>
                                <div className="ant-upload ant-upload-drag" css={css`
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    text-align: center;
                                    background: rgba(0, 0, 0, 0.02);
                                    border: 1px dashed #d9d9d9;
                                    border-radius: 8px;
                                    cursor: pointer;
                                    transition: border-color 0.3s;
                                    &:hover{
                                        border-color: ${stores.configStore.baseConfig.themeColor};
                                    }
                                `}>
                                    <span className="ant-upload ant-upload-btn" css={css`
                                        display: table;
                                        width: 100%;
                                        height: 100%;
                                        outline: none;
                                        padding: 16px 0;
                                        p.ant-upload-drag-icon {
                                            margin-bottom: 16px;
                                            .anticon {
                                                color: ${stores.configStore.baseConfig.themeColor};
                                                font-size: 48px;
                                            }
                                        }
                                        p.ant-upload-text {
                                            margin: 0 0 4px;
                                            color: rgba(0, 0, 0, 0.88);
                                            font-size: 16px;
                                        }
                                    `}>
                                        <div className="ant-upload-drag-container">
                                            <p className="ant-upload-drag-icon">
                                                <CameraOutlined />
                                            </p>
                                            <p className="ant-upload-text">{'摄像头拍照'.l('cc.cameraPhoto')}</p>
                                        </div>
                                    </span>
                                </div>
                            </span>
                        </div>
                    }
                </div>
            </div>
        }
        <Modal
            title={'拍照检索'.l('cc.photoSearch')}
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={false}
            centered
            width={720}
            destroyOnClose
            className='modal-camera-search'
        >
            <video id="camera-search-video" css={css`width: 100%;`}></video>
        </Modal>
    </div>
})
export default withRouter(SelectImageWithPanel)
