import configApi from "./apis/configApi";
import {stores} from "./stores";
import _ from 'lodash';
import {AxiosResponse} from 'axios';
import {Language} from "mam-core-react";
import {l} from "mam-core-react";
import {IResponse} from "mam-core-react/dist/modules/http";
import { commonUtil,initCookie } from 'mam-common-utils';
import {getApiDict, setApiOptions} from "mam-apis";
import { nxt } from './nxt'
import { ApiVersions } from 'mam-apis/publish/types/apiVersions'
import styleUtil from './utils/styleUtil';
import { mamMetadataReactLang } from 'mam-metadata-react';
import {mamComponentsReactLang} from 'mam-components-react';
import { mamCoreReactLang } from "mam-core-react";

class Config {
    public initCfg(): Promise<any> {
        initCookie();
        if (commonUtil.isDebug()){
            (window as any).$.cookie('MAM', 'H5iqoykaaAgL3zhYXLG4F1t5YH1bONqqt37zma_gVc5z7Ne2pG0SjjTfierlE0moqWAimMSETweSlG7k0HlXUXzeKhmFTaF9uvj0u7SQG82SK4YNyPdTRB_u1Pumg7auhInHuEQuihD9U9ffGMxO671hxC2ni6fOuYzKM8pxFX0', {
                path: '/'
            });
        }
        setApiOptions({
            apiVersion: ApiVersions.v2
        });
        nxt.apis = getApiDict();

        const promises: Array<Promise<any>> = [];
        promises.push(this.getConfig());
        promises.push(this.getLanguage());
        return Promise.all(promises);
    }

    private getConfig(): Promise<any> {
        return new Promise((resolve, reject) => {
            configApi.getConfigBase().then((res) => {
                if (res.data && res.data.success === true && res.data.data) {
                    if (commonUtil.isDebug()) {
                        // res.data.data.themeName = 'default';
                    }

                    stores.configStore.setBaseConfig(res.data.data);
                    if (res.data.data.currentUser){
                        stores.userStore.setCurrentUser(res.data.data.currentUser);
                    }

                    const themeColor = stores.configStore.baseConfig.themeColor;
                    let genColors = styleUtil.generateColors(themeColor);
                    stores.configStore.setBaseConfigFadeColors([genColors[4], genColors[3], genColors[2], genColors[1], genColors[0]])
                    styleUtil.changeThemeColor(genColors);

                    //加载皮肤
                    styleUtil.loadMainStyle().then(()=>{
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    });

                    //设置title
                    if (stores.configStore.baseConfig && stores.configStore.baseConfig.siteName) {
                        document.getElementsByTagName("title")[0].innerText = stores.configStore.baseConfig.siteName;
                    }
                } else {
                    this.showError(res);
                    reject();
                }
            }, (res) => {
                this.showError(res);
            });
        });
    }

    private showError(res: AxiosResponse<IResponse<any>>) {
        let error = '';
        if (!res || res.status === 0) {
            error = '无法连接到服务器，请稍后再试';
        } else {
            error = _.get(res.data, 'error.title', '');
            if (error === '') {
                error = l('system.500', '系统繁忙中，请稍后再试！');
            } else {
                error = l(_.get(res.data, 'error.code', ''), error);
            }
        }
        $('#spinner').fadeOut(function () {
            $(this).remove();
        });
        $('body').append('<div id="bootstrapper-load-error"><p>' + error + '</p><p><a onclick="location.reload()">' + l('system.retry', '立即重试') + '</a></p></div >');
    }

    private getLanguage(): Promise<any> {
        return new Promise((resolve, reject) => {
            (window as any).mam.language = new Language();
            let lang = (window as any).mam.language.get();
            (window as any).mam.language.load(lang, () => {
                //合并国际化字典
                (window as any).mam.language.append(mamCoreReactLang[lang]);
                (window as any).mam.language.append(mamMetadataReactLang[lang]);
                (window as any).mam.language.append(mamComponentsReactLang[lang]);
                resolve(undefined);
            }, {
                zh: import('./assets/lang/zh.js'),
                cht: import('./assets/lang/cht.js'),
                en: import('./assets/lang/en.js')
            });
        });
    }
}

const config = new Config();
export default config;
