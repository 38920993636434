import {action, computed, observable, runInAction} from "mobx";
import {IEntityType} from "../../../types/configTypes";
import uploadTypes from "../../../types/uploadTypes";
import {Stores} from "../../index";
import * as moment from "moment";
import uploadApi from "../../../apis/uploadApi";
import BaseStore from "../../base/baseStore";
import {ISelectAllStore} from "../selectAllStore";
import SelectAllStore from "../selectAllStore";
import PicPkgStore from "./picPkgStore";
import GroupStore from "./groupStore";
import MetadataOpeStore from './metadataOpeStore';
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import { getValidationItems } from "mam-metadata-react/dist/controls/mfcBaseControl";
import {http, validateItems} from 'mam-core-react'
import GeneralStore from "./generalStore";
import { IResponse, reqCatch } from "mam-core-react/dist/modules/http";

export interface IUploadStore{
    uploadStore: UploadStore;

    initUploadStore : (stores: Stores) => void;
}

export default class UploadStore extends BaseStore<Stores> implements ISelectAllStore {
    @observable
    public showGeneral: boolean;
    @observable
    public showPicPkg: boolean;
    @observable
    public showGroup: boolean;
    @observable
    public options: uploadTypes.IUploadOptions;
    @observable
    public transferType : number = uploadTypes.TransferTypes.WEB;
    /** 待上传任务列表，正在上传任务列表放在UploadPanelStore当中 */
    @observable
    public readyTasks: uploadTypes.ITask[] = [];
    @observable
    public selectedTask?: uploadTypes.ITask;
    /** 选中任务的临时元数据，非实时修改元数据，避免取消修改后元数据不能还原 */
    @observable
    public selectedTaskTempMetaItems?: IFormItem[]
    /** 选中任务的临时上传的附件 */
    @observable
    public selectedTaskAttachmentFiles?: uploadTypes.IFile[]
    /** 所有素材类型元数据 */
    @observable
    public allMetadataFields: uploadTypes.IMetadataFieldsRes;
    // 拓展元数据
    @observable
    public extendMetadataField: IFormItem[] = [];
    @observable
    public entityAllTypes: IEntityType[] = [];
   
    /** 是否批量编辑模式 */
    @observable
    public multiEditMode: boolean = false;
    /** 批量编辑临时元数据 */
    @observable
    public multiEditMeta: IFormItem[];
    /** 批量编辑，当前编辑的素材类型 */
    @observable
    public multiEditEntityType?: string;

    /** 图片包上传的主元数据和单图元数据 */
    @observable
    public picPackageMetadataField: uploadTypes.IPicPackageMetadataFieldRes;
    /** 图片包上传时选中的单图index */
    @observable
    public selectedFileIndex: number = 0;

    public selectAllStore: SelectAllStore;

    public generalStore: GeneralStore;

    public picPkgStore: PicPkgStore;

    public groupStore: GroupStore;

    /** 元数据相关扩展操作 */
    public metadataOpeStore: MetadataOpeStore;

    constructor(sts: Stores){
        super(sts);
        this.initSelectAllStore(sts, 'this.readyTasks', 'checked', this);

        this.generalStore = new GeneralStore(this);
        this.picPkgStore = new PicPkgStore(this);
        this.groupStore = new GroupStore(this);
        this.metadataOpeStore = new MetadataOpeStore(sts, this);
    }

    public initSelectAllStore(sts: Stores, listSyntax: string, itemSelectKey: string, currentStore?: BaseStore<Stores>){
        this.selectAllStore = new SelectAllStore(sts, listSyntax, itemSelectKey, currentStore);
    }

    @action
    public setShowGeneral(show: boolean){
        this.showGeneral = show;
    }

    @action
    public setShowPicPkg(show: boolean){
        this.showPicPkg = show;
    }

    @action
    public setShowGroup(show: boolean){
        this.showGroup = show;
    }

    @action
    public setOptions(options: uploadTypes.IUploadOptions){
        this.options = options;
    }

    @action
    public setTransferType(transferType: number){
        this.transferType = transferType;
    }

    /** 更新任务列表顺序 */
    @action
    public updateTasksPos(tasks: uploadTypes.ITask[]){
        this.readyTasks = tasks;
    }

    /** 更新文件列表顺序 */
    @action
    public updateTaskFilesPos(task: uploadTypes.ITask, files: uploadTypes.IFile[]){
        task.files = files;
    }

    @action
    public setTasks(tasks: uploadTypes.ITask[]){
        if (tasks.length === 0){
            this.readyTasks = tasks;
            this.selectedTask = undefined;
            return;
        }
        if (tasks && tasks.length > 0){
            this.initTasks(tasks).then(()=>{
                // 自动获取图片exif信息
                if (this.options.taskType === uploadTypes.TaskTypes.GENERAL){
                    tasks.forEach(task=>{
                        if (task.metadata.field && task.entityType === 'biz_sobey_picture'){
                            this.getEditedFieldByExif(task.metadata.field, task.picImage || '').then(newField=>{
                                runInAction(()=>{
                                    task.metadata.field = newField;
                                    this.readyTasks.push(task);
                                    if (!this.selectedTask){
                                        this.selectedTask = this.readyTasks[0];
                                    }
                                })
                            })
                        }
                        else {
                            runInAction(()=>{
                                this.readyTasks.push(task);
                            })
                        }
                    })
                }
                else if (this.options.taskType === uploadTypes.TaskTypes.MOCPICTUREPACKAGEUPLOAD && tasks.length > 0
                        && tasks[0].files){
                        tasks[0].files.forEach(file=>{
                        if (file.metadata.field && file.image){
                            this.getEditedFieldByExif(file.metadata.field, file.image).then(newField=>{
                                runInAction(()=>{
                                    file.metadata.field = newField;
                                })
                            })
                        }
                        runInAction(()=>{
                            this.readyTasks.push(tasks[0]);
                        })
                    })
                }
                else {
                    runInAction(()=>{
                        this.readyTasks = tasks;
                    })
                }
                runInAction(()=>{
                    if (this.readyTasks && this.readyTasks.length > 0){
                        this.selectedTask = this.readyTasks[0];
                    }
                })
            });
        }
    }

    @action
    public appendTasks(tasks: uploadTypes.ITask[]){
        this.initTasks(tasks).then(()=>{
            runInAction(()=>{
                tasks.forEach((task)=>{
                    // 自动获取图片exif信息
                    if (this.options.taskType === uploadTypes.TaskTypes.GENERAL){
                        if (task.metadata.field && task.entityType === 'biz_sobey_picture'){
                            this.getEditedFieldByExif(task.metadata.field, task.picImage || '').then(newField=>{
                                runInAction(()=>{
                                    task.metadata.field = newField;
                                    this.readyTasks.push(task);
                                })
                            })
                        }
                        else {
                            this.readyTasks.push(task);
                        }
                    }
                    else if (this.options.taskType === uploadTypes.TaskTypes.GROUPUPLOAD){
                        this.readyTasks.push(task);
                    }
                    else {
                        this.readyTasks.push(task);
                    }
                });
            })
        });
    }

    /** 为图片包或成组任务添加文件 */
    @action
    public appendFiles(task: uploadTypes.ITask, files: uploadTypes.IFile[]){
        if (task) {
            if (!task.files) {
                task.files = [];
            }
            // 过滤掉已有相同文件名的文件
            let filterFiles = files.filter(file=>{
                return _.findIndex(task.files, {fileName: file.fileName}) === -1;
            })
            if (filterFiles.length === 0){
                return;
            }
            if (this.options.taskType === uploadTypes.TaskTypes.MOCPICTUREPACKAGEUPLOAD
                || this.options.taskType === uploadTypes.TaskTypes.PICTUREPACKAGEUPLOAD) {
                this.picPkgStore.handleFiles(this.options, this.readyTasks, filterFiles).then((retFiles)=>{
                    runInAction(()=>{
                        retFiles.forEach((file) => {
                            if (task && task.files)
                            {
                                if (file.metadata.field && file.image){
                                    // 自动获取图片exif信息
                                    this.getEditedFieldByExif(file.metadata.field, file.image).then(newField=>{
                                        runInAction(()=>{
                                            file.metadata.field = newField;
                                            if (task && task.files){
                                                task.files.push(file);
                                            }
                                        })
                                    })
                                }
                                else {
                                    task.files.push(file);
                                }
                            }
                        });
                    });
                });
            }
            else if (this.options.taskType === uploadTypes.TaskTypes.GROUPUPLOAD)
            {
                task.files = task.files.concat(filterFiles);
            }
        }
    }

    @action
    public removeTask(task: uploadTypes.ITask){
        _.remove(this.readyTasks, (o: uploadTypes.ITask)=>{
            return o === task;
        })
    }

    /** 删除选中任务的某个文件 */
    @action
    public removeSelectedTaskFileByIdx(index: number){
        if (this.selectedTask && this.selectedTask.files)
        {
            this.selectedTask.files.splice(index, 1);
        }
    }

    /** 删除选中任务的某个文件 */
    @action
    public removeSelectedTaskFile(file: uploadTypes.IFile){
        if (this.selectedTask && this.selectedTask.files)
        {
            _.remove(this.selectedTask.files, (o: uploadTypes.IFile)=>{
                return o === file;
            });
        }
    }

    @action
    public removeTaskFile(task: uploadTypes.ITask, file: uploadTypes.IFile){
        if (task.files){
            let fileIdx = _.findIndex(task.files, o=>o === file);
            task.files.splice(fileIdx, 1)
            task.files = task.files.concat([])
        }
    }

    /** 修改选中任务的某个文件名称 */
    @action
    public editSelectedTaskFileName(index: number, name: string){
        if (this.selectedTask && this.selectedTask.files){
            let targetFile = this.selectedTask.files[index];
            // 处理后缀
            let ext = (targetFile.file as File).name.substring((targetFile.file as File).name.lastIndexOf('.'), (targetFile.file as File).name.length);
            if (name.indexOf(ext) === -1){
                name = name + ext;
            }
    
            targetFile.fileName = name;
            let newFile = new File([targetFile.file as File], name, {type: (targetFile.file as File).type});
            targetFile.file = newFile;
        }
    }

    @computed
    public get getFilterTasks(): uploadTypes.ITask[]{
        let types = this.entityAllTypes.filter((type) => {
            return type.selected;
        });
        return this.readyTasks.filter((task) => {
            return _.find(types, { code: task.entityType }) != null;
        });
    }

    @computed
    public get getCheckedTasks(): uploadTypes.ITask[]{
        return this.getFilterTasks.filter((task) => {
            return task.checked;
        });
    }

    /** 获取选中并且过滤显示出来的任务 */
    @computed
    public get getCheckedVisibleTasks(): uploadTypes.ITask[]{
        return this.getCheckedTasks.filter((item)=>{
            if (!this.stores.webUploadStore.isInit){ //webUploadStore未初始化，说明不是webUpload模块
                return true;
            }
            if (this.options.taskType === uploadTypes.TaskTypes.GENERAL && process.env.BUILD_TYPE !== 'moc'){
                if (_.findIndex(this.stores.webUploadStore.getCheckedFilterEntityTypes, {code: item.entityType}) > -1){
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        })
    }

    @action
    public initTasks(tasks: uploadTypes.ITask[]){
        return new Promise((resolve, reject)=>{
            if (tasks.length === 0){
                resolve(undefined);
                return;
            }
            tasks.forEach((task) => {
                task.checked = true;
                this.addEntityAllTypes(task);
            });
            this.selectAllStore.checkSelectAll();
            if(this.options.taskType === uploadTypes.TaskTypes.MOCPICTUREPACKAGEUPLOAD
                || this.options.taskType === uploadTypes.TaskTypes.PICTUREPACKAGEUPLOAD){
                tasks.forEach((task) => {
                    if (this.options.taskType === uploadTypes.TaskTypes.MOCPICTUREPACKAGEUPLOAD){
                        //图片包元数据赋值
                        this.assemblePicPackageMetadata(task);
                    }
                    else {
                        this.assembleMetadata(task);
                    }
                    this.getExtendMetadataField(task.entityType)
                });
                if (tasks[0].files){
                    this.picPkgStore.handleFiles(this.options, tasks, tasks[0].files).then((retFiles)=>{
                        runInAction(()=>{
                            if(retFiles && retFiles.length > 0){ 
                                this.setSelectedFileIndex(0) //初始化第一张单图选中
                                retFiles[0].isIconKeyframe = true //初始化第一张单图作为图片包的封面
                            }
                            tasks[0].files = retFiles;
                        });
                        resolve(undefined);
                    });
                }
            }
            else if (this.options.taskType === uploadTypes.TaskTypes.GROUPUPLOAD && tasks[0].files)
            {
                this.groupStore.handleFiles(this.options, tasks);
                tasks.forEach((task) => {
                    this.assembleMetadata(task);
                    this.getExtendMetadataField(task.entityType)
                });
                resolve(undefined);
            }
            else{
                this.generalStore.handleFiles(this.options, tasks).then(()=>{
                    runInAction(()=>{
                        tasks.forEach((task) => {
                            task.checked = true;
                            this.assembleMetadata(task);
                            this.getExtendMetadataField(task.entityType)
                            if (this.options.taskType === uploadTypes.TaskTypes.GENERAL)
                            {
                                this.addEntityAllTypes(task);
                            }
                        });
                        if (this.options.taskType === uploadTypes.TaskTypes.GENERAL)
                        {
                            this.selectAllStore.checkSelectAll();
                        }
                        resolve(undefined);
                    })
                })
            }
        })
    }

    @action
    public setSelectedTask(task?: uploadTypes.ITask){
        this.selectedTask = task;
    }

    @action
    public setSelectedTaskTempMetaItems(items?: IFormItem[]){
        this.selectedTaskTempMetaItems = items;
    }

    @action
    public setSelectedTaskAttachmentFiles(attachmentFiles?: uploadTypes.IFile[]){
        this.selectedTaskAttachmentFiles = attachmentFiles;
    }
    @action
    public setSelectedTaskAttachmentFilesOk(attachmentFiles?: uploadTypes.IFile[]){
        if(this.selectedTask){
            this.selectedTask.attachmentFiles = attachmentFiles;
        }
    }
    @action
    public addSelectedTaskAttachmentFiles(newFiles: uploadTypes.IFile[]){
        if(this.selectedTaskAttachmentFiles){
            this.selectedTaskAttachmentFiles = this.selectedTaskAttachmentFiles.concat(newFiles)
        }else{
            this.selectedTaskAttachmentFiles = newFiles;
        }
    }

    @action
    public getExtendMetadataField(type: string): Promise<any>{
        if (!this.stores.configStore.config.config.enableExtendMetadata){
            return Promise.resolve();
        }
        return new Promise((resolve, reject)=>{
            uploadApi.getExtendMetadataField(type).then((res)=>{
                runInAction(()=> {
                    if (res.data.success === true && res.data.data) {
                        this.extendMetadataField = res.data.data[0].fields;
                        resolve(true);
                    }
                    else
                    {
                        reject();
                    }
                });
            });
        });
    }

    @action
    public getAllMetadataFields(): Promise<any>{
        if (this.allMetadataFields)
        {
            return Promise.resolve();
        }
        return new Promise((resolve, reject)=>{
            uploadApi.getMetadataFields().then((res)=>{
                runInAction(()=> {
                    if (res.data.success === true && res.data.data) {
                        this.allMetadataFields = res.data.data;
                        resolve(res.data.data);
                    }
                    else
                    {
                        reject();
                    }
                });
            });
        });
    }

    @action
    public addEntityAllTypes(task: uploadTypes.ITask){
        if (_.find(this.entityAllTypes, { code: task.entityType }) == null) {
            let item = _.find(this.stores.configStore.config.entityTypes, { code: task.entityType });
            if (item)
            {
                item.selected = true;
                this.entityAllTypes.push(item);
            }
        }
    }

    @action
    public removeEntityAllTypes(task: uploadTypes.ITask){
        if (_.find(this.readyTasks, { entityType: task.entityType }) == null){
            _.remove(this.entityAllTypes, (o: IEntityType) => {
                return o.code === task.entityType;
            });
        }
    }

    @action
    public switchEntityTypeSelected(item: IEntityType){
        item.selected = !item.selected;
    }

    @action
    public setMultiEditMode(mode: boolean){
        this.multiEditMode = mode;
    }

    @action
    public setMultiEditMeta(meta: IFormItem[]){
        this.multiEditMeta = meta;
    }
    @action
    public setMultiEditEntityType(type?: string){
        this.multiEditEntityType = type;
    }
    @action
    public setExtendEditMeta(meta: IFormItem[]){
        this.extendMetadataField = meta;
    }

    @action
    public setSelectedTaskMetaField(items: IFormItem[], index: number, value: string){
        if (this.selectedTask && this.selectedTask.metadata.field)
        {
            //特殊处理标题: 去首尾空格
            let nameField = _.find(items, {fieldName: 'name_'});
            if (nameField){
                nameField.value = (nameField.value as string).replace(/^\s+|\s+$/g, '')
            }
            this.selectedTask.metadata.field = items;
            //修改题名需要更新task.metadata.name
            if (items[index].fieldName === 'name_'){
                this.selectedTask.metadata.name = value.replace(/^\s+|\s+$/g, '');
            }
        }
    }

    @action
    public setSelectedTaskMetaItems(items: IFormItem[]){
        if (this.selectedTask)
        {
            //特殊处理标题: 去首尾空格
            let nameField = _.find(items, {fieldName: 'name_'});
            if (nameField){
                nameField.value = (nameField.value as string).replace(/^\s+|\s+$/g, '')

                //修改题名需要更新task.metadata.name
                this.selectedTask.metadata.name = nameField.value;
            }
            this.selectedTask.metadata.field = items;
        }
    }

    @action
    public batchSetValueFromMultiEditMeta(checkedItems: IFormItem[]){
        this.getCheckedVisibleTasks.forEach((task) => {
            if (task.metadata.field && task.status === 'added')
            {
                task.metadata.field.forEach((tf)=>{
                    let n = _.find(checkedItems, (fi: IFormItem) => {
                        return fi.fieldName === tf.fieldName && fi.fieldPath === tf.fieldPath;
                    });
                    if (n)
                    {
                        tf.value = n.value;
                    }

                    if (tf.fieldName === 'name_'){//修改了名称需要更新metadata.name
                        task.metadata.name = tf.value as string;
                    }
                });
            }
        });
    }

    @action
    public validTasksMetadata(){
        let allPromise: Array<Promise<any>> = []
        let addedTasks = this.getCheckedVisibleTasks.filter((item)=>{
            return item.status === 'added'
        })
        addedTasks.forEach((task) => {
            if (task.metadata.field){
                task.validErrs = [];
                task.metadata.field.filter((fieldItem)=>{
                    return fieldItem.isShow !== false;
                }).forEach((fieldItem)=>{
                    let items = getValidationItems(fieldItem);
                    allPromise.push(new Promise((resolve, reject)=>{
                        validateItems(fieldItem, items).then((validErrs)=>{
                            resolve(undefined);
                        }, (validErrs)=>{
                            runInAction(()=>{
                                if (!task.validErrs){
                                    task.validErrs = [];
                                }
                                task.validErrs = task.validErrs.concat(validErrs.value);
                            })
                            reject();
                        });
                    }))
                })
            }
        });
        return Promise.all(allPromise);
    }

    @action
    public setSelectedTaskFile2Cover(index: number){
        if (this.selectedTask && this.selectedTask.files){
            this.selectedTask.files.forEach(file=>{
                file.isIconKeyframe = false;
            })
            if (this.selectedTask.files[index]){
                this.selectedTask.files[index].isIconKeyframe = true;
            }
        }
    }

    /** 从图片exif当中获取字段，修改元数据，返回元数据列表 */
    public getEditedFieldByExif(oriField: IFormItem[], base64: string): Promise<IFormItem[]>{
        if (base64 === ''){
            return Promise.resolve(oriField);
        }
        return new Promise((resolve, reject)=>{
            this.loadImageDom(base64).then(imgDom=>{
                commonUtil.asyncLoadScript('/libs/exif-js/exif-js.js', ()=>{
                    (window as any).EXIF.getData(imgDom, ()=> {
                        runInAction(()=>{
                            const cpField = commonUtil.copyObj(oriField);
                            if (cpField){
                                let dateTimeOriginal = (window as any).EXIF.getTag(imgDom, "DateTimeOriginal");
                                if (dateTimeOriginal){
                                    let dateTimeOriginalArr = dateTimeOriginal.split(' ');
                                    dateTimeOriginal = dateTimeOriginalArr[0].replace(/\:/g, '-') + ' ' + dateTimeOriginalArr[1];
                                    const shootingtimeField = _.find(cpField, { fieldName: 'shootingtime' });
                                    if (shootingtimeField){
                                        shootingtimeField.value = dateTimeOriginal;
                                    }
                                }
                                resolve(cpField);
                            }
                            else {
                                reject();
                            }
                        })
                    });
                })
            })
        })
    }

    public async checkDuplicateTask(tasks: uploadTypes.ITask[]){
        let duplicateTasksName: string[] = []
        for (let i = 0; i < tasks.length; i++){
            let params: uploadTypes.ICheckDuplicateTaskReq = {
                condition: {
                    name_: tasks[i].metadata.name,
                    fileSize_: (tasks[i].file as File).size
                },
                type: 'biz_sobey_agreement'
            }
            let res = await uploadApi.checkDuplicateTask(params)
            if (res.data.data && res.data.data.length > 0){
                duplicateTasksName.push(tasks[i].fileName || '')
            }
        }
        return duplicateTasksName;
    }

    private loadImageDom(base64: string): Promise<HTMLImageElement>{
        return new Promise((resolve, reject)=>{
            let imgDom = document.createElement('IMG') as HTMLImageElement;
            imgDom.setAttribute('src', base64);
            if (!imgDom.complete){
                imgDom.onload = ()=>{
                    resolve(imgDom);
                }
                imgDom.onerror = (e)=>{
                    resolve(imgDom);
                    console.error(e)
                }
            }
            else {
                resolve(imgDom);
            }
        })
    }

    //一般上传的元数据赋值
    private assembleMetadata(item: uploadTypes.ITask){
        let metadata: uploadTypes.IMetadataFieldsItem[] = this.allMetadataFields[item.entityType];
        let entityDataMetatdata = _.find(metadata, {metadataTypeCode: 'entityData'})
        if (!entityDataMetatdata){
            return;
        }

        //初始化给摄影师增加初始值-自己
        let photographerField = _.find(entityDataMetatdata.fields, { fieldName: 'photographer' });
        if(photographerField && this.stores.userStore.currentUser && this.stores.userStore.currentUser.nickName){
            photographerField.value = this.stores.userStore.currentUser.nickName;
        }

        item.metadata.field = commonUtil.copyObj(entityDataMetatdata.fields);

        let now = new Date();
        let specialFields: any = { // 特殊字段
            'name_': item.metadata.name,
            'filesize': !item.file && item.files ? item.files[0].fileSize : (item.file ? (item.file as File).size : 0),
            'fileext': !item.file && item.files ? ('.' + commonUtil.getExtension(item.files[0].fileName)) : (item.file ? commonUtil.getExtension((item.file as File).name) : ''),
            'createUser_': this.stores.userStore.currentUser ? this.stores.userStore.currentUser.nickName : '',
            'createDate_': moment(now).format('YYYY-MM-DD HH:mm:ss')
        }
        if (item.entityType === 'biz_sobey_agreement'){
            specialFields.authorizedName = item.metadata.name;
        }
        for (let sf in specialFields) {
            if (specialFields.hasOwnProperty(sf))
            {
                let field = _.find(item.metadata.field, (o: IFormItem) => {
                    return o.fieldName === sf;
                });
                if (field != null) {
                    field.value = specialFields[sf];
                }
            }
        }

        // 继承附加的field
        if (this.options.extraField !== undefined && this.options.extraField.length > 0) {
            this.options.extraField.forEach((e) => {
                let temp = _.find(item.metadata.field, (o: IFormItem) => {
                    return o.fieldName === e.fieldName;
                });
                if (temp != null && e.value != null)
                {
                    temp.value = e.value;
                }
            });
        }
    }

    // 获取图片包上传时图片包的元数据和单图的元数据
    @action
    public getPicPackageMetadataField(): Promise<any>{
        if (this.picPackageMetadataField)
        {
            return Promise.resolve();
        }
        return new Promise((resolve, reject)=>{
            uploadApi.getPicPackageMetadataField().then((res)=>{
                runInAction(()=> {
                    if (res.data.success === true && res.data.data) {
                        //初始化给摄影师增加初始值-自己
                        let photographerField = _.find(res.data.data.metadata, { fieldName: 'photographer' });
                        if(photographerField && this.stores.userStore.currentUser && this.stores.userStore.currentUser.nickName){
                            photographerField.value = this.stores.userStore.currentUser.nickName;
                        }
                        this.picPackageMetadataField = res.data.data;
                        resolve(res.data.data);
                    }else
                    {
                        reject();
                    }
                });
            });
        });
    }

    // 获取图片包上传时图片包的选中的单个文件
    @action
    public setSelectedFileIndex(index: number){
        this.selectedFileIndex = index
    }
    // 修改单图属性的元数据
    @action
    public setSelectedFileMetaField(items: IFormItem[], index: number, value: string){
        if (this.selectedTask && this.selectedTask.files && this.selectedTask.files.length > 0)
        {
            //特殊处理标题: 去首尾空格
            let nameField = _.find(items, {fieldName: 'name'});
            if (nameField && nameField.value){
                nameField.value = (nameField.value as string).replace(/^\s+|\s+$/g, '')
            }
            this.selectedTask.files[this.selectedFileIndex].metadata.field = items;
            //修改单图的名称需要更新单图文件名
            //注意： 只有单图的名称对应的是fieldName: 'name'，其他的均对应fieldName: 'name_'
            if (items[index].fieldName === 'name'){
                this.selectedTask.files[this.selectedFileIndex].metadata.name = value.replace(/^\s+|\s+$/g, '');
                this.selectedTask.files[this.selectedFileIndex].fileName = value.replace(/^\s+|\s+$/g, '')+"."+commonUtil.getExtension(this.selectedTask.files[this.selectedFileIndex].fileName)
            }
        }
    }
    // 赋值单图属性的元数据给其他单图
    @action
    public copyFileMeta(copyItems: IFormItem[]){
        if (this.selectedTask && this.selectedTask.files && this.selectedTask.files.length > 0)
        {
            this.selectedTask.files.map((file:uploadTypes.IFile)=>{
                if(file.metadata.field){
                    copyItems.map((item:IFormItem)=>{
                        let fieldObj = _.find(file.metadata.field,{fieldName:item.fieldName})
                        if(fieldObj){
                            fieldObj.value = item.value
                        }
                    })
                }
            })
        }
    }
    // 给单图修改其是否用作图片包的封面
    @action
    public setFileIsIconKeyframe(index: number){
        if (this.selectedTask && this.selectedTask.files && this.selectedTask.files.length > 0)
        {
            this.selectedTask.files.map((file:uploadTypes.IFile)=>{
                file.isIconKeyframe = false
            })
            this.selectedTask.files[index].isIconKeyframe = true
        }
    }
    //图片包上传的元数据赋值
    private assemblePicPackageMetadata(item: uploadTypes.ITask){
        let metadata = this.picPackageMetadataField.entity;
        item.metadata.field = commonUtil.copyObj(metadata);

        let now = new Date();
        let specialFields: any = { // 特殊字段
            'name_': item.metadata.name,
            'filesize': !item.file && item.files ? item.files[0].fileSize : (item.file ? (item.file as File).size : 0),
            'fileext': !item.file && item.files ? ('.' + commonUtil.getExtension(item.files[0].fileName)) : (item.file ? commonUtil.getExtension((item.file as File).name) : ''),
            'createUser_': this.stores.userStore.currentUser ? this.stores.userStore.currentUser.nickName : '',
            'createDate_': moment(now).format('YYYY-MM-DD HH:mm:ss')
        }
        for (let sf in specialFields) {
            if (specialFields.hasOwnProperty(sf))
            {
                let field = _.find(item.metadata.field, (o: IFormItem) => {
                    return o.fieldName === sf;
                });
                if (field != null) {
                    field.value = specialFields[sf];
                }
            }
        }

        // 继承附加的field
        // if (this.options.extraField !== undefined && this.options.extraField.length > 0) {
        //     this.options.extraField.forEach((e) => {
        //         let temp = _.find(item.metadata.field, (o: IFormItem) => {
        //             return o.fieldName === e.fieldName;
        //         });
        //         if (temp != null && e.value != null)
        //         {
        //             temp.value = e.value;
        //         }
        //     });
        // }
    }

    //特殊处理自定义选题元数据需单独处理
    @action
    public assembleExpendMetaData(tasks: uploadTypes.ITask[] | uploadTypes.ITask) {
        if(this.extendMetadataField && this.extendMetadataField.length > 0) {
            let obj: any = {}
            let currItem:IFormItem | undefined = _.find(this.extendMetadataField, { controlType: 30 })
            if(currItem && currItem.value) {
                let newArr: any[] = []
                newArr.push({
                    formtaskid : JSON.parse('' + currItem.value).code,
                    title : JSON.parse('' + currItem.value).title
                })
                obj = {
                    type: "model_sobey_object_formtaskrelation",
                    name: null,
                    metadata: newArr
                }
            }
            if(_.isArray(tasks)) {
                _.forEach(tasks, item => {
                    if(Object.keys(obj).length > 0) {
                        item.extendData = []
                        item.extendData.push(obj)
                    }
                })
            } else {
                if(Object.keys(obj).length > 0) {
                    tasks.extendData = []
                    tasks.extendData.push(obj)
                }
            }
        }
        return tasks
    }

    /** 追加扩展字段，例如：将视频关联到剧集下面 */
    @action
    public appendParentRelationExtendData(task: uploadTypes.ITask, relationInfo: uploadTypes.IRelationInfo[], relationDesc?: string) {
        if (!task.extendData){
            task.extendData = [];
        }
        // 如果要修改关联信息，则先删除原来添加的
        if (_.findIndex(task.extendData, {type: 'model_sobey_parent_relation'}) > -1){
            _.remove(task.extendData, {type: 'model_sobey_parent_relation'})
        }
        task.extendData.push({
            type: 'model_sobey_parent_relation',
            name: 'model_sobey_parent_relation',
            metadata: relationInfo
        })
        if (relationDesc){
            task.relationDesc = relationDesc;
        }
    }

    /** 追加扩展字段，例如：将版权书关联到剧集下面 */
    @action
    public appendChildrenRelationExtendData(task: uploadTypes.ITask, relationInfo: uploadTypes.IRelationInfo[]) {
        if (!task.extendData){
            task.extendData = [];
        }
        // 如果要修改关联信息，则先删除原来添加的
        if (_.findIndex(task.extendData, {type: 'model_sobey_child_relation'}) > -1){
            _.remove(task.extendData, {type: 'model_sobey_child_relation'})
        }
        task.extendData.push({
            type: 'model_sobey_child_relation',
            name: 'model_sobey_child_relation',
            metadata: relationInfo
        })
    }
}
