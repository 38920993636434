import ExtendStore from "../base/extendStore";
import MetadataMgStore from "./extends/metadataMg/metadataMgStore";
import InstanceStore from "./extends/instanceStore";
import AdvancedSearchSetStore from "./extends/advancedSearchSetStore";
import FacetSetStore from "./extends/facetSetStore";
import EnumerationStore from "./extends/enumerationStore";
import BusinessLogStore from "./extends/businessLogStore";
import OrgSetStore from "./extends/user/orgSetStore";
import RoleSetStore from "./extends/user/roleSetStore";
import AuthSetStore from "./extends/user/authSetStore";
import AllUserStore from "./extends/user/allUserStore";
import AuthGroupStore from "./extends/user/authGroupStore";
import EsSearchConfigStore from "./extends/esSearchConfig/esSearchConfigStore";
import TreeSetStore from "./extends/treeSetStore";
export default class ManageExtendStore extends ExtendStore {
    public metadataMgStore: MetadataMgStore;
    public instanceStore: InstanceStore;
    public advancedSearchSetStore: AdvancedSearchSetStore;
    public facetSetStore: FacetSetStore;
    public enumerationStore: EnumerationStore;
    public businessLogStore: BusinessLogStore;
    public orgSetStore: OrgSetStore;
    public roleSetStore: RoleSetStore;
    public authSetStore: AuthSetStore;
    public allUserStore: AllUserStore;
    public authGroupStore: AuthGroupStore;
    public esSearchConfigStore: EsSearchConfigStore;
    public treeSetStore: TreeSetStore;

    public initMetadataMgStore() {
        this.metadataMgStore = new MetadataMgStore(this.stores, this as any);
    }
    public initInstanceStore() {
        this.instanceStore = new InstanceStore(this.stores, this as any);
    }
    public initAdvancedSearchSetStore(){
        this.advancedSearchSetStore = new AdvancedSearchSetStore(this.stores, this as any);
    }
    public initFacetSetStore(){
        this.facetSetStore = new FacetSetStore(this.stores, this as any);
    }
    public initEnumerationStore(){
        this.enumerationStore = new EnumerationStore(this.stores, this as any);
    }
    public initBusinessLogStore() {
        this.businessLogStore = new BusinessLogStore(this.stores, this as any);
    }

    public initOrgSetStore() {
        this.orgSetStore = new OrgSetStore(this.stores, this as any);
    }

    public initRoleSetStore() {
        this.roleSetStore = new RoleSetStore(this.stores, this as any);
    }

    public initAuthSetStore() {
        this.authSetStore = new AuthSetStore(this.stores, this as any);
    }

    public initAllUserStore() {
        this.allUserStore = new AllUserStore(this.stores, this as any);
    }

    public initAuthGroupStore() {
        this.authGroupStore = new AuthGroupStore(this.stores, this as any);
    }
    
    public initEsSearchConfigStore(){
        this.esSearchConfigStore = new EsSearchConfigStore(this.stores, this as any);
    }

    public initTreeSetStore(){
        this.treeSetStore = new TreeSetStore(this.stores, this as any);
    }
}
