import { permissionUtil } from 'mam-common-utils';
import { IBasePermission } from './commonTypes';

namespace folderPermissionTypes {
    export interface IRelatedPermission {
        folderOwner: IUserInfo;
        users: IUserInfo[]
        folderPrivilege: string
        canSelectUser: boolean
        privilegeName: string
    }

    export interface IUserInfo extends IBasePermission {
        userCode: string
        loginName: string
        nickName: string
        selected?: boolean
        priviligeLevel?: number
        avatarUrl?: string
    }

    export interface ISaveFolderPermissionRequest {
        folderId?: string
        folderName?: string
        users: folderPermissionTypes.IUserInfo[],
        name?: string
        parentId: string
        privilege?: string
        folderPrivilege?: string
        isCoveredChildren?: boolean
        ownerUserCode?: string
    }

    export class CreatePublicFolder {
        public id: string = ''
        public name: string
        public parentId: string
        public privilegeEnable: boolean
        public privilege: string
    }

    export interface IOpenModalInfo {
        operateType: folderOperate
        parentId: string
        contentId: string
        name: string
        oldName?: string
        isCreate: boolean
    }

    export interface IUpdateFailEntiy {
        title: string
        contentId: string
        reason: string
        resultCode: string
    }

    export interface IUpdatePermissionTask {
        current: number
        taskId: string
        status: string
        progress: number
        total: number
        errorList: IUpdateFailEntiy[]
    }
    export interface IParentsInfo {
        users?: IUserInfo[]
        canSelectUser: boolean
        permissions: privilegesItem[]
        privilege: string
    }
    export interface privilegesItem {
        siteCode: string
        templateCode: string
        templateName: string
        templateType: number
    }
}
export default folderPermissionTypes

export enum folderOperate {
    create = 'create',// 创建
    edit = 'edit', // 编辑
    view = 'view'// 详情
}