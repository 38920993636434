import BaseStore from "../../../base/baseStore";
import {Stores} from "../../../index";
import _ from "lodash";
import { action, observable } from "mobx";

export default class PictureTabStore extends BaseStore<Stores>{
    @observable
    public pictureTabsSelKey: string = 'normal';

    constructor(stores: Stores) {
        super(stores)
    }

    @action
    public setPictureTabsSelKey(pictureTabsSelKey: string){
        this.pictureTabsSelKey = pictureTabsSelKey;
    }
}