import * as React from 'react';
import {IRouterType} from "../types/routerTypes";
import {Route} from 'react-router-dom'
import * as H from "history";
import {stores} from "../stores";
import { commonUtil } from 'mam-common-utils';

interface IAuthRouteProps extends IRouterType{
    location?: H.Location
}

const LOGIN_ROUTE = '/login';

class AuthRoute extends React.Component<IAuthRouteProps, any> {
    constructor(props: IAuthRouteProps) {
        super(props)
    }

    public render() {
        let loginPageUrl = LOGIN_ROUTE;
        if (stores.configStore.baseConfig.loginUrl){
            loginPageUrl = stores.configStore.baseConfig.loginUrl;
        }
        let routeProps : IRouterType;
        routeProps = {
            component: this.props.component
        };
        if (this.props.path !== undefined)
        {
            routeProps.path = this.props.path;
        }
        if (this.props.exact !== undefined)
        {
            routeProps.exact = this.props.exact;
        }
        let currentRoute: string | undefined = this.props.location ? this.props.location.pathname : undefined;
        if (this.onRouteChanged(loginPageUrl, currentRoute))
        {
            return (
                <Route {...routeProps}/>
            )
        }
        else
        {
            if (currentRoute && currentRoute !== loginPageUrl) {
                //记录当前url，登录后自动跳转到当前页面
                let backUrl = commonUtil.getUrlQueryParam('back_url');
                if (!backUrl){
                    loginPageUrl = commonUtil.addUrlParam(loginPageUrl, 'back_url=' + window.location.href);
                }
                let state = commonUtil.getUrlQueryParam('state');
                if (!state){
                    loginPageUrl = commonUtil.addUrlParam(loginPageUrl, 'state=' + encodeURIComponent(window.location.href));
                }
                window.location.href = loginPageUrl;
            }
            return null;
        }
    }

    private onRouteChanged(loginUrl: string, currentRoute: string | undefined) : boolean{
        if (currentRoute && currentRoute !== LOGIN_ROUTE && currentRoute !== loginUrl
            && stores.userStore.currentUser === undefined)
        {
            return false;
        }
        return true;
    }
}

export default AuthRoute