import ExtendStore from "../base/extendStore";
import { observable, action, runInAction } from 'mobx';
import recycleTypes from 'src/types/recycleTypes';
import { IBasePageResponse } from 'src/types/commonTypes';
import recycleApi from 'src/apis/recycleApi';
import { Stores } from '..';
import ccTypes from 'src/types/ccTypes';
import {l} from "mam-core-react";
import { message } from 'antd';

export default class RecycleStore extends ExtendStore {
    @observable
    public fullSearchData?: IBasePageResponse<any>
    public sortItems: ccTypes.searchTypes.ISortItem[] = [
        { text: l('recycle.sortDateDesc', '删除时间降序'), field: 'deleteTime_', desc: 'desc' },
        { text: l('recycle.sortDateAsc', '删除时间升序'), field: 'deleteTime_', desc: 'asc' }
    ]
    /** 当前选中排序类型 */
    @observable
    public currentSort: ccTypes.searchTypes.ISortItem = this.sortItems[0];
    @observable
    public keyword: string;

    constructor(stores: Stores) {
        super(stores)
        this.initSelectAllStore(stores, 'stores.recycleStore.fullSearchData.data', 'selected')
        this.initResotoreStore(stores)
        this.initDataViewModeStore(stores)
    }

    @action
    public setCurrentSort(sort: ccTypes.searchTypes.ISortItem) {
        this.currentSort = sort;
    }

    @action
    public queryFullSearchData(page?: number) {
        this.selectAllStore.switchAllSelected(false);
        this.fullSearchData = undefined;
        let sort: ccTypes.searchTypes.ISortItem = this.currentSort;
        let req: recycleTypes.IRecyclePageRequest = {
            pageIndex: page || 1,
            pageSize: 20,
            keyword: this.keyword || '',
            sortFields: [
                {
                    field: sort.field,
                    isDesc: sort.desc==='desc' ? true : false
                }
            ]
        };

        return new Promise((resolve, reject) => {
            recycleApi.search(req).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        if (res.data.data.data && res.data.data.data.length > 0){
                            res.data.data.data.forEach((item) => {
                                item.contentId = item.contentId_;
                                item.type = item.type_;
                                item.name = item.name_;
                                item.site = item.site_;
                            });
                        }
                        this.fullSearchData = res.data.data;
                    }
                });
                resolve(undefined);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    @action
    public checkRestore(req?: any) {
        return new Promise((resolve, reject) => {
            recycleApi.checkCanRestore(req).then(res => {
                resolve(undefined);
            }, () => {
                reject();
            });
        });
    }

    @action
    public setKeyword(keyword: string){
        this.keyword = keyword;
    }
}