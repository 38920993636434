import * as React from 'react';
import IControlProps from 'mam-metadata-react/dist/types/IControlProps';
import { stores } from 'src/stores';
import { Avatar, Tag, Tooltip } from 'antd';
import { UserOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

function LeadingRole(props: IControlProps){
    const [needExpand, setNeedExpand] = React.useState(false)
    const [isExpand, setIsExpand] = React.useState(false)
    const tagListRef = React.useRef(null);
    const tvSeriesStore = stores.entityStore.tvSeriesStore;

    React.useEffect(()=>{
        if (tagListRef.current){
            if (tagListRef.current && $(tagListRef.current as any).height() as number > 200){
                setNeedExpand(true);
                setIsExpand(false);
            }
        }
    }, [tagListRef.current]);

    const showMore = React.useCallback((show: boolean)=>{
        setIsExpand(show);
    }, []);

    if (!tvSeriesStore.tvMovieRes || !tvSeriesStore.tvMovieRes.data 
        || tvSeriesStore.tvMovieRes.data.length === 0){
        return null;
    }
    const data = tvSeriesStore.tvMovieRes.data[0];
    if (!data.leadingRole || data.leadingRole.length === 0){
        return null;
    }
    return <div className="mfc-tag">
        <div className="tag-list" ref={tagListRef} 
                style={{height: needExpand && !isExpand ? '190px' : 'auto'}}>
            {
                data.leadingRole.map((role, index)=>{
                    return <div className="role-item" key={index}>
                        <div className="avatar">
                            <Avatar shape="square" size={60} icon={<UserOutlined />} src={process.env.DEV_SERVER + role.avatar_url}/>
                        </div>
                        <div className="name-tag">
                            {role.name}
                        </div>
                    </div>
                })
            }
        </div>
        {
            needExpand &&
            <div className="tags-bottom">
                {
                    !isExpand ?
                    <a className="show-more" onClick={showMore.bind(window, true)}>
                        {'显示更多主演'}<DownOutlined />
                    </a> : 
                    <a className="show-more" onClick={showMore.bind(window, false)}>
                        {'隐藏更多主演'}<UpOutlined /> 
                    </a>
                }
            </div>
        }
    </div>
}
export default LeadingRole