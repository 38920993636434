
import BaseStore from 'src/stores/base/baseStore';
import { observable, action } from 'mobx';
import {Stores} from "../index";

class AddEntityStore extends BaseStore<Stores> {
    @observable
    public show: boolean = false

    @action
    public setShow(show: boolean) {
        this.show = show
    }
}

export default AddEntityStore