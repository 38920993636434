import ExtendStore from "../base/extendStore";
import OptoolConfigurationStore from "./extends/optoolConfigurationStore";
import NavConfigStore from "./extends/navConfigStore";
import DetailPageConfigurationStore from "./extends/detailPageConfigurationStore";
import TaskCenterConfigStore from "./extends/taskCenterConfigStore";

export default class SysmanageExtendStore extends ExtendStore {
    public optoolFigurationStore: OptoolConfigurationStore;
    public navConfigStore:NavConfigStore;
    public detailPageConfigurationStore: DetailPageConfigurationStore;
    public taskCenterConfigStore: TaskCenterConfigStore;
    
    public initOptoolConfigurationStore() {
        this.optoolFigurationStore = new OptoolConfigurationStore(this.stores, this as any);
    }
    public initNavConfigStore(){
        this.navConfigStore = new NavConfigStore(this.stores, this as any);
    }
    public initDetailPageConfigurationStore(){
        this.detailPageConfigurationStore = new DetailPageConfigurationStore(this.stores, this as any);
    }
    public initTaskCenterConfigStore(){
        this.taskCenterConfigStore = new TaskCenterConfigStore(this.stores, this as any);
    }
}
