import { IRemoveAlbumEntitiesReq } from './../types/albumDetailTypes';
import { AxiosPromise } from "axios";
import { http, IResponse } from "mam-core-react/dist/modules/http";
import { IAlbumEntitieReq, ICollectFieldItem } from "src/types/albumDetailTypes";
import bizTypes from "src/types/bizTypes";
import iSearchTypes from "src/types/iSearchTypes";

export const getCurrentCollectFields = (contentId: string):AxiosPromise<IResponse<ICollectFieldItem[]>> => {
    return http.get<IResponse<ICollectFieldItem[]>>(`/scntm/v1/album/aggregation-condition/${contentId}`);
}

export const updateCurrentCollectFields = (contentId: string, fields: ICollectFieldItem[]):AxiosPromise<IResponse<any>> => {
    return http.patch<IResponse<any>>(`/scntm/v1/album/aggregation-condition/${contentId}`, fields);
}

export const queryAlbumEntities = (params: IAlbumEntitieReq, configOpts?: any):AxiosPromise<IResponse<iSearchTypes.IFullSearchDataRes>> => {
    return http.post<IResponse<iSearchTypes.IFullSearchDataRes>>('/smcss/v1/search/album', params, configOpts);
}

export const removeAlbumEntities = (params: IRemoveAlbumEntitiesReq):AxiosPromise<IResponse<any>> => {
    return http.post<IResponse<any>>('/scntm/v1/album/relation/remove', params);
}