import { BaseStore } from 'mam-core-react';
import { Stores } from 'src/stores';
import IMetadataOpeStore, { ICustomControl } from 'mam-metadata-react/dist/store/metadataOpeStore';
import UploadStore from './uploadStore';

export default class MetadataOpeStore extends BaseStore<Stores> implements IMetadataOpeStore {
    /** 自定义组件 */
    public customControls?: ICustomControl[]

    public uploadStore: UploadStore;

    public constructor(stores: Stores, uploadStore: UploadStore) {
        super(stores);
        this.uploadStore = uploadStore;
    }

    public setCustomControls(customControls?: ICustomControl[]){
        this.customControls = customControls;
    }

}
