import { stores } from 'src/stores';
import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import mmsTypes from '../types/mmsTypes';
import ccTypes from './../types/ccTypes';
import { IRequset } from './../components/biz/toolbar/btns/mmsTask';
import favoriteTypes from 'src/types/favoriteTypes';
import { IResponse } from "mam-core-react/dist/modules/http";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";

namespace mmsApi {
    export const uploadCommonImg = (formData: FormData, hideLoading?: boolean): AxiosPromise<IResponse<string>> => {
        return http.post(`/scntm/v1/common/upload-image`, formData, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                hideLoading: hideLoading !== undefined ? hideLoading : false
            },
        })
    }

    export const updateEntityKeyframe = (req: mmsTypes.IUpdateEntityKeyframeReq): AxiosPromise<IResponse<string>> => {
        return http.patch(`/scntm/v1/entity/keyframe`, req)
    }

    export const getPreviewInfo = (contentId: string): AxiosPromise<IResponse<mmsTypes.IPreviewInfo[]>> => {
        return http.get<IResponse<mmsTypes.IPreviewInfo[]>>(`/scntm/v1/entity/preview-info/${contentId}`)
    }

    export const recordDownloadLog = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/sflud/v1/download/log`, contentIds, {
            headers: { hideLoading: true }
        })
    }

    export namespace download {
        export const validDownload = (data: mmsTypes.downloadTypes.IValidOrGetFileDownload) => {
            return http.post<any>(`/entity/valid-download`, data)
        }
        export const getFileDownloadInfo = (contentIds: string[]): AxiosPromise<IResponse<mmsTypes.downloadTypes.IFileDownloadInfoRes>> => {
            let paramStr = '';
            contentIds.forEach((id) => {
                paramStr += 'contentIds=' + id + '&';
            })
            paramStr = paramStr.substring(0, paramStr.length - 1);

            if (window.location.pathname.indexOf('/cc/tenant') > -1 && stores.tenantStore.params.site) {
                return http.get<IResponse<mmsTypes.downloadTypes.IFileDownloadInfoRes>>('/scntm/v2/entity/download/fileinfo?' + paramStr, { headers: { "sobeycloud-op-site": stores.tenantStore.params.site } })
            } else {
                return http.get<IResponse<mmsTypes.downloadTypes.IFileDownloadInfoRes>>('/scntm/v2/entity/download/fileinfo?' + paramStr)
            }
        }
        export const download4Zip = (param: mmsTypes.downloadTypes.IDownload4ZipParam[]) => {
            return http.post<any>(`/sflud/v1/download/zip`, param, {
                responseType: 'blob',
                headers: {
                    noHandleResponse: true
                }
            })
        }
        export const logDownload = (param: mmsTypes.downloadTypes.ILogDownloadReq) => {
            return http.post<any>(`/sboic/v1/business-log`, param)
        }
        export const calcDownloadScore = (param: mmsTypes.downloadTypes.ICalcDownloadScoreReq) => {
            return http.post<IResponse<mmsTypes.downloadTypes.ICalcDownloadScoreResult>>(`/scpm/v1/point/calc`, param)
        }
        export const updateDownloadScore = (param: mmsTypes.downloadTypes.ICalcDownloadScoreResult) => {
            return http.post<IResponse<any>>(`/scpm/v1/point/update`, param)
        }
        export const getAllConfig = (): AxiosPromise<IResponse<mmsTypes.downloadTypes.IScoreConfigItem[]>> => {
            return http.get<IResponse<mmsTypes.downloadTypes.IScoreConfigItem[]>>('/scpm/v1/config/all')
        }
        export const getCurrentScore = (): AxiosPromise<IResponse<mmsTypes.downloadTypes.ICurrentScoreRes>> => {
            return http.get<IResponse<mmsTypes.downloadTypes.ICurrentScoreRes>>('/scpm/v1/point/current')
        }
    }
    export namespace favorite {
        export const getFavoriteTags = () => {
            return http.get<IResponse<favoriteTypes.IFavoirteTag[]>>(`/scntm/v1/favorite/tags`)
        }
        export const doFavorite = (data: mmsTypes.favoriteTypes.IDoFavorite) => {
            return http.post<any>('/scntm/v1/favorite', data)
        }
        export const cancelFavorite = (contentIds: string[]) => {
            let paramStr = '';
            contentIds.forEach((id) => {
                paramStr += 'contentIds=' + id + '&';
            })
            paramStr = paramStr.substring(0, paramStr.length - 1);
            return http.delete('/scntm/v1/favorite?' + paramStr);
        }
        export const updateFavoriteTags = (data: mmsTypes.favoriteTypes.IFavoriteTagsUpdateRequest) => {
            return http.post<IResponse<boolean>>(`/favorite/update/tags`, data)
        }
        // export const favoriteSaveAs = (data: mmsTypes.favoriteTypes.ISetFavorite) => {
        //     return http.post<any>(`/favorite/copy`, data)
        // }
    }
    export namespace collection {
        export const collect = (data: mmsTypes.collectionTypes.ICollectReq) => {
            return http.post<IResponse<mmsTypes.collectionTypes.ICollectRes[]>>(`/scntm/v1/favorite`, data)
        }
        export const getCollectionStatus = (contentIds: string[]): AxiosPromise<IResponse<mmsTypes.collectionTypes.ICollectStatus[]>> => {
            return http.post(`/scntm/v1/favorite/state`, contentIds)
        }
        export const removeCollect = (contentIds: string[]) => {
            let paramStr = '';
            contentIds.forEach((id) => {
                paramStr += 'contentIds=' + id + '&';
            })
            paramStr = paramStr.substring(0, paramStr.length - 1);
            return http.delete(`/scntm/v1/favorite?${paramStr}`)
        }
    }
    export const getHistoryKeywords = (param: string): AxiosPromise<IResponse<mmsTypes.downloadTypes.IHistoryKeywords[]>> => {
        return http.get<IResponse<mmsTypes.downloadTypes.IHistoryKeywords[]>>(`/search/tip-search?keyword=${param}`)
    }
    export const deleteHistoryKeywords = (param?: string): AxiosPromise<IResponse<boolean>> => {
        return http.delete(`/search/history${!param ? '' : '/' + param}`)
    }

    export const getHotKeywords = (top: number): AxiosPromise<IResponse<mmsTypes.hotKeywordsTypes.IHotKeywords[]>> => {
        return http.get<IResponse<mmsTypes.hotKeywordsTypes.IHotKeywords[]>>(`/search/hot/keyword?top=${top}`)
    }

    export namespace exportModal {
        export const getExternalsystem = (businessType?: string): AxiosPromise<IResponse<mmsTypes.exportTypes.IGetExternalsystem[]>> => {
            return http.get<IResponse<mmsTypes.exportTypes.IGetExternalsystem[]>>('/smegw/v1/export/external-system' + (businessType ? `?businessType=${businessType}`:''))
        }

        export const getExternalsystemByCode = (externalSystemIdentity?: string): AxiosPromise<IResponse<mmsTypes.exportTypes.IGetExternalsystem[]>> => {
            return http.get<IResponse<mmsTypes.exportTypes.IGetExternalsystem[]>>(`/smegw/v1/external-system?externalSystemIdentity=${externalSystemIdentity}`)
        }

        export const getTranscodeTemplate = (): AxiosPromise<IResponse<mmsTypes.exportTypes.IGetTranscodeTemplate[]>> => {
            return http.get<IResponse<mmsTypes.exportTypes.IGetTranscodeTemplate[]>>('/smegw/v1/export/transcode-template')
        }

        export const getEntityInfo = (param: mmsTypes.exportTypes.IGetInfoParams): AxiosPromise<IResponse<mmsTypes.exportTypes.IGetEntityInfoRes>> => {
            let paramStr = '';
            param.contentIds.forEach((id) => {
                paramStr += 'contentIds=' + id + '&';
            })
            paramStr = paramStr.substring(0, paramStr.length - 1);
            return http.get<IResponse<mmsTypes.exportTypes.IGetEntityInfoRes>>(`/scntm/v1/entity/export/fileinfo?${paramStr}`)
        }

        export const getLm = () => {
            return http.get('/programcode/get-column')
        }

        export const getProgramcode = (params: any) => {
            return http.post('/programcode/get', params)
        }

        export const getValidateInteractionFields = (url: string, params: any) => {
            return http.post(url, params)
        }

        export const exporttask = (params: mmsTypes.exportTypes.IExportTaskReq) => {
            return http.post('/smegw/v1/export/task', params)
        }
        //检查素材归档成功后所在条码是否在线
        export const onlineCheck = (params: mmsTypes.exportTypes.IOnlineCheckReq[]): AxiosPromise<IResponse<mmsTypes.exportTypes.IOnlineCheckRes[]>> => {
            return http.post<IResponse<mmsTypes.exportTypes.IOnlineCheckRes[]>>('/archivemanager/v1/api/external/medium/offline-check', params)
        }
        export const exportCheck = (request: mmsTypes.exportTypes.IExportTaskReq): AxiosPromise<IResponse<mmsTypes.exportTypes.IExportCheckRes>> => {
            return http.post<IResponse<mmsTypes.exportTypes.IExportCheckRes>>('/smegw/v1/export/check', request)
        }
    }

    export namespace deleteModal {
        export const executeDelete = (contentIds: string[]): AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>('/scntm/v1/recycle', contentIds)
        }
        //删除审核
        export const addDeleteTask = (params: mmsTypes.deleteTypes.IAddDeleteTaskType): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scmas/v1/deletematerialaudit/create-audit-task', params)
        }

        export const getProgress = (progressid: string): AxiosPromise<IResponse<mmsTypes.deleteModal.IProgressRes>> => {
            return http.get<IResponse<mmsTypes.deleteModal.IProgressRes>>(`/scntm/v1/recycle/progress/${progressid}`)
        }
    }

    export namespace CreatePlanningModal {
        export const createTopicTask = (data: any) => {
            return http.post('/api/financial-media/create-topic-task', data)
        }
    }

    export const recycleAdd = (params: mmsTypes.IRecycleAddReq[]) => {
        return http.post('/recycle/add', params)
    }

    export namespace OutputExcelModal {

        export const exportCsvForPart = (contentIds: string[]) => {
            let timestamp = new Date().getTime();
            let xmlResquest = new XMLHttpRequest();
            xmlResquest.open("POST", process.env.DEV_SERVER + "/search/exportCsvForPart", true);
            xmlResquest.setRequestHeader("Content-type", "application/json");
            xmlResquest.responseType = "blob";
            xmlResquest.onload = () => {
                let content = xmlResquest.response;
                let elink = document.createElement('a');
                elink.download = timestamp + ".xls";
                elink.style.display = 'none';
                let blob = new Blob([content]);
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            };
            xmlResquest.send(JSON.stringify(contentIds));
            // return http.post<IResponse<string>>('/search/exportCsvForPart', contentIds)
        }

        export const exportCsvForAll = (req: ccTypes.searchTypes.IFullSearchReq) => {
            let timestamp = new Date().getTime();
            let xmlResquest = new XMLHttpRequest();
            xmlResquest.open("POST", process.env.DEV_SERVER + "/search/exportCsvForAll", true);
            xmlResquest.setRequestHeader("Content-type", "application/json");
            xmlResquest.responseType = "blob";
            xmlResquest.onload = () => {
                let content = xmlResquest.response;
                let elink = document.createElement('a');
                elink.download = timestamp + ".xls";
                elink.style.display = 'none';
                let blob = new Blob([content]);
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            };
            xmlResquest.send(JSON.stringify(req));
            // return http.post<IResponse<string>>('/search/exportCsvForAll', req)
        }
    }

    export namespace moveFolder {
        export const move = (request: mmsTypes.moveFolder.IMoveReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/folder/resource/move', request)
        }

        export const copy = (request: mmsTypes.moveFolder.ICopyReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/entity/copy', request)
        }
    }

    export namespace UnlockModal {
        export const getLocks = (arr: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/get-locks', arr)
        }

        export const deleteLocks = (params: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/delete-locks', params)
        }
    }

    export namespace SmartProcessModal {
        //新的发起智能处理的接口
        export const sendToIntelligentAnalysis = (request: mmsTypes.smartProcessTypes.ISmartProcessIntelligentAnalysisReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/intelligent/flows', request)
        }
        //获取新的发起智能处理的配置项
        export const getSmartConfigBtns = (): AxiosPromise<IResponse<mmsTypes.smartProcessTypes.ISmartProcessIntelligentAnalysisBtnItem[]>> => {
            return http.get<IResponse<mmsTypes.smartProcessTypes.ISmartProcessIntelligentAnalysisBtnItem[]>>('/scntm/v1/intelligent/functions')
        }
    }

    export namespace TaskModal {
        export const getSpecifiedEntityData = (contentIds: any[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/get-specified-entity-data', contentIds)
        }

        export const getDescription = (request: IRequset): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/get-description', request)
        }

        export const getRelationEntityByIds = (hsIds: any[]): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/entity/get-relation-entity-by-ids?${hsIds.join('&')}`)
        }

        export const updateBaseEntityData = (item: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/entity/update-base-entity-data', item)
        }
    }

    export namespace ShareModal {
        export const getSites = (): AxiosPromise<IResponse<mmsTypes.shareTypes.ISites[]>> => {
            return http.get<IResponse<mmsTypes.shareTypes.ISites[]>>('/home/get-sites')
        }

        export const getFiles = (contentIds: string[]): AxiosPromise<IResponse<mmsTypes.shareTypes.IFiles[]>> => {
            return http.post<IResponse<mmsTypes.shareTypes.IFiles[]>>('/file/getFiles', contentIds)
        }

        export const getWorkTeam = (): AxiosPromise<IResponse<mmsTypes.shareTypes.IMyTeams[]>> => {
            return http.get<IResponse<mmsTypes.shareTypes.IMyTeams[]>>('/scntm/v1/share/usergroups')
        }

        export const shareWorkteam = (contentIds: string[], groupCodes: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/share/workteam', { contentIds, groupCodes })
        }

        export const getOrganizationAllSubs = (siteCode: string): AxiosPromise<IResponse<mmsTypes.shareTypes.IOrganizationAllSubs>> => {
            return http.get<IResponse<mmsTypes.shareTypes.IOrganizationAllSubs>>(`/organization/get-organization-all-subs?opsite=${siteCode}`)
        }

        export const getOrganizationsUsers = (req: mmsTypes.shareTypes.IQueryUserReq, siteCode?: string): AxiosPromise<IResponse<mmsTypes.shareTypes.IOrganizationsUsersRes>> => {
            return http.post<IResponse<mmsTypes.shareTypes.IOrganizationsUsersRes>>(`/organization/get-organizations-users${siteCode ? `?opsite=${siteCode}` : ''}`, req)
        }

        export const directToUsers = (data: mmsTypes.shareTypes.IDirectToUsersReq) => {
            return http.post('/share/direct-to-users', data)
        }

        export const shareLink = (data: mmsTypes.shareTypes.IShareLinkReq) => {
            if (window.location.pathname.indexOf('/cc/tenant') > -1 && stores.tenantStore.params.site) {
                return http.post('/scntm/v1/share/link', data, { headers: { "sobeycloud-op-site": stores.tenantStore.params.site } })
            } else {
                return http.post('/scntm/v1/share/link', data)
            }
        }

        export const reshareLink = (data: mmsTypes.shareTypes.IReshareLinkReq) => {
            if (window.location.pathname.indexOf('/cc/tenant') > -1 && stores.tenantStore.params.site) {
                return http.patch('/scntm/v1/share/link', data, { headers: { "sobeycloud-op-site": stores.tenantStore.params.site } })
            } else {
                return http.patch('/scntm/v1/share/link', data)
            }

        }

        export const shareUser = (data: mmsTypes.shareTypes.IShareUserReq) => {
            if (window.location.pathname.indexOf('/cc/tenant') > -1 && stores.tenantStore.params.site) {
                return http.post('/scntm/v1/share/user', data, { headers: { "sobeycloud-op-site": stores.tenantStore.params.site } })
            } else {
                return http.post('/scntm/v1/share/user', data)
            }
        }

        export const cancelShare = (links: string[]) => {
            let paramStr = '';
            links.forEach((link) => {
                paramStr += 'links=' + link + '&';
            })
            paramStr = paramStr.substring(0, paramStr.length - 1);
            return http.delete('/scntm/v1/share?' + paramStr)
        }
    }

    export namespace selectDirectoryModal {
        export const getChildren = (parentId: string, trees?: number[]): AxiosPromise<IResponse<mmsTypes.selectDirectoryModalTypes.ITreeItem[]>> => {
            return http.post<IResponse<mmsTypes.selectDirectoryModalTypes.ITreeItem[]>>('/folder/get-default-childrens', {
                'parentId': parentId,
                'trees': trees
            })
        }
        export const createDirectory = (params: mmsTypes.selectDirectoryModalTypes.ISelectDirectoryParams,
            item: mmsTypes.selectDirectoryModalTypes.ITreeItem): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/' + params.requestType + '-tree/create', item);
        }
    }
    //新专辑
    export namespace createAlbumModal {
        export const albumCreate = (data: mmsTypes.createAlbumModalTypes.IAlbumCreateReq): AxiosPromise<IResponse<mmsTypes.createAlbumModalTypes.IAlbumCreateRes>> => {
            return http.post<IResponse<mmsTypes.createAlbumModalTypes.IAlbumCreateRes>>('/album/create', data)
        }

        export const albumAddEntity = (data: mmsTypes.createAlbumModalTypes.IAlbumAddEntityReq): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/album/add-entity', data)
        }
        export const getAlbumFields = (): AxiosPromise<IResponse<mmsTypes.createAlbumModalTypes.IAlbumFieldsResItem[]>> => {
            return http.get<IResponse<mmsTypes.createAlbumModalTypes.IAlbumFieldsResItem[]>>('/album/condition-fields')
        }
    }

    export namespace TechanditModal {
        export const sendflow = (params: string, ids: string[]): AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>(`/flowstep/hiveflow/sendflow/${params}`, ids)
        }
    }

    export namespace clearInvalidShareModal {
        export const clearInvalidShare = (isSend: boolean): AxiosPromise<IResponse<boolean>> => {
            return http.get<IResponse<boolean>>(`/share/clear-invalid-share?sort=ctime&order=desc&linkType=6&isSend=${isSend}`)
        }
    }

    export namespace cancelShareModal {
        export const cancelShareWorkteam = (contentIds: string[], groupCodes: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/share/cancel-share-workteam', { contentIds, groupCodes })
        }

        export const cancelShareToUser = (data: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/share/cancel-share-to-user', data)
        }
    }

    export namespace copyModal {
        export const checkName = (type: string, data: any) => {
            return http.post(`/${type}-tree/check-name`, data)
        }

        export const treeCopy = (requestUrl: string, request: mmsTypes.copyTypes.IRequest): AxiosPromise<IResponse<{ [key: string]: string }>> => {
            return http.post<IResponse<{ [key: string]: string }>>(requestUrl, request)
        }

        export const createById = (request: mmsTypes.copyTypes.ICreateByIdTypes): AxiosPromise<IResponse<mmsTypes.copyTypes.ICreateByIdRes>> => {
            return http.post<IResponse<mmsTypes.copyTypes.ICreateByIdRes>>('/folder/create-by-id', request)
        }

        export const getFilesByPath = (contentId: string): AxiosPromise<IResponse<mmsTypes.copyTypes.IGetFilesByPathRes[]>> => {
            return http.get<IResponse<mmsTypes.copyTypes.IGetFilesByPathRes[]>>(`/file/get-files-by-path?parentId=${contentId}`)
        }
    }

    export namespace CopyrightModal {
        export const getFields = (contentId: string): AxiosPromise<IResponse<IFormItem[]>> => {
            return http.get<IResponse<IFormItem[]>>('/copyright/getCopyrightFields?contentId=' + contentId)
        }
    }

    export namespace restoreModal {
        export const checkRestore = (models: mmsTypes.restoreTypes.IRestoreReq[]): AxiosPromise<IResponse<mmsTypes.restoreTypes.IRestoreRes>> => {
            return http.post<IResponse<mmsTypes.restoreTypes.IRestoreRes>>('/recycle/check-restore', models)
        }

        export const restore = (contentIds: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/scntm/v1/recycle/restore', contentIds)
        }
    }

    export namespace deleteThoroughlyModal {
        export const recycleDelete = (items: string[]): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/recycle/delete', items)
        }

        export const syncUserQuota = (): AxiosPromise<IResponse<boolean>> => {
            return http.get<IResponse<boolean>>('/user/sync-user-quota')
        }
    }

    export namespace clearModal {
        export const clear = (): AxiosPromise<IResponse<any>> => {
            return http.delete('/scntm/v1/recycle')
        }
    }

    export namespace createFolderModal {
        export const getPermissiontemplate = (): AxiosPromise<IResponse<mmsTypes.createFolderTypes.IPermissiontemplate[]>> => {
            return http.get<IResponse<mmsTypes.createFolderTypes.IPermissiontemplate[]>>('/business/external-system/get-permissiontemplate')
        }

        export const savePublicFolder = (request: mmsTypes.createFolderTypes.ISavePublicFolderReq): AxiosPromise<IResponse<mmsTypes.createFolderTypes.ISavePublicFolderRes>> => {
            return http.post<IResponse<mmsTypes.createFolderTypes.ISavePublicFolderRes>>('/folder/save-public-folder', request)
        }
    }
    //发起归档
    export namespace applyArchiveModal {
        export const getArchiveGroup = (request: any): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>('/archivemanager/v1/api/newpolicy/list-policy', request)
        }
        export const applyArchivePost = (request: mmsTypes.applyArchiveTypes.IApplyArchivePostReq): AxiosPromise<IResponse<boolean>> => {
            return http.post<IResponse<boolean>>('/archivemanager/v1/api/flow/manual-archive', request)
        }
        //检查素材是否归档的接口：返回全部的素材，依据选中的归档组以及返回具体素材状态进行判断是否未发起过归档、是否归档中、是否已归档
        export const newCheckEntityArchived = (request: mmsTypes.applyArchiveTypes.ICheckEntityArchivedReq[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>('/archivemanager/v1/api/entity/archive-info', request)
        }
    }
    //发起回迁
    export namespace applyRestoreModal {
        export const applyRestore = (contentIds: string[]): AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>('/archivemanager/v1/api/flow/manual-restore', contentIds)
        }
    }
    //获取归档组名称
    export namespace getOriginGroupNameModal {
        export const getOriginGroupName = (): AxiosPromise<IResponse<string[]>> => {
            return http.get<IResponse<string[]>>('/api/entity/origingroupname')
        }
    }

    // 挑选篮批量编辑
    export namespace batchEditModal {
        export const edit = (data: ccTypes.IBasketEditItem[]) => {
            return http.post('/entity/batch/edit', data)
        }
    }

    //导出Excel字段配置
    export namespace exportExcel {
        //获取字段配置信息
        export const getExportFields = () => {
            return http.get('/search/get-export-fields-user');
        }
        //导出字段配置选择成功
        export const saveExportFields = (exportFileds: ccTypes.IExportFileds[]) => {
            return http.post('/search/save-export-fields-user', exportFileds);
        }
    }

    //语音转写模块
    export namespace audioTranscription {
        export const startSpeech2Text = (contentId: string): AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>(`/scntm/v1/intelligent/speech-text/${contentId}`);
        }

        export const queryProgress = (id: string): AxiosPromise<IResponse<mmsTypes.audioTranscription.IQueryProgressRes>> => {
            return http.get<IResponse<mmsTypes.audioTranscription.IQueryProgressRes>>(`/scntm/v1/flow/progress/${id}`, {
                headers: {
                    hideLoading: true
                }
            });
        }

        export const stopProgress = (id: string): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>(`/scntm/v1/flow/stop/${id}`);
        }
    }

    //图片包模块: 加入组图、拆组
    export namespace picturePackage {
        export const joinGroup = (contentIds: string[], picturePackageId: string): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/entity/picture/package/join`, { contentIds, picturePackageId });
        }
        export const breakGroup = (picturePackageId: string): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/entity/picture/package/split`, { picturePackageId });
        }
    }


    export namespace Tenant {
        export const getSites = (): AxiosPromise<IResponse<ccTypes.sideContentTypes.ITenantTreeItem[]>> => {
            return http.get<IResponse<ccTypes.sideContentTypes.ITenantTreeItem[]>>('/scntm/v1/site/share')
        }
        //租户共享
        export const tenantShare = (shareSiteType: string, contentIds: string[], linkSites: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/site/share-entity`, { shareSiteType, contentIds, linkSites });
        }
        //移动
        export const moveToFolder = (contentId: string, folderId: string): AxiosPromise<IResponse<any>> => {
            if (window.location.pathname.indexOf('/cc/tenant') > -1 && stores.tenantStore.params.site) {
                return http.post('/scntm/v1/site/move-entity', { contentId, folderId }, { headers: { "sobeycloud-op-site": stores.tenantStore.params.site } })
            } else {
                return http.post<IResponse<any>>(`/scntm/v1/site/move-entity`, { contentId, folderId });
            }
        }
        //推送到华栖云: 接口待定
        export const pushToHuaxi = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scntm/v1/`, { contentIds });
        }
    }

    //编目相关
    export namespace catalog {
        //发起编目: 编目微服务
        export const catalogCreate = (contentIds: string[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/sctlg/v1/task/create`, contentIds)
        }
    }

    export namespace sr {
        export const getTranscodetemplates = (): AxiosPromise<IResponse<mmsTypes.sr.ITranscodetemplateItem[]>> => {
            return http.get<IResponse<mmsTypes.sr.ITranscodetemplateItem[]>>(`/smegw/v1/supertranscode/super-transcodetemplate`)
        }

        export const supertranscode = (param: mmsTypes.sr.ISupertranscodeReq): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/smegw/v1/supertranscode/super-transcode-filegroup`, param)
        }
    }

    export namespace batchInitiatingSmartProcess4All {
        //之前发起原子能力的接口：检索过滤的素材
        export const sendBatchFlow = (req: mmsTypes.batchInitiatingSmartProcess4All.ISendBatchFlowReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/intelligentProcess/send-batch-flow`, req)
        }
        //之前发起原子能力的接口：已选的素材
        export const sendBatchFlowCustom = (req: mmsTypes.batchInitiatingSmartProcess4All.ISendBatchFlowCustomReq[]): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/intelligentProcess/send-batch-flow/custom`, req)
        }
        //智能分析新接口：检索过滤的素材
        export const sendSmartFlowForSearch = (req: mmsTypes.batchInitiatingSmartProcess4All.ISendSmartFlowForSearchReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/intelligentProcess/v2/send-batch-flow`, req)
        }
        //智能分析新接口：已选的素材
        export const sendSmartFlowForEntity = (req: mmsTypes.batchInitiatingSmartProcess4All.ISendSmartFlowForEntityReq): AxiosPromise<IResponse<any[]>> => {
            return http.post<IResponse<any[]>>(`/swfbb/v1/intelligentprocess/send-smart-flow`, req)
        }
        //智能分析获取配置项接口
        export const getSmartFlowConfig = (): AxiosPromise<IResponse<mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem[]>> => {
            return http.get<IResponse<mmsTypes.batchInitiatingSmartProcess4All.ISmartFlowConfigItem[]>>(`/swfbb/v1/manage/intelligent/smart-flow-config`)
        }
    }
}
export default mmsApi;
