import React from 'react';
import { observer } from 'mobx-react';
import { Modal, message, Table } from 'antd';
import _ from 'lodash'
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';

export interface IAddFieldModalRef{
    open: (items: manageTypes.esSearchConfig.advancedSearchConfig.IAdvancedSearchField[], callback: (selectedItems: manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[])=>void)=>Promise<any>;
}

const AddFieldModal = observer(React.forwardRef<IAddFieldModalRef, any>((props, ref) => {
    const [visible, setVisible] = React.useState(false);
    const [allItems, setAllItems] = React.useState<manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[]>([])
    const [selectedItems, setSelectedItems] = React.useState<manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[]>([])
    const filterItems = React.useRef<manageTypes.esSearchConfig.advancedSearchConfig.IAdvancedSearchField[]>([])
    const callbackRef = React.useRef<(selectedItems: manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[])=>void>()
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(()=>{
        if (visible){
            setIsLoading(true);
            manageApi.esSearchConfig.advancedSearchConfig.getAllFields().then((res)=>{
                if (res.data.data){
                    setAllItems(res.data.data.filter(item=>{
                        return _.findIndex(filterItems.current, {indexCode: item.indexCode}) === -1;
                    }));
                }
            }).catch(res=>{
                if (res.data && res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                else {
                    console.error(res);
                }
            }).finally(()=>{
                setIsLoading(false);
            })
        }
    }, [visible]);

    const handleCancel = React.useCallback(()=>{
        setVisible(false);
    }, []);

    const handleOk = React.useCallback(()=>{
        if (callbackRef.current){
            callbackRef.current(selectedItems)
            setVisible(false);
        }
    }, [selectedItems]);

    React.useImperativeHandle(ref,()=>({
        open:(items: manageTypes.esSearchConfig.advancedSearchConfig.IAdvancedSearchField[], callback: (selectedItems: manageTypes.esSearchConfig.advancedSearchConfig.IFieldItem[])=>void)=>{
            return new Promise((resolve,reject)=>{
                callbackRef.current = callback;
                filterItems.current = items;
                setVisible(true);
                resolve(undefined)
            })
        }
    }));

    const columns = [
        {
          title: '字段名'.l('searchConfig.indexCode'),
          dataIndex: 'indexCode',
          key: 'name'
        },
        {
          title: '字段显示名'.l('searchConfig.fieldName'),
          dataIndex: 'fieldName',
          key: 'fieldName',
        }
    ]

    const rowSelection = {
        selectedRowKeys: selectedItems.map(item=>item.indexCode),
        onChange: (selectedRowKeys: string[]) => {
            setSelectedItems(allItems.filter((item)=>{
                return _.find(selectedRowKeys, (key)=>{
                    return key === item.indexCode as any;
                })
            }))
        }
    };
    return <Modal
        title={'新增字段'.l('searchConfig.addFields')}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        centered
        width={600}
        destroyOnClose
        className={'facet-config-add-fields-modal'}
    >
        <Table rowSelection={rowSelection} columns={columns} dataSource={allItems} 
            pagination={false} loading={isLoading} rowKey={'indexCode'}/>
    </Modal>
}))
export default AddFieldModal