import ExtendStore from "../../base/extendStore";
import EntityStore from '../entityStore';
import {action, observable, runInAction} from "mobx";
import entityTypes from "../../../types/entityTypes";
import entityApi from "../../../apis/entityApi";
import * as React from "react";
import { Stores } from "../../index";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";

//图片包
export default class PicpackageStore extends ExtendStore{
    public parentStore: EntityStore;
    @observable
    public singlePicInfo: React.RefObject<any>; 
    @observable
    public seletedPicPathItem: entityTypes.IPreviewFileItem;  //图片包选中的某一个图片Path对象
    @observable
    public seletedPicEntityData: IFormItem[] = [];//图片包的单图属性元数据
    @observable
    public picPackageAllPicEntityData: any;  //图片包的所有图的元数据 {"picContentId1": [],"picContentId2": []}
    @observable
    public singlePicMetaEditable: boolean = false;//图片包的单图属性是否为编辑状态
    @observable
    public allExifData: any[];//图片包的所有图的exif数据
    @observable
    public wapIsShowSinglePicMeta: boolean = true;//手机端中图片包详情元数据部分显示单图的元数据，切换后则显示图片包元数据

    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores);
        this.parentStore = parentStore;
        this.singlePicInfo = React.createRef();
    }

    /** 获取所有单个图的元数据 */
    @action
    public getPicPackageAllPicMetadata(contentId: string): Promise<IFormItem[]> {
        return new Promise((resolve, reject) => {
            entityApi.getPicPackageAllPicMetadata(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data){
                        this.picPackageAllPicEntityData = res.data.data;
                        if(this.seletedPicPathItem && res.data.data[this.seletedPicPathItem.sourceFileGuid || this.seletedPicPathItem.fileGuid]){
                            this.seletedPicEntityData = this.picPackageAllPicEntityData[this.seletedPicPathItem.sourceFileGuid || this.seletedPicPathItem.fileGuid]
                        }
                        resolve(res.data.data)
                    }else {
                        reject();
                    }
                });
            });
        });
    }
    /** 设置当前选中的图片id并切换单图的元数据 */
    @action
    public setSeletedPicPathItem(item:entityTypes.IPreviewFileItem) {
        this.seletedPicPathItem = item   
        this.setSeletedPicEntityData()
    }
    @action
    public setSeletedPicEntityData(items?:IFormItem[]) {
        if(items){
            this.seletedPicEntityData = items
        }else{
            if(this.seletedPicPathItem && this.picPackageAllPicEntityData){
                this.seletedPicEntityData = this.picPackageAllPicEntityData[this.seletedPicPathItem.sourceFileGuid || this.seletedPicPathItem.fileGuid] 
            }
        }
    }
    /** 单图的元数据编辑状态 */
    @action
    public setSinglePicMetaEditable(editable: boolean) {
        this.singlePicMetaEditable = editable;
    }

    @action
    public setAllExifData(data: any[]) {
        this.allExifData = data;
    }

    @action
    public setWapIsShowSinglePicMeta(wapIsShowSinglePicMeta: boolean) {
        this.wapIsShowSinglePicMeta = wapIsShowSinglePicMeta;
    }
    
}
