import ExtendStore from "../../../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import { Stores } from 'src/stores'
import _ from 'lodash'
import { message } from 'antd'
import SelectAllStore from "../../../extends/selectAllStore";
import { IEntityType } from "src/types/configTypes";
import datasetDetailsApi from "src/apis/datasetDetailsApi"
import contentMgTypes from "src/types/contentMgTypes"
import contentMgApi from "src/apis/contentMgApi"
import ContentMgStore from "../../contentMgStore"
import mmsApi from "src/apis/mmsApi"

export default class MyCollectionStore extends ExtendStore {
    @observable
    public order: string = 'desc'//时间升序降序
    @observable
    public myCollectionListResponse: contentMgTypes.myCollection.IGetMyCollectionListResponse | undefined
    public selectAllStore: SelectAllStore
    @observable
    public filterEntityTypes: IEntityType[] = []//分类
    private contentMgStore: ContentMgStore;

    public constructor(stores: Stores, contentMgStore: ContentMgStore) {
        super(stores)
        this.contentMgStore = contentMgStore;
        this.initDownloadStore(stores)
        this.initFavoriteStore(stores)
        this.initOutstoreStore(stores);
        this.initDataViewModeStore(stores);
        this.initSelectAllStore(stores, 'stores.contentMgStore.myCollectionStore.myCollectionListResponse.data', 'selected');

    }
    @action
    public init = () => {
        // //设置分类和选中分类
        this.filterEntityTypes = this.stores.configStore.config.entityTypes.filter((item: any) => {
            return item.code !== 'rundown' && item.code !== 'sequence'
                && item.code !== 'scene' && item.code !== 'shot' && item.code !== 'biz_sobey_newsscript'
        })
    }
    @action
    public getMyCollectionList(page: number) {
        let params: contentMgTypes.myCollection.IMyCollectionParams = {
            keyword: "",
            isDesc: this.order === 'desc',
            pageIndex: page,
            pageSize: 20
        }
        this.myCollectionListResponse = undefined
        return new Promise((resolve, reject) => {
            contentMgApi.myCollection.getMyCollectionList(params).then(res => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.myCollectionListResponse = res.data.data
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    //取消收藏
    @action
    public cancelCollection = (contentIds: string[])=> {
        return new Promise((resolve, reject) => {
            contentMgApi.myCollection.cancelCollection(contentIds).then(res=>{
                message.success('取消收藏成功！'.l('myCollection.cancelCollectionSuccess'))
                setTimeout(()=>{
                    this.getMyCollectionList(1);
                }, 1000)
                resolve(undefined)
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject()
            })
        })
    }
    @action
    public changeOrder(type: string) {
        this.order = type
        this.getMyCollectionList(1)
    }
    public getIsSelectItem = () => {
        if (!this.myCollectionListResponse) {
            return []
        } else {
            if (this.myCollectionListResponse.data) {
                return this.myCollectionListResponse.data.filter((item) => {
                    return item.selected
                })
            }
            return []
        }
    }
    //下载
    public download = (item: contentMgTypes.myCollection.IGetMyCollectionList) => {
        if (item.restype !== 'sequence' && item.restype !== 'scene' && item.restype !== 'shot') {
            this.downloadStore.setAllEntityItems([{
                contentId: item.rescontentid
            }])
            this.downloadStore.initAllItems();
        }
    }
}