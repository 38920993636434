import ExtendStore from "../base/extendStore";
import { Stores } from '..';
import { observable, computed, action, runInAction } from 'mobx';
import shareTypes from 'src/types/shareTypes';
import ccTypes from 'src/types/ccTypes';
import shareApi from 'src/apis/shareApi';
import ShareStore from './shareStore';
import { l } from 'mam-core-react';

export default class PersonShareStore extends ExtendStore{
    private parentStore: ShareStore;
    @observable
    public params: shareTypes.IParams;
    /** 排序下拉框 */
    @observable
    public sorts: ccTypes.searchTypes.ISortItem[];
    /** 已选排序方式 */
    @observable
    public selectedSort: ccTypes.searchTypes.ISortItem;
    @observable
    public shareList?: shareTypes.IShareList;
    @computed
    public get selectedLinkShareItems(){
        if (!this.shareList) {
            return []
        } else {
            return this.shareList.data.filter(item => item.selected)
        }
    }
    @observable
    public isShowSource:boolean = false

    constructor(stores: Stores, parentStore: ShareStore){
        super(stores);
        this.parentStore = parentStore;

        this.initDataViewModeStore(stores);
        this.initSelectAllStore(stores, 'stores.shareStore.personShareStore.shareList.data', 'selected');
        this.initCopyStore(stores);
        this.dataViewModeStore.setDataViewMode(ccTypes.DataViewModes.LIST);
    }

    @action
    public emptyShareList(){
        this.shareList = undefined;
    }

    @action
    public initSorts(){
        this.sorts = [];
        this.sorts.push({ field: 'create_date', desc: 'desc',text: l('share.createTimeDesc', '创建时间降序') });
        this.sorts.push({ field: 'create_date', desc: 'asc',text: l('share.createTimeAsc', '创建时间升序') });
        this.sorts.push({ field: 'expire_date', desc: 'desc',text: l('share.expiredTimeDesc', '失效时间降序') });
        this.sorts.push({ field: 'expire_date', desc: 'asc',text: l('share.expiredTimeAsc', '失效时间升序') });
        this.sorts.push({ field: 'name', desc: 'desc',text: l('share.nameDesc', '名称降序') });
        this.sorts.push({ field: 'name', desc: 'asc',text: l('share.nameAsc', '名称升序') });
        this.selectedSort = this.sorts[0];
    }

    @action
    public getLinkShare(page: number){
        this.selectAllStore.switchAllSelected(false);
        let req: shareTypes.IShareListReq = {
            pageIndex: page || 1,
            pageSize: 24,
            'keyword': this.parentStore.keyword || '',
            sortFields: [{
                field: this.selectedSort.field,
                isDesc: this.selectedSort.desc === 'desc'
            }],
            shareType: this.params.shareType ? this.params.shareType : 'receive'
        }
        shareApi.getLinkShare(req).then((res)=>{
            runInAction(()=>{
                this.shareList = res.data.data;
            });
        })
    }

    @action
    public setParams(params: shareTypes.IParams){
        this.params = params;
    }

    @action
    public setSelectedSort(sort: ccTypes.searchTypes.ISortItem){
        this.selectedSort = sort;
    }
}