import BaseStore from "../base/baseStore";
import {action, observable, runInAction} from "mobx";
import {Stores} from "../index";
import uploadTypes from "../../types/uploadTypes";
import uploadApi from "../../apis/uploadApi";
import _ from 'lodash';
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import { message } from "antd";

export interface IUploadPanelStore{
    uploadPanelStore: UploadPanelStore;

    initUploadPanelStore : (stores: Stores) => void;
}

export default class UploadPanelStore extends BaseStore<Stores> {
    @observable
    public showTaskPanel: boolean = false;
    /** 正在上传任务列表，mah的上传功能也是使用的这个变量 */
    @observable
    public tasks: uploadTypes.ITask[];

    @observable
    public uploadedRefreshSpan:number = 3000

    @action
    public setShowTaskPanel(show: boolean){
        this.showTaskPanel = show;
    }

    @action
    public getUnFinishedTask(param: uploadTypes.IUnfinishedTaskReq){
        return new Promise((resolve, reject)=>{
            uploadApi.getUnfinishedTask(param).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data)
                    {
                        // 目前只支持普通上传的断点续传
                        let list = res.data.data.filter((task)=>{
                            return task.taskType === uploadTypes.TaskTypes.GENERAL;
                        })
                        list.forEach((task) => {
                            task.status = 'error';
                            task.inited = true;
                            task.sizeTotal = task.chunkFinished = task.chunkTotal = 0;
                            if (task.files)
                            {
                                task.files.forEach((file) => {
                                    file.fileSizeString = commonUtil.formatSize(file.fileSize || 0);
                                    file.progress = this.calcFileProgress(file);
                                    file.status = file.progress === 100 ? 'success' : 'error';
                                    if (!task.sizeTotal)
                                    {
                                        task.sizeTotal = 0;
                                    }
                                    if (file.fileSize)
                                    {
                                        task.sizeTotal += file.fileSize;
                                    }
                                    if (!task.chunkTotal)
                                    {
                                        task.chunkTotal = 0;
                                    }
                                    if (file.chunkTotal)
                                    {
                                        task.chunkTotal += file.chunkTotal;
                                    }
                                });
                            }
                            task.progress = this.calcTaskProgress(task);
                            task.sizeTotalString = commonUtil.formatSize(task.sizeTotal);
                        });
                        this.tasks = list;
                        resolve(undefined);
                    }
                    else {
                        reject();
                    }
                });
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject();
            });
        })
    }

    @action
    public addTask(task: uploadTypes.ITask){
        if (!this.tasks)
        {
            this.tasks = [];
        }
        this.tasks.push(task);
    }

    @action
    public updateTaskProgress(task: uploadTypes.ITask){
        let targetTask = _.find(this.tasks, {taskId: task.taskId});
        if (targetTask && task.files && targetTask.files)
        {
            if (task.progress){
                targetTask.progress = parseFloat(task.progress as any);
            }
            targetTask.status = task.status;
            targetTask.speed = task.speed;

            task.files.forEach((file, index) => {
                if (targetTask && targetTask.files)
                {
                    targetTask.files[index].progress = parseFloat(file.progress as any);
                    targetTask.files[index].status = file.status;
                }
            });
        }
        //上传任务全部成功后，自动收起上传任务进度弹窗
        if((this.tasks && this.tasks.length > 0 && _.filter(this.tasks, (o)=>o.progress!== 100).length === 0 ) && this.showTaskPanel){
            setTimeout(() => {
                this.setShowTaskPanel(false);
            }, 2000)
        }
    }

    @action
    public updateTaskSurplusTime(task: uploadTypes.ITask){
        let targetTask = _.find(this.tasks, {taskId: task.taskId});
        if (targetTask && task.files && targetTask.files)
        {
            if (task.surplusTime){
                targetTask.surplusTime = task.surplusTime;
            }

            task.files.forEach((file, index) => {
                if (targetTask && targetTask.files)
                {
                    targetTask.files[index].surplusTime = file.surplusTime;
                }
            });
        }
    }

    @action
    public editTaskStatus(task: uploadTypes.ITask, status: string){
        task.status = status;
    }

    @action
    public removeTask(task: uploadTypes.ITask){
        let idx = _.findIndex(this.tasks, {taskId: task.taskId});
        this.tasks.splice(idx, 1);
    }

    @action
    public emptySuccessTask(){
        this.tasks.filter(task=>task.status === 'success').forEach(task=>{
            this.removeTask(task)
        })
    }

    private calcProgress = (current: number, total: number): number => {
        if (current !== 0 && current === total)
        {
            return 100;
        }
        let result: number = current && total ? ((current / total) * 100) : 0;
        if (result.toString().indexOf('.') === -1)
        {
            return result;
        }
        return parseFloat(result.toFixed(2));
    }

    private calcFileProgress(file: uploadTypes.IFile) {
        return this.calcProgress(file.chunkIndex || 0, file.chunkTotal || 0);
    }

    private calcTaskProgress(task: uploadTypes.ITask) {
        let count: number = 0;
        for (let i = 0; i < (task.files || []).length; i++)
        {
            if (task.files)
            {
                count += task.files[i].chunkIndex || 0;
            }
        }
        task.chunkFinished = count;
        return this.calcProgress(task.chunkFinished, task.chunkTotal || 0);
    }
}
