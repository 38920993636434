import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import batchEditTypes from 'src/types/batchEditTypes'
import batchEditApi from 'src/apis/batchEditApi'
import configtApi from 'src/apis/configApi'
import { message } from 'antd'
import _ from 'lodash'
import { Stores } from 'src/stores'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import { commonUtil } from 'mam-common-utils'
export default class BatchEditStore extends ExtendStore {
    @observable
    public batchEntityTypes: batchEditTypes.IEntityTypes[] = [];
    @observable
    public batchData: batchEditTypes.IEntityBatchTypes[] = [];
    @observable
    public batchCurrentType: string;
    @observable
    public metadatas: IFormItem[] = [];
    @observable
    public isMetaEdit: boolean
    @observable
    public batchEditSelectedRowKeys: number[] = []
    @observable
    public allChecked: boolean //控制全选
    @observable
    public showModal: boolean = false//控制弹窗显示

    public constructor(stores: Stores) {
        super(stores);
    }

    @action
    public setAllChecked(value: boolean) {
        this.allChecked = value;
    }

    @action
    public openModal() {
        this.showModal = true;
        this.getEntityTypes().then(res => {
            if (this.batchEntityTypes.length > 0) {
                this.getBasicDatas()
                this.getBatchData()
            } else {
                runInAction(()=>{
                    this.batchData = []
                })
            }
        })
    }

    @action
    public setShowModal(show: boolean) {
        this.showModal = show;
    }

    @action
    public delBatchItem(ids: number[]) {
        return new Promise((resolve, reject) => {
            batchEditApi.deleteEntityBatch(ids).then((res) => {
                runInAction(() => {
                    this.getEntityTypes().then(res => {
                        if (this.batchEntityTypes.length > 0) {
                            this.getBatchData()
                        } else {
                            runInAction(()=>{
                                this.batchData = []
                            })
                        }
                    })
                    resolve(true)
                })
            }).catch(res => {
                reject(false)
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }

    //获取顶部类型数据
    @action
    public getEntityTypes() {
        return new Promise((resolve, reject) => {
            batchEditApi.getCurrentTypes().then((res) => {
                runInAction(() => {
                    this.batchEntityTypes = res.data.data;
                    if (res.data.data && res.data.data.length > 0) {
                        this.setBatchCurrentType(res.data.data[0].code)
                    }
                    this.allChecked = false
                    resolve(true)
                })
            }).catch(res => {
                reject(false)
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        })
    }

    //获取左侧列表数据
    @action
    public getBatchData() {
        const params = {
            entityType: this.batchCurrentType,
            pageIndex: 1,
            pageSize: 1000
        }
        batchEditApi.getEntityBatch(params).then((res) => {
            runInAction(() => {
                this.batchData = res.data.data.data
            })
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

    //设置当前类型
    @action
    public setBatchCurrentType(currentType: string) {
        this.batchCurrentType = currentType
    }

    // 获取元数据
    @action
    public getBasicDatas() {
        this.metadatas = [];
        if (this.batchCurrentType !== undefined) {
            batchEditApi.getEntityData(this.batchCurrentType).then((res) => {
                runInAction(() => {
                    if (res.data.data && _.find(res.data.data, { groupCode: 'default_group' })) {
                        let metadatas = _.find(res.data.data, { groupCode: 'default_group' }).fields
                        _.remove(metadatas, (o: any) => o.fieldName === 'name_')
                        this.metadatas = metadatas
                    }
                })
            }).catch(res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
            })
        }
    }

    //实现选择
    @action
    public setBatchEditSelectedRowKeys(value: number[]) {
        this.batchEditSelectedRowKeys = value;
    }

    //获取单个
    @action
    public getEntityBasicData(contentId: string) {
        batchEditApi.getEntityBaseData(contentId).then((res) => {
            runInAction(() => {
                if (res.data.data && _.find(res.data.data, { groupCode: 'default_group' })) {
                    let metadatas = _.find(res.data.data, { groupCode: 'default_group' }).fields
                    _.remove(metadatas, (o: any) => o.fieldName === 'name_')
                    this.metadatas = metadatas
                }
            })
        }).catch(res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title)
            }
        })
    }

}