import MetadataMgStore from './metadataMgStore';
import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import manageApi from 'src/apis/manageApi';
import manageTypes from 'src/types/manageTypes';
import { message } from 'antd';
import { reqCatch } from 'mam-core-react';

export default class EntityTypeStore extends ExtendStore {
    public parentStore: MetadataMgStore;
    @observable
    public entityTypeList: manageTypes.instanceSetTypes.IInstanceItem[] = []
    @observable
    public currentEntityCode: string = ''
    @observable
    public programFormCode?: string
    @observable
    public isLoadingFullEntityData: boolean = true
    public constructor(stores: Stores, parentStore: MetadataMgStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setCurrentEntityCode = (currentEntityCode: string)=> {
        this.currentEntityCode = currentEntityCode
        this.programFormCode = undefined;
        localStorage.setItem('metadataMg_currentEntityCode', currentEntityCode)
    }
    @action
    public setProgramFormCode = (code?: string)=> {
        this.programFormCode = code;
    }
    @action
    public getEntityTypeList = ()=> {
        return new Promise((resolve, reject)=> {
            this.isLoadingFullEntityData = true
            manageApi.metadata.getEntityTypeList().then((res)=>{
                runInAction(()=> {
                    if (res.data.success && res.data.data){
                        this.entityTypeList = res.data.data
                        if (this.entityTypeList && this.entityTypeList.length > 0){
                            let cacheCurrentEntityCode = localStorage.getItem('metadataMg_currentEntityCode')
                            this.currentEntityCode = cacheCurrentEntityCode || this.entityTypeList[0].code
                        }
                        this.isLoadingFullEntityData = false
                    }
                })
            }).catch((res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            }).finally(()=>{
                resolve(undefined)
            })
        })
       
    }

    @action
    public setProgramForm = (item: manageTypes.instanceSetTypes.IInstanceItem, programForm: manageTypes.metadata.IProgramFormItem)=>{
        runInAction(()=>{
            item.programForm = programForm;
        })
    }
}