import React from 'react';
import ExtendStore from 'src/stores/base/extendStore';
import EntityStore from '../entityStore';
import { observable, computed, action, runInAction } from 'mobx';
import { Stores } from 'src/stores';
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import entityApi from "src/apis/entityApi";
import entityTypes from "src/types/entityTypes";
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { message } from 'antd';
import _ from 'lodash';
import { reqCatch } from 'mam-core-react';

export default class PaperarticleStore extends ExtendStore {
    public parentStore: EntityStore;
    //当前选中的文章
    @observable
    public currentPaperarticle: iSearchTypes.IFullSearchData | undefined;
    //文章列表
    @observable
    public paperarticleList: iSearchTypes.IFullSearchData[] | undefined
    @observable
    public isReq: boolean = false;
    @observable
    public paperarticleEntity: entityTypes.IEntity;
    //文章列表下的所有图片
    @observable
    public pictureList: entityTypes.IFileGroupFileItem[] = []
    
    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setCurrentPaperarticle(currentPaperarticle: iSearchTypes.IFullSearchData | undefined) {
        if(currentPaperarticle && this.currentPaperarticle && this.currentPaperarticle.contentId === currentPaperarticle.contentId){
            return
        }
        this.currentPaperarticle = currentPaperarticle;
        if(this.parentStore.params?.paperlayoutId && currentPaperarticle){
            this.getPaperarticleEntity(currentPaperarticle.contentId)
        }
    }

    @action
    public getPaperarticleList(parentId: string){
        this.isReq = true
        this.paperarticleList = undefined
        this.parentStore.queryFullSearchData(parentId).then(res=>{
            runInAction(() => {
                this.paperarticleList = _.orderBy(res, ['articleOrderId'], ['asc']);
                if(this.parentStore.params?.paperarticleId){
                    this.setCurrentPaperarticle(_.find(this.paperarticleList, {contentId: this.parentStore.params.paperarticleId}))
                }else if(!this.currentPaperarticle && this.paperarticleList.length > 0){
                    this.setCurrentPaperarticle(this.paperarticleList[0])
                }
                this.pictureList = []
                if(this.paperarticleList.length > 0){
                    this.paperarticleList.map(o=>{
                        this.parentStore.getFileGroups(o.contentId).then((groupRes:entityTypes.IFileGroup[])=>{
                            runInAction(() => {
                                if(groupRes){
                                    let group = _.find(groupRes,{typeCode: 'paperarticlegroup'})
                                    if(group && group.fileItems && group.fileItems.length > 0){
                                        group.fileItems.map(f=>{
                                            f.parentId = o.contentId
                                            f.parentName = o.name_
                                        })
                                        this.pictureList = this.pictureList.concat(group.fileItems)
                                    }
                                }
                            })
                        })
                    })
                }
            })
        }).finally(()=>{
            runInAction(() => {
                this.isReq = false
            })
        })
    }

    @action
    public getPaperarticleEntity(contentId: string){
        this.parentStore.getEntityBaseData(contentId).then(res=>{
            runInAction(() => {
                this.paperarticleEntity = res
            })
        })
    }

    @action
    public setPictureList(pictureList: entityTypes.IFileGroupFileItem[]){
        this.pictureList = pictureList
    }
 
}
