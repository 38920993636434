import { commonUtil } from 'mam-common-utils';
import datasetDetailsTypes from 'src/types/datasetDetailsTypes';
import { action, observable, runInAction } from 'mobx';
import { Stores } from "../index";
import datasetDetailsApi from 'src/apis/datasetDetailsApi';
import { message } from 'antd'
import SelectAllStore from "../extends/selectAllStore";
import ExtendStore from "../base/extendStore";
import TreeStore from './subModules/treeStore/treeStore';
import EntitysDataStore from './subModules/entitysDataStore/entitysDataStore';
import {IEntityType} from "src/types/configTypes";
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import entityTypes from 'src/types/entityTypes';
import entityApi from 'src/apis/entityApi';

export default class DatasetDetailsStore extends ExtendStore {
    public treeStore:TreeStore//左侧树仓库
    public entitysDataStore: EntitysDataStore//右侧内容区域仓库
    @observable
    public isEditData: boolean = false//编辑切换
    @observable
    public entity: entityTypes.IEntity;
    @observable
    public entityData: entityTypes.IEntityDataItem[];
    @observable
    public tab: string = 'metadata'//左侧tab切换
    @observable
    public contentId: string//用户id
    @observable
    public isCollection: boolean //是否收藏
    @observable
    public showBatchEditModal: boolean = false //批量编辑弹窗
    @observable
    public selectBatchEditTypeData: any[] = [] //批量编辑类型数据
    @observable
    public activeId: number = 0//控制激活的类型
    @observable
    public batchCurrentType: string;
    
    @observable
    public metadatas: IFormItem[] = [];
    public selectAllStore: SelectAllStore
    @observable
    public keyword: string
    @observable
    public moveModal: boolean = false//移动素材模态框
    @observable
    public moveToFolderId: string = ""//移动模态框选中文件id

    constructor(stores: Stores) {
        super(stores);
        this.treeStore = new TreeStore(stores, this);
        this.entitysDataStore = new EntitysDataStore(stores, this)
    }
    @action
    public setIsCollection = (type: boolean) => {
        this.isCollection = type
    }
    @action
    public changeMoveModal = (type: boolean) => {
        this.moveModal = type
    }
    @action
    public changeIsEditData = () => {
        this.isEditData = !this.isEditData
    }
    @action
    public setTab = (type: string) => {
        this.tab = type
    }
    @action
    public changeKeyword = (keyword: string) => {
        this.keyword = keyword
    }
    @action
    public setContentId = (id: string) => {
        this.contentId = id
    }
    @action
    public changeMoveToFolderId = (id: string) => {
        this.moveToFolderId = id
    }
    @action
    public setShowBatchEditModal = (type: boolean) => {
        this.showBatchEditModal = type
    }
    @action
    public setSelectBatchEidtTypeData = (data: any) => {
        this.selectBatchEditTypeData = commonUtil.copyObj(data)
    }
    @action
    public setActiveId(value: number) {
        this.activeId = value;
    }
    //设置当前类型
    @action
    public setBatchCurrentType(currentType: string) {
        this.batchCurrentType = currentType
    }
    @action
    public queryInfoData = () => {
        return new Promise((resolve, reject) => {
            entityApi.getBaseData(this.contentId).then(res => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.entity = res.data.data
                        this.isCollection=res.data.data.extensions.favorite//是否收藏
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    @action
    public queryEntityData(){
        return new Promise((resolve, reject) => {
            entityApi.getEntityData(this.contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entityData = res.data.data;
                        resolve(this.entityData);
                    }
                    else {
                        reject();
                    }
                });
            })
        });
    }
    //收藏
    @action
    public collection = (params:datasetDetailsTypes.ICollectionParams) => {
        return new Promise((resolve, reject) => {
            datasetDetailsApi.collection(params).then(res => {
                if(res){
                    if(!params.favorite){
                        message.success("收藏成功")
                    }else{
                        message.success("取消收藏成功")
                    }
                    this.setIsCollection(!params.favorite)
                }
                resolve(res)
            }, res => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
}
