import ExtendStore from "../base/extendStore"
import { observable, action, runInAction } from 'mobx'
import navApi from 'src/apis/navApi';
import { message } from 'antd';
import { Stores } from '..';
import { INav } from "mam-components-react/dist/modules/mocHeader/topNav";
import MessageMgStore from "./messageMgStore";

export default class LeftNavStore extends ExtendStore {
    @observable
    public navs: INav[] = [{
        icon: "",
        id: 324324234,
        name: "我的消息",
        openNewPage: false,
        url: "/messageMg/myMessage"
    }]
    @observable
    public openKeys: string[]
    @observable
    public current?: string
    private messageMgStore: MessageMgStore

    public constructor(stores: Stores, messageMgStore: MessageMgStore) {
        super(stores);
        this.messageMgStore = messageMgStore;
    }

    @action
    public setOpenKeys(openKeys: string[]){
        this.openKeys = openKeys;
    }

    @action
    public setCurrent(current?: string){
        this.current = current;
    }
}
