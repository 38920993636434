import { action, observable, computed, runInAction } from "mobx";
import BaseStore from "../base/baseStore";
import mmsTypes from '../../types/mmsTypes'
import mmsApi from "../../apis/mmsApi";
import _ from 'lodash'
import {Stores} from "../index";
import { message } from 'antd';
import configApi from "src/apis/configApi";
import { IDownloadConfig } from "src/types/configTypes";
import { confirmTipCopyrightModel, warningTipCopyrightModel } from "src/components/biz/tipCopyrightModal/tipCopyrightModal";
import { reqCatch } from "mam-core-react";

export default class DownloadStore extends BaseStore<Stores> {
    // 下载弹窗的显示与隐藏
    @observable
    public isShowDownload: boolean = false
    @observable
    public downloadItems: mmsTypes.downloadTypes.IFileDownloadInfoDownloadItem[]
    // 所有item 包含选择的和没有选择的
    @observable
    public allItems: mmsTypes.downloadTypes.ISingleDownloadItemInfo[] = []
    // 所有的a链接，触发下载
    @observable
    public allALinks: object = {}
    /** 要下载的所有素材 */
    @observable
    public allEntityItems: any[] = []
    /** 上传下载配置 */
    @observable
    public downloadConfig?: IDownloadConfig
    @observable
    public groupSelectd: string = '全部'
    // 所有选中的项
    @computed
    public get selectedItems(): mmsTypes.downloadTypes.ISingleDownloadItemInfo[]{
        return this.allItems.filter((item)=>{
            return item.selected
        });
    }

    @action
    public getDownloadConfig(){
        configApi.getDownloadConfig().then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.downloadConfig = res.data.data;
                    configApi.getScoreConfig('Is_Enable_Point').then(res=>{
                        runInAction(()=>{
                            if (this.downloadConfig && res.data.data && res.data.data.value === 'true'){
                                this.downloadConfig.scoreEnable = true;
                            }
                        })
                    }).catch(reqCatch)
                }
            })
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title)
            }
            else {
                console.error(res);
            }
        })
    }

    // 控制下载弹窗的显示与隐藏
    @action
    public setModalSH(show: boolean) {
        this.isShowDownload = show;
    }

    public groupFilter(fileGroups: mmsTypes.downloadTypes.IDownloadItemFileGroup[]) {
        return fileGroups.filter(element => {
            if (this.groupSelectd != '全部'){
                return element.showName == this.groupSelectd
            }
            else{
                return true
            }
        })
    }

    // 初始化items
    @action
    public initAllItems() {
        this.allItems = []
        mmsApi.download.getFileDownloadInfo(this.allEntityItems.map(item=>item.contentId)).then((res) => {
            runInAction(()=>{
                if (res.data.data && res.data.data.downloadItems){
                    this.downloadItems = res.data.data.downloadItems;
                    let aitem: mmsTypes.downloadTypes.ISingleDownloadItemInfo;
                    res.data.data.downloadItems.forEach((item)=>{
                        const entityItem = _.find(this.allEntityItems, {contentId: item.contentId});
                        let allFilesCount = 0;//统计所有文件组下的文件数量总和
                        if (item.fileGroups){
                            this.groupFilter(item.fileGroups).forEach((fg)=>{
                                if (fg.files){
                                    allFilesCount += fg.files.length;
                                }
                            })
                        }
                        if (item.fileGroups){
                            this.groupFilter(item.fileGroups).forEach((fg, fgindex)=>{
                                if (fg.files){
                                    fg.files.forEach((file, fileindex)=>{
                                        aitem = {
                                            contentId: item.contentId,
                                            entityName: item.entityName,
                                            downloadEntityName: item.entityName,
                                            entityType: item.entityType,
                                            entityNameRowSpan: allFilesCount,
                                            groupName: fg.groupName,
                                            groupNameRowSpan: fg.files.length,
                                            showName: fg.showName,
                                            fileName: file.fileName,
                                            fileExt: file.fileExt,
                                            fileGuid: file.fileGuid,
                                            fileSize: file.fileSize,
                                            videoFormatInfo: file.videoFormatInfo,
                                            audioFormatInfo: file.audioFormatInfo,
                                            bitRateInfo: file.bitRateInfo,
                                            webDownloadPath: file.webDownloadPath,
                                            selected: false,
                                            importauditstatus: entityItem?.importauditstatus,
                                            isSensitive: entityItem?.isSensitive
                                        };
                                        if (fgindex > 0 || fileindex > 0){//合并头部
                                            aitem.entityName = undefined;
                                        }
                                        if (fileindex > 0){//合并头部
                                            aitem.showName = undefined;
                                        }
                                        this.allItems.push(aitem);
                                    })
                                }
                            })
                        }
                    })

                    // 版权过期提醒
                    if (res.data.data.copyright){
                        if (res.data.data.downloadItems.length > 0){
                            confirmTipCopyrightModel('选中的素材包含版权快过期或已过期，确定是否继续下载？', res.data.data.copyright).then(()=>{
                                this.selectAll(true)
                                this.setModalSH(true);
                            })
                        }
                        else {
                            warningTipCopyrightModel('选中的素材均已过期，无法发起下载', res.data.data.copyright)
                        }
                    }
                    else {
                        this.selectAll(true)
                        this.setModalSH(true);
                    }
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    @action
    public initAllHypermediaItems(hypermediaItems: any[]) {
        if (hypermediaItems.length > 0){
            hypermediaItems.forEach((item)=>{
                this.allItems.push({
                    contentId: item.contentId,
                    entityName: item.name_,
                    downloadEntityName: item.name_,
                    entityType: 'biz_sobey_hypermedia',
                    entityNameRowSpan: 1,
                    groupName: '',
                    groupNameRowSpan: 1,
                    showName: '高质量',
                    fileName: item.name_,
                    fileExt: '',
                    fileGuid: '',
                    fileSize: '-',
                    videoFormatInfo: '',
                    audioFormatInfo: '',
                    bitRateInfo: '',
                    webDownloadPath: '',
                    selected: false
                })
            })
        }
    }

    @action
    public setAllEntityItems(allEntityItems: any[]) {
        this.allEntityItems = allEntityItems;
    }

    @action
    public selectItem(indexArr: number[], select: boolean){
        indexArr.forEach((index)=>{
            this.allItems[index].selected = select;
        });
    }

    @action
    public selectAll(select: boolean){
        this.allItems.forEach(item=>{
            item.selected = select;
        })
    }

    @action
    public setGroupSelectd(group: string) {
        this.groupSelectd = group;
        this.allItems.forEach(item => {
            item.selected = false;
        })
    }
}
