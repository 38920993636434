import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import { workteamDetailsTypes } from 'src/types/workteamDetailsTypes';
import {IResponse} from "mam-core-react/dist/modules/http";


namespace workteamDetailsApi {
    export const queryEntity = (params:workteamDetailsTypes.IWorkteamDetailsParams): AxiosPromise<IResponse<workteamDetailsTypes.IWorkteamDetailsRes>> => {
        return http.post<IResponse<workteamDetailsTypes.IWorkteamDetailsRes>>('/work-team/query-entity',params)
    }


}

export default workteamDetailsApi