import { AxiosPromise } from "axios"
import { http } from "mam-core-react"
import { IResponse } from "mam-core-react/dist/modules/http"
import { IRemoveRelationReq, ISearchEntitiesReq, ISearchEntitiesRes } from "src/types/mocTopicDetailTypes"

export const searchEntities = (req: ISearchEntitiesReq): AxiosPromise<IResponse<ISearchEntitiesRes>> => {
    return http.post<IResponse<ISearchEntitiesRes>>('/scntm/v1/moc-album/content', req)
}

export const removeEntities = (req: IRemoveRelationReq): AxiosPromise<IResponse<any>> => {
    return http.post<IResponse<any>>('/scntm/v1/moc-album/relation/remove', req)
}