import { action, observable } from "mobx";
import { Stores } from "src/stores";
import ExtendStore from "src/stores/base/extendStore";
import manageTypes from "src/types/manageTypes";
import MetadataMgStore from "./metadataMgStore";

export default class EditMetaFieldStore extends ExtendStore {
    @observable
    public showEditModal: boolean = false;
    @observable
    public editField?: manageTypes.metadata.IMetadataSource;
    public parentStore: MetadataMgStore;

    public constructor(stores: Stores, parentStore: MetadataMgStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public openModal(editField: manageTypes.metadata.IMetadataSource){
        this.editField = editField;
        this.showEditModal = true;
    }

    @action
    public closeModal(){
        this.showEditModal = false;
        this.editField = undefined;
    }

    @action
    public changeEditField(fieldCode: string, value: any){
        if (this.editField){
            this.editField[fieldCode] = value;
        }
    }
}