import ExtendStore from '../base/extendStore';
import { action, observable, runInAction } from 'mobx';
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import { message } from 'antd';
import _ from 'lodash'
import { Stores } from 'src/stores';
import IOutputExcelStore from './extends/iOutputExcelStore';
import IAdvancedSearchStore from './extends/iAdvancedSearchStore';
import { commonUtil } from 'mam-common-utils'
import mmsApi from 'src/apis/mmsApi';
import { reqCatch } from 'mam-core-react';

export default class ISearchStore extends ExtendStore {
    @observable
    public params: iSearchTypes.ISearchParams
    @observable
    public searchKeyword1: string = '';  // 主搜索框值
    @observable
    public searchKeyword2: string = '';  // 次检索框值
    /** 是否显示层面过滤 */
    @observable
    public showFacetFilter: boolean = true;
    @observable
    public fullSearchData?: iSearchTypes.IFullSearchDataRes;
    @observable
    public fullSearchQuerySecs: number = 0;
    /** 层面数据 */
    @observable
    public aggrs: iSearchTypes.IFacets[] = [];
    @observable
    public isReqAggrs = false;
    @observable
    public sortItems: iSearchTypes.ISortItem[] = []
    /** 当前选中排序类型 */
    @observable
    public currentSort: iSearchTypes.ISortItem;
    /** 检索范围 */
    @observable
    public keywordSearchType: string = 'fullText';

    @observable
    public pageSize: number = 30;

    @observable
    public isLoadingFullSearchData = true;

    @observable
    public showRightBox = false; // 是否显示右侧区域

    /** 选中的检索模板 */
    @observable
    public searchTemplateId?: string;

    public iOutputExcelStore: IOutputExcelStore;
    public iAdvancedSearchStore: IAdvancedSearchStore;

    constructor(stores: Stores) {
        super(stores)
        this.iOutputExcelStore = new IOutputExcelStore(stores);
        this.iAdvancedSearchStore = new IAdvancedSearchStore(stores);
        this.initSelectAllStore(stores, 'stores.iSearchStore.fullSearchData.data', 'selected');
        this.initSearchFilterStore(stores);
        this.initDataViewModeStore(stores);
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
        this.initDeleteStore(stores);
        this.initBatchSmartProcess(stores);
        this.initCreateDatasetStore(stores);
        this.initBatchInitiatingSmartProcess4AllStore(stores, this);
        this.initUploadStore(stores);
        this.initUploadPanelStore(stores);
        this.initCreateFolderStore(stores);
        this.initShareStore(stores);

        let searchTemplateId = sessionStorage.getItem('iSearch.searchTemplateId');
        if (searchTemplateId){
            this.searchTemplateId = searchTemplateId;
        }
        let keywordSearchType = sessionStorage.getItem('iSearch.keywordSearchType');
        if (keywordSearchType) {
            this.keywordSearchType = keywordSearchType;
        }
    }

    public getFullSearchParam(page?: number): iSearchTypes.IFullSearchDataReq{
        this.getSortItems();
        let param: iSearchTypes.IFullSearchDataReq = {
            pageIndex: page || 1,
            pageSize: this.pageSize,
            personId: '',
            keywords: this.params.keyword.filter(item=>item !== ''),
            facetConditions: [],
            conditions: [],
            sortFields:[{
                field: this.currentSort.field,
                isDesc: this.currentSort.field === '_score' ? true : this.currentSort.desc === 'desc'
            }]

        }
        if (this.params.folderId){
            param.folderId = this.params.folderId;
        }
        if (this.params.facetConditions) {
            this.params.facetConditions.forEach((condition) => {
                if (condition.items) {
                    condition.items.forEach((n) => {
                        let item = _.find(param.facetConditions, { field: n.field });
                        if (item == null) {
                            param.facetConditions.push(n);
                        } else {
                            if (_.isArray(item.value)) {
                                if (!_.includes(item.value, n.value)) {
                                    item.value.push(n.value as string);
                                }
                            } else {
                                if (item.value !== n.value) {
                                    item.value = [item.value, n.value as string];
                                }
                            }
                        }
                    });
                }
            });
        }
        // 高级检索
        if (this.params.conditions) {
            this.params.conditions.forEach((condition) => {
                if (condition.type !== 'advancedSearch') {
                    return;
                }
                if (condition.items) {
                    condition.items.forEach((n) => {
                        let value = n.value;
                        //日期参数处理
                        if (condition.indexType === 5 && value && value instanceof Array && value.length > 0) {
                            let valueArr = value[0].split(',');
                            if (valueArr && valueArr.length === 2) {
                                value = [`${valueArr[0]} 00:00:00 TO ${valueArr[1]} 23:59:59`]
                            }
                        }
                        //输入框多个逗号分开处理
                        if ((condition.indexType === 0 || condition.indexType === 7) 
                        && value && value instanceof Array && value.length > 0) {
                            let valueArr = value[0].split(',');
                            if (valueArr) {
                                value = valueArr
                            }
                        }
                        param.conditions.push({
                            field: n.field,
                            searchRelation: n.nexus,
                            value
                        });
                    });
                }
            });
        }
        //人脸检索传参
        if (this.stores.imageSearchStore.faces && this.stores.imageSearchStore.faces.length > 0){
            // 要检索多个人脸的临时方案，以后等多个personId检索支持，就调整这段代码
            if (this.stores.configStore.baseConfig.customCfg &&
            this.stores.configStore.baseConfig.customCfg.searchFaceByAdvancedFace){
                param.conditions.push({
                    field: 'face',
                    searchRelation: 'eq',
                    value: this.stores.imageSearchStore.faces.map(item=>item.name)
                });
            }
            else {
                if (this.stores.imageSearchStore.faces[0].person_id){
                    param.personId = this.stores.imageSearchStore.faces[0].person_id;
                }
                if (this.stores.imageSearchStore.faces[0].cluster_ids){
                    param.clusterIds = this.stores.imageSearchStore.faces[0].cluster_ids;
                }
            }
        }
        //选中的检索模板
        if (this.searchTemplateId){
            param.templateId = this.searchTemplateId;
        }
        if (param.templateId || param.keywords.length > 0 
            || param.conditions.length > 0 || param.facetConditions.length > 0){
            param.isQueryDirectSub = true;
        }
        //检索范围
        if (this.keywordSearchType) {
            param.keywordSearchType = this.keywordSearchType;
        }
        return param;
    }

    @action
    public faceSearchAndQueryFullSearchData(page?: number, isAppend?: boolean){
        this.isLoadingFullSearchData = true;
        this.fullSearchData = undefined;
        this.aggrs = [];
        if (process.env.BUILD_TYPE === 'mah' && 
            this.stores.imageSearchStore.params && this.stores.imageSearchStore.params.base64){
                this.stores.imageSearchStore.searchFace().then(()=>{
                if (this.stores.imageSearchStore.selectedFace){
                    this.queryFullSearchData(page, isAppend);
                    this.queryAggrs(page);
                }
                else {
                    runInAction(()=>{
                        this.isLoadingFullSearchData = false;
                    })
                }
            });
        }
        else {
            this.queryFullSearchData(page, isAppend);
            this.queryAggrs(page);
        }
    }

    /**
     * @param page 
     * @param isAppend 是否追加模式，滚动分页时使用
     * @returns 
     */
    @action
    public queryFullSearchData(page?: number, isAppend?: boolean): Promise<iSearchTypes.IFullSearchDataRes | undefined>{
        return new Promise((resolve, reject)=>{
            this.selectAllStore.switchAllSelected(false);
            let param = this.getFullSearchParam(page);
	        if (!isAppend) {
                this.fullSearchData = undefined;
            }
            this.isLoadingFullSearchData = true;
            this.fullSearchQuerySecs = 0;
            let before = new Date();
            let qPromise = iSearchApi.queryFullSearchData(param);
            // 带人脸的检索，接口需要更换
            if (param.clusterIds || param.personId){
                qPromise = iSearchApi.queryFullSearchDataWithFace(param);
            }
            qPromise.then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.data){
                        res.data.data.data.forEach((item)=>{
                            item.contentId = item.contentId_;
                            item.type = item.type_;
                        })
                        if (!res.data.data.aggrs){
                            res.data.data.aggrs = []
                        }
                    }
		            if (!isAppend) {
                        this.fullSearchData = res.data.data;
                        if (this.fullSearchData) {
                            this.favoriteCheck(this.fullSearchData.data);
                            if (this.stores.configStore.baseConfig.customEdition === 'guowang'){
                                this.seriesRelshipStateCheck(this.fullSearchData.data)
                                this.copyrightStateCheck(this.fullSearchData.data)
                            }
                        }
                    }
                    else if (this.fullSearchData && res.data.data && res.data.data.data.length > 0) {
                        this.fullSearchData.data = this.fullSearchData.data.concat(res.data.data.data);
                        this.fullSearchData.pageIndex = res.data.data.pageIndex;
                        this.fullSearchData.pageSize = res.data.data.pageSize;
                        this.fullSearchData.pageTotal = res.data.data.pageTotal;
                        this.fullSearchData.recordTotal = res.data.data.recordTotal;
                        this.favoriteCheck(res.data.data.data);
                        if (this.stores.configStore.baseConfig.customEdition === 'guowang'){
                            this.seriesRelshipStateCheck(res.data.data.data)
                            this.copyrightStateCheck(res.data.data.data)
                        }
                    }
                    this.isLoadingFullSearchData = false;
                    resolve(res.data.data);
                })
            }, res=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
		        reject();
            }).finally(() => {
                runInAction(() => {
                    let after = new Date();
                    this.fullSearchQuerySecs = parseFloat(((after.getTime() - before.getTime()) / 1000).toFixed(2));
                })
            });

        })
    }

    /** 查询层面数据 */
    @action
    public queryAggrs(page?: number, extensions?: any){
        let param = this.getFullSearchParam(page || 1);
        param.extension = extensions;
        this.aggrs = [];
        this.isReqAggrs = true;
        iSearchApi.queryAggrs(param).then(res => {
            runInAction(()=>{
                if (res.data.data){
                    this.initFacets(res.data.data);
                    this.aggrs = res.data.data;
                }
            })
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        }).finally(()=>{
            runInAction(()=>{
                this.isReqAggrs = false;
            })
        });
    }

    @action
    public favoriteCheck(items: iSearchTypes.IFullSearchData[]){
        if (items) {
            let contentIds: string[] = items.map((item) => {
                return item.contentId;
            })
            mmsApi.collection.getCollectionStatus(contentIds).then(res=>{
                if (res.data.data){
                    runInAction(() => {
                        if (res.data.data) {
                            res.data.data.forEach((favoriteItem) => {
                                if (this.fullSearchData) {
                                    let item = _.find(this.fullSearchData.data, { contentId: favoriteItem.rescontentid });
                                    if (item){
                                        item.isFavorite = true;
                                        item.favoriteObjId = favoriteItem.contentId_;
                                    }
                                }
                            })
                        }
                    })
                }
            }).catch(reqCatch)
        }
    }

    /** 查询剧集关联信息 */
    @action
    public seriesRelshipStateCheck(items: iSearchTypes.IFullSearchData[]){
        if (items) {
            let contentIds: string[] = items.filter(item=>item.type === 'biz_sobey_video').map((item) => {
                return item.contentId;
            })
            if (contentIds.length > 0){
                iSearchApi.getSeriesRelshipState(contentIds).then(res=>{
                    if (res.data.data){
                        runInAction(() => {
                            if (res.data.data) {
                                res.data.data.forEach((contentId) => {
                                    if (this.fullSearchData) {
                                        let item = _.find(this.fullSearchData.data, { contentId: contentId });
                                        if (item){
                                            item.isRelshipSeries = true;
                                        }
                                    }
                                })
                            }
                        })
                    }
                }).catch(reqCatch)
            }
        }
    }

    /** 查询版权信息 */
    @action
    public copyrightStateCheck(items: iSearchTypes.IFullSearchData[]){
        if (items) {
            let contentIds: string[] = items.filter(item=>item.type === 'biz_sobey_series' 
                || item.type === 'biz_sobey_video').map((item) => {
                return item.contentId;
            })
            if (contentIds.length > 0){
                iSearchApi.getCopyrightState(contentIds).then(res=>{
                    if (res.data.data){
                        runInAction(() => {
                            if (res.data.data) {
                                res.data.data.forEach((contentId) => {
                                    if (this.fullSearchData) {
                                        let item = _.find(this.fullSearchData.data, { contentId: contentId });
                                        if (item){
                                            item.isRelshipCopyright = true;
                                        }
                                    }
                                })
                            }
                        })
                    }
                }).catch(reqCatch)
            }
        }
    }

    /** 设置收藏状态 */
    @action
    public setItemFavorite(item: any, favorite: boolean, favoriteObjId?: string){
        if (this.fullSearchData && this.fullSearchData.data){
            let target = _.find(this.fullSearchData.data, {contentId: item.contentId});
            if (target){
                if (favoriteObjId){
                    target.favoriteObjId = favoriteObjId;
                }
                target.isFavorite = favorite;
            }
        }
    }

    @action
    public setParams(params: iSearchTypes.ISearchParams){
        this.params = params;
    }

    @action
    public setSearchTemplateId(id?: string){
        this.searchTemplateId = id;
        sessionStorage.setItem('iSearch.searchTemplateId', this.searchTemplateId || '');
    }

    @action
    public setKeywordSearchType(type: string) {
        this.keywordSearchType = type;
        sessionStorage.setItem('iSearch.keywordSearchType', this.keywordSearchType || '');
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
        localStorage.setItem('searchStore.pageSize', size.toString());
    }

    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }
    @action
    public setSearchKeyword2(str: string) {
        this.searchKeyword2 = str;
    }

    @action
    public setShowFacetFilter(show: boolean) {
        this.showFacetFilter = show;
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: iSearchTypes.ISortItem[] = []
        if (config && config.sortFields && config.sortFields.length > 0) {
            sortItems = config.sortFields.map((item: any) => {
                return {
                    text: item.name || '',
                    field: item.field,
                    desc: item.desc ? 'desc' : 'asc',
                    hideDirection: true
                }
            })
        } else {
            sortItems = [
                { text: '相关度'.l('search.relatedRate'), field: '_score', hideDirection: true },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
                { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
                { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' }
            ]
        }
        sortItems.unshift({
            text: '相关度'.l('search.relativeRate'), field: '_score', hideDirection: true
        });
        this.setSortItems(sortItems)
        if(!this.currentSort){
            let sortStorage = localStorage.getItem('iSearch_current_sort')
            if (sortStorage){
                try {
                    this.setCurrentSort(JSON.parse(sortStorage))
                }
                catch (e){
                    console.error(e);
                }
            }
            else {
                //参数里关键字不为空，默认选中相关度，否则默认取第一个
                const keyword = this.params.keyword.filter(item=>item !== '');
                if (keyword.length === 0){
                    let scoreItem = _.find(this.sortItems, {field: '_score'});
                    if (scoreItem){
                        this.setCurrentSort(scoreItem);
                    }
                    else {
                        this.setCurrentSort(sortItems[0])
                    }
                }
                else {
                    const scoreItem = _.find(this.sortItems, {field: '_score'});
                    if (scoreItem){
                        this.setCurrentSort(scoreItem);
                    }
                    else {
                        this.setCurrentSort(sortItems[0])
                    }
                }
            }
        }
    }

    @action
    public setSortItems = (sortItems: iSearchTypes.ISortItem[]) => {
        this.sortItems = sortItems
    }

    @action
    public setCurrentSort(sort: iSearchTypes.ISortItem) {
        this.currentSort = sort;
        localStorage.setItem('iSearch_current_sort', JSON.stringify(this.currentSort))
    }

    @action
    public setShowRightBox(showRightBox: boolean){
        this.showRightBox = showRightBox;
    }

    //默认创建时间和相关度排序自动切换
    @action
    public autoRelatedSort(isToRelated: boolean) {
        if(isToRelated){
            const score = _.find(this.sortItems, {field: '_score'});
            if (score){
                this.setCurrentSort(score)
            }
        }else{
            const createDate = _.find(this.sortItems, {field: 'createDate_', desc: 'desc'});
            if (createDate){
                this.setCurrentSort(createDate)
            }
        };
    }
    
    public getCurrentFolderCode(){
        return this.stores.folderSearchStore.selectedFolderId;
    }

    public isPublicFolder(){
        return !this.stores.folderSearchStore.selectedFolder?.isPrivateFolder;
    }

    public createPrivateFolder(){
    }

    public isRoot(){
        if (!this.stores.folderSearchStore.selectedFolder){
            return false;
        }
        return _.findIndex(this.stores.folderSearchStore.rootFolders, {key: this.stores.folderSearchStore.selectedFolder.key}) > -1
    }

    /**
     * 重命名
     */
    @action
    public switchRenameStatus(index: number, editMode: boolean) {
        if (this.fullSearchData) {
            if (editMode) {
                this.fullSearchData.data[index].oldName = this.fullSearchData.data[index].name_
            }
            this.fullSearchData.data[index].editMode = editMode
        }
    }

    @action
    public updateShowName(index: number, newName: string) {
        if (this.fullSearchData) {
            this.fullSearchData.data[index].name_ = newName
        }
    }

    /** 初始化层面数据 */
    protected initFacets(facets: iSearchTypes.IFacets[]) {
        if (facets){
            facets.forEach(item => {
                if (item.facetFieldName === 'createDate_') {
                    item.startDate = '';
                    item.endDate = '';
                }
                item.showMore = false;
                if (item.facetValue) {
                    item.facetValue.forEach((o: iSearchTypes.IFacetsFacetValue) => {
                        o.isShow = true;
                        o.isSelected = false;
                    });
                }
                item.checkboxIsOpen = false;
            });
        }
    }
}
