import BaseStore from "../base/baseStore";
import { action, observable, runInAction, computed } from 'mobx'
import mmsApi from '../../apis/mmsApi'
import mmsTypes from '../../types/mmsTypes'
import { stores } from '..';
import { message } from 'antd';
import { IBaseEntity } from 'src/types/commonTypes';
import {Stores} from "../index";
import { l } from 'mam-core-react';

/** mch的收藏功能 */
export default class FavoriteStore extends BaseStore<Stores> {
    // 选择了的
    @observable
    public selected: string[] = []
    @observable
    public isShowMore: boolean = false
    @observable
    public isShowFavModal: boolean = false
    /** 是否是编辑标签 */
    @observable
    public isEdit: boolean = false
    @observable
    public tags: mmsTypes.favoriteTypes.IGetFavoriteTag[] = []
    @observable
    public onFavoriteCallback?: () => void // 收藏回调
    @observable
    public selectedItems: IBaseEntity[] = []
    @computed
    public get getContentIds() {
        return this.selectedItems.map(s => s.contentId)
    }

    // 已经选择的删除一个
    @action
    public removeSelectedItem(index: number) {
        this.selected.splice(index, 1);
    }
    // 添加到已经选择
    @action
    public addSelectedItem(tagName: string) {
        this.selected.push(tagName)
    }
    //  是否显示更多
    @action
    public enableShowMore() {
        this.isShowMore = true
    }
    //  选择的是否已经存在此项
    @action
    public isExistItem(value: string) {
        return this.selected.some(ele => ele === value)
    }
    //  显示隐藏模态框
    @action
    public setFavModalSH(value: boolean) {
        this.isShowFavModal = value
        if (!value) {
            if (this.onFavoriteCallback) {
                this.onFavoriteCallback()
            }
            this.isEdit = false
        }
    }
    // 过滤文件夹
    @action
    public setItems(items: IBaseEntity[]) {
        this.selectedItems = items.filter(s => s.type !== 'folder')
    }
    // 添加素材进来
    public addContentIds(items: IBaseEntity[]) {
        this.setItems(items)
        const favoriteTagEnable = true;
        if (favoriteTagEnable) {
            this.initStoreDate()
        } else {
            const data: mmsTypes.favoriteTypes.IDoFavorite = {
                contentIds: this.getContentIds,
                tags: []
            }
            this.doFavorite(data)
        }
    }
    // 初始配置，防止冲突
    @action
    public initStoreDate() {
        this.isShowMore = false
        this.selected = []
        mmsApi.favorite.getFavoriteTags().then(res => {
            if (res.data.data) {
                this.addOneTags(res.data.data)
                runInAction(() => {
                    // if (this.isEdit && bizContentId) {
                    //     this.tags.forEach(s => {
                    //         if (s.contentId.indexOf(bizContentId) > -1) {
                    //             this.selected.push(s.showValue)
                    //             s.selected = true
                    //         }
                    //     })
                    // }
                    this.isShowMore = this.tags.length > 10
                })
            }
        }).then(() => {
            this.setFavModalSH(true);
        })
    }
    // 修改标签
    @action
    public updateTags(bizContentId: string) {
        this.isEdit = true
        this.selectedItems = [{
            contentId: bizContentId,
            isFavorite: true,
            favoriteObjId: '',
            type: ''
        }]
        this.initStoreDate()
    }
    // 恢复一个标签
    @action
    public recoverOneTags(tagName: string) {
        this.tags.forEach(tag => {
            if (tag.showValue === tagName) {
                tag.selected = false;
            }
        })
    }
    // 选中某个tag
    @action
    public selectOneTags(inpValNum: string) {
        this.tags.forEach(tag => {
            if (tag.showValue === inpValNum) {
                tag.selected = true
            }
        })
    }
    // 添加一个tag
    @action
    public addOneTags(elements: mmsTypes.favoriteTypes.IGetFavoriteTag[]) {
        if (!elements){
            return;
        }
        this.tags = []
        elements.forEach((element: mmsTypes.favoriteTypes.IGetFavoriteTag) => {
            this.tags.push(element)
        });
    }

    @action
    public changeItemsFavorite(isFavorite: boolean) {
        this.selectedItems.forEach(s => {
            s.isFavorite = isFavorite
        })
    }

    public doFavorite(request: mmsTypes.favoriteTypes.IDoFavorite) {
        mmsApi.favorite.doFavorite(request).then(res => {
            message.success('收藏成功'.l('favorite.setOk'), 1.5);
            runInAction(() => {
                this.changeItemsFavorite(true)
                if (this.onFavoriteCallback) {
                    this.onFavoriteCallback()
                }
            })
        }).catch(err => {
            message.error('收藏失败'.l('favorite.setFailed'), 1.5);
        })
    }

    public cancelFavorite(contentIds: string[]){
        mmsApi.favorite.cancelFavorite(contentIds).then(res => {
            message.success(l('favorite.cancelOk', '已取消收藏'))
            runInAction(() => {
                this.changeItemsFavorite(false)
            });
        }).catch(() => {
            message.error('取消收藏失败'.l('favorite.cancelFailed'))
        });
    }
}
