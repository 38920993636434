import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import entityApi from 'src/apis/entityApi'
import _ from 'lodash'
import { Stores } from '..'
import { message } from 'antd'
import iSearchTypes from 'src/types/iSearchTypes'
import entityTypes from 'src/types/entityTypes'
import { IParams } from 'src/types/seriesDetailTypes'
import { MetadataForm } from 'mam-metadata-react'
import { CrawlFromWebStore } from 'mam-biz-components'
import iSearchApi from 'src/apis/iSearchApi'
import { reqCatch } from 'mam-core-react'

export default class SeriesDetailStore extends ExtendStore {
    @observable
    public params: IParams
    @observable
    public baseData?: entityTypes.IEntity;
    @observable
    public metadatas: IFormItem[] = []
    @observable
    public keyframePath: string = '' //当前显示海报图
    @observable
    public isMetaEdit: boolean
    @observable
    public entitiesRes?: iSearchTypes.IFullSearchDataRes
    @observable
    public isLoadingEntities = true;
    @observable
    public isReqAlbums = true;
    @observable
    public sortItems: iSearchTypes.ISortItem[] = []
    @observable
    public keyframes: string[] = []
    /**右侧抽屉 */
    @observable
    public openDrawer: boolean = false
    /** 当前选中排序类型 */
    @observable
    public currentSort: iSearchTypes.ISortItem;
    public metadataForm?: MetadataForm;
    public crawlFromWebStore: CrawlFromWebStore<Stores>;

    public constructor(stores: Stores) {
        super(stores);
        this.initSelectAllStore(stores, 'stores.seriesDetailStore.entitiesRes.data', 'selected');
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
        this.initDataViewModeStore(stores);
        this.crawlFromWebStore = new CrawlFromWebStore(stores, this);
    }

    @action
    public setParams(params: IParams) {
        this.params = params;
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: iSearchTypes.ISortItem[] = []
        // if (config && config.sortFields && config.sortFields.length > 0) {
        //     sortItems = config.sortFields.map((item: any) => {
        //         return {
        //             text: item.name || '',
        //             field: item.field,
        //             desc: item.desc ? 'desc' : 'asc',
        //             hideDirection: true
        //         }
        //     })
        // } else {
        sortItems = [
            { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
            { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
            { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
            { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' },
            { text: '分集号', field: 'episodeSerialNumber', desc: 'desc' },
            { text: '分集号', field: 'episodeSerialNumber', desc: 'asc' },
            { text: '相关度'.l('search.relatedRate'), field: '_score', hideDirection: true }
        ]
        // }
        this.sortItems = sortItems;
        if (!this.currentSort) {
            this.setCurrentSort(sortItems[0]);
        }
    }

    @action
    public setCurrentSort(sort: iSearchTypes.ISortItem) {
        this.currentSort = sort;
    }

    @action
    public setCurrentKeyframe(currentKeyframe: string) {
        this.keyframePath = currentKeyframe;
    }

    @action
    public setOpenDrawer(openDrawer: boolean) {
        this.openDrawer = openDrawer;
    }

    @action
    public getBasicDatas() {
        this.metadatas = [];
        this.keyframePath = '';
        entityApi.getBaseData(this.params.contentId, `keys=albumtype&keys=score&keys=subtitle&keys=differenceEpisodeCount`).then((res) => {
            runInAction(() => {
                if (res.data.data) {
                    this.baseData = res.data.data;
                    // if (res.data.data.keyframe && res.data.data.keyframe.filePath){
                    //     this.keyframePath = res.data.data.keyframe.filePath
                    // }
                }
            })
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
        })
        entityApi.getEntityData(this.params.contentId).then((res) => {
            runInAction(() => {
                if (res.data.data) {
                    let defaultGroup = _.find(res.data.data, { groupCode: 'default_group' })
                    if (defaultGroup) {
                        this.metadatas = defaultGroup.fields;
                    }
                }
            });
        })

        entityApi.getFileGroups(this.params.contentId).then(res => {
            if (res.data.data && _.find(res.data.data, { typeCode: 'poster' })) {
                this.keyframes = _.map(_.find(res.data.data, { typeCode: 'poster' })?.fileItems as entityTypes.IFileGroupFileItem[], 'filePath')
            }
            this.keyframePath = this.keyframes[0]
        }, res => {
            if (res.data.error && res.data.error.title) {
                message.error(res.data.error.title);
            }
        })
    }

    @action
    public getEntitys(page?: number, keyword?: string) {
        this.isLoadingEntities = true;
        let param: iSearchTypes.IFullSearchDataReq = {
            pageIndex: page || 1,
            pageSize: 45,
            personId: '',
            keywords: keyword ? [keyword] : [],
            facetConditions: [],
            conditions: [],
            sortFields:[{
                field: this.currentSort.field,
                isDesc: this.currentSort.field === '_score' ? true : this.currentSort.desc === 'desc'
            }],
            parentId: this.params.contentId
        }
        iSearchApi.queryFullSearchData(param).then((res) => {
            runInAction(() => {
                if (res.data.data && res.data.data.data) {
                    res.data.data.data.forEach((item) => {
                        item.contentId = item.contentId_;
                        item.type = item.type_;
                    })
                    this.entitiesRes = res.data.data;
                    if (this.entitiesRes) {
                        if (this.stores.configStore.baseConfig.customEdition === 'guowang'){
                            this.seriesRelshipStateCheck(this.entitiesRes.data)
                            this.copyrightStateCheck(this.entitiesRes.data)
                        }
                    }
                }
            })
        }).finally(() => {
            runInAction(() => {
                this.isLoadingEntities = false;
            })
        })
    }

    /** 查询剧集关联信息 */
    @action
    public seriesRelshipStateCheck(items: iSearchTypes.IFullSearchData[]){
        if (items) {
            let contentIds: string[] = items.filter(item=>item.type === 'biz_sobey_video').map((item) => {
                return item.contentId;
            })
            if (contentIds.length > 0){
                iSearchApi.getSeriesRelshipState(contentIds).then(res=>{
                    if (res.data.data){
                        runInAction(() => {
                            if (res.data.data) {
                                res.data.data.forEach((contentId) => {
                                    if (this.entitiesRes) {
                                        let item = _.find(this.entitiesRes.data, { contentId: contentId });
                                        if (item){
                                            item.isRelshipSeries = true;
                                        }
                                    }
                                })
                            }
                        })
                    }
                }).catch(reqCatch)
            }
        }
    }

    /** 查询版权信息 */
    @action
    public copyrightStateCheck(items: iSearchTypes.IFullSearchData[]){
        if (items) {
            let contentIds: string[] = items.filter(item=>item.type === 'biz_sobey_series' 
                || item.type === 'biz_sobey_video').map((item) => {
                return item.contentId;
            })
            if (contentIds.length > 0){
                iSearchApi.getCopyrightState(contentIds).then(res=>{
                    if (res.data.data){
                        runInAction(() => {
                            if (res.data.data) {
                                res.data.data.forEach((contentId) => {
                                    if (this.entitiesRes) {
                                        let item = _.find(this.entitiesRes.data, { contentId: contentId });
                                        if (item){
                                            item.isRelshipCopyright = true;
                                        }
                                    }
                                })
                            }
                        })
                    }
                }).catch(reqCatch)
            }
        }
    }

    @action
    public setMetaEdit(isEdit: boolean) {
        this.isMetaEdit = isEdit;
    }

    @action
    public setMetadatas(fields: IFormItem[]) {
        this.metadatas = fields;
    }

    public getEntityName() {
        return this.baseData?.name;
    }

    public getMetaItems() {
        return this.metadataForm?.getItems();
    }

    public getContentId() {
        return this.params.contentId;
    }
}