import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import { IResponse } from "mam-core-react/dist/modules/http";
import batchEditTypes from 'src/types/batchEditTypes'
namespace batchEditApi {

    //获取顶部类型
    export const getCurrentTypes = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/scntm/v1/bulk-edit/current-types');
    }

    //获取选择的数据
    export const getEntityBatch = (params: any): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/scntm/v1/bulk-edit', { params });
    }

    //添加数据到批量编辑
    export const addBatchEdit = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/bulk-edit', contentIds);
    }

    //删除
    export const deleteEntityBatch = (ids: number[]): AxiosPromise<IResponse<any>> => {
        let url = '/scntm/v1/bulk-edit?ids=' + ids.join('&ids=')
        return http.delete<IResponse<any>>(url);
    }

    //按照类型删除或者全部删除
    export const deleteEntityBatchAsCurrentType = (currentType?: string): AxiosPromise<IResponse<any>> => {
        let url = `/scntm/v1/bulk-edit/clear${currentType ? '?entityTypes=' + currentType : ''}`
        return http.delete<IResponse<any>>(url);
    }

    //获取元数据
    export const getEntityData = (entityType: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/metadata/fields/bulk-edit/${entityType}`);
    }

    //获取单个内容
    export const getEntityBaseData = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/entity/entitydata/${contentId}`);
    }

    //批量编辑
    export const updateEntityBatchForm = (updateInfo: batchEditTypes.IUpdateInfoTypes): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/entity/update-entity-data', updateInfo);
    }
}

export default batchEditApi