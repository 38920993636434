import { action, observable } from 'mobx'
import _ from 'lodash'
import { Stores } from 'src/stores'
import ExtendStore from 'src/stores/base/extendStore'
import IndexFieldConfigStore from './indexFieldConfigStore'
import FacetConfigStore from './facetConfigStore'
import AdvancedSearchConfigStore from './advancedSearchConfigStore'
import manageTypes from 'src/types/manageTypes'
import ManageStore from '../../manageStore'
import SearchTemplateStore from './searchTemplateStore/searchTemplateStore'
import ExcelExportFieldsConfigStore from './excelExportFieldsConfigStore'
import SearchMatchConfigStore from './searchMatchConfigStore'

export default class EsSearchConfigStore extends ExtendStore {
    public tabList: manageTypes.esSearchConfig.ITabItem[] = [
        {
            key: 'baseConfig',
            title: '基本配置'.l('searchConfig.baseConfig'),
            show: true,
        },
        {
            key: 'indexFieldConfig',
            title: '索引字段配置'.l('searchConfig.indexFieldConfig'),
            show: true,
        },
        {
            key: 'facetConfig',
            title: '层面配置'.l('searchConfig.facetConfig'),
            show: true,
        },
        {
            key: 'advancedSearchConfig',
            title: '高级检索配置'.l('searchConfig.advancedSearchConfig'),
            show: true,
        },
        {
            key: 'defaultSearchConfig',
            title: '默认检索配置'.l('searchConfig.defaultSearchConfig'),
            show: true,
        },
        {
            key: 'searchTemplate',
            title: '检索模板配置',
            show: true
        },
        {
            key: 'excelExport',
            title: 'excel导出配置',
            show: true
        },
        {
            key: 'searchMatchConfig',
            title: '全文检索配置',
            show: true
        },
        {
            key: 'searchResult',
            title: '检索结果展示配置',
            show: true
        }
    ]
    @observable 
    public tab: string = "baseConfig"
    public indexFieldConfigStore: IndexFieldConfigStore;
    public facetConfigStore: FacetConfigStore;
    public advancedSearchConfigStore: AdvancedSearchConfigStore;
    public searchTemplateStore: SearchTemplateStore;
    public excelExportFieldsConfigStore: ExcelExportFieldsConfigStore;
    public searchMatchConfigStore: SearchMatchConfigStore;
    private manageStore: ManageStore

    public constructor(stores: Stores, manageStore: ManageStore) {
        super(stores);
        this.manageStore = manageStore;
        this.indexFieldConfigStore = new IndexFieldConfigStore(stores, this);
        this.facetConfigStore = new FacetConfigStore(stores, this);
        this.advancedSearchConfigStore = new AdvancedSearchConfigStore(stores, this);
        this.searchTemplateStore = new SearchTemplateStore(stores);
        this.excelExportFieldsConfigStore = new ExcelExportFieldsConfigStore(stores, this);
        this.searchMatchConfigStore = new SearchMatchConfigStore(stores, this);
    }

    @action
    public setTab = (tab: string)=> {
        this.tab = tab
    }
}
