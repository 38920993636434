import { http } from 'mam-core-react'
import personalCenterTypes from 'src/types/personalCenterTypes'
import { AxiosPromise } from 'axios'
import { IResponse } from 'mam-core-react/dist/modules/http'

namespace personalCenterApi {
    export const getMessageTable = (pageIndex: number): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/user/get-current-user-message?pageIndex=${pageIndex}`)
    }
    //个人信息
    export const getUserInfo = ():AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/user/personal-center`);
    }
    
    export const changeUserInfo = (model: personalCenterTypes.IChangeUserInfo): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>('/scntm/v1/user/personal-center', model)
    }

    export const changePassword = (model:  personalCenterTypes.IChangePassword):AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>('/scntm/v1/user/personal-center/password', model)
    }
}

export default personalCenterApi