import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { IResponse, http } from 'mam-core-react/dist/modules/http'
import { IFaceRecoItem, IFaceRecoReq, IFaceRecoRes, IFaceSearchReq, IFaceSearchRes, IGetPersonDetailReq, IGetPersonDetailRes, IPersonTypeCollectionItem, ISearchBySimilarityReq, ISearchBySimilarityRes } from 'src/types/imageSearchTypes'

namespace imageSearchApi {
    export const searchFace = (request: IFaceRecoReq): AxiosPromise<IResponse<IFaceRecoRes>> => {
        return http.post<IResponse<IFaceRecoRes>>('/skldg/v1/entity/people/face', request)
    }

    export const searchBySimilarity = (req: ISearchBySimilarityReq,type:string): AxiosPromise<IResponse<ISearchBySimilarityRes>> => {
        let url = ''
        switch (type) {
            case 'picture':
                url = '/saims/v1/vector/image/search'
                break;
            case 'video':
                url = '/saims/v1/vector/video/keyframe-search'
                break;
            case 'videoToVideo':
                url = '/saims/v1/vector/video/search'
                break;
            default:
                break;
        }
        return http.post<IResponse<ISearchBySimilarityRes>>(url, req)
    }

    export const faceSearch = (req: IFaceSearchReq): AxiosPromise<IResponse<IFaceSearchRes>> => {
        return http.post<IResponse<IFaceSearchRes>>('/smcss/v1/search/face', req)
    }

    export const getPersonDetail = (req: IGetPersonDetailReq, cfg?: AxiosRequestConfig): AxiosPromise<IResponse<IGetPersonDetailRes>> => {
        return http.post<IResponse<IGetPersonDetailRes>>('/skldg/v1/entity/full-search', req, cfg)
    }

    export const getPersonTypeCollection = (): AxiosPromise<IResponse<IPersonTypeCollectionItem[]>> => {
        return http.get<IResponse<IPersonTypeCollectionItem[]>>('/skldg/v1/entity/people/categorys')
    }
}

export default imageSearchApi