import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import { IBasePageResponse } from 'src/types/commonTypes';
import recycleTypes from 'src/types/recycleTypes';
import {IResponse} from "mam-core-react/dist/modules/http";

namespace recycleApi {
    export const search= (param: recycleTypes.IRecyclePageRequest): AxiosPromise<IResponse<IBasePageResponse<any>>> => {
        return http.post<IResponse<IBasePageResponse<any>>>('/scntm/v1/recycle/resource', param)
    }

    export const checkCanRestore = (req: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/recycle/check-restore');
    }

    export const deleteCompletely = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
        let paramStr = '';
        contentIds.forEach((id)=>{
            paramStr += 'contentIds=' + id + '&';
        })
        paramStr = paramStr.substring(0, paramStr.length - 1);
        return http.delete('/scntm/v1/recycle?' + paramStr);
    }
}

export default recycleApi