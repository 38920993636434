import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import SysmanageStore from '../sysmanageStore';
import sysmanageApi from 'src/apis/sysmanageApi';
import sysmanageTypes from 'src/types/sysmanageTypes';
import { message } from 'antd';
import { ITabs } from 'src/pages/entity/modules/topTabs/topTabs';
import _ from 'lodash'
import { commonUtil } from 'mam-common-utils';

export default class DetailPageConfigurationStore extends ExtendStore {
    public parentStore: SysmanageStore;
    @observable
    public detailData?: sysmanageTypes.detailPage.IDetailPageCfg

    public constructor(stores: Stores, parentStore: SysmanageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public getDetailData(){
        return new Promise((resolve, reject)=>{
            sysmanageApi.detailPage.getDetailPageCfg().then(res => {
                runInAction(()=>{
                    if (res.data.data) {
                        this.detailData = res.data.data;
                    }
                })
                resolve(undefined);
            }).catch((res)=>{
                if (res.data && res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                else {
                    console.error(res);
                }
                reject();
            });
        })
    }

    @action
    public sortTopTabs(newList: ITabs[], currEntityCode: string){
        if (!this.detailData) {
            return;
        }
        const topTabs = _.find(this.detailData.topTabs, {entityType: currEntityCode});
        if (topTabs){
            topTabs.tabs = newList;
        }
    }

    @action
    public removeTopTab(index: number, currEntityCode: string){
        if (!this.detailData) {
            return;
        }
        const topTabs = _.find(this.detailData.topTabs, {entityType: currEntityCode});
        if (topTabs){
            topTabs.tabs.splice(index, 1);
        }
    }

    @action
    public editTopTab(newTab: ITabs, currEntityCode: string){
        if (!this.detailData) {
            return;
        }
        const topTabs = _.find(this.detailData.topTabs, {entityType: currEntityCode});
        if (topTabs){
            let target = _.find(topTabs.tabs, (o)=>{
                return commonUtil.equalsObj(o.key, newTab.key);
            });
            if (target){
                target.title = newTab.title;
                target.show = newTab.show;
                target.icon = newTab.icon;
                target.outerFrameUrl = newTab.outerFrameUrl;
            }
        }
    }

    @action
    public addTopTab(newTab: ITabs, currEntityCode: string){
        if (!this.detailData) {
            return;
        }
        const topTabs = _.find(this.detailData.topTabs, {entityType: currEntityCode});
        if (topTabs){
            topTabs.tabs.push(newTab);
        }
        else {
            this.detailData.topTabs.push({
                entityType: currEntityCode,
                tabs: [
                    newTab
                ]
            })
        }
    }
}
