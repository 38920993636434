import ExtendStore from "../base/extendStore";
import MediaStore from "./extends/mediaStore";
import RundownStore from "./extends/rundownStore";
import CopyrightStore from './extends/copyrightStore';
import KeyframeStore from './extends/keyframeStore';
import ScenesStore from './extends/scenesStore';
import DocumentOpeStore from './extends/documentOpeStore';
import SmartViewStore from './extends/smartViewStore/smartViewStore';
import ContentIndexStore from "./extends/contentIndexStore";
import PicpackageStore from "./extends/picpackageStore";
import PreviewImageStore from "./extends/previewImageStore";
import TvSeriesStore from "./extends/tvSeriesStore";
import SeriesStore from "./extends/seriesStore";
import SensitiveParts4PlayerStore from "./extends/sensitiveParts4PlayerStore";

export default class EntityExtendStore extends ExtendStore {
    public mediaStore: MediaStore;
    public rundownStore: RundownStore;
    public copyrightStore: CopyrightStore
    public keyframeStore: KeyframeStore
    public scenesStore: ScenesStore
    public documentOpeStore: DocumentOpeStore
    public smartViewStore: SmartViewStore;
    public contentIndexStore: ContentIndexStore;
    public picpackageStore: PicpackageStore;
    public previewImageStore: PreviewImageStore;
    public tvSeriesStore: TvSeriesStore;
    public seriesStore: SeriesStore;
    public sensitiveParts4PlayerStore: SensitiveParts4PlayerStore;

    public initMediaStore() {
        this.mediaStore = new MediaStore(this.stores);
    }

    public initRundownStore() {
        this.rundownStore = new RundownStore(this.stores);
    }

    public initCopyrightStore() {
        this.copyrightStore = new CopyrightStore(this.stores, this as any);
    }

    public initKeyframeStore() {
        this.keyframeStore = new KeyframeStore(this.stores, this as any);
    }

    public initScenesStore(){
        this.scenesStore = new ScenesStore(this.stores, this as any);
    }

    public initDocumentOpeStore(){
        this.documentOpeStore = new DocumentOpeStore(this.stores, this as any);
    }

    public initSmartViewStore() {
        this.smartViewStore = new SmartViewStore(this.stores, this as any);
    }

    public initContentIndexStore() {
        this.contentIndexStore = new ContentIndexStore(this.stores, this as any);
    }

    public initPicpackageStore() {
        this.picpackageStore = new PicpackageStore(this.stores, this as any);
    }

    public initPreviewImageStore() {
        this.previewImageStore = new PreviewImageStore(this.stores, this as any);
    }

    public initTvSeriesStore(){
        this.tvSeriesStore = new TvSeriesStore(this.stores, this as any)
    }

    public initSeriesStore(){
        this.seriesStore = new SeriesStore(this.stores)
    }

    public initSensitiveParts4PlayerStore(){
        this.sensitiveParts4PlayerStore = new SensitiveParts4PlayerStore(this.stores, this as any)
    }
}
