import { action, observable } from 'mobx'
import _ from 'lodash'
import { Stores } from 'src/stores'
import ExtendStore from 'src/stores/base/extendStore'
import SysmanageStore from '../sysmanageStore';
import sysmanageApi from 'src/apis/sysmanageApi';
import sysmanageTypes from 'src/types/sysmanageTypes';

export default class TaskCenterConfigStore extends ExtendStore {
    public parentStore: SysmanageStore;
    public tabList: sysmanageTypes.taskCenterConfig.ITabItem[] = [
        {
            key: 'baseConfig',
            title: '基本配置'.l('searchConfig.baseConfig'),
            show: true,
        },
    ]
    @observable 
    public tab: string = "baseConfig"

    public constructor(stores: Stores, parentStore: SysmanageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setTab = (tab: string)=> {
        this.tab = tab
    }
}
