import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import { Stores } from '..'
import GenSetStore from './genSetStore'
import { IGenResultItem, IGenSetConditionSubCateTag } from 'src/types/aiGcTypes'
import { commonUtil } from 'mam-common-utils'
import { translatePrompt, txt2Img } from 'src/apis/aiGcApi'
import moment from 'moment'
import { reqCatch } from 'mam-core-react'
import { message } from 'antd'

export default class AiGcStore extends ExtendStore {
    @observable
    public genResultList: IGenResultItem[] = []
    @observable
    public isSubmitDraw = false;
    public genSetStore: GenSetStore;
    /** 每次生成创建一个，创建日期不相同就是历史结果 */
    @observable
    public currentDate: Date;

    public constructor(stores: Stores) {
        super(stores);

        this.genSetStore = new GenSetStore(stores, this)

        // let genResultCacheStr = localStorage.getItem('aiGc_genResult');
        // if (genResultCacheStr){
        //     try {
        //         let genResultCache = JSON.parse(genResultCacheStr)
        //         this.genResultList = genResultCache;
        //     }
        //     catch (e){
        //         console.error(e)
        //     }
        // }
    }

    @action
    public async submitDraw(prompt: string, isDrawSame?: boolean){
        if (this.isSubmitDraw){
            return;
        }
        this.isSubmitDraw = true;
        let p = '';
        if (!isDrawSame){
            if (this.genSetStore.promptMode === 'tag'){
                p = prompt 
                + (this.genSetStore.positivePrompt ? (prompt ? ',' : '') + this.genSetStore.positivePrompt : '');
            }
            else{
                p = this.genSetStore.positivePrompt;
                try {
                    let res = await translatePrompt({
                        input_language: 'zh_CN',
                        output_language: 'en_XX',
                        text: this.genSetStore.positivePrompt
                    })
                    if (!res.data.success){
                        message.error((res.data as any).message)
                        return;
                    }
                    if (res.data.data){
                        p = res.data.data.text;
                    }
                }
                catch (res){
                    console.error(res)
                }
            }
        }
        else {
            p = prompt;
        }
        runInAction(()=>{
            this.currentDate = new Date();
        })
        txt2Img({
            prompt: p + (this.genSetStore.regularPositivePrompt ? ',' + this.genSetStore.regularPositivePrompt : ''),
            negative_prompt: this.genSetStore.negativePrompt,
            ...this.genSetStore.advancedParams
        }).then((res)=>{
            if (!res.data.success){
                message.error((res.data as any).message)
                return;
            }
            runInAction(()=>{
                if (res.data.data && res.data.data.images && res.data.data.images.length > 0){
                    res.data.data.images.forEach(image=>{
                        this.appendGenResult({
                            url: 'data:image/png;base64,' + image,
                            createDate: this.currentDate,
                            prompt: p
                        })
                    })
                }     
            })
        }).catch(reqCatch).finally(()=>{
            runInAction(()=>{
                this.isSubmitDraw = false;
            })
        })
    }

    @action
    public appendGenResult(item: IGenResultItem){
        this.genResultList.unshift(item);
        // if (this.genResultList.length > 5){
        //     this.genResultList.pop();
        // }
        // try {
        //     localStorage.setItem('aiGc_genResult', JSON.stringify(this.genResultList))
        // }
        // catch (e){
        //     console.error(e)
        // }
    }

    @action
    public deleteGenResult(index: number){
        this.genResultList.splice(index, 1);
        this.genResultList = this.genResultList.concat([]);
        // try {
        //     localStorage.setItem('aiGc_genResult', JSON.stringify(this.genResultList))
        // }
        // catch (e){
        //     console.error(e)
        // }
    }
}