import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../manageStore';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash';
import { commonUtil } from "mam-common-utils";
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';
import { reqCatch } from 'mam-core-react';

export default class TreeSetStore extends ExtendStore {
    public parentStore: ManageStore;

    //分类树主列表
    @observable
    public categoryList: manageTypes.treeSet.ICategoryListItem[] = []
    @observable
    public currentCategory: manageTypes.treeSet.ICategoryListItem | undefined = undefined
    @observable
    public isSearching: boolean = false

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setCategoryList(categoryList: manageTypes.treeSet.ICategoryListItem[]){
        this.categoryList = categoryList
    }

    @action
    public getCategoryList = (): Promise<any> => {
        this.isSearching = true
        return new Promise((resolve, reject) => {
            manageApi.treeSet.getCategoryList().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.categoryList = res.data.data
                        if(!this.currentCategory || (this.currentCategory && !_.find(this.categoryList, {treeTypeCode: this.currentCategory.treeTypeCode}))){
                            this.setCurrentCategory(this.categoryList[0] || undefined)
                        }
                        if(this.currentCategory && _.find(this.categoryList, {treeTypeCode: this.currentCategory.treeTypeCode})){
                            this.setCurrentCategory(_.find(this.categoryList, {treeTypeCode: this.currentCategory.treeTypeCode}))
                        }
                        this.isSearching = false
                        resolve(true)
                    }
                })
            }).catch(reqCatch)
        })
    }

    @action
    public setCurrentCategory(currentCategory: manageTypes.treeSet.ICategoryListItem | undefined){
        this.currentCategory = currentCategory
    }
}
