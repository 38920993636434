import ExtendStore from 'src/stores/base/extendStore'
import { Stores } from 'src/stores'
import ManageStore from '../manageStore'
import { observable, action, runInAction } from 'mobx'
import { message } from 'antd'
import _ from 'lodash'
import { commonUtil } from "mam-common-utils"
import manageTypes from "src/types/manageTypes"
import manageApi from "src/apis/manageApi"

export default class EnumerationStore extends ExtendStore{
    public parentStore: ManageStore

    @observable
    public page: number = 1
    @observable
    public size: number = 30
    @observable
    public isSearching: boolean = false
    @observable
    public listData: manageTypes.enumerationTypes.IEnumListItem[]
    @observable
    public currList: manageTypes.enumerationTypes.IEnumListItem[]
  
    @observable
    public showAddEditModal: boolean = false

    @observable
    public keyword: string = ''

    @observable
    public currentItem: manageTypes.enumerationTypes.IEnumListItem | undefined  //当前选中
    @observable
    public isEdit: boolean = false //当前选中是否处于编辑状态

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore
    }

    @action
    public clear(){
        this.isEdit = false
        this.currentItem = undefined
    }
    @action
    public setShowAddEditModal(show: boolean){
        this.showAddEditModal = show
    }
    @action
    public setKeyword(keyword: string){
        this.keyword = keyword
    }
    @action
    public setIsEdit(isEdit: boolean){
        this.isEdit = isEdit
    }
    @action
    public searchKeyword(){
        if (this.keyword) {
            let newArr: manageTypes.enumerationTypes.IEnumListItem[] = []
            _.map(this.listData, item => {
                if (item.name.indexOf(this.keyword) !== -1) {
                    newArr.push(item)
                }
                if (item.description.indexOf(this.keyword) !== -1) {
                    newArr.push(item)
                }
            })
            this.setCurrList(this.unique(newArr))
        } else {
            this.setCurrList(this.listData)
        }
    }
    @action
    public setCurrList(currList: manageTypes.enumerationTypes.IEnumListItem[]){
        this.currList = currList
        if(this.currList.length > 0){
            this.setCurrentItem(this.currList[0])
        }else{
            this.currentItem = undefined
        }
    }
    @action
    public setCurrentItem(currentItem: manageTypes.enumerationTypes.IEnumListItem | undefined){
        this.currentItem = currentItem
        if(this.currentItem){
            this.getChildren(this.currentItem.id)
        }
    }
    @action
    public changeListItem(item: manageTypes.enumerationTypes.IEnumListItem ){
        let copyListData = commonUtil.copyObj(this.listData)
        let idx  = _.findIndex(copyListData,{id: item.id})
        if(idx >= 0){
            copyListData[idx] = commonUtil.copyObj(item)
            if(this.currentItem && item.id === this.currentItem.id){
                this.setCurrentItem(copyListData[idx])
            }
        }else{
            copyListData.push(item)
        }
        this.listData = commonUtil.copyObj(copyListData)
        console.log(this.listData)
        this.searchKeyword()
        if(!this.currentItem){
            this.setCurrentItem(this.currList[0])
        }
    }
    @action
    public setPage(page: number){
        this.page = page
    }
    /* 获取列表,查最外层的全部列表则id默认0,查子项则id传父级id */
    @action
    public getList = (): Promise<any> => {
        this.isSearching = true
        return new Promise((resolve, reject) => {
            manageApi.enumerationApi.getList('0').then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        if(res.data.data){
                            this.listData = res.data.data
                            this.setCurrList(this.listData)
                        }
                        this.isSearching = false
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 查子项则id传父级id */
    @action
    public getChildren = (parentId: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.enumerationApi.getList(parentId).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data && this.currentItem) {
                        this.currentItem.fixItemVOS = res.data.data
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    
    // 过滤
    public unique = (arr: any[]) => {
        return arr.filter((item, index) => {
            return arr.indexOf(item, 0) === index
        })
    }
}
