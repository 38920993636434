import { Stores } from '../index';
import * as React from "react";
import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import { MetadataForm } from 'mam-metadata-react'
import bizTypes from 'src/types/bizTypes';

export default class CreateMocTopicStore extends ExtendStore {
    @observable
    public showModal:boolean = false
    @observable
    public selectedItems: any[] = []
    @observable
    public currentTab: string = 'addIntoTopic';
    @observable
    public keyframePath: string = '';
    public createTopicMetadataRef: React.RefObject<MetadataForm> = React.createRef()
    @observable
    public selectedAlbumContentId: string;
    @observable
    public addOrMoveAlbumList: any[]=[]//添加或移动到哪些专辑
    @observable
    public parentAlbumContentId: string = '';

    public constructor(stores: Stores){
        super(stores);
    }

    @action
    public setCurrentTab(tab: string){
        this.currentTab = tab;
    }
    @action
    public setAddOrMoveAlbumList(list: any[]){
        this.addOrMoveAlbumList = list;
    }
    @action
    public setParentAlbumContentId(parentAlbumContentId: string){
        this.parentAlbumContentId = parentAlbumContentId;
    }
    @action
    public openModal(selectedItems:any[]){
        this.selectedItems = selectedItems;
        this.keyframePath = '';
        if (this.selectedItems.length === 0){//未选择素材，自动选中创建专辑tab
            this.currentTab = 'createTopic'
        }
        else {
            this.currentTab = 'addIntoTopic'
        }
        this.setShowCreateAlbumModal(true)
    }
    @action
    public setShowCreateAlbumModal = (show:boolean) => {
        this.showModal = show
    }
    @action
    public setKeyframePath(path: string){
        this.keyframePath = path;
    }
    @action
    public setSelectedItems(selectedItems:any[]){
        this.selectedItems = selectedItems;
    }
    @action
    public setSelectedAlbumContentId(contentId: string){
        this.selectedAlbumContentId = contentId;
    }
}