import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../manageStore';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash'
import manageTypes from "src/types/manageTypes"
import manageApi from "src/apis/manageApi";
export default class InstanceStore extends ExtendStore {
    public parentStore: ManageStore;
    @observable
    public currentCode?: string;
    @observable
    public isEditModal: boolean = false;//是否是修改弹框
    @observable
    public creatObjModal: boolean = false; //是否显示创建业务对象弹框
    @observable
    public instanceList: manageTypes.instanceSetTypes.IInstanceItem[] = []; //查询实例列表
    @observable
    public currList: manageTypes.instanceSetTypes.IInstanceItem[] = []; //当前实例列表
    @observable
    public isQueryInstance: boolean = true; //是否在请求实例列表数据
    @observable
    public baseMetaList: manageTypes.instanceSetTypes.IBaseMeta[] = []; //当前基本元数据列表
    @observable
    public isQueryData: boolean = false;
    @observable
    public currBaseMeta?: manageTypes.instanceSetTypes.IBaseMeta; //当前选中的基本元数据
    @observable
    public parentBaseMeta?: manageTypes.instanceSetTypes.IBaseMeta; //当前选中的基本元数据
    @observable
    public metaModal: boolean = false; //是否显示元数据弹框
    @observable
    public expandObjModal: boolean = false; //是否显示拓展元数据下业务对象弹框
    @observable
    public expandInstanceList: manageTypes.instanceSetTypes.IInstanceItem[] = []; //查询实例列表
    @observable
    public isQueryExpandList: boolean = false; //是否正在查询实例列表
    @observable
    public currExpandList: manageTypes.instanceSetTypes.IInstanceItem[] = []; //当前实例列表
    @observable
    public currExpandInstance?: manageTypes.instanceSetTypes.IInstanceItem; //当前拓展元数据下选中实例
    @observable
    public expandMetaList: manageTypes.instanceSetTypes.IBaseMeta[] = []; //当前拓展元数据列表
    @observable
    public currExpandMeta?: manageTypes.instanceSetTypes.IBaseMeta; //当前选中的拓展元数据
    @observable
    public parentExpandMeta?: manageTypes.instanceSetTypes.IBaseMeta; //当前选中的基本元数据
    @observable
    public expandMetaModal: boolean = false; //是否显示拓展元数据弹框
    @observable
    public isShowList: boolean = false; //是否显示拓展元数据列表
    @observable
    public fileGroupList: manageTypes.instanceSetTypes.IFileGroupItem[] = []; //文件组列表
    @observable
    public fileGroupModal: boolean = false; //文件组弹框
    @observable
    public dataTypes: any = { // 数据类型
        'string': '字符型',
        'long': '整型',
        'float': '浮点数',
        'boolean': '布尔型',
        'date': '日期+时间类型',
        'complex': '复杂类型',
        'enum': '枚举类型'
    }

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setCurrentCode(code: string){
        this.currentCode = code;
    }

    // 获取实例对象列表
    @action
    public getInstanceList(): Promise<manageTypes.instanceSetTypes.IInstanceItem[]> {
        this.isQueryInstance = true
        return new Promise((resolve, reject) => {
            manageApi.instanceSetApi.getInstanceList().then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.instanceList = res.data.data
                        this.currList = res.data.data
                        this.isQueryInstance = false
                        resolve(res.data.data)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                    this.isQueryInstance = false
                }
                reject();
            });
        });
    }

    // 获取实例对象下基本元数据列表
    @action
    public getBaseMeatList(): Promise<any> {
        this.isQueryData = true
        this.baseMetaList = []
        return new Promise((resolve, reject) => {
            manageApi.instanceSetApi.getBaseMetaList(this.currentCode || '').then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.baseMetaList = res.data.data
                        this.isQueryData = false
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                    this.isQueryData = false
                }
                reject();
            });
        });
    }

    // 实例对象下拓展元数据获取业务对象列表
    @action
    public getExpandMetaObjList(): Promise<any> {
        this.isQueryExpandList = true
        return new Promise((resolve, reject) => {
            manageApi.instanceSetApi.getExpandMetaObjList(this.currentCode || '').then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.expandInstanceList = res.data.data
                        this.currExpandList = res.data.data
                        this.isQueryExpandList = false
                        if(!this.currExpandInstance || (this.currExpandInstance && !_.find(this.expandInstanceList, {code: this.currExpandInstance.code}))){
                            this.currExpandInstance = this.expandInstanceList[0] || undefined
                        }else{
                            this.currExpandInstance = undefined
                        }
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                    this.isQueryExpandList = false
                }
                reject();
            });
        });
    }

    // 实例对象下拓展元数据新增业务对象
    @action
    public addExpandInstance(req: manageTypes.instanceSetTypes.IInstanceItem): Promise<any> {
        return new Promise((resolve, reject) => {
            manageApi.instanceSetApi.addExpandInstance(this.currentCode || '', req).then((res) => {
                runInAction(() => {
                    message.success("添加成功")
                    resolve(true)
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    // 实例对象下拓展元数据修改业务对象
    @action
    public editExpandInstance(req: manageTypes.instanceSetTypes.IInstanceItem): Promise<any> {
        return new Promise((resolve, reject) => {
            manageApi.instanceSetApi.editExpandInstance(this.currentCode || '', req).then((res) => {
                runInAction(() => {
                    message.success("修改成功")
                    resolve(true)
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    // 获取实例对象下拓展元数据列表
    @action
    public getExpandMetaList(): Promise<any> {
        this.isQueryData = true
        this.isShowList = true
        this.expandMetaList = []
        return new Promise((resolve, reject) => {
            manageApi.instanceSetApi.getExpandMetaList(this.currentCode || '', this.currExpandInstance?.code).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.expandMetaList = res.data.data
                        this.isQueryData = false
                        if(!this.currExpandMeta || (this.currExpandMeta && !_.find(this.expandMetaList, {code: this.currExpandMeta.code}))){
                            this.currExpandMeta = this.expandMetaList[0] || undefined
                        }else{
                            this.currExpandMeta = undefined
                        }
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                    this.isQueryData = false
                }
                reject();
            });
        });
    }

    // 获取实例对象下文件组列表
    @action
    public getFileGroupList(): Promise<any> {
        this.isQueryData = true
        this.fileGroupList = []
        return new Promise((resolve, reject) => {
            manageApi.instanceSetApi.getFileGroupList(this.currentCode || '').then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.fileGroupList = res.data.data
                        this.isQueryData = false
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                    this.isQueryData = false
                }
                reject();
            });
        });
    }
    // 实例对象下新增文件组
    @action
    public addFileGroup(req: manageTypes.instanceSetTypes.IFileGroupItem): Promise<any> {
        return new Promise((resolve, reject) => {
            manageApi.instanceSetApi.addFileGroup(this.currentCode || '', req).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        message.success("添加成功")
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title) {
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }
    // 创建实例对象弹框显示隐藏
    @action
    public setCreatObjModal(payLoad: boolean) {
        this.creatObjModal = payLoad;
    }
    // 查询修改列表
    @action
    public setCurrList(payLoad: manageTypes.instanceSetTypes.IInstanceItem[]) {
        this.currList = payLoad;
    }
    // 当前选中基本元数据
    @action
    public setCurrBaseMeta(payLoad?: manageTypes.instanceSetTypes.IBaseMeta) {
        this.currBaseMeta = undefined;
        if (payLoad)
            this.currBaseMeta = payLoad;
    }
    // 当前选中基本元数据
    @action
    public setParentBaseMeta(payLoad?: manageTypes.instanceSetTypes.IBaseMeta) {
        this.parentBaseMeta = undefined;
        if (payLoad)
            this.parentBaseMeta = payLoad;
    }
    @action
    public setParentExpandMeta(payLoad?: manageTypes.instanceSetTypes.IBaseMeta) {
        this.parentExpandMeta = undefined;
        if (payLoad)
            this.parentExpandMeta = payLoad;
    }
    // 修改元数据弹框显示状态
    @action
    public setMetaModal(payLoad: boolean, isEditModal?: boolean) {
        this.metaModal = payLoad;
        if(!payLoad){//关闭弹窗时
            this.isEditModal = false
        }else{
            this.isEditModal = isEditModal ? true : false
        }
    }
    // 拓展元数据下业务对象弹框
    @action
    public setExpandObjModal(payLoad: boolean, isEditModal?: boolean) {
        this.expandObjModal = payLoad;
        if(!payLoad){//关闭弹窗时
            this.isEditModal = false
        }else{
            this.isEditModal = isEditModal ? true : false
        }
    }
    // 修改拓展元数据下业务对象列表
    @action
    public setCurrExpandList(payLoad: manageTypes.instanceSetTypes.IInstanceItem[]) {
        this.currExpandList = payLoad;
    }
    // 设置当前选中的拓展业务对象
    @action
    public setCurrExpandInstance(payLoad?: manageTypes.instanceSetTypes.IInstanceItem) {
        this.currExpandInstance = payLoad;
    }
    // 当前选中拓展元数据
    @action
    public setCurExpandMeta(payLoad?: manageTypes.instanceSetTypes.IBaseMeta) {
        this.currExpandMeta = payLoad; 
    }
    // 修改拓展元数据弹框显示状态
    @action
    public setExpandMetaModal(payLoad: boolean, isEditModal?: boolean) {
        this.expandMetaModal = payLoad;
        if(!payLoad){//关闭弹窗时
            this.isEditModal = false
        }else{
            this.isEditModal = isEditModal ? true : false
        }
    }
    // 修改文件组弹框显示状态
    @action
    public setFileGroupModal(payLoad: boolean) {
        this.fileGroupModal = payLoad;
    }
}
