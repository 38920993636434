import MetadataMgStore from './metadataMgStore';
import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';
import { message } from 'antd';

export default class AppsMgStore extends ExtendStore {
    @observable
    public showEditAppModal: boolean = false;
    public parentStore: MetadataMgStore;
    @observable
    public appList: manageTypes.metadata.IApplicationRes[] = []
    @observable
    public currentAppCode: string = ''
    @observable
    public isLoadingFullAppData: boolean = true
    @observable
    public isUpdate: boolean = false
    @observable
    public updateAppCode: string

    public constructor(stores: Stores, parentStore: MetadataMgStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public setShowEditAppModal(showEditAppModal: boolean){
        this.showEditAppModal = showEditAppModal;
    }

    @action
    public setCurrentAppCode = (currentAppCode: string)=>{
        this.currentAppCode = currentAppCode;
        localStorage.setItem('metadataMg_currentAppCode', currentAppCode)
    }
    @action
    public setIsUpdate = (isUpdate: boolean)=> {
        this.isUpdate = isUpdate
    }

    @action
    public setUpdateAppCode = (updateAppCode: string) => {
        this.updateAppCode = updateAppCode
    }

    @action
    public getApplication(){
        return new Promise((resolve, reject)=>{
            this.isLoadingFullAppData = true
            manageApi.metadata.getApplication().then((res)=>{
                runInAction(()=>{
                    if(res.data.success && res.data.data){
                        this.appList = res.data.data;
                        this.isLoadingFullAppData = false
                        if (this.appList && this.appList.length > 0){
                            let cacheCurrentAppCode = localStorage.getItem('metadataMg_currentAppCode')
                            this.currentAppCode = cacheCurrentAppCode || this.appList[0].applicationCode
                        }
                    }
                })
            }).catch((res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            })
        })
        
    }
}