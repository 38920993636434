import ExtendStore from "../../../base/extendStore";
import PaperlayoutStore from './extends/paperlayoutStore';
import PaperarticleStore from './extends/paperarticleStore';

export default class EntityExtendStore extends ExtendStore {
    public paperlayoutStore: PaperlayoutStore
    public paperarticleStore: PaperarticleStore

    public initPaperlayoutStore() {
        this.paperlayoutStore = new PaperlayoutStore(this.stores, this as any);
    }

    public initPaperarticleStore() {
        this.paperarticleStore = new PaperarticleStore(this.stores, this as any);
    }
}
