import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../../manageStore';
import { observable, action, runInAction } from 'mobx';
import { message } from 'antd';
import _ from 'lodash';
import manageApi from 'src/apis/manageApi';
import manageTypes from "src/types/manageTypes";

export default class AllUserStore extends ExtendStore {
    public parentStore: ManageStore;

    @observable
    public page: number = 1;
    @observable
    public size: number = 30;
    //是否正在检索用户列表
    @observable
    public isSearching: boolean = false;
    //当前选中部门的用户列表
    @observable
    public userListRes: manageTypes.userSetTypes.IGetUserListRes | undefined; 
    
    //当前选中的待编辑的用户
    @observable
    public currentUser: manageTypes.userSetTypes.IUserListItem | undefined 

    @observable
    public roleListRes: manageTypes.userSetTypes.IGetRoleListRes | undefined ; //角色列表
    
    @observable
    public showAddUserModal: boolean = false;

    //全部用户
    @observable
    public allUserListRes: manageTypes.userSetTypes.IGetUserListRes | undefined ; 
    @observable
    public keyword: string = '';
    @observable
    public isDisable: string = '-1' //状态：'-1'全部 '0'正常，'1'禁用

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public clear(){
        this.allUserListRes = undefined
    }
    @action
    public setKeyword(keyword: string){
        this.keyword = keyword
    }
    @action
    public setIsDisable(isDisable: string){
        this.isDisable = isDisable
    }

    /* 当前用户列表的页码 */
    @action
    public setPage(page: number){
        this.page = page
    }

    /* 获取所有角色 */
    @action
    public getRoleList = (): Promise<any> => {
        let req: manageTypes.userSetTypes.IGetRoleListReq = {
            pageIndex: 1,
            pageSize: 100,
            keyword: '',
            sortFields: [],
        }
        return new Promise((resolve, reject) => {
            manageApi.orgSetApi.getRoleList(req).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.roleListRes = res.data.data
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }

    /* 获取全部用户 */
    @action
    public getAllUserList = (): Promise<any> => {
        this.isSearching = true
        let req: manageTypes.userSetTypes.IGetAllUserListReq = {
            isDisable: "",
            pageIndex: this.page,
            pageSize: this.size,
            keyword: this.keyword,
            //sortFields: [],
        }
        if(this.isDisable !== "-1"){
            req.isDisable = this.isDisable === "1"
        }
        return new Promise((resolve, reject) => {
            manageApi.userSetApi.getAllUserList(req).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        if(this.stores.configStore.baseConfig.customCfg?.enableBirtvManageUserShow){
                            res.data.data.data = _.filter(res.data.data.data,o=>o.loginName!== 'admin')
                            if(this.page === 1){
                                res.data.data.data.unshift(
                                    {
                                        avatarUrl: '',
                                        createTime: '',
                                        loginName: 'sysadmin',
                                        nickName: '系统管理员',
                                        siteCode: '',
                                        userCode: 'sysadmin',
                                        email: '',
                                        disabled: 0,  //0正常 1禁用
                                        phone: '',
                                        roles: [],
                                        id: -1,
                                        operate: 0,
                                        organizations: [{children: [],description: "根部门",id: 1,operate: 0,organizationCode: "Daaa",organizationName: "全部",parentId: -1,siteCode: "S1",parentOrganization: ''}],
                                        pwdChangeTime: '',
                                        rootUserFlag: false,
                                        type: 0,
                                    },
                                    {
                                        avatarUrl: '',
                                        createTime: '',
                                        loginName: 'secadmin',
                                        nickName: '安全管理员',
                                        siteCode: '',
                                        userCode: 'secadmin',
                                        email: '',
                                        disabled: 0,  //0正常 1禁用
                                        phone: '',
                                        roles: [],
                                        id: -2,
                                        operate: 0,
                                        organizations: [{children: [],description: "根部门",id: 1,operate: 0,organizationCode: "Daaa",organizationName: "全部",parentId: -1,siteCode: "S1",parentOrganization: ''}],
                                        pwdChangeTime: '',
                                        rootUserFlag: false,
                                        type: 0,
                                    },
                                    {
                                        avatarUrl: '',
                                        createTime: '',
                                        loginName: 'secauditor',
                                        nickName: '安全审计员',
                                        siteCode: '',
                                        userCode: 'secauditor',
                                        email: '',
                                        disabled: 0,  //0正常 1禁用
                                        phone: '',
                                        roles: [],
                                        id: -3,
                                        operate: 0,
                                        organizations: [{children: [],description: "根部门",id: 1,operate: 0,organizationCode: "Daaa",organizationName: "全部",parentId: -1,siteCode: "S1",parentOrganization: ''}],
                                        pwdChangeTime: '',
                                        rootUserFlag: false,
                                        type: 0,
                                    }
                                )
                            }
                        }
                        this.allUserListRes = res.data.data
                        this.isSearching = false
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    
}
