import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import { IUser } from './userTypes';
import { IValidationErrInfo } from 'mam-core-react/dist/types/validationTypes';
import { IEntityType } from "./configTypes";

namespace uploadTypes {
    export enum TransferTypes {
        WEB = 1,
        VTUBE = 2
    }

    export enum TaskTypes {
        GENERAL = 1,     // 普通模式
        PICTUREPACKAGEUPLOAD = 3,   // 图片包上传
        GROUPUPLOAD = 2,     // 视音频分离上传
        AGREEMENT = 7,
        MOCPICTUREPACKAGEUPLOAD = 6
    }

    export interface IUploadTypeBtn {
        type: number
        title: string
        icon: string
    }

    export enum UploadModules {
        YUNPAN = 1, // 云盘
        WEBUPLOAD = 2, // web引入
        NECS = 3 // 新闻通联
    }

    export enum RelationContentType {
        NONE = 0, // 不关联其他内容
        MANUSCRIPT = 1, // 新闻通联的稿件
        DATASET = 2, // 资料集
        ATTACHMENT = 3  // 附件上传
    }

    export interface IUploadOptions {
        configInst?: any
        openModal?: (tasks: uploadTypes.ITask[], options: uploadTypes.IUploadOptions) => void
        module?: number
        taskType?: number,
        transferType?: number,
        targetFolder?: string,
        targetFolderPath?: string,
        relationContentType?: number,
        relationContentId?: string,
        loginToken?: string,
        writeMetadataBefore?: (tasks: uploadTypes.ITask[], params: IUploadOptions) => boolean
        extraField?: IFormItem[]
    }

    export interface IFile {
        id: string
        fileId?: string
        entityType?: string
        file: File | IVtubeFile
        fileName: string
        fileSize?: number
        metadata: ITaskMetadata
        progress?: number
        status?: string
        fileSizeString?: string
        surplusTime?: number
        chunkTotal?: number
        chunkIndex?: number
        image?: string
        type?: number
        isIconKeyframe?: boolean
    }

    export interface IVtubeFile {
        FilePath: string
        FileSize: number
    }

    export interface ITaskMetadataExtendMetadataInfoMetadatas{
        guid: string | undefined
        field: IFormItem[]
    }

    export interface ITaskMetadataExtendMetadataInfo{
        metadataType: string
        metadatas: ITaskMetadataExtendMetadataInfoMetadatas[]
    }

    export interface ITaskMetadata {
        field?: IFormItem[]
        name: string
        ext?: string
        isPass?: boolean
        extendMetadataInfo?: ITaskMetadataExtendMetadataInfo
        isReady?: boolean
    }
    export interface ITask {
        id: string
        taskId?: string
        entityType: string
        attachmentFiles?: IFile[]
        files?: IFile[]
        file?: File | IVtubeFile
        picImage?: string
        fileName?: string
        metadata: ITaskMetadata
        progress: number
        speed?: string
        status: string
        checked?: boolean
        sizeTotal?: number
        sizeTotalString?: string
        surplusTime?: number
        chunkTotal?: number
        chunkFinished?: number
        taskType?: number
        targetFolder?: string
        transferType?: number
        taskClientId?: string
        type?: string
        inited?: boolean
        extendData?: any
        createTime?: string
        fileTotal?: number
        isJsUpload?: boolean
        keyframe?: string
        targetFolderName?: string
        targetType?: number
        taskStatus?: number
        userCode?: string
        validErrs?: IValidationErrInfo[]
        relationDesc?: string
    }

    export interface IMetadataFieldReq {
        source: string
    }

    export interface IMetadataFieldsItem{
        metadataTypeName: string
        metadataTypeCode: string
        fields: IFormItem[]
    }
    export interface IMetadataFieldsRes {
        biz_sobey_audio: IMetadataFieldsItem[]
        biz_sobey_dataset: IMetadataFieldsItem[]
        biz_sobey_document: IMetadataFieldsItem[]
        biz_sobey_hypermedia: IMetadataFieldsItem[]
        biz_sobey_other: IMetadataFieldsItem[]
        biz_sobey_picture: IMetadataFieldsItem[]
        biz_sobey_rundown: IMetadataFieldsItem[]
        biz_sobey_script: IMetadataFieldsItem[]
        biz_sobey_video: IMetadataFieldsItem[]
    }
    export interface IPicPackageMetadataFieldRes {
        entity: IFormItem[]
        metadata: IFormItem[]
    }

    export interface IUnfinishedTaskReq {
        relationId: string
        relationContentType: number
        targetType: number
    }

    export interface IUnfinishedTaskRes extends uploadTypes.ITask {
        createTime: string
        fileTotal: number
        isJsUpload: boolean
        keyframe: string
        targetFolderName: string
        targetType: number
        taskId: string
        taskStatus: number
        userCode: string
    }

    export interface IUploadConfigUploadRule{
        audio: string
        example: string[]
        name: string
        video: string
    }
    export interface IUpoladConfig {
        // 暂时只用这个参数 没把type写完
        config: {
            uploadedRefreshSpan: number
            uploadRules: IUploadConfigUploadRule[]
        }
        currentUser: IUser  
    }

    export interface IFolderTreeItem {
        isRoot?: boolean  //增加一个是否是根节点，便于区分禁止选择
        contentId: string
        isPrivateFolder: boolean,
        name: string
        path: string
        showName: string
        children?: IFolderTreeItem[]
        parentId?: string   
        showPath?: string   //parentId和parentShowName需要自己拼接组装该节点的父级数据
    }
    export interface ITopicRes {
        pageIndex: number
        pageSize: number
        pageTotal: number
        recordTotal: number
        data:ITopicItem[]
    }
    export interface ITopicItem {
        title: string
        code: string
    }

    export interface IFilterEntityTypes extends IEntityType{
        unchecked?: boolean
    }

    export interface IUploadUrl{
        name: string
        url: string
    }

    export interface IPicExtendFieldsRes{
        metadataType: string
        fields: IFormItem[]
    }

    export interface IRelationInfo{
        contentid: string
        entitytype: string
    }

    export interface ICheckDuplicateTaskReq{
        condition: {
            name_: string
            fileSize_: number
        },
        type: 'biz_sobey_agreement'
    }

    export interface ICheckDuplicateTaskRes{
        contentId_: string
        name_: string
    }
}
export default uploadTypes
