import { BaseStore } from 'mam-core-react';
import { action, observable, runInAction } from 'mobx';
import { Stores } from 'src/stores';
import entityTypes from 'src/types/entityTypes';
import EntityStore from '../entityStore';

export default class PreviewImageStore extends BaseStore<Stores> {
    public parentStore: EntityStore;
    public imgConDom?: HTMLDivElement;
    @observable
    public currentImgIndex: number;

    @observable
    public bboxPosition?: entityTypes.IBBoxRectPos

    public constructor(stores: Stores, parentStore: EntityStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    public setImgConDom(dom: HTMLDivElement){
        this.imgConDom = dom;
    }

    @action
    public setShowBBox(pos: entityTypes.IBBoxRectPos){
        this.bboxPosition = pos;
    }

    @action
    public setHideBBox(){
        this.bboxPosition = undefined;
    }

    @action
    public setCurrentImgIndex(index: number){
        this.currentImgIndex = index;
    }
}