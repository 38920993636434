import ExtendStore from "../../../base/extendStore"
import { Stores } from 'src/stores'
import { action, observable, runInAction } from "mobx"
import contentMgTypes from "src/types/contentMgTypes"
import contentMgApi from "src/apis/contentMgApi"
import { reqCatch } from "mam-core-react"

export default class TencentRevertJobSchedule extends ExtendStore {
    @observable
    public showSearch: boolean = false
    @observable
    public fields: contentMgTypes.tencentRevertJobSchedule.IFieldItem[] = [
        {
            alias: '发起者',
            controlData: undefined,
            controlType: 5,
            fieldName: "initiator",  
            value: "",  
            nexus:"eq",
        },
        {
            alias: '任务状态',
            controlData: "{\"\": \"全部\",\"0\": \"未执行\",\"1\": \"正在执行\",\"2\": \"成功执行\",\"-1\": \"执行失败\"}",
            controlType: 8,
            fieldName: "executeStatus",  
            value: "",  
            nexus:"eq",
        },
        {
            alias: '创建时间',
            controlData: undefined,
            controlType: 16,
            fieldName: "executeTime",  
            value: "",  
            nexus:"between",
        },
        {
            alias: '完成时间',
            controlData: undefined,
            controlType: 16,
            fieldName: "finishTime",  
            value: "",  
            nexus:"between",
        }
    ]
    @observable
    public messageId: string = ''
    @observable
    public listSelectedRowKeys: any = []
    @observable
    public listSelectedRows: contentMgTypes.tencentRevertJobSchedule.IQueryData[] = []
    @observable
    public isSearching: boolean = false
    @observable
    public queryListRes: contentMgTypes.tencentRevertJobSchedule.IQueryRes
    @observable
    public showExcelExportModal: boolean = false
    public excelTaskCenterSize = 2000;

    public constructor(stores: Stores) {
        super(stores)
    }
    
    @action
    public clearSearch() {
        this.fields.map(o=>{
            o.value = ""
        })
    }

    public getQueryParam(page: number, pageSize: number){
        let param: contentMgTypes.tencentRevertJobSchedule.IQueryTaskReq = {
            conditions: [],
            page_index: page,
            page_size: pageSize,
            sort_bys: {
              field_name: 'triggerTime',
              is_desc: 'true'
            }
        }
        if (this.messageId){
            param.conditions.push({
                field_name: 'messageId',
                field_value: this.messageId,
                nexus: 'like'
            })
        }
        let qFields = this.fields.filter(item=>item.value !== '' && item.value !== '[]')
        if (qFields.length > 0){
            qFields.forEach(item=>{
                let value = item.value;
                if (item.controlType === 16){
                    let splitStr = item.value.split(',')
                    value = `${splitStr[0]} 00:00:00 TO ${splitStr[1]} 23:59:59`
                }
                else if (item.fieldName === 'executeStatus'){
                    let parseVal = ''
                    try {
                        parseVal = JSON.parse(item.value)[0]
                    }
                    catch(e){
                        parseVal = item.value
                    }
                    value = parseInt(parseVal, 10) as any
                }
                param.conditions.push({
                    field_name: item.fieldName,
                    field_value: value,
                    nexus: item.nexus as any
                })
            })
        }
        return param;
    }

    @action
    public queryList(page?: number) {
        let param = this.getQueryParam(page || 1, 100)
        contentMgApi.tencentRevertJobSchedule.queryTask(param).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.queryListRes = res.data.data;
                }
            })
        }).catch(reqCatch)
    }

    @action
    public setFieldItemValue(index: number, value: string, displayValue?: string) {
        this.fields[index].value = value
    }
    @action
    public setFieldItemNexus(index: number, value: string) {
        this.fields[index].nexus = value
    }

    @action
    public setShowSearch(showSearch: boolean) {
        this.showSearch = showSearch
    }

    @action
    public setMessageId(messageId: string) {
        this.messageId = messageId
    }

    @action
    public setListSelectedRowKeys = (selectedRowKeys: any, selectedRows: any[]) => {
        this.listSelectedRowKeys = selectedRowKeys
        this.listSelectedRows = selectedRows
    }
    @action
    public clearListSelected = () => {
        this.listSelectedRowKeys = []
        this.listSelectedRows = []
    }
    @action
    public setShowExcelExportModal = (show: boolean)=>{
        this.showExcelExportModal = show;
    }
}
