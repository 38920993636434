import { observable, action, runInAction } from 'mobx'
import { Stores } from '..'
import ExtendStore from '../base/extendStore'
import _ from 'lodash';
import entityTypes from 'src/types/entityTypes';
import entityApi from 'src/apis/entityApi';
import { message } from 'antd';
import { reqCatch } from 'mam-core-react';
import { IBasicInfo, IHypermediaRelationMetadata, INewspaperDetailParam, ISearchEntitiesReq, ISearchEntitiesRes } from 'src/types/newspaperDetailTypes';
import ccApi from 'src/apis/ccApi';
import ccTypes from 'src/types/ccTypes';
import { getHypermediaRelation } from 'src/apis/newspaperDetailApi';

export default class NewspaperDetailStore extends ExtendStore {
    @observable
    public params: INewspaperDetailParam;
    @observable
    public keyword: string = ''
    @observable
    public baseData?: entityTypes.IEntity;
    @observable
    public editionList: IHypermediaRelationMetadata[]
    @observable
    public isReqEditionList: boolean = false;
    @observable
    public selectedEdition: IHypermediaRelationMetadata
    @observable
    public basicInfo: IBasicInfo = {};
    @observable
    public entitiesRes?: ISearchEntitiesRes;
    @observable
    public isReqEntities: boolean = false;

    constructor(stores: Stores) {
        super(stores)
        this.initSelectAllStore(stores, 'stores.newspaperDetailStore.entitiesRes.data', 'selected');
        this.initDataViewModeStore(stores);
        this.initDownloadStore(stores)
        this.initOutstoreStore(stores)
        this.initShareStore(stores)
        this.dataViewModeStore.setDataViewMode(ccTypes.DataViewModes.LIST)
    }

    @action
    public setParams(params: INewspaperDetailParam){
        this.params = params;
    }

    @action
    public getBasicDatas(){
        entityApi.getBaseData(this.params.contentId, `keys=description_&keys=paperdate`).then((res) => {
            runInAction(() => {
                if (res.data.data) {
                    this.baseData = res.data.data;
                    if (res.data.data.keyframe && res.data.data.keyframe.filePath){
                        this.basicInfo.keyframePath = res.data.data.keyframe.filePath
                    }
                    if (res.data.data.entityData.paperdate){
                        this.basicInfo.paperdate = res.data.data.entityData.paperdate;
                    }
                    if (res.data.data.name){
                        let lastBlankIdx = res.data.data.name.lastIndexOf(' ');
                        let title = res.data.data.name.substring(0, lastBlankIdx);
                        let issueInfo = res.data.data.name.substring(lastBlankIdx + 1, res.data.data.name.length);
                        this.basicInfo.title = title;
                        this.basicInfo.issueInfo = issueInfo;
                    }
                    if (res.data.data.entityData.description_){
                        this.basicInfo.description = res.data.data.entityData.description_;
                    }
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        })
    }

    @action
    public getEditionList(){
        this.isReqEditionList = true;
        getHypermediaRelation(this.params.contentId).then(res=>{
            runInAction(()=>{
                if (res.data.data && res.data.data.metadata){
                    this.editionList = res.data.data.metadata;
                    if (this.editionList.length > 0){
                        this.selectedEdition = this.editionList[0]
                    }
                }
            })
        }).catch(reqCatch).finally(()=>{
            runInAction(()=>{
                this.isReqEditionList = false;
            })
        })
    }

    @action
    public setSelectedEdition(item: IHypermediaRelationMetadata){
        this.selectedEdition = item;
    }

    @action
    public setKeyword(kw: string){
        this.keyword = kw;
    }

    @action
    public searchEntities(page?: number){
        this.entitiesRes = undefined;
        this.isReqEntities = true;
        let req: ISearchEntitiesReq = {
            pageIndex: page || 1,
            pageSize: 20,
            keyword: [this.keyword],
            sortFields: [{
                field: 'createDate_',
                isDesc: true
            }],
            conditions: [],
            facetConditions: [{
                field: "model_sobey_parent_relation.contentid", 
                value: [this.params.contentId],
                isMetadataField: true
            },{
                field: "boardNum", 
                value: [this.selectedEdition.boardNum]
            }],
            albumId: this.params.contentId
        };
        ccApi.searchApi.fullSearch(req).then(res=>{
            runInAction(()=>{
                if (res.data.data && res.data.data.data.length > 0){
                    res.data.data.data.forEach((item) => {
                        item.contentId = item.contentId_;
                        item.type = item.type_;
                    });
                    this.entitiesRes = res.data.data;
                }
            })
        }).catch(reqCatch).finally(()=>{
            runInAction(()=>{
                this.isReqEntities = false;
            })
        })
    }

    @action
    public setKeyframe(url: string){
        runInAction(()=>{
            this.basicInfo.keyframePath = url;
        })
    }
}