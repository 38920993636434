import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import advancedSearchTypes from 'src/types/advancedSearchTypes';
import {IResponse} from "mam-core-react/dist/modules/http";

namespace advancedSearchApi{
    export const getFields = ():AxiosPromise<IResponse<advancedSearchTypes.IField[]>> => {
        return http.get<IResponse<advancedSearchTypes.IField[]>>('/scntm/v1/search/config/advanced-search-conditions');
    }

    export const getAdvancedSearchConfig = ():AxiosPromise<IResponse<advancedSearchTypes.IFeildConfigItem[]>> => {
        return http.get<IResponse<advancedSearchTypes.IFeildConfigItem[]>>('/scntm/v1/configuration-center/manager/search/advanced-search-condition');
    }

    export const saveAdvancedSearchConfig = (param: advancedSearchTypes.IFeildConfigItem[]):AxiosPromise<IResponse<any>> => {
        return http.put<IResponse<any>>('/scntm/v1/configuration-center/manager/search/advanced-search-condition', param);
    }
}
export default advancedSearchApi