import {action, observable, runInAction} from "mobx";
import ccTypes from "../../../../types/ccTypes";
import ccApi from "../../../../apis/ccApi";

export default class FilterTemplateStore{
    @observable
    public userHabits: ccTypes.IUserHabitHabitValue[];
    @observable
    public moduleName: string
    public searchKey: string;

    @action
    public setModuleName(name: string){
        this.moduleName = name;
    }

    @action
    public getUserHabit(searchKey: string) {
        this.searchKey = searchKey;
        ccApi.getUserHabit(searchKey).then((res) => {
            runInAction(() => {
                if (res.data.data) {
                    res.data.data.forEach((item) => {
                        this.userHabits = JSON.parse(item.habitValue as string);
                    })
                }
            })
        });
    }
}