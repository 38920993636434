import { http } from 'mam-core-react'
import { AxiosPromise } from 'axios'
import bizTypes from 'src/types/bizTypes'
import { IResponse } from 'mam-core-react/dist/modules/http'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import iSearchTypes from 'src/types/iSearchTypes'

namespace bizApi {
    export namespace common{
        export namespace subscribe{
            export const addSubscribe = (params: bizTypes.common.subscribe.IAddSubscribeReq):AxiosPromise<IResponse<any>> => {
                return http.post<IResponse<any>>('/subscribe/add', params)
            } 
            export const removeSubscribe = (key: string, value: string):AxiosPromise<IResponse<any>> => {
                return http.delete(`/subscribe?key=${key}&value=${value}`)
            } 
            export const subscribeCheck = (params: bizTypes.common.subscribe.ISubscribeCheckReq):AxiosPromise<IResponse<string[]>> => {
                return http.post<IResponse<string[]>>('/subscribe/check', params, {
                    headers: {hideLoading: true}
                })
            }
        }
    }
    export namespace publish{
        export const publishEntitys = (contentIds: string[]):AxiosPromise<IResponse<bizTypes.publish.IPublishRes[]>> => {
            return http.post<IResponse<bizTypes.publish.IPublishRes[]>>('/release', contentIds)
        }
        export const cancelPublishEntitys = (contentIds: string[]):AxiosPromise<IResponse<bizTypes.publish.IPublishRes[]>> => {
            return http.post<IResponse<bizTypes.publish.IPublishRes[]>>('/release/cancle', contentIds)
        }
    }
    export namespace album{
        export const getAlbumMetadataFields = ():AxiosPromise<IResponse<bizTypes.createAlbum.IGetAlbumMetadataFieldRes>> => {
            return http.get<IResponse<bizTypes.createAlbum.IGetAlbumMetadataFieldRes>>(`/scntm/v1/metadata/fields/upload/biz_sobey_album`)
        }
        export const createAlbum = (params: bizTypes.createAlbum.ICreateAlbumReq):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/album', params)
        }
        export const queryAlbumManual = (params: bizTypes.createAlbum.IAlbumManualReq):AxiosPromise<IResponse<iSearchTypes.IFullSearchDataRes>> => {
            let qUrl = '';
            for (let key in params){
                qUrl += `&${key}=${params[key]}`;
            }
            qUrl = qUrl.substring(1);
            return http.get<IResponse<iSearchTypes.IFullSearchDataRes>>('/smcss/v1/album/manual?' + qUrl);
        }
        export const addRelation = (params: bizTypes.createAlbum.IAddRelationReq):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/album/relation/add', params)
        }
        export const moveEntities = (params: bizTypes.createAlbum.IMoveEntities):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/album/relation/move', params)
        }
    }
    export namespace createSeries{
        export const getSeriesMetadataFields = ():AxiosPromise<IResponse<bizTypes.createSeries.IGetSeriesMetadataFieldRes>> => {
            return http.get<IResponse<bizTypes.createSeries.IGetSeriesMetadataFieldRes>>(`/scntm/v1/metadata/fields/upload/biz_sobey_series`)
        }
        export const createSeries = (params: bizTypes.createSeries.ICreateSeriesReq):AxiosPromise<IResponse<string>> => {
            return http.post<IResponse<string>>('/scntm/v1/entity', params)
        }
        export const addRelation = (params: bizTypes.createSeries.IAddRelationReq):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/entity/relation/parent-children', params)
        }
        export const uploadPoster = (formData: FormData): AxiosPromise<IResponse<string>> => {
            return http.post(`/sflud/v1/upload/SmallFile`, formData, {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    hideLoading: true
                },
            })
        }
    }
    export namespace mocTopic{
        export const getTopicMetadataFields = ():AxiosPromise<IResponse<bizTypes.createMocTopic.IGetTopicMetadataFieldRes>> => {
            return http.get<IResponse<bizTypes.createMocTopic.IGetTopicMetadataFieldRes>>(`/scntm/v1/metadata/fields/upload/biz_sobey_album`)
        }
        export const createTopic = (params: bizTypes.createMocTopic.ICreateTopicReq):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/moc-album', params)
        }
        export const addRelation = (params: bizTypes.createMocTopic.IAddRelationReq):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/moc-album/relation/add', params)
        }
    }
    export namespace push{
        export const pushEntity = (params:string[]):AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>(`/scbdp/v1/configuration-center/admin/carousel`, params)
        }
    }
}

export default bizApi