import BaseStore from "../../base/baseStore";
import { action, observable, runInAction, computed } from "mobx";
import { Stores } from "../../index";
import mmsTypes from "../../../types/mmsTypes";
import mmsApi from "../../../apis/mmsApi";
import EntityTableStore from "./entityTableStore";
import * as React from "react";
import _ from 'lodash';
import playerUtil from "mam-common-utils/dist/modules/playerUtil";
import MetadataForm from "mam-metadata-react/dist/metadataForm";
import { message } from 'antd';
import { confirmTipCopyrightModel, warningTipCopyrightModel } from "src/components/biz/tipCopyrightModal/tipCopyrightModal";
import { commonUtil } from "mam-common-utils";
import { http } from "mam-core-react";
import ITreeItem from "mam-metadata-react/dist/types/ITreeItem";

export interface IOutstoreStore {
    outstoreStore: OutstoreStore;

    initOutstoreStore: (stores: Stores) => void;
}

export interface IOutstoreItemType {
    contentId: string
    type?: string
    type_?: string
    inpoint?: number
    outpoint?: number
    frameRate?: number
    storyid?: string
    sceneid?: string
    shotid?: string
    canEdit?: boolean
    check_code?: string
    extensions?:{
        canEdit: boolean
    }
    operatePermission?: {
        canWrite: boolean
    }
}

export default class OutstoreStore extends BaseStore<Stores> {
    public entityTableStore: EntityTableStore;
    @observable
    public visible: boolean;
    @observable
    public visibleCanpin: boolean;
    @observable
    public items: IOutstoreItemType[];
    @observable
    public externalsystemList: mmsTypes.exportTypes.IGetExternalsystem[];
    @observable
    public transcodeTemplateList: mmsTypes.exportTypes.IGetTranscodeTemplate[];
    /** 当前选中的外部系统 */
    @observable
    public selectedSystem: mmsTypes.exportTypes.IGetExternalsystem | undefined;
    /** 当前选中的外部系统（多选），需要开启多选开关后，这个参数才会生效使用 */
    @observable
    public selectedMultiSystem: mmsTypes.exportTypes.IGetExternalsystem[] | undefined;
    @observable
    public entitys: mmsTypes.exportTypes.IGetEntityInfo[];
    @observable
    public interactionFieldsForm: React.RefObject<MetadataForm>;
    @observable
    public errorModalEnable: boolean
    @observable
    public errorModalInfo: mmsTypes.IErrorTableInfo[] = []
    @observable
    public currentCopyrightInfo: mmsTypes.exportTypes.ICopyRightInfo;
    @observable
    public applyReason?: string
    //天津定制是否是参评
    @observable
    public businessType?: string

    constructor(stores: Stores) {
        super(stores);
        this.entityTableStore = new EntityTableStore(this);
        this.interactionFieldsForm = React.createRef();
    }

    @action
    public openModal(items: IOutstoreItemType[],businessType?:string) {
        this.businessType = businessType
        this.items = items;
        this.getExternalsystem(businessType);
        this.getTranscodeTemplate().then(()=>{
            runInAction(()=>{
                if(businessType === 'canpin'){
                    this.visibleCanpin = true;
                }else{
                    this.visible = true;
                }
            })
        });
    }
    @action
    public hideModal() {
        if(this.businessType === 'canpin'){
            this.visibleCanpin = false;
        }else{
            this.visible = false;
        }
        //关闭弹窗，清空列表选中
        this.stores.searchStore.selectAllStore.switchAllSelected(false);
        this.stores.searchStore.selectAllStore.switchListItemSelected(undefined, false);
    }

    @action
    public getExternalsystem(businessType?:string) {
        mmsApi.exportModal.getExternalsystem(businessType).then(res => {
            runInAction(() => {
                if (res.data.data) {
                        this.externalsystemList = res.data.data.filter((item)=>{
                            return item.exportConfigureInfo.enableExport;
                        });
                    if (this.externalsystemList && this.externalsystemList.length > 0) {
                        // 默认选中出库系统
                        let sSelectedSystemId = localStorage.getItem('externalSystemID');
                        if (sSelectedSystemId) {
                            this.selectedSystem = this.externalsystemList.find(item => {
                                return item.externalSystemID === sSelectedSystemId
                            })
                        }
                        if (!this.selectedSystem) {
                            this.selectedSystem = this.externalsystemList[0];
                        }
                        // 多选模式
                        if (this.stores.configStore.baseConfig.functionSwitch.outstore.enableMultiSelectExternalSystem){
                            this.selectedMultiSystem = [this.externalsystemList[0]]
                        }

                        this.externalsystemList.forEach((system) => {
                            if (system.exportConfigureInfo.exportSupportType) {
                                system.exportConfigureInfo.exportSupportTypeArr = JSON.parse(system.exportConfigureInfo.exportSupportType);
                            }
                            if (system.exportConfigureInfo.extendParameter)//扩展参数
                            {
                                system.exportConfigureInfo.extendParameterObj = {};
                                const extendParam = JSON.parse(system.exportConfigureInfo.extendParameter);
                                extendParam.forEach((param: any) => {
                                    system.exportConfigureInfo.extendParameterObj[param.key] = param.value;
                                })
                            }
                        })
                        
                        this.getCategoryTreeByApi()
                    }
                    else {
                        message.error('外部系统列表为空！请先配置外部系统'.l('modal.outstoreSysIsNullPlzSetting'));
                    }
                }
            })
        });
    }

    @action
    public getTranscodeTemplate() {
        return new Promise((resolve, reject)=>{
            mmsApi.exportModal.getTranscodeTemplate().then(res => {
                runInAction(() => {
                    if (res.data.data) {
                        this.transcodeTemplateList = res.data.data;
                        if (this.transcodeTemplateList && this.transcodeTemplateList.length > 0) {
                            this.entityTableStore.setAllTranscodeTemplateID(this.transcodeTemplateList[0].transcodeTemplateID);
                        }
                        this.getEntityInfo().then(()=>{
                            resolve(undefined)
                        }, ()=>{
                            reject()
                        });
                    }
                    else {
                        reject()
                    }
                })
            })
        })
    }

    @action
    public getEntityInfo() {
        return new Promise((resolve, reject)=>{
            const request = {
                "contentIds": new Array<string>()
            }
            // 用于获取权限
            const itemDict = {}
            this.items.forEach(s => {
                s.canEdit = s.canEdit || (s.extensions && s.extensions.canEdit) || (s.operatePermission && s.operatePermission.canWrite)
                itemDict[s.contentId] = s
                request.contentIds.push(s.contentId)
            })
            mmsApi.exportModal.getEntityInfo(request).then(res => {
                runInAction(() => {
                    if (res.data.success) {
                        if (res.data.data && res.data.data.exportItems) {
                            res.data.data.exportItems.forEach((item, index) => {
                                const currentType = item.entityType;
                                const currentTemplates = this.loopMatchTemplate(currentType);
    
                                //获取多音轨信息
                                const videoGroup = _.find(item.groupInfos, { groupName: 'videogroup' });
    
                                // 同一个视频，多个不同片段同时出库，用上面方式获取originalItem有问题
                                let originalItem: IOutstoreItemType = this.items[index];
    
                                item.entity = {
                                    contentId: item.contentId,
                                    title: item.entityName,
                                    type: item.entityType,
                                    typeName: item.entityType,
                                    templates: currentTemplates,
                                    transcodetemplate: (currentTemplates[0]) ? currentTemplates[0].transcodeTemplateID : "",
                                    oldTitle: item.entityName,
                                    timeCode: item.timeCode,
                                    frameRate: this.items[index].frameRate,
                                    storyid: this.items[index].storyid,
                                    sceneid: this.items[index].sceneid,
                                    shotid: this.items[index].shotid,
                                    partType: this.items[index].type,
                                    canWrite: itemDict[item.contentId] && itemDict[item.contentId].canEdit,
                                    check_code: originalItem.check_code
                                }
                                //如果是片段导出，包含type_字段，表示是检索页发起的出库
                                if ((!originalItem.type_ || originalItem.type_.indexOf('biz_sobey') !== 0)
                                    && originalItem.inpoint !== undefined && originalItem.outpoint !== undefined && originalItem.inpoint !== -1 && originalItem.outpoint !== -1) {
                                    item.entity.inpoint = originalItem.inpoint;
                                    item.entity.outpoint = originalItem.outpoint;
                                    item.entity.duration = originalItem.outpoint - originalItem.inpoint;
                                }
                                item.audioTracks = videoGroup ? videoGroup.fileItems.filter((fitem) => {
                                    return fitem.track && fitem.track.startsWith('A');
                                }).map((fitem1) => {
                                    return fitem1.track;
                                }) : undefined
                            })
                            this.entitys = res.data.data.exportItems;

                            // 版权过期提醒
                            if (res.data.data.copyright){
                                if (res.data.data.exportItems.length > 0){
                                    confirmTipCopyrightModel('选中的素材包含版权快过期或已过期，确定是否继续出库？', res.data.data.copyright).then(()=>{
                                        resolve(undefined)
                                    }, ()=>{
                                        reject()
                                    })
                                }
                                else {
                                    warningTipCopyrightModel('选中的素材均已过期，无法发起出库', res.data.data.copyright)
                                    reject()
                                }
                            }
                            else {
                                resolve(undefined)
                            }
                        }
                    }
                    else {
                        reject()
                    }
                })
            })
        })
    }

    @action
    public changeExternalSystem(externalSystemID: string) {
        localStorage.setItem('externalSystemID', externalSystemID);
        this.selectedSystem = _.find(this.externalsystemList, { 'externalSystemID': externalSystemID });
        //切换外部系统后，转码模板下拉重新生成
        if(this.entitys && this.entitys.length > 0){
            this.entitys.forEach((item, index)=>{
                const currentType = item.entityType;
                const currentTemplates = this.loopMatchTemplate(currentType);
                item.entity.templates = currentTemplates;
                item.entity.transcodetemplate = (currentTemplates[0]) ? currentTemplates[0].transcodeTemplateID : ""
            })
        }
        this.getCategoryTreeByApi()
    }

    @action
    public setSelectedMultiSystem(systems?: mmsTypes.exportTypes.IGetExternalsystem[]){
        this.selectedMultiSystem = systems;
    }

    /** 判断素材是否支持选择的外部系统 */
    @action
    public isEntitySupportSystem(entityInfo: mmsTypes.exportTypes.IGetEntityInfo) {
        let support = false;
        if (this.selectedSystem) {
            this.selectedSystem.exportConfigureInfo.exportSupportTypeArr?.forEach((type) => {
                if (entityInfo.entity.type === type.code) {
                    support = true;
                }
            })
        }
        return support;
    }

    @action
    public selectTracks(entityInfo: mmsTypes.exportTypes.IGetEntityInfo, vals: string[]) {
        entityInfo.selectedTracks = vals;
    }

    @action
    public showErrorModal(show: boolean) {
        this.errorModalEnable = show
    }

    @action
    public openErrorModal(errorList: Array<{ contentId: string, entityName: string, message: string }>) {
        const datas: Array<{ name: string, reason: string, contentId: string }> = [];
        errorList.forEach((s: { contentId: string, entityName: string, message: string }, index: number) => {
            const message = s.message.split(':').join('包含敏感信息"'.l('modal.outstoreSensitiveJoin')) + '"';
            let data = datas.find(o => o.contentId === s.contentId);
            if (data) {
                data.reason += '，' + message;
            } else {
                data = {
                    name: s.entityName,
                    reason: message,
                    contentId: s.contentId
                };
                datas.push(data);
            }
            if (index === errorList.length - 1) {
                data.reason += '。';
            }
        });

        if (datas.length > 1) {
            const errorInfos = [{
                tip: '以下素材检测到敏感信息，出库失败'.l('modal.outstoreSensitiveTitle'),
                tableTitle: '素材名'.l('entity.name'),
                tableReason: '敏感信息'.l('entity.sensitive'),
                datas
            }];
            this.errorModalInfo = errorInfos;
            this.showErrorModal(true)
        }
        return datas
    }

    @action
    public openCopyright = (item: mmsTypes.exportTypes.IGetEntityInfo) => {
        mmsApi.CopyrightModal.getFields(item.contentId).then(res => {
            runInAction(() => {
                if (res.data && res.data.data) {
                    this.currentCopyrightInfo = {
                        name: item.entityName,
                        contentId: item.contentId,
                        createDate: item.createDate,
                        createUser: item.createUser,
                        metadata: res.data.data
                    }
                }
            })
        })
    }
    /** 申请原因 */
    @action
    public setApplyReason = (applyReason: string) => {
        this.applyReason = applyReason;
    }

    /** 
     * 获取多码率信息，拼接成字符串，分号分割
     * 
     * @param type id:获取id；name:获取name
     */
    public getMultiTransTemplateInfo = (type: string) => {
        let str = '';
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate) {
            const exportTranscodeTemplate = JSON.parse(this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate);
            exportTranscodeTemplate.forEach((template: any) => {
                let obj = _.find(this.transcodeTemplateList, { transcodeTemplateName: template.transcodeTemplateName });
                if (obj) {
                    if (type === 'id') {
                        str += obj.transcodeTemplateID + ';';
                    }
                    else {
                        str += obj.transcodeTemplateName + ';';
                    }
                }
            });
            if (str.length > 0) {
                str = str.substring(0, str.length - 1);
            }
        }
        return str;
    }

    private loopMatchTemplate(type: string): mmsTypes.exportTypes.IGetTranscodeTemplate[] {
        let configTransTmpList: mmsTypes.exportTypes.IGetTranscodeTemplate[] | undefined;//外部系统配置支持的转码模板
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo 
            && this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate){
            configTransTmpList = JSON.parse(this.selectedSystem.exportConfigureInfo.exportTranscodeTemplate);
        }
        let ts: any[] = []
        for (let i = 0; i < this.transcodeTemplateList.length; i++) {
            for (let j = 0; j < this.transcodeTemplateList[i].supportType.length; j++) {
                if (this.transcodeTemplateList[i].supportType[j] === type) {
                    if (configTransTmpList && configTransTmpList.length > 0){
                        if (_.findIndex(configTransTmpList, {transcodeTemplateID : this.transcodeTemplateList[i].transcodeTemplateID}) > -1){
                            ts.push(this.transcodeTemplateList[i])
                            break
                        }
                    }
                }
            }
        }
        if (!ts || ts.length === 0){//为空默认添加原格式
            ts = [
                {
                    transcodeTemplateID: '1',
                    transcodeTemplateName: '原格式',
                    supportType: [type]
                }
            ]
        }
        return ts
    }

    @action
    private getCategoryTreeByApi = () => {
        // 给js表达式让eval使用
        const user = this.stores.userStore.currentUser;
        const config = this.stores.configStore.config;
        if (this.selectedSystem && this.selectedSystem.exportConfigureInfo
             && this.selectedSystem.exportConfigureInfo.interactionFields) {
            this.selectedSystem.exportConfigureInfo.interactionFields.forEach((item, index) => {
                if (Number(item.controlType) === 404) {
                    item.controlType = 14
                    item.isShow = false
                    if (item.value && this.selectedSystem) {
                        let url = String(item.value);
                        let controlData = '';
                        try {
                            url = commonUtil.evalSyntax(url, ['user', 'config'], [user, config]);
                        } catch (error) { }
                        const isDefaultSelectFirst = commonUtil.getQueryParam(url, 'isDefaultSelectFirst');
                        let newInteractionFields = commonUtil.copyObj(this.selectedSystem.exportConfigureInfo.interactionFields);
                        http.get(url).then(res => {
                            runInAction(()=>{
                                if (res.data.data && _.isArray(res.data.data)) {
                                    const columns = res.data.data;
                                    const columnsTree = this.bindColumnTree(columns, {
                                        id: 0,
                                        categoryCode: '',
                                        categoryName: '',
                                        categoryParent: '',
                                        categoryType: '',
                                        children: []
                                    }, '').children;
                                    controlData = JSON.stringify(columnsTree);
                                    if (columnsTree.length && this.selectedSystem) {
                                        newInteractionFields[index].isShow = true
                                        newInteractionFields[index].controlData = controlData;
                                        newInteractionFields[index].value = '';
                                        if (isDefaultSelectFirst === 'true' && columns.length > 0){
                                            newInteractionFields[index].value = columns[0].code
                                        }
                                        this.selectedSystem.exportConfigureInfo.interactionFields = newInteractionFields;
                                    }
                                }
                            })
                        }, err => {
                            console.log(err);
                        });
                    }
                }
            })
        }
    }

    private bindColumnTree(columns: mmsTypes.exportTypes.ICategoryApiTree[], parent: ITreeItem, parentCode: string) {
        const haveChild = columns.find(s => s.parentCode === parentCode);
        if (haveChild || !parentCode) {
            columns.forEach(c => {
                const isFirstTree = !parentCode && !c.parentCode;
                if ((isFirstTree || c.parentCode === parentCode) && c.code) {
                    const tree: ITreeItem = {
                        "children": [],
                        "id": 0,
                        "categoryCode": (!parentCode ? '' : (parentCode + '/')) + c.code,
                        "categoryName": c.name,
                        "categoryParent": parentCode || '',
                        "categoryType": ''
                    };
                    parent.children.push(tree);
                    this.bindColumnTree(columns, tree, c.code);
                }
            });
        }
        return parent;
    }
}
