import { AxiosPromise } from "axios";
import { http } from "../utils/http";
import { IResponse } from "mam-core-react/dist/modules/http";
import manageTypes from "src/types/manageTypes";
import copyrightType from 'src/types/copyrightTypes'

namespace copyrightApi {
    //获取左侧初始数据
    export const getFullSearchData = (requestParam: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/search/full-search', requestParam);
    }

    //获取左侧初始数据
    export const getFacetsData = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/scntm/v1/configuration-center/manager/copyright/facet');
    }

    // 获取左侧精确筛选数据
    export const getFieldsData = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/scntm/v1/copyright/advanced-search-conditions');
    }

    // 获取右侧排序
    export const getConfigSort = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/copyright/config');
    }

    // 获取右侧列表数据
    export const getFullSearchTableData = (value: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/copyright/search', value);
    }

    // 获取左侧弹出框树形结构数据
    export const getLeftTreeData = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/organization/get-organization-all-subs?code=');
    }

    // 获取左侧弹出框入库人员
    export const getLeftPersonData = (value: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/organization/get-organizations-users', value);
    }

    // 获取弹出框元数据
    export const getModalMetadata = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>(`/scntm/v1/copyright/${contentId}`);
    }

    // 获取弹出框元数据
    export const getModalMetadataMult = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/copyright/getCopyrightFields');
    }

    // 更新元数据
    export const updateMetadata = (value: any): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>('/scntm/v1/copyright', value);
    }

    // 批量更新元数据
    export const updateMetadataMult = (value: any): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/copyright/batchUpdate', value);
    }

    export namespace manageApi {
        // 获取所有层面
        export const getAllFeacts = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>('/scntm/v1/configuration-center/manager/copyright/facet');
        }
        //层面操作
        export const addOrEditFeacts = (param: any): AxiosPromise<IResponse<any>> => {
            return http.put<IResponse<any>>('/scntm/v1/configuration-center/manager/copyright/facet', param);
        }

        // 获取所有高级检索
        export const getAllConditions = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>('/scntm/v1/configuration-center/manager/copyright/advanced-search-condition');
        }
        //高级检索操作
        export const addOrEditConditions = (param: any): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>('/scntm/v1/configuration-center/manager/copyright/advanced-search-condition', param);
        }
        //元数据获取
        export const getMetadataField = (): AxiosPromise<IResponse<any>> => {
            return http.get<IResponse<any>>('/scntm/v1/configuration-center/manager/copyright/metadata-fields');
        }
        //元数据新增
        export const addMetadataField = (params: any): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/configuration-center/manager/copyright/metadata-fields', params);
        }
        //元数据更新
        export const updateMetadataField = (params: manageTypes.metadata.IMetadataSource): AxiosPromise<IResponse<any>> => {
            return http.patch<IResponse<any>>('/scntm/v1/configuration-center/manager/copyright/metadata-fields', params);
        }
        //元数据删除
        export const deleteMetadataField = (fieldIds: number[]): AxiosPromise<IResponse<any>> => {
            let ids = '';
            fieldIds.forEach((id) => {
                ids += 'ids=' + id + '&'
            })
            ids = ids.substring(0, ids.length - 1)
            return http.delete('/scntm/v1/configuration-center/manager/copyright/metadata-fields?' + ids);
        }
        //元数据排序
        export const orderMetadataField = (params: any[]): AxiosPromise<IResponse<any>> => {
            return http.patch('/scntm/v1/configuration-center/manager/copyright/metadata-fields/order', params);
        }
        //元数据排序
        export const getMetadataAllField = (combinResourceType: boolean): AxiosPromise<IResponse<any>> => {
            let url = '/scntm/v1/configuration-center/manager/resource/fields?resourceTypes=entityData'
            if (!combinResourceType) {
                url += '&combinResourceType=false'
            }
            return http.get(url);
        }

        //版权限制
        export const getCopyrightConfig = (): AxiosPromise<IResponse<any>> => {
            return http.get('/scntm/v1/configuration-center/manager/copyright/config');
        }
        //版权限制
        export const updateCopyrightConfig = (params: any): AxiosPromise<IResponse<any>> => {
            return http.patch('/scntm/v1/configuration-center/manager/copyright/config', params);
        }
    }

    export namespace agreementApi {
        // 获取所有版权书
        export const getAgreement = (pramas: copyrightType.IAgreementPramas): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/smcss/v1/search', pramas);
        }

        // 获取所有版权书
        export const delAgreement = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
            return http.post<IResponse<any>>('/scntm/v1/recycle', contentIds);
        }
    }
}

export default copyrightApi