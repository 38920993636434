import CcStore from "../ccStore";
import { action, observable, runInAction } from "mobx";
import ccTypes from "../../../types/ccTypes";
import ccApi from "../../../apis/ccApi";
import { Stores } from "../../index";
import _ from 'lodash'
import commonUtil from "mam-common-utils/dist/modules/commonUtil";
import { message } from 'antd';
import CloudEntityStore from './cloudEntityStore';

export default class CloudStore extends CcStore {
    public cloudEntityStore: CloudEntityStore;

    @observable
    public params: ccTypes.cloudTypes.ICloudParams;
    @observable
    public fullSearchData?: ccTypes.searchTypes.IFullSearchRes;
    // 主搜索框的值
    @observable
    public clouldKeyword1: string = ''
    // 主搜索框前面的选项
    @observable
    public clouldKeyword1Type: string
    @observable
    public pageSize: number = 30;
    @observable
    public pageSizes: number[] = [30,60,100]

    constructor(stores: Stores) {
        super(stores);
        this.initSelectAllStore(stores, 'stores.cloudStore.fullSearchData.data', 'selected');
        this.initUploadStore(stores);
        this.initUploadPanelStore(stores);
        this.initOutstoreStore(stores);
        this.initProcessStore(stores);
        this.initDeleteStore(stores);
        this.initCreatePlanningStore(stores);
        this.initMoveStore(stores);
        this.initUnlockStore(stores);
        this.initRenameStore(stores);
        this.initShareStore(stores);
        this.initCopyStore(stores);
        this.initCreateFolderStore(stores);
        this.initJoinGroupStore(stores)
        this.cloudEntityStore = new CloudEntityStore(stores);
    }

    @action
    public setParams(params: ccTypes.cloudTypes.ICloudParams) {
        this.params = params;
    }

    @action
    public queryFullSearchData(page?: number): Promise<any> {
        this.selectAllStore.switchAllSelected(false);
        this.fullSearchData = undefined;
        let param = this.params || {};
        return new Promise((resolve, reject) => {
            this.getSortItems();
            let req: ccTypes.cloudTypes.IFullSearchReq = {
                pageIndex: page || param.page,
                pageSize: this.pageSize,
                sortFields: [{
                    field: param.sort ? param.sort : this.currentSort.field,
                    isDesc: param.order ? param.order === 'desc' : this.currentSort.desc === 'desc'
                }],
                keyword: param.keyword ? [param.keyword] : [],
                folderId: param.folderCode,
                type: param.type,
                name: param.name ? param.name : '',
                conditions: [],
                facetConditions: param.facetConditions ? param.facetConditions.map(o => {
                    return {
                        field: o.field || '',
                        value: o.items ? o.items[0].value : (o.value ? o.value : '')
                    };
                }) : [],
                isQueryDirectSub: false
            };
            if (this.params.advancedSearch) {
                this.params.advancedSearch.forEach((field)=>{
                    let value: string = field.value as string;
                    if (!value){
                        return;
                    }
                    if (field.controlType === 16 || field.controlType === 23){
                        let valArr = value.split(',');
                        value = `${valArr[0] ? valArr[0] + ' 00:00:00' : ''} ~ ${valArr[1] ? valArr[1] + ' 23:59:59' : ''}`
                    }
                    req.conditions.push({ field: field.fieldCode, value: [value], searchRelation: field.searchRelation, isMetadataField: field.isMetadataField })
                })
            }
            //如果有关键词或高级检索条件，则是否查询子文件夹参数isQueryDirectSub变true
            if(param.keyword || req.conditions.length > 0){
                req.isQueryDirectSub = true
            }
            if(this.currentSort.field === "default"){//相关度排序时不传排序
                req.sortFields = []
            }
            ccApi.cloudApi.fullSearch(req).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        this.initFacets(res.data.data.facets);
                        if (res.data.data.data) {
                            this.resolveQueryResult(res.data.data.data, (item) => {
                            });
                        }
                        this.fullSearchData = res.data.data;
                        this.fullSearchData.data.forEach(item => {
                            item.selected = false
                        })
                        // this.isFirstLevel = this.fullSearchData ? this.fullSearchData.parent.length === 1 : false
                    }
                });
                resolve(true);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            })
        });
    }

    /**
     * 重命名
     */

    @action
    public switchRenameStatus(index: number, editMode: boolean) {
        if (this.fullSearchData) {
            if (editMode) {
                this.fullSearchData.data[index].oldName = this.fullSearchData.data[index].name_
            }
            this.fullSearchData.data[index].editMode = editMode
        }
    }

    @action
    public updateShowName(index: number, newName: string) {
        if (this.fullSearchData) {
            this.fullSearchData.data[index].name_ = newName
        }
    }

    @action
    public setClouldKeyword1(keyword: string) {
        this.clouldKeyword1 = keyword
    }

    @action
    public setClouldKeyword1Type(type: string) {
        this.clouldKeyword1Type = type
    }

    @action
    public addNewFullSearchData(fullSearchData: any) {
        if (this.fullSearchData) {
            this.selectAllStore.switchAllSelected(false)
            this.selectAllStore.switchListItemSelected(undefined, false)
            if (!fullSearchData.isCreate) {
                setTimeout(() => {
                    runInAction(() => {
                        //延迟执行 不然会刷不出来
                        this.queryFullSearchData().then(() => {
                            this.cloudEntityStore.initTree()
                        })
                    })
                }, 500)
            } else {
                runInAction(() => {
                    if (this.fullSearchData) {
                        const fullSearchData1 = commonUtil.copyObj(this.fullSearchData)
                        fullSearchData1.data.unshift(fullSearchData)
                        this.fullSearchData = fullSearchData1
                    }
                })
            }
        }
    }

    @action
    public refresh() {
        this.queryFullSearchData()
    }

    @action
    public create = (model: any) => {
        this.selectAllStore.switchAllSelected(false)
        this.selectAllStore.switchListItemSelected(undefined, false)
        if (this.fullSearchData) {
            this.addNewFullSearchData(model)
        }
    }

    /** 设置收藏状态 */
    @action
    public setItemFavorite(item: any, favorite: boolean, favoriteObjId?: string){
        if (this.fullSearchData && this.fullSearchData.data){
            let target = _.find(this.fullSearchData.data, {contentId: item.contentId});
            if (target){
                if (favoriteObjId){
                    target.favoriteObjId = favoriteObjId;
                }
                target.isFavorite = favorite;
            }
        }
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
        localStorage.setItem('cloudStore.pageSize', size.toString());
        this.queryFullSearchData(1).then(()=>{});
    }

    @action
    public setPageSizes(sizes: number[]){
        this.pageSizes = sizes;
    }

    public getCurrentFolderCode(){
        return this.params.folderCode;
    }

    public isPublicFolder(){
        return this.params.isPublic || false;
    }

    public isRoot(){
        return this.cloudEntityStore.isRoot(this.getCurrentFolderCode())
    }

    public createPrivateFolder(){
        let model: any = {
            id: '',
            site: '',
            site_: '',
            contentId_: '',
            contentId: '',
            name_: (window as any).l('cloud.create', '新建文件夹'),
            name: (window as any).l('cloud.create', '新建文件夹'),
            createDate_: '',
            createUser_: '',
            createdate: '',
            creator: '',
            catalogstate: '',
            type_: 'folder',
            type: 'folder',
            aspect_: 0,
            filesize: 0,
            fileSize_: 0,
            size_: 0,
            tree_path_: '',
            treePath: '',
            formatFlag: 0,
            trimin: '',
            trimout: '',
            frameRate: 0,
            durationTc: '',
            distributionMark: '',
            isCollected: false,
            siteName: '',
            isPickedup: false,
            isPlaceholder: 0,
            isPublish: 0,
            keyframepath: '',
            keyframe: '',
            privilege_: '',
            permissionInfo: {
                canRead: false,
                canWrite: false,
                canExecute: false,
                canDelete: false
            },
            resourceType: '',
            archiveList: [],
            barcodes: [],
            meterialLabel: [],
            duration: '',
            isFavorite: false,
            storagedDate: '',
            storagedUser: '',
            isMy: false,
            canRead: false,
            canWrite: true,
            canExecute: false,
            canDelete: false,
            fileExt: '',
            isCreate: true,
            editMode: true,
            parentId: this.getCurrentFolderCode()
        }
        this.create(model)
    }

    public getParent = () => {
        // if (this.fullSearchData && this.fullSearchData.parent && this.fullSearchData.parent.length > 0) {
        //     return this.fullSearchData.parent[this.fullSearchData.parent.length - 1]
        // }
        // return {
        //     canDelete: false,
        //     canExecute: false,
        //     canRead: false,
        //     canWrite: false,
        //     contentId: '',
        //     treeType: 1
        // }
    }
}
