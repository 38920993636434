import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import { IResponse } from "mam-core-react/dist/modules/http";
import datasetDetailsTypes from "../types/datasetDetailsTypes";

namespace datasetDetailsApi {
    export const getTreeRoot = (contentId: string): AxiosPromise<IResponse<datasetDetailsTypes.ITreeItem>> => {
        return http.get(`/scntm/v1/data-set/root-node/${contentId}`)
    }

    export const getTreeChildren = (folderId: string): AxiosPromise<IResponse<datasetDetailsTypes.ITreeItem[]>> => {
        return http.get(`/scntm/v1/folder/children?folderId=${folderId}`)
    }
    //v2获取文件夹children
    export const getTreeChildrenV2 = (params: datasetDetailsTypes.IGetChildrenParams): AxiosPromise<IResponse<datasetDetailsTypes.ITreeResultType>> => {
        return http.get(`/scntm/v2/folder/children`, { params })
    }
    export const fullSearch = (req: datasetDetailsTypes.IFullSearchReq): AxiosPromise<IResponse<datasetDetailsTypes.IFullSearchRes>> => {
        return http.post<IResponse<datasetDetailsTypes.IFullSearchRes>>('/scntm/v1/search/dataset', req)
    }
    export const addTreeItem = (params: datasetDetailsTypes.IAddTreeItemReq): AxiosPromise<IResponse<datasetDetailsTypes.ITreeItem>> => {
        return http.post(`/scntm/v1/folder`, params)
    }
    export const queryUpload = (params: datasetDetailsTypes.IQueryUploadParams): AxiosPromise<IResponse<datasetDetailsTypes.IQueryUploadRes>> => {
        return http.post(`/upload/get-all-fields-by-source`, params)
    }
    export const queryChooseBlue = (params: datasetDetailsTypes.IQueryChooseBlueParams): AxiosPromise<IResponse<datasetDetailsTypes.IQueryChooseBlueRes>> => {
        return http.post(`/search_pickedup/query`, params)
    }
    export const editFolderName = (params: datasetDetailsTypes.IEditFolderNameParams): AxiosPromise<IResponse<boolean>> => {
        return http.post(`/dataset/update-folder`, params)
    }
    export const updateEntityData = (params: datasetDetailsTypes.IUpdateEntityDataParams): AxiosPromise<IResponse<any>> => {
        return http.post(`/entity/update-entity-data`, params)
    }
    export const collection = (params: datasetDetailsTypes.ICollectionParams): AxiosPromise<IResponse<datasetDetailsTypes.ICollectionRes>> => {
        return http.post(`/favorite/favorite`, params)
    }

    export const moveEntities = (request: datasetDetailsTypes.IMoveEntitiesReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/folder/resource/move', request)
    }

    export const datasetDelCheck = (contentIds: string[]): AxiosPromise<IResponse<string[]>> => {
        return http.post(`/scntm/v1/data-set/del/check`, contentIds)
    }
    export const seriesDelCheck = (param: {
        parentContentIds: string[]
        parentEntityType: "biz_sobey_series"
    }): AxiosPromise<IResponse<string[]>> => {
        return http.post(`/scntm/v1/entity/relation/parent-children/haschild`, param)
    }
    //修改文件夹顺序
    export const updateFolderOrder = (pramas: datasetDetailsTypes.IUpdateFolderOrder): AxiosPromise<IResponse<any>> => {
        return http.patch(`/scntm/v1/folder/order`, pramas)
    }
    //获取文件夹顺序
    export const getFolderOrder = (folderId: string): AxiosPromise<IResponse<any>> => {
        return http.get(`/scntm/v1/folder/order?folderId=${folderId}`)
    }
}

export default datasetDetailsApi
