import {action, observable, runInAction} from "mobx";
import BaseStore from "../base/baseStore";

import { message } from "antd";
import _ from 'lodash'
import { Stores } from "../index";
import ccTypes from "src/types/ccTypes";

export default class IframeInfoStore extends BaseStore<Stores> {
    @observable
    public iframeInfo:ccTypes.IIframeInfo;
    @action
    public setIframeInfo(info: ccTypes.IIframeInfo){
        this.iframeInfo = info;
    }
}
