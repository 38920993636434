export enum AddEditMode {
    ADD = 1,
    EDIT = 2
}

export interface IBasePageRequest {
    pageIndex: number
    pageSize: number
    sort: string
    order: string
    keyword: string
}

export interface IBasePageResponse<T> {
    pageIndex: number
    pageSize: number
    pageTotal: number
    recordTotal: number
    data: T[]
}

export interface IBasePermission {
    canRead: boolean
    canWrite: boolean
    canExecute: boolean
    canDelete: boolean
}

export interface IBaseEntity {
    isFavorite: boolean
    favoriteObjId: string
    contentId: string
    contentId_?: string
    type?: string
    entity_type_?: string
    isOwner?: boolean
    name?: string
}