import { action, observable } from "mobx";
import BaseStore from "../base/baseStore";
import mmsApi from './../../apis/mmsApi';
import {Stores} from "../index";


export default class RestoreStore extends BaseStore<Stores>{
    @observable
    public showRestoreModal:boolean = false

    @observable
    public questionModels:any[]

    @action
    public setShowRestoreModal(show:boolean){
        this.showRestoreModal = show
    }

    @action
    public setQuestionModels(questionModels:any[]){
        this.questionModels = questionModels
    }

    @action
    public restoreSend = (items: any[]) => {
        return new Promise((resolve, reject) => {
            mmsApi.restoreModal.restore(items.map((item)=>{
                return item.contentId
            })).then(res => {
                    if (res.data.success && res.data.data) {
                        resolve(undefined)
                    } else {
                        reject()
                    }
                })
                .catch(err => {
                    reject()
                })
        })
    }
}