import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import advancedSearchTypes from './advancedSearchTypes';
import { IBaseEntity } from './commonTypes';

namespace ccTypes {
    export enum DataViewModes {
        LARGE = 1,   // 图例模式
        CARD = 2,    // 明信片模式
        LIST = 3   // 列表模式
    }

    export interface IParams {
        site?: string
        isPublic?: boolean
        page: number
        conditions: ccTypes.searchTypes.ISearchCondition[]
    }

    export interface IIframeInfo {
        src:string
        show:boolean
    }

    export enum FormatFlag {
        SD = 1,
        HD = 2,
        FHD = 3,
        SHD = 4,
        None = 0
    }

    export const COMMON_USER_HABIT_KEY: string = 'common_systemSearch';
    export const COMMON_MODULE_NAME: string = 'common';
    export const SEARCH_USER_HABIT_KEY: string = 'systemSearch';
    export const SEARCH_MODULE_NAME: string = 'search';

    export interface IUserHabitHabitValue {
        templateName: string
        conditions: ccTypes.searchTypes.ISearchCondition[]
    }

    export interface IUserHabit {
        habitKey: string
        habitValue: string
        id: number
        moduleName: string
        userCode: string
    }

    export interface ISaveUserHabitReq {
        key: string,
        value: string,
        moduleName: string,
        creator?: string
    }

    export interface IPrivilege {
        createTime: Date
        deleted: number
        id: number
        ownerCode: string
        siteCode: string
        siteName: string
        templateCode: string
        templateName: string
        templateType: string
        selected: boolean;
    }
    export interface IBasketItemData {
        contentExt: string
        contentId: string
        contentName: string
        contentType: string
        aiDataType?: string
        duration: number
        frameRate: number
        id: string
        inpoint: number
        pickDate: string
        pickSite: string
        pickUserCode: string
        selected?: boolean
        extendData?: any
        cdscore?: number
        keyframePath?:string
    }
    export interface IBasketItemsReqData {
        pageSize: number
        pageIndex: number
        contentType?: string
    }
    export interface IBasketAddItems {
        contentId: string
        contentName: string
        contentType: string
        contentExt?: string
        aiDataType?: string
        inpoint?: number
        duration?: number
        frameRate?: number
        extendData?: any
        fileext?: string
        contentCreateTime?: string
    }
    // 挑选篮获取对应的数量
    export interface IBasketItemRes {
        data: IBasketItemData[]
        pageIndex: number
        pageSize: number
        pageTotal: number
        recordTotal: number
    }
    export namespace sideContentTypes {
        export interface IFolder{
            showName: string
            name: string
            path: string
            contentId: string
            isPrivateFolder: boolean
            showSub?: boolean
            parentCode?: string
            children?: IFolder[]
        }
        export interface ISearchReqCondition {
            field: string
            value: string | string[]
            searchRelation?: string
            nexus?: string
            isMetadataField?: boolean
        }
        export interface ISearchTreeSubItem {
            showName: string
            conditions: ISearchReqCondition[]
            active?: boolean
        }
        export interface ISearchTreeRes {
            id: number
            showName: string
            conditions: ISearchReqCondition[]
            subItems: ISearchTreeSubItem[],
            showCon?: boolean
            active?: boolean
        }

        export interface IFolderReq {
            pageIndex: number
            pageSize: number
            sort: string
            order: string
            folderCode: string
            type: string
            keyword: string
            name: string
        }

        export interface IWorkteamRes {
            creatorCode: string
            createTime?: string
            delete?: number
            groupCode: string
            groupName?: string
            id?: number
            priviligeLevel?: number
            siteCode?: string
            siteName?: string
            type?: string[]
        }

        export interface ITenantNavItem {
            siteTypeName: string
            siteTypeCode: string
            tree: ITenantTreeItem[]
            active: boolean
            showSubs: boolean
        }
        export interface ITenantTreeItem {
            siteCode: string
            siteName: string
            active: boolean
        }

    }

    export namespace searchTypes {
        export interface ISearchCondition {
            key?: string
            value?: string | string[]
            items?: ccTypes.sideContentTypes.ISearchReqCondition[]
            name?: string
            field?: string
            array?: boolean
            type?: string
        }

        export interface ISearchParams extends ccTypes.IParams {
            folder?: string
            folderCode?: string
            order?: string
            keyword: string[]
            advancedSearch?: advancedSearchTypes.IField[]
            resourceName?: string
            imageSearchBase64?: string
            facetConditions: ccTypes.searchTypes.ISearchCondition[]

        }

        export interface ISortItem {
            text: string
            field: string
            desc: string
            hideDirection?: boolean
        }

        export interface IFullSearchSortBy {
            field: string
            isDesc: boolean
        }
        export interface IFullSearchReq {
            conditions: ccTypes.sideContentTypes.ISearchReqCondition[]
            facetConditions: ccTypes.sideContentTypes.ISearchReqCondition[]
            keyword: string[]
            pageIndex: number
            pageSize: number
            sortFields: IFullSearchSortBy[]
            highLight?: boolean
            resourceName?: string
            lastCount?: number //检索相关推荐增加一个请求参数：主检索结果的个数
        }

        export interface IFacetsFacetValue {
            count?: number
            showValue: string
            value: string | string[]
            isShow?: boolean
            isSelected?: boolean
        }
        export interface IFacets {
            facetFieldName: string
            facetLayer?: number
            facetShowName: string
            facetValue?: IFacetsFacetValue[]
            checkboxIsOpen?: boolean
            startDate?: string
            endDate?: string
            showMore?: boolean
            searchKeyword?: string
        }
        export interface IFullSearchBreadcrumbs{
            folderId: string
            name: string
        }
        export interface IFullSearchData{
            contentId: string
            contentId_: string
            createDate_: string
            createUser_: string
            creator: string
            keyframe_: string
            name_: string
            operateCode_: number
            order_: number
            site_: string
            tree_: string[]
            type_: string
            type: string
            fileext: string
            filesize: number
            oldName?: string
            editMode: boolean
            selected: boolean
            parentId: string
            isFavorite: boolean
            favoriteObjId?: string
            duration?: number
            operatePermission?: {
                canDelete: boolean
                canExecute: boolean
                canRead: boolean
                canWrite: boolean
                priviligeLevel: number
            }
            producedDate?:string
            contentCharacteristics?:string
            episodeCount?:number
            director?:string
            starring?:string
            summary?:string
        }
        export interface IFullSearchRes {
            pageIndex: number
            pageTotal: number
            pageSize: number
            recordTotal: number
            data: IFullSearchData[]
            facets: IFacets[]
            sortFields: string[]
            usedTime: number
            breadcrumbs?: IFullSearchBreadcrumbs[]
        }
        export interface IRecommendSearchRes {
            data: any[]
            words: string[]
            recommendType: string
        }
        export interface IRecommendSmartFullSearchRes {
            data: ISmartQueryResultData[]
            words: string[]
            recommendType: string
        }

        export interface ISmartFullSearchRes {
            queryResult: ISmartQueryResult
            sortFields: string[]
            facets: IFacets[]
            usedTime: number
        }

        export interface ISelectedFacets {
            showValue: string,
            parentName: string,
            value: string[]
        }

        export interface ISmartQueryResult {
            data: ISmartQueryResultData[]
            pageIndex: number
            pageSize: number
            pageTotal: number
            recordTotal: number
        }

        export interface ISmartQueryResultData {
            contentId_: string
            createDate_: string
            createUser_: string
            distributionMark: string
            eventorg: string
            guid_: string
            inpoint: number
            keyframeno: string
            keyword: string
            metaid_: string
            namespace_: string
            operateCode: string
            outpoint: number
            privilegeUserGroup_: string
            privilege_: string
            score: string
            site_: string
            storyid: string
            system_: string
            title: string
            scenename?: string
            type_: string
            _identity_: string
            _version_: string
            userName: string
            eventpeople: string
            eventplace: string
            keyframepath?: string
            isFavorite: boolean
            topic?: string
            durationTc: string
            sceneid?: string
            shotid?: string
        }

        export interface IEntityPathData {
            duration: number,
            filePathPrefix: string,
            bitRate: number,
            fileGUID: string,
            filePath: string,
            frameRate: number,
            size: number,
            height: number,
            width: number,
            durationTc: string,
            groupName: string,
            imageHeight: number,
            imageWidth: number
        }

        export interface IEntityPath {
            hostId: string,
            success: boolean
            data: IEntityPathData[]
        }

        export interface IRecordOperationHabitReq {
            id: string
            entityType: string
        }

        export interface taskCenterReq {
            FlowName: string
            PageIndex: number
            PageSize: number
        }
        export interface taskCenterRes {
            pageIndex: number,
            pageTotal: number,
            pageSize: number,
            recordTotal: number,
            data: taskCenterItem[]
        }
        export interface taskCenterItem {
            flowInstanceId: string,
            flowInstanceName: string,
            messageId: string,
            status: number,
            beginTime: string,
            endTime: string,
            currentProgress: number,
            creator: string
        }
    }

    export namespace cloudTypes {
        export interface ICloudParamsFieldConditions {
            resourceType: string
        }
        export interface ICloudParams extends ccTypes.IParams {
            folderCode: string
            searchType: string
            keyword: string
            type: string
            isQueryDirectSub?: boolean
            fieldConditions?: ICloudParamsFieldConditions
            advancedSearch?: advancedSearchTypes.IField[]
            sort?: string
            order?: string
            name?: string
            facetConditions: ccTypes.searchTypes.ISearchCondition[]
        }

        export interface IFullSearchParentDynamicResponse {
            folderpath: string
            deleteflag: string
            subtype: string
            rlvideoformatid: string
            userCode: string
            rhvideoformatid: string
            id: string
        }
        export interface IFullSearchParent {
            count: number
            treeType: number
            name: string
            type: string
            contentId: string
            frameRate: number
            fileExt: string
            isFavorite: boolean
            aspect_: number
            formatFlag: number
            isMy: boolean
            dynamicResponse: ccTypes.cloudTypes.IFullSearchParentDynamicResponse
            canRead: boolean
            canWrite: boolean
            canExecute: boolean
            canDelete: boolean
        }
        export interface IFullSearchFacetsFacetValue {
            count: number
            showValue: string
            value: string
            isSelected?: boolean
            isShow?: boolean
        }
        export interface IFullSearchFacets {
            facetFieldName: string
            facetLayer: number
            facetShowName: string
            facetValue: ccTypes.cloudTypes.IFullSearchFacetsFacetValue[]
            checkboxIsOpen?: boolean
            showMore?: boolean
            searchKeyword?: string
            startDate?: string
            endDate?: string
        }
        export interface IFullSearchRes {
            parent: ccTypes.cloudTypes.IFullSearchParent[]
            storageSize: number
            storageUsage: number
            facets: ccTypes.cloudTypes.IFullSearchFacets[]
            pageIndex: number
            pageTotal: number
            pageSize: number
            recordTotal: number
            data: any[]
        }
        export interface IFullSearchCondition {
            field: string
            value: string | string[]
            searchRelation?: string
            isMetadataField?: boolean
        }
        export interface IFullSearchReq {
            pageIndex: number
            pageSize: number
            keyword: string[]
            folderId: string
            type: string
            name: string
            sortFields: ccTypes.searchTypes.IFullSearchSortBy[]
            fieldConditions?: ccTypes.cloudTypes.ICloudParamsFieldConditions
            conditions: ccTypes.cloudTypes.IFullSearchCondition[]
            facetConditions: ccTypes.searchTypes.ISearchCondition[]
            isQueryDirectSub: boolean
        }
    }

    export namespace workSpaceTypes {
        export interface IWorkSpaceReq {
            pageIndex: number
            pageSize: number
            sort: string
            order: string
            folderCode: string
            type: string
            keyword: string
            name: string
        }
    }

    export namespace relativeInfo {
        export interface IGetRelativeInfoRes {
            tag: string[]
            value: {
                confidence: number
                description: string
                iconPath: string
                level: number
                name: string
                tag: string[]
                title: string
                type: string
            }
        }

        export interface IEntityRelationRelationShip {
            type: string
            name: string
        }
        export interface IGetEntityRelationRes {
            relationShip: IEntityRelationRelationShip[]
            tag: string[]
        }

        export interface IGetRelativeEventsRes {
            createTime: string
            description: string
            eventDate: string
            iconPath: string
            id: number
            name: string
            picturePaths: string[]
            propertyData: any
            source: string
            type: any
        }

        export interface IGetRelativeAlbums {
            albumtype: string
            name_: string
            createDate_: string
            createUser_: string
            keyframe_: string
            contentId_: string
            operateCode: string
            entityCount: number
            broswerCount: number
            site_: string
        }
    }

    /**
     * checkName
     */

    export namespace CheckName {
        export interface ICheckNameReq {
            ContentId: string
            Type: string
            NewName: string
            ParentId: string
        }

        export interface IReNameReq {
            resourceId: string
            newName: string
            oldName: string
        }

        export interface ICheckNameRes {
            haveQuestionModel: boolean
            questionModelCount: number
            questionModels: IQuestionModels[]
        }

        interface IQuestionModels {
            aspect_: number
            canDelete: boolean
            canExecute: boolean
            canRead: boolean
            canWrite: boolean
            contentId: string
            dynamicResponse: any
            fileExt: string
            formatFlag: number
            frameRate: number
            isFavorite: boolean
            isMy: boolean
            name: string
        }
    }

    export namespace createFolder {
        export interface ICreateFolderReq {
            name: string
            parentId: string
            privilege?: string
        }
        export interface ICreateFolder {
            aspect_: number
            canDelete: boolean
            canExecute: boolean
            canRead: boolean
            canWrite: boolean
            contentId: string
            count: number
            createTime: string
            dynamicResponse: any
            fileExt: string
            formatFlag: number
            frameRate: number
            isFavorite: boolean
            isMy: boolean
            name: string
            treeType: number
            type: string
            size?: string
            siteCode?: string
            durationTc?: string
            isCreate?: boolean
            keyframe: string
            catalogstate: string
        }
    }

    export interface ICreateCatalogueRes {
        key: string
        value: string
        name?: string
    }

    export interface IBasketEditItem {
        contentId: string
        title: string
        keyframe: string
        entityType: string
    }

    export interface IBasketCannotWriteItem {
        name: string
        reason: string
    }

    export interface IExportFileds {
        selected?: boolean,
        resourceFieldName?: string,
        resourceDesc?: string,
        type?: string,
        format?: string
        key?: number
        pitchOn?: number
        createUser?: string
        createTime?: string
    }

    export namespace Dataset {
        export interface ICreateDatasetReq {
            keyframePath?: string
            entityData: IFormItem[]
            relatedContentIds: string[]
        }

        export enum ChangeEntityRelationOperateType{
            ADD = 0,
            DELETE = 1
        }

        export interface IChangeEntityRelationReq {
            dataSetId: string
            dataSetFolderId: string
            relatedContentIds: string[],
            operateType: ChangeEntityRelationOperateType
        }
    }

    export interface IFavoriteStateRes{
        contentId: string
        isFavorite: boolean
    }

    export interface IUserStorageDetail{
        storageCode: string
        storageSize: number 
        usedSize: number
    }
    export interface IUserStorageRes{
        detailes: IUserStorageDetail[]
        storageSize: number
        usedSize: number
    }
}
export default ccTypes
