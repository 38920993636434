import uploadTypes from "../../../types/uploadTypes";
import {action, observable} from "mobx";
import {uploader} from "../../../components/upload/core/uploader";
import UploadStore from "./uploadStore";
import _ from 'lodash';

export default class GroupStore{
    private uploadStore: UploadStore;

    constructor(uploadStore: UploadStore) {
        this.uploadStore = uploadStore;
    }

    @action
    public handleFiles(options: uploadTypes.IUploadOptions, tasks: uploadTypes.ITask[]) {
        tasks.forEach((task)=>{
            if (!task.metadata.name
                && task.files && task.files.length > 0) {
                task.metadata.name = task.files[0].metadata.name;
            }
            if (task.metadata.field && task.metadata.name)
            {
                let metaField = _.find(task.metadata.field, { fieldName: 'name_' });
                if (metaField)
                {
                    metaField.value = task.metadata.name;
                }
            }
        })
    }
}