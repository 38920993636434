import ExtendStore from '../base/extendStore';
import _ from 'lodash'
import { Stores } from "../index"
import InformationStore from './subModules/informationStore'
export default class PersonalCenterStore extends ExtendStore {
    
    public informationStore: InformationStore
    constructor(stores: Stores) {
        super(stores)
        this.informationStore = new InformationStore(stores, this)
    }
}