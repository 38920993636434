import ExtendStore from "../../base/extendStore";
import { action, observable, runInAction } from "mobx";
import entityTypes from "../../../types/entityTypes";
import entityApi from "../../../apis/entityApi";
import * as React from "react";
import ExportSectionModal from "../../../pages/entity/modules/exportSectionModal/exportSectionModal";
import { Stores } from "../../index";
import SaveSectionModal from "../../../pages/entity/modules/saveSectionModal/saveSectionModal";
import _ from "lodash";

export default class MediaStore extends ExtendStore {
    /** 编目信息（树形） */
    @observable
    public catalogTree: entityTypes.ICatalogTreeItem[];
    /** 编目树展开的keys */
    @observable
    public catalogTreeExpandedKeys: string[];
    /** 当前正在编辑的编目 */
    @observable
    public currentCatalog: entityTypes.IEditCatalog;
    /** 编目层数量统计 */
    @observable
    public catalogDataCount: entityTypes.ICatalogDataCount = {
        program: 0,
        sequence: 0,
        scene: 0,
        shot: 0
    };
    /** 和播放器currentTime绑定的字段，定义这个变量是为了同步页面渲染，必须初始化watchPlayerTime方法 */
    @observable
    public playerCurrentTime: number = 0;
    public watchPlayerTmLock = false;

    public exportSectionModal: React.RefObject<ExportSectionModal>;
    public saveSectionModal: React.RefObject<SaveSectionModal>

    public constructor(stores: Stores) {
        super(stores)

        this.exportSectionModal = React.createRef();
        this.saveSectionModal = React.createRef();
    }

    public watchPlayerTime(){
        if (this.stores.entityStore.viewer && this.stores.entityStore.viewer.player) {
            this.stores.entityStore.viewer.player.media.addEventListener('accurateTimeUpdate', (res: any) => {
                if (this.watchPlayerTmLock){
                    return;
                }
                this.watchPlayerTmLock = true;
                setTimeout(()=>{
                    if (this.stores.entityStore.viewer.player.currentTime !== this.playerCurrentTime){
                        runInAction(()=>{
                            this.playerCurrentTime = this.stores.entityStore.viewer.player.currentTime
                        })
                    }
                    this.watchPlayerTmLock = false;
                }, 500)
            })
        }
    }

    @action
    public setCurrentCatalog(catalog: entityTypes.IEditCatalog) {
        this.currentCatalog = catalog;
    }

    /** 显示编目tab */
    @action
    public showCatalogTab(catalog: entityTypes.IEditCatalog) {
        this.setCurrentCatalog(catalog);
        this.stores.entityStore.selectedTab.top = 'catalogInfo';
    }

    @action
    public setCatalogTreeExpandedKeys(expandedKeys: string[]) {
        this.catalogTreeExpandedKeys = expandedKeys;
    }
}