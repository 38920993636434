import { Stores } from './../index';
import * as React from "react";
import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import IFormItem from 'mam-metadata-react/dist/types/IFormItem'
import { MetadataForm } from 'mam-metadata-react'
import bizTypes from 'src/types/bizTypes';

export default class CreateAlbumStore extends ExtendStore {
    @observable
    public showCreateAlbumModal:boolean = false
    @observable
    public selectedItems: any[] = []
    @observable
    public currentTab: string = 'addIntoAlbum';
    @observable
    public keyframePath: string = '';
    public createAlbumMetadataRef: React.RefObject<MetadataForm> = React.createRef()
    @observable
    public selectedAlbumContentId: string;
    @observable
    public enableAutoCollect: boolean = false;//专辑汇聚
    @observable
    public selectedCollectFields?: bizTypes.createAlbum.IAutoCollectField[]
    @observable
    public addOrMoveAlbumList: any[]=[]//添加或移动到哪些专辑
    @observable
    public parentAlbumContentId: string = '';

    public constructor(stores: Stores){
        super(stores);
    }

    @action
    public setCurrentTab(tab: string){
        this.currentTab = tab;
    }
    @action
    public setEnableAutoCollect(enableAutoCollect: boolean){
        this.enableAutoCollect = enableAutoCollect;
    }
    @action
    public setAddOrMoveAlbumList(list: any[]){
        this.addOrMoveAlbumList = list;
    }
    @action
    public setParentAlbumContentId(parentAlbumContentId: string){
        this.parentAlbumContentId = parentAlbumContentId;
    }
    @action
    public openModal(selectedItems:any[]){
        this.enableAutoCollect = false;
        this.selectedItems = selectedItems;
        this.keyframePath = '';
        this.selectedCollectFields = [];
        if (this.selectedItems.length === 0){//未选择素材，自动选中创建专辑tab
            this.currentTab = 'createAlbum'
        }
        else {
            this.currentTab = 'addIntoAlbum'
        }
        this.setShowCreateAlbumModal(true)
    }
    @action
    public setShowCreateAlbumModal = (show:boolean) => {
        this.showCreateAlbumModal = show
    }
    @action
    public setSelectedCollectFields(fields: bizTypes.createAlbum.IAutoCollectField[]){
        this.selectedCollectFields = fields;
    }
    @action
    public setKeyframePath(path: string){
        this.keyframePath = path;
    }
    @action
    public setSelectedItems(selectedItems:any[]){
        this.selectedItems = selectedItems;
    }
    @action
    public setSelectedAlbumContentId(contentId: string){
        this.selectedAlbumContentId = contentId;
    }
    //移动专辑
    @action
    public moveAlbum() {
        return new Promise((resolve,reject) => {
            /*let TargetAlbumIds:string[]=[]
            this.addOrMoveAlbumList.map(item=>{
                if (!item.key.includes("-")) {
                    TargetAlbumIds.push(item.key)
                } else if (item.key.includes("-")) {
                    TargetAlbumIds.push(item.key.split("-")[item.key.split("-").length - 1])  
                }
            })
            if(TargetAlbumIds.includes(window.location.pathname.split("/")[3])){
                TargetAlbumIds.splice(TargetAlbumIds.indexOf(window.location.pathname.split("/")[3]),1)
            }
            let params={
                TargetAlbumIds,
                relatedModels:this.selectedItems.map(item=>{
                  return  {
                        contentId:item.contentId_,
                        type:item.type_,
                        guid:item.guid_?item.guid_:null
                    }
                }),
                originAlbumId:window.location.pathname.split("/")[3]
            }
            bizApi.album.moveAlbum(params).then((res) => {
                    if(res.data.success){
                        resolve(true)
                    }
            },(res)=>{
                if(res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })*/
        });
    }
}