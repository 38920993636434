import { http } from 'mam-core-react'
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { IResponse } from 'mam-core-react/dist/modules/http'
import { INav } from 'mam-components-react/dist/modules/mocHeader/topNav';
import { INavigationRes } from 'src/types/navTypes';

namespace navApi {
    export const getNavigation = (parentId?: number, typeCode?: string, cfg?: AxiosRequestConfig):AxiosPromise<IResponse<INav[]>> => {
        return http.get<IResponse<INav[]>>(`/scntm/v1/config/navigation?typeCode=${typeCode !== undefined ? typeCode : 'top'}&parentid=${parentId !== undefined ? parentId : ''}`, cfg);
    }

    export const getNavigations = (parentid?: number, cfg?: AxiosRequestConfig):AxiosPromise<IResponse<INavigationRes[]>> => {
        return http.get<IResponse<INavigationRes[]>>(`/navigation` + (parentid ? `?parentid=${parentid}` : ''), cfg);
    }

    export const getNavigationByParentCode = (parentcode?: string):AxiosPromise<IResponse<INavigationRes[]>> => {
        return http.get<IResponse<INavigationRes[]>>(`/navigation` + (parentcode ? `?parentcode=${parentcode}` : ''));
    }
}

export default navApi