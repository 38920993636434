import { AxiosPromise } from "axios"
import { http } from "mam-core-react"
import { IResponse } from "mam-core-react/dist/modules/http"
import { IFolder, IFolderRes } from "src/types/folderSearchTypes"

/**
 * 查询文件夹
 */
export const getFolders = (foldercode: string): AxiosPromise<IResponse<IFolderRes[]>> => {
    return http.get<IResponse<IFolderRes[]>>(`/scntm/v1/folder/children?folderId=${foldercode}`)
}

/**
 * 初始化文件夹
 */
export const initCloudFolders = (): AxiosPromise<IResponse<IFolderRes[]>> => {
    return http.get<IResponse<IFolderRes[]>>(`/scntm/v1/folder/init`)
}