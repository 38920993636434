import { observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import { Stores } from '..'
import { BaseStore } from 'mam-core-react';
import AiGcStore from './aiGcStore';
import { IAdvancedParam, IGenSetConditionSubCateTag } from 'src/types/aiGcTypes';

export const defaultNegaPrompt = '(watercolor pencil),nude,nipples,full body, spread leg, arm up, large breasts,shaved pussy,((heart-shaped pupils)),(uncensored), sexually suggestive,saliva trail, suggestive fluid, (cum on body), tattoo, sweating, presenting, exhibitionism, wet cream dripping, female orgasm, liquid crystal fluid radiant cinematic lighting, solo uncensored cute assertive drunk blush, lowres, bad anatomy, bad hands, text, error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, bad feet, censored, panties, bra, genitals, pussy, naked';

export default class GenSetStore extends BaseStore<Stores> {
    @observable
    public selectedTags: IGenSetConditionSubCateTag[] = []
    @observable
    public negativePrompt: string = defaultNegaPrompt;
    @observable
    public positivePrompt: string = ''
    /** 固定设置的正向提示词，发起的任何请求都会带上 */
    @observable
    public regularPositivePrompt: string = ''
    @observable
    public promptMode: string = 'tag'
    public advancedParams: IAdvancedParam = {
        width: 1024,
        height: 1024,
        steps: 20,
        cfg_scale: 7,
        sampler_index: 'DPM++ 2S a Karras',
        batch_size: 1
    }
    private parent: AiGcStore;

    public constructor(stores: Stores, parent: AiGcStore) {
        super(stores);
        this.parent = parent
    }

    @action
    public setRegularPositivePrompt(prompt: string){
        this.regularPositivePrompt = prompt;
    }

    @action
    public setSelectedTags(tags: IGenSetConditionSubCateTag[]){
        this.selectedTags = tags;
    }

    @action
    public setPositivePrompt(prompt: string){
        this.positivePrompt = prompt
    }

    @action
    public setNegativePrompt(prompt: string){
        this.negativePrompt = prompt
    }

    @action
    public setPromptMode(mode: string){
        this.promptMode = mode;
        this.selectedTags = [];
        this.positivePrompt = '';
    }

    public setAdvancedParams(params: IAdvancedParam){
        this.advancedParams = params;
    }
}