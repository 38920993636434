import ExtendStore from 'src/stores/base/extendStore';
import _ from 'lodash'
import { Stores } from 'src/stores';
import { action, observable, runInAction } from 'mobx';
import { message } from 'antd';
import SearchTemplateStores from './searchTemplateStore';
import { commonUtil } from 'mam-common-utils';
import manageTypes from 'src/types/manageTypes';
import { controlTypeDict } from 'src/pages/sysmanage/modules/rightSide/esSearchConfig/modules/advancedSearchConfig/advancedSearchConfig';
import manageApi from 'src/apis/manageApi';
import { reqCatch } from 'mam-core-react';

export default class CreateEditTemplateStore extends ExtendStore {
    @observable
    public showModal: boolean = false;
    public id?: string;
    @observable
    public templateName: string = '';
    @observable
    public selectedFields: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateFieldsItem[] = [];

    public constructor(stores: Stores){
        super(stores)
    }

    @action
    public openModal(item?: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateItem){
        this.id = item ? item.id : undefined;
        this.templateName = item ? item.name : '';
        this.showModal = true;
        // 编辑模式还原conditions
        if (item && item.conditions && this.id){
            item.conditions.forEach(condition=>{
                let findTargetField = _.find(this.stores.manageStore.esSearchConfigStore.searchTemplateStore.allFields, {indexCode: condition.indexCode});
                if (findTargetField){
                    let targetField: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateFieldsItem = {
                        ...findTargetField,
                        searchRelation: 0,
                        values: [],
                        controlType: controlTypeDict[findTargetField.indexType][0].code
                    };
                    targetField.searchRelation = condition.searchRelation;
                    targetField.values = condition.values;
                    if (condition.id){
                        targetField.conditionId = condition.id;
                    }
                    this.selectedFields.push(targetField);
                }
            })
        }
    }

    @action
    public closeModal(){
        this.showModal = false;
        this.selectedFields = [];
    }

    @action
    public removeSelectedField(item: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateFieldsItem){
        _.remove(this.selectedFields, (field)=>{
            return field === item;
        })
    }

    @action
    public appendSelectedField(items: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateFieldsItem[]){
        this.selectedFields = this.selectedFields.concat(items)
    }

    @action
    public setItemSearchRelation(item: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateFieldsItem, searchRelation: number){
        item.searchRelation = searchRelation;
    }

    @action
    public setItemValues(item: manageTypes.esSearchConfig.searchTemplate.ISearchTemplateFieldsItem, values: string[]){
        item.values = values;
    }

    public setId(id?: string){
        this.id = id;
    }

    @action
    public setTemplateName(name: string){
        this.templateName = name;
    }
}