import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import ManageStore from '../../manageStore';
import { observable, action, runInAction } from 'mobx';
import _ from 'lodash';
import { message } from 'antd';
import manageApi from 'src/apis/manageApi'
import manageTypes from "src/types/manageTypes";

export default class AuthGroupStore extends ExtendStore {
    public parentStore: ManageStore;

    @observable
    public page: number = 1;
    @observable
    public size: number = 30;
    @observable
    public isSearching: boolean = false;
    @observable
    public listRes: manageTypes.authGroupTypes.IGetGroupListRes | undefined ; 
  
    @observable
    public showAddGroupModal: boolean = false;

    @observable
    public keyword: string = '';
    @observable
    public userKeyword: string = '';

    @observable
    public currentGroup: manageTypes.authGroupTypes.IGroupListItem | undefined  //当前选中权限组
    @observable
    public isEdit: boolean = false; //当前选中权限组是否处于编辑状态
    @observable
    public isSearchingUser: boolean = false;
    @observable
    public userPage: number = 1;
    @observable
    public userSize: number = 30;
    //已分配用户
    @observable
    public usersRes: manageTypes.authGroupTypes.IGetAuthGroupUsersRes
    //已选中的用户
    @observable
    public selectedRowKeysUser: string[] = []
    @observable
    public selectedRowsUser: manageTypes.authGroupTypes.IAuthGroupUsersItem[] = []
    //全部用户
    @observable
    public allUsers: manageTypes.authGroupTypes.IAuthGroupUsersItem[] = []
    @observable
    public showAssignUserModal: boolean = false; //显示分配用户弹窗
    @observable
    public showEditUserModal: boolean = false; //显示权限组的用户修改弹窗

    public queryAllUsersMaxPageSize: number = 100;

    public constructor(stores: Stores, parentStore: ManageStore) {
        super(stores)
        this.parentStore = parentStore;
    }

    @action
    public clear(){
        this.isEdit = false
        this.currentGroup = undefined
        this.listRes = undefined
    }
    @action
    public setShowAddGroupModal(show: boolean){
        this.showAddGroupModal = show
    }
    @action
    public setKeyword(keyword: string){
        this.keyword = keyword
    }
    @action
    public setUserKeyword(userKeyword: string){
        this.userKeyword = userKeyword
    }
    @action
    public setIsEdit(isEdit: boolean){
        this.isEdit = isEdit
    }
    @action
    public setShowAssignUserModal(showAssignUserModal: boolean){
        this.showAssignUserModal = showAssignUserModal
    }
    @action
    public setShowEditUserModal(showEditUserModal: boolean){
        this.showEditUserModal = showEditUserModal
    }
    @action
    public setCurrentGroup(currentGroup: manageTypes.authGroupTypes.IGroupListItem | undefined){
        this.currentGroup = currentGroup
        //this.getGroupInfo(currentGroup.groupCode) //考虑到本身列表项信息够用了，就暂时不另调接口查信息
    }

    /* 当前权限组列表的页码 */
    @action
    public setPage(page: number){
        this.page = page
    }
    /* 当前权限组-用户列表的页码 */
    @action
    public setUserPage(userPage: number){
        this.userPage = userPage
    }
    /* 当前权限组-用户列表的选中 */
    @action
    public setSelectedUser(selectedRowKeys: string[], selectedRows: manageTypes.authGroupTypes.IAuthGroupUsersItem[]){
        this.selectedRowKeysUser = selectedRowKeys
        this.selectedRowsUser = selectedRows
    }

    /* 获取权限组列表 */
    @action
    public getList = (): Promise<any> => {
        this.isSearching = true
        let req: manageTypes.authGroupTypes.IGetGroupListReq = {
            pageIndex: this.page,
            pageSize: this.size,
            keyword: this.keyword,
        }
        return new Promise((resolve, reject) => {
            manageApi.authGroupApi.getList(req).then((res) => {
                runInAction(() => {
                    if (res.data.success && res.data.data) {
                        this.listRes = res.data.data
                        if(this.listRes.data){
                            if(this.listRes.data.length > 0){
                                this.currentGroup = this.listRes.data[0]
                            }else{
                                this.currentGroup = undefined
                            }
                        }
                        this.isSearching = false
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 新增权限组 */
    @action
    public addGroup = (req: manageTypes.authGroupTypes.IAddEditGroupReq): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.authGroupApi.addGroup(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 修改权限组 */
    @action
    public editGroup = (req: manageTypes.authGroupTypes.IAddEditGroupReq): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.authGroupApi.editGroup(req).then((res) => {
                runInAction(() => {
                    if (res.data.success) {
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    

    /* 获取选中的权限组信息 */
    @action
    public getGroupInfo = (groupCode: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            manageApi.authGroupApi.getGroupInfo(groupCode).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.currentGroup = res.data.data
                        resolve(true)
                    }
                })
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
                reject()
            })
        })
    }
    /* 获取选中的权限组的用户列表 */
    @action
    public getGroupUsers = (): Promise<any> => {
        this.setSelectedUser([],[])
        this.isSearchingUser = true
        let req: manageTypes.userSetTypes.IGetAllUserListReq = {
            isDisable: "",
            pageIndex: this.userPage || 1,
            pageSize: this.userSize,
            keyword: this.userKeyword,
            //sortFields: [],
        }
        return new Promise((resolve, reject) => {
            if(this.currentGroup){
                manageApi.authGroupApi.getGroupUsers(this.currentGroup.groupCode,req).then((res) => {
                    runInAction(() => {
                        if (res.data.data) {
                            this.usersRes = res.data.data
                            this.isSearchingUser = false
                            resolve(true)
                        }
                    })
                }, (res) => {
                    if (res.data.error && res.data.error.title){
                        message.error(res.data.error.title)
                    }
                    reject()
                })
            }
            
        })
    }
  
}
