import React from 'react';
import { observer } from 'mobx-react';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddFieldModal, { IAddFieldModalRef } from './addFieldModal/addFieldModal';
import { stores } from 'src/stores';
import TableList from './tableList';
import _ from 'lodash'
import sysmanageApi from 'src/apis/sysmanageApi';
import manageApi from 'src/apis/manageApi';

export const controlTypeDict = {
    0: [{code: 5, name: '单行文本'}],
    1: [{code: 5, name: '单行文本'}, {code: 4, name: '数字类型'}],
    2: [{code: 5, name: '单行文本'}, {code: 4, name: '数字类型'}],
    3: [{code: 8, name: '下拉框'},{code: 17, name: '用户选择'}],
    4: [{code: 7, name: '布尔类型'}],
    5: [{code: 16, name: '日期范围'}],
    6: [{code: 14, name: '树形数据'}],
    7: [{code: 5, name: '单行文本'}],
    8: [{code: 5, name: '单行文本'}, {code: 14, name: '树形数据'}]
}

const AdvancedSearchConfig: React.FC = observer(() => {
    const advancedSearchConfigStore = stores.manageStore.esSearchConfigStore.advancedSearchConfigStore;
    const addFieldModalRef = React.useRef<IAddFieldModalRef | null>(null)

    const openAddFieldModal = React.useCallback(()=>{
        if (addFieldModalRef.current){
            addFieldModalRef.current.open(advancedSearchConfigStore.fieldList, (newItems)=>{
                manageApi.esSearchConfig.advancedSearchConfig.addAdvancedSearchFields(newItems.map(item=>{
                    return {
                        controlType: controlTypeDict[item.indexType][0].code,
                        fieldName: item.fieldName,
                        fixItemId: item.fixItemId,
                        indexCode: item.indexCode,
                        indexId: item.id,
                        indexType: item.indexType,
                        isNested: item.isNested
                    }
                })).then(res=>{
                    message.success('新增成功！'.l('com.addSuccess'))
                    advancedSearchConfigStore.queryFieldList();
                }).catch(res=>{
                    if (res.data && res.data.error && res.data.error.title){
                        message.error(res.data.error.title);
                    }
                    else {
                        console.error(res);
                    }
                });
            });
        }
    }, [addFieldModalRef.current]);

    return <div className="facet-config">
        <div className="top-tool">
            <div className="tl"></div>
            <div className="tr">
                <Button type={'primary'} onClick={openAddFieldModal}>
                    <PlusOutlined />{'添加字段'.l('searchConfig.addField')}
                </Button>
            </div>
        </div>
        <div className="table-body">
            <TableList></TableList>
        </div>
        <AddFieldModal ref={addFieldModalRef}></AddFieldModal>
    </div>
})
export default AdvancedSearchConfig