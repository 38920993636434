import ExtendStore from '../base/extendStore'
import { observable, action, runInAction } from 'mobx'
import _ from 'lodash'
import { Stores } from 'src/stores'
import { message } from 'antd'

export default class ContentMgPlatformStore extends ExtendStore {
    @observable
    public currentId: string = ''
    @observable
    public openKeys: string[] = ['5465467457', '546546744527', '5465467452337', '54654267452337', '213213123']

    public constructor(stores: Stores) {
        super(stores);
    }

    @action
    public setCurrentId(id: string){
        this.currentId = id;
    }

    @action
    public setOpenKeys(strs: string[]){
        this.openKeys = strs;
    }
}