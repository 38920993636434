import UserStore from './userStore'
import ConfigStore from "./configStore";
import SearchStore from "./cc/searchStore/searchStore";
import CloudStore from "./cc/cloudStore/cloudStore";
import WorkteamStore from "./workteam/workteamStore";
import ShareStore from "./share/shareStore";
import WorkSpaceStore from 'src/stores/cc/workSpaceStore';
import EntityStore from "./entity/entityStore";
import RecycleStore from "./cc/recycleStore";
import WorkteamDetailsStore from './cc/workteamDetailsStore';
import TenantStore from './cc/tenantStore';
import ShareDetailStore from './shareDetail/shareDetailStore';
import BasketStore from './basket/basketStore'
import SysmanageStore from './sysmanage/sysmanageStore';
import SharePageStore from "./sharePage/sharePageStore";
import ManageStore from './manage/manageStore';
import WebUploadStore from './webUpload/webUploadStore';
import ISearchStore from './iSearch/iSearchStore';
import ImageSearchStore from './imageSearch/imageSearchStore';
import IframeInfoStore from './iframeInfoStore/iframeInfoStore';
import ContentMgStore from './contentMg/contentMgStore';
import StatisticStore from './statistic/statisticStore';
import DatasetDetailsStore from './datasetDetails/datasetDetailsStore';
import PersonalCenterStore from 'src/stores/personalCenter/personalCenterStore'
import MocSelectFolderStore from './extends/mocSelectFolderStore';
import CopyrightStore from './copyright/copyrightStore';
import CopyrightManageStore from './copyright/copyrightManageStore';
import CopyrightBootStore from './copyright/copyrightBookStore';
import TaskSearchStore from './taskSearch/taskSearchStore';
import CreateAlbumStore from './createAlbum/createAlbumStore';
import AlbumDetailStore from './albumDetail/albumDetailStore';
import AttachmentStore from './attachment/attachmentStore';
import MocTopicDetailStore from './mocTopicDetail/mocTopicDetailStore';
import BatchEditStore from './batchEdit/batchEditStore';
import NewspaperDetailStore from './newspaperDetail/newspaperDetailStore';
import CreateMocTopicStore from './createMocTopic/createMocTopicStore';
import ContentMgPlatformStore from './contentMgPlatform/contentMgPlatformStore';
import MessageMgStore from './messageMg/messageMgStore';
import CreateSeriesStore from './createSeries/createSeriesStore';
import SeriesDetailStore from './seriesDetail/seriesDetailStore';
import FolderSearchStore from './folderSearch/folderSearchStore';
import NewspaperStore from './newspaper/newspaperStore';
import AiGcStore from './aiGc/aiGcStore';
import AiAssistantStore from 'mam-biz-components/modules/aiAssistant/store/aiAssistantStore';
export class Stores {
    public configStore: ConfigStore = new ConfigStore(this)
    public userStore: UserStore = new UserStore(this)
    public searchStore: SearchStore = new SearchStore(this)
    public cloudStore: CloudStore = new CloudStore(this)
    public workteamStore: WorkteamStore = new WorkteamStore(this)
    public shareStore: ShareStore = new ShareStore(this)
    public workSpaceStore: WorkSpaceStore = new WorkSpaceStore(this)
    public entityStore: EntityStore = new EntityStore(this)
    public recycleStore: RecycleStore = new RecycleStore(this)
    public workteamDetailsStore: WorkteamDetailsStore = new WorkteamDetailsStore(this)
    public shareDetailStore: ShareDetailStore = new ShareDetailStore(this)
    public basketStore: BasketStore = new BasketStore(this)
    public sysmanageStore: SysmanageStore = new SysmanageStore(this)
    public sharePageStore: SharePageStore = new SharePageStore(this)
    public tenantStore: TenantStore = new TenantStore(this)
    public manageStore: ManageStore = new ManageStore(this)
    public webUploadStore: WebUploadStore = new WebUploadStore(this)
    public iSearchStore: ISearchStore = new ISearchStore(this)
    public imageSearchStore: ImageSearchStore = new ImageSearchStore(this)
    public IframeStore: IframeInfoStore = new IframeInfoStore(this)
    public contentMgStore: ContentMgStore = new ContentMgStore(this)
    public messageMgStore: MessageMgStore = new MessageMgStore(this)
    public statisticStore: StatisticStore = new StatisticStore(this)
    public datasetDetailsStore: DatasetDetailsStore = new DatasetDetailsStore(this)
    public personalCenterStore: PersonalCenterStore = new PersonalCenterStore(this)
    public mocSelectFolderStore: MocSelectFolderStore = new MocSelectFolderStore(this)
    public copyrightStore:CopyrightStore = new CopyrightStore(this)
    public copyrightManageStore:CopyrightManageStore = new CopyrightManageStore(this)
    public copyrightBookStore:CopyrightBootStore = new CopyrightBootStore(this)
    public taskSearchStore:TaskSearchStore = new TaskSearchStore(this)
    public createAlbumStore: CreateAlbumStore = new CreateAlbumStore(this)
    public albumDetailStore: AlbumDetailStore = new AlbumDetailStore(this)
    public attachmentStore: AttachmentStore = new AttachmentStore(this)
    public mocTopicDetailStore: MocTopicDetailStore = new MocTopicDetailStore(this)
    public newspaperDetailStore: NewspaperDetailStore = new NewspaperDetailStore(this)
    public batchEditStore:BatchEditStore = new BatchEditStore(this)
    public createMocTopicStore: CreateMocTopicStore = new CreateMocTopicStore(this)
    public contentMgPlatformStore: ContentMgPlatformStore = new ContentMgPlatformStore(this)
    public seriesDetailStore: SeriesDetailStore = new SeriesDetailStore(this)
    public createSeriesStore: CreateSeriesStore = new CreateSeriesStore(this)
    public folderSearchStore: FolderSearchStore = new FolderSearchStore(this)
    public aiAssistantStore: AiAssistantStore<Stores> = new AiAssistantStore(this)
    public newspaperStore: NewspaperStore = new NewspaperStore(this)
    public aiGcStore: AiGcStore = new AiGcStore(this)
}
export let stores = new Stores();
