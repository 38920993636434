import { action, observable, runInAction } from "mobx";
import ccTypes from "../../../types/ccTypes";
import CcStore from "../ccStore";
import { Stores } from "../../index";
import ccApi from "../../../apis/ccApi";
import _ from 'lodash';
import SearchTreeStore from "./searchTreeStore";
import { message } from 'antd';
import PictureTabStore from "./pictureTabStore/pictureTabStore";


export default class SearchStore extends CcStore {
    public searchTreeStore: SearchTreeStore;
    public pictureTabStore: PictureTabStore;
    @observable
    public params: ccTypes.searchTypes.ISearchParams
    @observable
    public fullSearchData?: ccTypes.searchTypes.IFullSearchRes;

    @observable
    public previewEntity: any;
    @observable
    public selectedPreviewEntity: ccTypes.searchTypes.IEntityPathData | undefined;
   
    @observable
    public searchKeyword1: string = '';  // 主搜索框值
    @observable
    public searchKeyword2: string = '';  // 次检索框值

    @observable
    public pageSize: number = 30;
    @observable
    public pageSizes: number[] = [30,60,100]

    constructor(stores: Stores) {
        super(stores)
        this.initOutstoreStore(stores);
        this.initProcessStore(stores);
        this.initDeleteStore(stores);
        this.initCreatePlanningStore(stores);
        this.initOutputExcelStore(stores);
        this.initExportExcelStore(stores);
        this.initUnlockStore(stores);
        this.initShareStore(stores);
        this.initSelectAllStore(stores, 'stores.searchStore.fullSearchData.data', 'selected');
        this.initJoinGroupStore(stores)

        this.searchTreeStore = new SearchTreeStore(stores);
        this.pictureTabStore = new PictureTabStore(stores);
    }

    @action
    public queryFullSearchData(page?: number): Promise<any> {
        this.selectAllStore.switchAllSelected(false);
        this.fullSearchData = undefined;
        return new Promise((resolve, reject) => {
            this.getSortItems();
            if (this.stores.configStore.config.searchResultNum){
                this.pageSize = this.stores.configStore.config.searchResultNum;
            }
            const pageSizeStorage = localStorage.getItem('searchStore.pageSize');
            if (pageSizeStorage !== null){
                this.pageSize = parseInt(pageSizeStorage, 10)
            }
            let req: ccTypes.searchTypes.IFullSearchReq = {
                pageIndex: page || this.params.page,
                pageSize: this.pageSize,
                keyword: this.params.keyword,
                sortFields: [{
                    field: this.currentSort.field,
                    isDesc: this.currentSort.desc === 'desc'
                }],
                conditions: [],
                facetConditions: [],
                highLight: true
            };
            if (this.params.facetConditions) {
                this.params.facetConditions.forEach((condition) => {
                    if (condition.items) {
                        condition.items.forEach((n) => {
                            let item = _.find(req.facetConditions, { field: n.field });
                            if (item == null) {
                                //特殊处理，在全部资源下的类型传参
                                if(!n.field){
                                    n.field = "type_"
                                }
                                req.facetConditions.push(n);
                            } else {
                                if (_.isArray(item.value)) {
                                    if (!_.includes(item.value, n.value)) {
                                        item.value.push(n.value as string);
                                    }
                                } else {
                                    if (item.value !== n.value) {
                                        item.value = [item.value, n.value as string];
                                    }
                                }
                            }
                        });
                    } else {
                        if (condition.field && condition.value) {
                            req.facetConditions.push({ field: condition.field, value: condition.value })
                        }
                    }
                });
            }
            if (this.params.advancedSearch) {
                this.params.advancedSearch.forEach((field)=>{
                    let value: string = field.value as string;
                    if (!value){
                        return;
                    }
                    if (field.controlType === 16 || field.controlType === 23){
                        let valArr = value.split(',');
                        value = `${valArr[0] ? valArr[0] + ' 00:00:00' : ''} ~ ${valArr[1] ? valArr[1] + ' 23:59:59' : ''}`
                    }
                    req.conditions.push({ field: field.fieldCode, value: [value], searchRelation: field.searchRelation, isMetadataField: field.isMetadataField })
                })
            }
            if (this.params.resourceName) {
                req.resourceName = this.params.resourceName
            }

            if(this.currentSort.field === "default"){//相关度排序时不传排序
                req.sortFields = []
            }
            ccApi.searchApi.fullSearch(req).then((res) => {
                runInAction(() => {
                    if (res.data.success === true && res.data.data) {
                        res.data.data.facets = this.getCustomFacets(res.data.data.facets)
                        this.initFacets(res.data.data.facets);
                        if (res.data.data.data) {
                            this.resolveQueryResult(res.data.data.data);
                        }
                        this.fullSearchData = res.data.data;
                    }
                })
                resolve(true);
            }, (res) => {
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                }
                reject();
            });
        });
    }

    @action
    public setParams(searchParams: ccTypes.searchTypes.ISearchParams) {
        this.params = searchParams;
        if (searchParams.keyword){
            if (searchParams.keyword[0]){
                this.setSearchKeyword1(searchParams.keyword[0]);
            }
            if (searchParams.keyword[1]){
                this.setSearchKeyword2(searchParams.keyword[1]);
            }
        }
    }

    @action
    public setSearchKeyword1(str: string) {
        this.searchKeyword1 = str;
    }
    @action
    public setSearchKeyword2(str: string) {
        this.searchKeyword2 = str;
    }

    /**
     * 重命名
     */
    @action
    public switchRenameStatus(index: number, editMode: boolean) {
        if (this.fullSearchData) {
            if (editMode) {
                this.fullSearchData.data[index].oldName = this.fullSearchData.data[index].name_
            }
            this.fullSearchData.data[index].editMode = editMode
        }
    }

    @action
    public updateShowName(index: number, newName: string) {
        if (this.fullSearchData) {
            this.fullSearchData.data[index].name_ = newName
        }
    }

    @action
    public setPageSize(size: number){
        this.pageSize = size;
        localStorage.setItem('searchStore.pageSize', size.toString());
        this.queryFullSearchData(1).then(()=>{});
    }

    @action
    public setPageSizes(sizes: number[]){
        this.pageSizes = sizes;
    }

    /** 设置预览素材 */
    @action 
    public setPreviewEntity(entity: any){
        this.previewEntity = entity;
        this.getFilePathByContentId(entity.contentId);
    }

    /** 根据素材id获取地址 */
    @action 
    public getFilePathByContentId(contentId:string){
        ccApi.searchApi.getEntityPath(contentId).then(res=>{
            runInAction(() => {
                if(res.data.success&&res.data.data&&res.data.data[0]){
                    this.selectedPreviewEntity = res.data.data[0];
                }else{
                    this.selectedPreviewEntity = undefined
                }
            })
        },res=>{
            this.selectedPreviewEntity = undefined;
        })
    }

    /** 设置收藏状态 */
    @action
    public setItemFavorite(item: any, favorite: boolean, favoriteObjId?: string){
        if (this.fullSearchData && this.fullSearchData.data){
            let target = _.find(this.fullSearchData.data, {contentId: item.contentId});
            if (target){
                if (favoriteObjId){
                    target.favoriteObjId = favoriteObjId;
                }
                target.isFavorite = favorite;
            }
        }
    }
}
