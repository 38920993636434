import BaseStore from "../base/baseStore";
import { action, observable } from "mobx";
import ccTypes from "../../types/ccTypes";
import { Stores } from "../index";

export interface IDataViewModeStore {
    dataViewModeStore: DataViewModeStore;

    initDataViewModeStore: (stores: Stores) => void;
}

export default class DataViewModeStore extends BaseStore<Stores> {
    /** 列表显示模式 */
    @observable
    public dataViewMode: number = ccTypes.DataViewModes.LARGE;

    @action
    public setDataViewMode(mode: number) {
        this.dataViewMode = mode;
    }
}