import ExtendStore from "../../base/extendStore";
import {action, observable, runInAction} from "mobx";
import entityTypes from "../../../types/entityTypes";
import entityApi from "../../../apis/entityApi";

export default class RundownStore extends ExtendStore{
    /** 关联素材数量，控制底部tab是否显示 */
    @observable
    public linkedListLength: number = 0;
    /** 文稿列表 */
    @observable
    public scriptsList: entityTypes.ILinkedEntity[];
    @observable
    public selectedScript: entityTypes.ILinkedEntity;
    @observable
    public isQueryScriptList: boolean = false;

    @action
    public setLinkedListLength(len: number){
        this.linkedListLength = len;
    }

    @action
    public queryScriptsList(){
        const entity = this.stores.entityStore.entity;
        this.isQueryScriptList = true;
        entityApi.getLinkedEntity(entity.contentId).then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    this.scriptsList = res.data.data;
                    if (this.scriptsList.length > 0)
                    {
                        this.selectedScript = this.scriptsList[0];
                    }
                }
            })
        }).finally(()=>{
            runInAction(()=>{
                this.isQueryScriptList = false;
            })
        });
    }

    @action
    public setSelectedScript(script: entityTypes.ILinkedEntity){
        this.selectedScript = script;
    }
}