import {IBaseConfig, IDownloadConfig, IScoreConfigItem, IUiModule, ICatalogConfig} from "../types/configTypes";
import {http} from "../utils/http";
import {AxiosPromise} from "axios";
import {IResponse} from "mam-core-react/dist/modules/http";
import { INav } from "mam-components-react/dist/modules/mocHeader/topNav";

namespace configApi {
    export const getConfigBase = (): AxiosPromise<IResponse<IBaseConfig>> =>  {
        return http.get<IResponse<IBaseConfig>>('/v1/config/base')
    }

    export const getLoginConfig = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/v1/config/login')
    }

    export const getSearchConfig = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/v1/config/search')
    }

    export const getSearchResultFieldsConfig = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/v1/config/search/result/fields')
    }

    export const getWebUploadConfig = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/v1/config/upload')
    }

    export const getEntityConfig = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/v1/config/entity')
    }

    export const getDownloadConfig = (): AxiosPromise<IResponse<IDownloadConfig>> => {
        return http.get<IResponse<IDownloadConfig>>('/v1/config/download')
    }

    export const getScoreConfig = (key: string): AxiosPromise<IResponse<IScoreConfigItem>> => {
        return http.get<IResponse<IScoreConfigItem>>(`/scpm/v1/config?key=${key}`)
    }

    export const getFavoriteConfig = (): AxiosPromise<IResponse<any>> => {
        return http.get<IResponse<any>>('/scntm/v1/config/favorite')
    }

    /** 获取导航配置 */
    export const getNavigationConfig = (typeCode: string, parentId?: string): AxiosPromise<IResponse<INav[]>> => {
        return http.get<IResponse<INav[]>>(`/scntm/v1/config/navigation?typeCode=${typeCode}&parentid=` + (parentId ? `${parentId}` : '-1'))
    }

    export const getUiModules = (keys: string): AxiosPromise<IResponse<IUiModule[]>> => {
        return http.get<IResponse<IUiModule[]>>(`/v1/ui-module?keys=${keys}`)
    }

    export const getCatalogConfig = (): AxiosPromise<IResponse<ICatalogConfig>> => {
        return http.get<IResponse<ICatalogConfig>>(`/sctlg/v1/config`, {
            headers: {hideLoading: true}
        })
    }
}

export default configApi
