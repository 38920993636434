import { http } from "../utils/http";
import { AxiosPromise } from "axios";
import { IResponse } from 'mam-core-react/dist/modules/http';
import iEntityTypes from 'src/types/iEntityTypes';
import IFormItem from 'mam-metadata-react/dist/types/IFormItem';
import { commonUtil } from "mam-common-utils";

namespace iEntityApi {
    export const getContentStructLayer = (contentId: string, type: string, id: string): AxiosPromise<IResponse<iEntityTypes.IContentStructLayer[]>> => {
        return http.get<IResponse<iEntityTypes.IContentStructLayer[]>>(`/scntm/v1/aidata/content-struct/layer?contentId=${contentId}&type=${type}&id=${id}`);
    }

    export const getStoryAidata = (contentId: string): AxiosPromise<IResponse<iEntityTypes.IAidataRes>> => {
        return http.get<IResponse<iEntityTypes.IAidataRes>>(`/scntm/v1/aidata/story?contentId=${contentId}`);
    }

    export const getStorySingle = (contentId: string, storyid: string): AxiosPromise<IResponse<iEntityTypes.IMetaOriginal>> => {
        return http.get<IResponse<iEntityTypes.IMetaOriginal>>(`/scntm/v1/aidata/story/${contentId}/${storyid}`);
    }

    export const getStoryMetadata = (contentId: string, storyid: string): AxiosPromise<IResponse<iEntityTypes.IPartMetadataRes[]>> => {
        return http.get<IResponse<iEntityTypes.IPartMetadataRes[]>>(`/scntm/v1/aidata/story/metadata/${contentId}/${storyid}`);
    }

    export const getSceneAidata = (contentId: string, story: iEntityTypes.IMetaOriginal): AxiosPromise<IResponse<iEntityTypes.IAidataRes>> => {
        return http.get<IResponse<iEntityTypes.IAidataRes>>(`/scntm/v1/aidata/scene?contentId=${contentId}&inpoint=${story.inpoint}&outpoint=${story.outpoint}`);
    }

    export const getSceneSingle = (contentId: string, sceneid: string): AxiosPromise<IResponse<iEntityTypes.IMetaOriginal>> => {
        return http.get<IResponse<iEntityTypes.IMetaOriginal>>(`/scntm/v1/aidata/scene/${contentId}/${sceneid}`);
    }

    export const getSceneMetadata = (contentId: string, sceneid: string): AxiosPromise<IResponse<iEntityTypes.IPartMetadataRes[]>> => {
        return http.get<IResponse<iEntityTypes.IPartMetadataRes[]>>(`/scntm/v1/aidata/scene/metadata/${contentId}/${sceneid}`);
    }

    export const getShotAidata = (contentId: string, scene: iEntityTypes.IMetaOriginal): AxiosPromise<IResponse<iEntityTypes.IAidataRes>> => {
        return http.get<IResponse<iEntityTypes.IAidataRes>>(`/scntm/v1/aidata/shot?contentId=${contentId}&inpoint=${scene.inpoint}&outpoint=${scene.outpoint}`);
    }

    export const getShotSingle = (contentId: string, shotid: string): AxiosPromise<IResponse<iEntityTypes.IMetaOriginal>> => {
        return http.get<IResponse<iEntityTypes.IMetaOriginal>>(`/scntm/v1/aidata/shot/${contentId}/${shotid}`);
    }

    export const getShotMetadata = (contentId: string, shotid: string): AxiosPromise<IResponse<iEntityTypes.IPartMetadataRes[]>> => {
        return http.get<IResponse<iEntityTypes.IPartMetadataRes[]>>(`/scntm/v1/aidata/shot/metadata/${contentId}/${shotid}`);
    }

    export const getContentStructExtend = (contentId: string, type: 'entity' | 'story', inpoint?: number, outpoint?: number): AxiosPromise<IResponse<iEntityTypes.IContentStructExtendRes>> => {
        return http.get<IResponse<iEntityTypes.IContentStructExtendRes>>(`/scntm/v1/aidata/content-struct/extend?contentId=${contentId}&type=${type}` + (inpoint !== undefined && outpoint !== undefined ? `&inpoint=${inpoint}&outpoint=${outpoint}` : ``));
    }

    export const updateStoryMetadata = (contentId: string, storyid: string, metadata: IFormItem[]): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.patch<IResponse<IFormItem[]>>(`/scntm/v1/aidata/story/metadata/${contentId}/${storyid}`, metadata);
    }

    export const updateSceneMetadata = (contentId: string, sceneid: string, metadata: IFormItem[]): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.patch<IResponse<IFormItem[]>>(`/scntm/v1/aidata/scene/metadata/${contentId}/${sceneid}`, metadata);
    }

    export const updateShotMetadata = (contentId: string, shotid: string, metadata: IFormItem[]): AxiosPromise<IResponse<IFormItem[]>> => {
        return http.patch<IResponse<IFormItem[]>>(`/scntm/v1/aidata/shot/metadata/${contentId}/${shotid}`, metadata);
    }

    export const getFaceAidata = (contentId: string, inpoint?: number, outpoint?: number, fileGuid?: string): AxiosPromise<IResponse<iEntityTypes.IFaceAidataRes>> => {
        if (inpoint !== undefined && outpoint !== undefined){
            return http.get<IResponse<iEntityTypes.IFaceAidataRes>>(`/scntm/v1/aidata/face?contentId=${contentId}&inpoint=${inpoint}&outpoint=${outpoint}` + (fileGuid ? `&fileGuid=${fileGuid}` : ''));
        }
        return http.get<IResponse<iEntityTypes.IFaceAidataRes>>(`/scntm/v1/aidata/face?contentId=${contentId}` + (fileGuid ? `&fileGuid=${fileGuid}` : ''));
    }

    export const getFaceSequence = (contentId: string, personId: string, clusterId: string): AxiosPromise<IResponse<iEntityTypes.IFaceSequenceRes>> => {
        return http.get<IResponse<iEntityTypes.IFaceSequenceRes>>(`/scntm/v1/aidata/face/sequence?contentId=${contentId}&personId=${personId || ''}&clusterId=${clusterId || ''}`);
    }

    export const getSpeechAidata = (contentId: string, page: number, pageSize: number, inpoint?: number, outpoint?: number, keyword?: string): AxiosPromise<IResponse<iEntityTypes.ISpeechAidataRes>> => {
        let url = `/scntm/v1/aidata/speech?contentId=${contentId}&pageIndex=${page}&pageSize=${pageSize}`;
        if (inpoint !== undefined && outpoint !== undefined){
            url = commonUtil.addUrlParam(url, `inpoint=${inpoint}&outpoint=${outpoint}`)
        }
        if (keyword !== undefined){
            url = commonUtil.addUrlParam(url, `keyword=${keyword}`)
        }
        return http.get<IResponse<iEntityTypes.ISpeechAidataRes>>(url);
    }

    export const getSubtitleAidata = (contentId: string, page: number, pageSize: number, inpoint?: number, outpoint?: number, keyword?: string, isContainsLocation?: boolean): AxiosPromise<IResponse<iEntityTypes.ISpeechAidataRes>> => {
        let url = `/scntm/v1/aidata/subtitle?contentId=${contentId}&pageIndex=${page}&pageSize=${pageSize}`;
        if (inpoint !== undefined && outpoint !== undefined){
            url = commonUtil.addUrlParam(url, `inpoint=${inpoint}&outpoint=${outpoint}`)
        }
        if (keyword !== undefined){
            url = commonUtil.addUrlParam(url, `keyword=${keyword}`)
        }
        if (isContainsLocation){
            url = commonUtil.addUrlParam(url, `isContainsLocation=${isContainsLocation}`)
        }
        return http.get<IResponse<iEntityTypes.ISpeechAidataRes>>(url);
    }

    export const getTagTimelines = (contentId: string): AxiosPromise<IResponse<iEntityTypes.ILBTimeline[]>> => {
        return http.get<IResponse<iEntityTypes.ILBTimeline[]>>(`/scntm/v1/aidata/named-entity/time-line?contentId=${contentId}`)
    }

    export const getContentStructCount = (contentId: string, type: string, id?: string): AxiosPromise<IResponse<iEntityTypes.IContentStructCountRes>> => {
        return http.get<IResponse<iEntityTypes.IContentStructCountRes>>(`/scntm/v1/aidata/content-struct/count?contentId=${contentId}&type=${type}` + (id ? `&id=${id}` : ''));
    }

    /** 查询标签时间线 */
    export const getNamedEntity = (contentId: string, name: string): AxiosPromise<IResponse<iEntityTypes.INamedEntityRes[]>> => {
        return http.get<IResponse<iEntityTypes.INamedEntityRes[]>>(`/scntm/v1/aidata/named-entity?contentId=${contentId}&keyword=${name}`);
    }

    export const getPicAidata = (contentId: string, fileGuid: string): AxiosPromise<IResponse<iEntityTypes.IPicAidataRes>> => {
        return http.get<IResponse<iEntityTypes.IPicAidataRes>>(`/scntm/v1/aidata/pic-shot/${contentId}/${fileGuid}`);
    }

    /** 修改未知人脸 */
    export const modifyUnknowFace = (req: iEntityTypes.IModifyUnknowFace): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/scntm/v1/aidata/face/person-info', req);
    }

    export const getTermDetail = (id: string, name: string, type: string) => {
        return http.get<IResponse<iEntityTypes.ITermDetail[]>>('/skldg/v1/term/detail?id=' + (id || '') + '&term=' + name + '&type=' + type);
    }

    /** 查询腾讯人脸详细 */
    export const getTencentPersonDetail = (personId: string) => {
        return http.get<IResponse<iEntityTypes.ITencentPersonDetail[]>>(`/skldg/v1/tencent/UserDefinePerson/detail?PersonID=${personId}`);
    }
    /** 腾讯人脸信息修改 */
    export const modifyTencentPersonDetail = (req: iEntityTypes.IModifyTencentPersonDetailReq): AxiosPromise<IResponse<any>> => {
        return http.put<IResponse<any>>('/skldg/v1/tencent/UserDefinePerson', req);
    }
    /** 腾讯人脸未知变已知 */
    export const getTencentFaceCategory = (): AxiosPromise<IResponse<iEntityTypes.ITencentFaceCategoryRes[]>> => {
        return http.get<IResponse<iEntityTypes.ITencentFaceCategoryRes[]>>('/skldg/v1/tencent/UserDefinePerson/category');
    }
    /** 腾讯人脸未知变已知，人脸图片修改 */
    export const modifyTencentUnknownFaceImage = (req: {
        image_base64: string
    }): AxiosPromise<IResponse<{MediaID: number}>> => {
        return http.post<IResponse<{MediaID: number}>>('/skldg/v1/tencent/UserDefinePerson/image', req);
    }
    /** 腾讯人脸未知变已知 */
    export const modifyTencentUnknownFace = (req: iEntityTypes.IModifyTencentUnknownFaceReq): AxiosPromise<IResponse<any>> => {
        return http.put<IResponse<any>>('/skldg/v1/tencent/UserDefinePerson/unknown', req);
    }
    /** 腾讯人脸发起翻库 */
    export const revertTencentFace = (req: iEntityTypes.IRevertTencentFaceReq): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>('/skldg/v1/task/person/revert', req);
    }

    export const updateSubtitleAidata = (contentId: string, item: iEntityTypes.ISpeechAidata): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/scntm/v1/aidata/subtitle/text/${contentId}`, [item]);
    }

    export const updateSpeechAidata = (contentId: string, item: iEntityTypes.ISpeechAidata): AxiosPromise<IResponse<any>> => {
        return http.patch<IResponse<any>>(`/scntm/v1/aidata/speech/text/${contentId}`, [item]);
    }

    export const produceSpeechSrtFile = (contentId: string): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/scntm/v1/aidata/speech/${contentId}/srt`);
    }

    export const getTvMovieInfo = (name: string): AxiosPromise<IResponse<iEntityTypes.ITvMovieInfoRes>> => {
        return http.get<IResponse<iEntityTypes.ITvMovieInfoRes>>(`/skldg/v1/entity/tv-movie-play?type=1&name=${name}&actors=&page=1&size=1`)
    }

    /** 获取人脸位置 */
    export const getFacePos = (target: string, bbox: number[]): AxiosPromise<IResponse<iEntityTypes.IGetFacePosRes>> => {
        return http.post<IResponse<iEntityTypes.IGetFacePosRes>>('/faceAnalyser/image/face-analyser', {target, bbox}, {
            headers: {
                'noHandleResponse': 'true'
            }
        });
    }
    export const imageFaceSwapper = (param: iEntityTypes.IImageFaceSwapperReq): AxiosPromise<IResponse<iEntityTypes.IGetFacePosRes>> => {
        return http.post<IResponse<iEntityTypes.IGetFacePosRes>>('/faceAnalyser/image/face-swapper', param, {
            headers: {
                'noHandleResponse': 'true'
            }
        });
    }
    export const imageFaceBlur = (param: iEntityTypes.IImageFaceBlurReq): AxiosPromise<IResponse<iEntityTypes.IGetFacePosRes>> => {
        return http.post<IResponse<iEntityTypes.IGetFacePosRes>>('/faceAnalyser/image/face-blur', param, {
            headers: {
                'noHandleResponse': 'true'
            }
        });
    }

    export const getSensitiveSequence = (contentIds: string[]): AxiosPromise<IResponse<any>> => {
        return http.post<IResponse<any>>(`/scntm/v1/sensitive/query-sequence`, contentIds);
    }
}
export default iEntityApi;
