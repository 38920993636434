import * as React from "react";
import ExtendStore from '../../../base/extendStore'
import { Stores } from "../../../index";
import {action, observable, runInAction} from "mobx";
import IFormItem from "mam-metadata-react/dist/types/IFormItem";
import { commonUtil } from 'mam-common-utils';
import { reqCatch } from 'mam-core-react';
import _ from 'lodash';
import NewspaperStore from "../../newspaperStore";
import iSearchTypes from 'src/types/iSearchTypes';
import iSearchApi from 'src/apis/iSearchApi';
import entityApi from "src/apis/entityApi";
import entityTypes from "src/types/entityTypes";
import newspaperApi from "src/apis/newspaperApi";
import newspaperTypes from "src/types/newspaperTypes";
import { message } from 'antd';

export default class PaperPackageStore extends ExtendStore {
    private parentStore: NewspaperStore;
    @observable
    public params: entityTypes.IEntityParam
    @observable
    public entity: entityTypes.IEntity;
    @observable
    public entityData: entityTypes.IEntityDataItem[];

    /** 查询素材详情报错信息 */
    @observable
    public loadError?: string
    /** 错误码 */
    @observable
    public loadErrorCode?: string

    @observable
    public metaEditable: boolean = false;

    @observable
    public keyword: string = ''
    @observable
    public issuenum: string = '' //期号
    @observable
    public paperdate: string[] = [] //发行日期
    @observable
    public currentDate: string[] = []

    @observable
    public pageIndex: number = 1
    @observable
    public pageSize: number = 30
    public pageSizes: number[] = [30,60,100]
    @observable
    public isLoadingFullSearchData: boolean = false
    @observable
    public fullSearchData?: iSearchTypes.IFullSearchDataRes;
    @observable
    public sortItems: iSearchTypes.ISortItem[] = []
    /** 当前选中排序类型 */
    @observable
    public currentSort: iSearchTypes.ISortItem;

    public baseInfo: React.RefObject<any>;

    public constructor(stores: Stores, parentStore: NewspaperStore) {
        super(stores)
        this.parentStore = parentStore;
        
        this.baseInfo = React.createRef();
        this.initSelectAllStore(stores, 'stores.newspaperStore.paperPackageStore.fullSearchData.data', 'selected');
        this.initDataViewModeStore(stores);
        this.initDownloadStore(stores);
        this.initOutstoreStore(stores);
        this.initDeleteStore(stores);
    }

    @action
    public setKeyword(keyword: string) {
        this.keyword = keyword
    }
    @action
    public setIssuenum(issuenum: string) {
        this.issuenum = issuenum
    }
    @action
    public setPaperdate(paperdate: string[]) {
        this.paperdate = paperdate
    }

    @action
    public setParams(params: any) {
        if (!this.params){
            this.params = params;
        }
        else {
            this.params = Object.assign({}, this.params, params);
        }
    }

    @action
    public updateEntityDataGroup(group: entityTypes.IEntityDataItem, field: string, val: any){
        group[field] = val;
    }

    @action
    public setEntityDataGroupFields(groupCode: string, fields: IFormItem[]){
        let group = _.find(this.entityData, {groupCode})
        if (group){
            group.fields = fields;
        }
    }

    @action
    public setMetaEditable(editable: boolean) {
        this.metaEditable = editable;
    }

    @action
    public init(){
        this.keyword = ''
        this.getSortItems()
        this.queryFullSearchData(1)
    }

    @action
    public setPageSize(pageSize: number){
        this.pageSize = pageSize
        this.queryFullSearchData(1)
    }

    @action
    public initEntity(contentId?: string, cb?: ()=>void){
        let allP: Array<Promise<any>> = []
        allP.push(this.getBaseData(contentId || this.params.contentId))
        allP.push(this.getEntityData(contentId || this.params.contentId))
        Promise.all(allP).then(()=>{
            if(cb){
                cb()
            }
        })
    }

    @action
    public getBaseData(contentId: string): Promise<entityTypes.IEntity> {
        this.loadError = ''
        this.loadErrorCode = ''
        return new Promise((resolve, reject) => {
            entityApi.getBaseData(contentId, `keys=enddate&needEntityData=true&needViewCount=true&keys=mam_view&keys=mam_collection&keys=mam_download&keys=mam_export`).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entity = res.data.data;
                        this.entity.contentId = contentId;
                        this.entity.contentId_ = contentId;
                        this.entity.paths = this.entity.previewFile || [];
                        // if (!this.frameRate){
                        //     this.frameRate = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].frameRate : 0;
                        // }
                        //处理时长
                        this.entity.duration = this.entity.paths && this.entity.paths.length > 0 ? this.entity.paths[0].duration : 0;
                        // if (this.entity.duration && this.frameRate){
                        //     this.entity.durationTc = playerUtil.l100Ns2Tc(this.entity.duration, this.entity.type, this.frameRate);
                        // }

                        //是否收藏
                        // mmsApi.collection.getCollectionStatus([this.entity.contentId]).then((res2) => {
                        //     runInAction(() => {
                        //         if (res2.data.data){
                        //             let favoriteItem = _.find(res2.data.data, (favoriteItem)=>{
                        //                 return favoriteItem.rescontentid === this.entity.contentId
                        //             })
                        //             if (favoriteItem) {
                        //                 this.entity.isFavorite = true;
                        //                 this.entity.favoriteObjId = favoriteItem.contentId_;
                        //             }
                        //         }
                        //     });
                        // });

                        //如果是图片包，需要获取所有单图的元数据
                        // if(this.entity.type === 'biz_sobey_picpackage'){
                        //     if(this.entity.paths && this.entity.paths[0] && this.entity.paths[0].fileGuid){ //初始化设定选中第一个单图选中
                        //         this.picpackageStore.seletedPicPathItem = this.entity.paths[0]
                        //     }
                        //     this.picpackageStore.getPicPackageAllPicMetadata(this.entity.contentId)
                        // }
                        resolve(this.entity)
                    }
                    else {
                        reject()
                    }
                })
            }, (res) => {
                runInAction(() => {
                    if (res.data.error && res.data.error.title){
                        if (res.data.error.code){
                            this.loadErrorCode = res.data.error.code;
                            if (res.data.error.code === 'B1066') {
                                this.loadError = '系统未定义该类型，无法浏览。'
                            }
                        }
                        this.loadError = res.data.error.title
                    }
                    reject()
                })
            })
        })
    }

    @action
    public getEntityData(contentId: string): Promise<entityTypes.IEntityDataItem[]> {
        return new Promise((resolve, reject) => {
            entityApi.getEntityData(contentId).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        this.entityData = res.data.data;
                        resolve(this.entityData);
                    }
                    else {
                        reject();
                    }
                });
            })
        });
    }

    @action
    public getSortItems = () => {
        const config = this.stores.configStore.config;
        let sortItems: iSearchTypes.ISortItem[] = []
        //排序写死
        sortItems = [
            //{ text: '相关度'.l('search.relatedRate'), field: '_score', hideDirection: true },
            { text: '发行日期'.l('search.paperdate'), field: 'paperdate', desc: 'desc' },
            { text: '发行日期'.l('search.paperdate'), field: 'paperdate', desc: 'asc' },
            { text: '期数'.l('search.issuenum'), field: 'issuenum', desc: 'desc' },
            { text: '期数'.l('search.issuenum'), field: 'issuenum', desc: 'asc' },
            { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
            { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
            // { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
            // { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' }
        ]
        // if (config && config.sortFields && config.sortFields.length > 0) {
        //     sortItems = config.sortFields.map((item: any) => {
        //         return {
        //             text: item.name || '',
        //             field: item.field,
        //             desc: item.desc ? 'desc' : 'asc',
        //             hideDirection: true
        //         }
        //     })
        // } else {
        //     sortItems = [
        //         { text: '相关度'.l('search.relatedRate'), field: '_score', hideDirection: true },
        //         { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'desc' },
        //         { text: '入库时间'.l('search.importTime'), field: 'createDate_', desc: 'asc' },
        //         { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'desc' },
        //         { text: '标题名'.l('search.titleName'), field: 'name_', desc: 'asc' }
        //     ]
        // }
        // sortItems.unshift({
        //     text: '相关度'.l('search.relativeRate'), field: '_score', hideDirection: true
        // });
        this.setSortItems(sortItems)
        if(!this.currentSort){
            this.setCurrentSort(sortItems[0])
            // let sortStorage = localStorage.getItem('paperPackage_current_sort')
            // if (sortStorage){
            //     try {
            //         this.setCurrentSort(JSON.parse(sortStorage))
            //     }
            //     catch (e){
            //         console.error(e);
            //     }
            // }
            // else {
            //     //参数里关键字不为空，默认选中相关度，否则默认取第一个
            //     const keyword = this.params?.keyword
            //     if (!keyword){
            //         let scoreItem = _.find(this.sortItems, {field: '_score'});
            //         if (scoreItem){
            //             this.setCurrentSort(scoreItem);
            //         }
            //         else {
            //             this.setCurrentSort(sortItems[0])
            //         }
            //     }
            //     else {
            //         const scoreItem = _.find(this.sortItems, {field: '_score'});
            //         if (scoreItem){
            //             this.setCurrentSort(scoreItem);
            //         }
            //         else {
            //             this.setCurrentSort(sortItems[0])
            //         }
            //     }
            // }
        }
    }

    @action
    public setSortItems = (sortItems: iSearchTypes.ISortItem[]) => {
        this.sortItems = sortItems
    }

    @action
    public setCurrentSort(sort: iSearchTypes.ISortItem) {
        this.currentSort = sort;
        //localStorage.setItem('paperPackage_current_sort', JSON.stringify(this.currentSort))
    }

    @action
    public queryFullSearchData(page?: number): Promise<iSearchTypes.IFullSearchDataRes> {
        this.isLoadingFullSearchData = true
        this.fullSearchData = undefined
        
        if(page){
            this.pageIndex = page
        }
        
        let req: newspaperTypes.IFullSearchReq = {
            pageIndex: page || this.pageIndex,
            pageSize: this.pageSize,
            conditions: [],
            facetConditions: [],
            keywords: this.keyword ? [this.keyword] : [],
            parentId: this.params.contentId,
            sortFields:[{
                field: this.currentSort.field,
                isDesc: this.currentSort.field === '_score' ? true : this.currentSort.desc === 'desc'
            }]
        }

        if(this.issuenum){
            req.conditions.push({
                field: 'issuenum',
                value: [this.issuenum],
            })
        }

        if(this.paperdate[0] && this.paperdate[1]){
            req.conditions.push({
                field: 'paperdate',
                value: [this.paperdate[0]+' 00:00:00'+' ~ '+this.paperdate[1]+' 23:59:59'],
            })
        }

        return new Promise((resolve, reject) => {
            newspaperApi.search(req).then((res) => {
                runInAction(() => {
                    if (res.data.data) {
                        if (res.data.data.data) {
                            this.parentStore.resolveQueryResult(res.data.data.data);
                        }
                        this.fullSearchData = res.data.data
                        resolve(res.data.data)
                    }else {
                        reject()
                    }
                    this.isLoadingFullSearchData = false
                })
            }).catch(reqCatch)
        })
    }

    @action
    public save(req: any) {
        return new Promise((resolve, reject) => {
            newspaperApi.paperPackage.save(this.params.contentId, req).then((res) => {
                runInAction(() => {
                    if(res.data.success){
                        message.success('保存成功！'.l('paperPackage.saveSuccess'))
                        setTimeout(() => {
                            
                        }, 300);
                        resolve(true)
                    }
                })
            }).catch(reqCatch)
        })
    }

}
