import {action, computed, observable, runInAction} from "mobx";
import { message } from 'antd';
import _ from 'lodash'
import { BaseStore } from "mam-core-react";
import { Stores } from "..";
import { getFolders, initCloudFolders } from "src/apis/folderSearchApi";
import { IFolder } from "src/types/folderSearchTypes";
import { treeUtil } from "mam-common-utils";

export default class FolderSearchStore extends BaseStore<Stores>{
    @observable
    public rootFolders: IFolder[];
    @observable
    public loadedKeys: string[] = []
    @observable
    public expandedKeys: string[] = []
    @observable
    public selectedFolderId?: string;

    @computed
    public get selectedFolder(){
        if (!this.selectedFolderId){
            return undefined;
        }
        return treeUtil.getTreeDataRecursively(this.rootFolders, 'key', this.selectedFolderId)
    }

    @action
    public initTree(){
        return new Promise((resolve, reject)=>{
            this.setExpandedKeys([]);
            this.setLoadedKeys([]);
            initCloudFolders().then((res)=>{
                runInAction(()=>{
                    if (res.data.data){
                        this.rootFolders = res.data.data.map((item)=>{
                            return {
                                title: item.showName,
                                key: item.contentId,
                                isLeaf: false,
                                isPrivateFolder: item.isPrivateFolder
                            }
                        });
                        resolve(undefined);
                    }
                })
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title);
                    reject();
                }
            })
        })
    }

    @action
    public getFolders(parentCode: string){
        return new Promise((resolve, reject)=>{
            getFolders(parentCode).then(res=>{
                runInAction(()=>{
                    if (res.data.data && res.data.data.length > 0){
                        let folder: IFolder | undefined = treeUtil.getTreeDataRecursively(this.rootFolders, 'key', parentCode)
                        if (folder){
                            folder.children = res.data.data.map((item)=>{
                                return {
                                    title: item.showName,
                                    key: item.contentId,
                                    isLeaf: false,
                                    isPrivateFolder: item.isPrivateFolder,
                                    parentCode: folder?.key
                                }
                            });
                        }
                        // 根节点不重新赋值，会导致展开后不缩进
                        this.rootFolders = this.rootFolders.concat([])
                    }
                });
            }, (res)=>{
                if (res.data.error && res.data.error.title){
                    message.error(res.data.error.title)
                }
            }).finally(()=>{
                resolve(undefined)
            });
        })
    }

    public isRoot(code: string){
        return _.findIndex(this.rootFolders, {key: code}) > -1;
    }

    @action
    public setLoadedKeys = (loadedKeys: string[]) => {
        this.loadedKeys = loadedKeys
    }

    @action
    public setExpandedKeys = (expandedKeys: string[]) => {
        this.expandedKeys = expandedKeys
    }

    @action
    public setSelectedFolderId = (id?: string)=>{
        this.selectedFolderId = id
    }
}