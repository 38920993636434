import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';

export interface IGlobalModal{
    modal: Omit<ModalStaticFunctions, 'warn'>
}

export const gModal: IGlobalModal = {
    modal: Modal
}

const GlobalModal: React.FC = observer(() => {
    const [modal, contextHolder] = Modal.useModal();

    React.useEffect(()=>{
        gModal.modal = modal;
    }, [modal]);

    return <>{contextHolder}</>
})
export default GlobalModal