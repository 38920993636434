import { message } from "antd";
import { action, observable, runInAction } from "mobx";
import advancedSearchApi from "src/apis/advancedSearchApi";
import advancedSearchTypes from "src/types/advancedSearchTypes";
import BaseStore from "../base/baseStore";
import {Stores} from "../index";

class AdvancedSearchStore extends BaseStore<Stores>{
    @observable
    public showAdvancedSearch:boolean = false

    @observable
    public fields?: advancedSearchTypes.IField[]

    @action
    public setShowAdvancedSearch(showAdvancedSearch:boolean){
        this.showAdvancedSearch = showAdvancedSearch
    }

    @action
    public getFields(){
        advancedSearchApi.getFields().then(res=>{
            runInAction(()=>{
                if (res.data.data){
                    res.data.data.forEach((item)=>{
                        item.alias = '';
                        item.fieldPath = '';
                        item.minLength = 0;
                        item.maxLength = 0;
                        item.isShow = true;
                        item.isReadOnly = false;
                        item.isMustInput = false;
                        item.isMultiSelect = false;
                        item.isArray = false;
                        item.value = '';
                        item.searchRelation = 'eq';
                        if (item.controlType === 16){
                            item.controlData = '{\"type\":\"onlypass\",\"showTime\":false}'
                        }
                    })
                    this.fields = res.data.data;
                }
            })
        }, res=>{
            if (res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
        });
    }

    @action
    public setFields(fields?: advancedSearchTypes.IField[]){
        this.fields = fields;
    }

    @action
    public setFieldRelation(index: number, value: string){
        if (this.fields){
            this.fields[index].searchRelation = value;
        }
    }

    @action
    public setFieldValue(index: number, value: string | number){
        if (this.fields){
            this.fields[index].value = value;
        }
    }
}

export default AdvancedSearchStore