import { observable, action, runInAction } from 'mobx'
import { message } from 'antd'
import _ from 'lodash'
import ExtendStore from 'src/stores/base/extendStore';
import { Stores } from 'src/stores';
import EsSearchConfigStore from './esSearchConfigStore';
import manageTypes from 'src/types/manageTypes';
import manageApi from 'src/apis/manageApi';

export default class ExcelExportFieldsConfigStore extends ExtendStore{
    public parentStore: EsSearchConfigStore;
    @observable
    public fieldList: manageTypes.esSearchConfig.excelExportFieldsConfig.IExportExcelFieldItem[] = [];
    @observable
    public isReqFieldList: boolean = false;

    public constructor(stores: Stores, parentStore: EsSearchConfigStore) {
        super(stores);
        this.parentStore = parentStore;
    }

    @action
    public setFieldList(fieldList: manageTypes.esSearchConfig.excelExportFieldsConfig.IExportExcelFieldItem[]){
        this.fieldList = fieldList;
    }

    @action
    public queryFieldList(){
        this.isReqFieldList = true;
        manageApi.esSearchConfig.excelExportFieldsConfig.getExportFields().then((res)=>{
            runInAction(()=>{
                if (res.data.data){
                    this.fieldList = res.data.data;
                }
            })
        }).catch(res=>{
            if (res.data && res.data.error && res.data.error.title){
                message.error(res.data.error.title);
            }
            else {
                console.error(res);
            }
        }).finally(()=>{
            runInAction(()=>{
                this.isReqFieldList = false;
            })
        })
    }

    @action
    public removeFieldItem(id: number){
        manageApi.esSearchConfig.excelExportFieldsConfig.deleteExportFields([id]).then(()=>{
            message.success('删除成功！'.l('com.deleteSuccess'));
            this.queryFieldList();
        })
    }
}
