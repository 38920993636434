import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Provider } from 'mobx-react'

import * as serviceWorker from './serviceWorker';
import { Route } from 'react-router-dom'

import './nxt';
import config from './config';
import { stores } from './stores'
import AuthRoute from "./router/authRoute";
import NotFound from "./pages/NotFound";
import Home from "./pages/home";
import LoadingComponent, { LoadingComponentModes } from "./router/loadingComponent";
import Loadable from "react-loadable";
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/locale/zh_CN';
import zh_TW from 'antd/locale/zh_TW';
import SignalrNotification from "./components/signalr/signalrNotification"
import moment from "moment";
import 'moment/locale/zh-cn';
import { setHttpOptions } from "./utils/http";
import { commonUtil } from 'mam-common-utils'
import styleUtil from './utils/styleUtil';
import GlobalModal, { gModal } from './components/extend/modal/globalModal';
import { setConfigStore, setGlobalModal } from 'mam-biz-components';

let showRequestLoadingTc: NodeJS.Timeout | undefined;
setHttpOptions({
    baseReqPath: commonUtil.isDebug() ? '/proxy' : '',
    headers: {
        'x-b3-sampled': '1',
        'product': process.env.BUILD_TYPE
    },
    addReqCountCallback: (requestLoadingCount: number) => {
        if (showRequestLoadingTc){
            window.clearTimeout(showRequestLoadingTc);
        }
        // 加载转圈动画最长不超过10秒，提升用户体验
        showRequestLoadingTc = setTimeout(()=>{
            if (stores.configStore.showRequestLoading){
                stores.configStore.setShowRequestLoading(false);
            }
        }, 10000)

        if (!stores.configStore.showRequestLoading) {
            stores.configStore.setShowRequestLoading(true);
        }
    },
    removeReqCountCallback: (requestLoadingCount: number) => {
        if (requestLoadingCount <= 0) {
            stores.configStore.setShowRequestLoading(false);

            if (showRequestLoadingTc){
                window.clearTimeout(showRequestLoadingTc);
            }
        }
    }
});

if (process.env.DEFAULT_THEME_COLOR){
    window['__theme_COLOR_cfg'] = {
        colors: styleUtil.getColors4ThemeChange(styleUtil.generateColors(process.env.DEFAULT_THEME_COLOR)),
        url: `/css/theme-colors-${process.env.CURRENT_TIME}.css`
    }
}

config.initCfg().then((data) => {
    let antdLocale: any;
    const lang = (window as any).mam.language.get();
    if (!lang || lang === 'zh') {
        moment.locale('zh-cn');
        antdLocale = zh_CN;
    }
    else if (lang === 'cht') {
        moment.locale('zh-tw');
        antdLocale = zh_TW;
    }

    setConfigStore(stores.configStore)
    setGlobalModal(gModal)

    ReactDOM.render(
        <ConfigProvider locale={antdLocale}
            theme={{
                token: {
                    colorPrimary: stores.configStore.baseConfig.themeColor
                }
            }}>
            <Provider {...stores}>
                <BrowserRouter>
                    <Switch>
                        {
                            <Route path="/sharePassword/:linkUrl" name="sharePassword" component={Loadable({
                                loader: () => import('./pages/sharePage/sharePassword'),
                                loading: LoadingComponent
                            })} exact={false}></Route>
                        }
                        {
                            <Route path="/shareList/:linkUrl" name="shareList" component={Loadable({
                                loader: () => import('./pages/sharePage/shareList'),
                                loading: LoadingComponent
                            })} exact={false}></Route>
                        }
                        {
                            <Route path="/shareEntity/:contentId" name="shareEntity" component={Loadable({
                                loader: () => import('./pages/sharePage/shareEntity'),
                                loading: LoadingComponent
                            })} exact={false}></Route>
                        }
                        {
                            process.env.BUILD_TYPE === 'moc' &&
                            <Route path="/wap" name="wap" component={Loadable({
                                loader: () => import('./pages/wap/wap'),
                                loading: LoadingComponent
                            })} exact={false}></Route>
                        }
                        <AuthRoute path="/login" name="login" component={Loadable({
                            loader: () => import('./pages/login/login'),
                            loading: LoadingComponent
                        })} exact={true}></AuthRoute>
                        <AuthRoute path="/wps" name="wps" component={Loadable({
                            loader: () => import('./pages/wps/wps'),
                            loading: LoadingComponent
                        })} exact={true}></AuthRoute>
                        <AuthRoute path="/sysmanage" name="sysmanage" component={Loadable({
                            loader: () => import('./pages/sysmanage/sysmanage'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/manage" name="manage" component={Loadable({
                            loader: () => import('./pages/manage/manage'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/transcodePage/test" name="transcodePageTest" component={Loadable({
                            loader: () => import('./pages/transcodePage/test'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/transcodePage/downloadPart" name="downloadPart" component={Loadable({
                            loader: () => import('./pages/transcodePage/downloadPart'),
                            loading: LoadingComponent
                        })} exact={false}></AuthRoute>
                        <AuthRoute path="/" name="home" component={Home}></AuthRoute>
                    </Switch>
                </BrowserRouter>
                {/* todo: 等后端，开关还不确定要不要 */}
                {/* {
                    stores.userStore.currentUser &&
                    stores.configStore.config.signalrSwitch &&
                    <SignalrNotification ip= {''}/>
                } */}
                <LoadingComponent isLoading={true} pastDelay={true} timedOut={true} error={true} retry={()=>{}}
                    mode={LoadingComponentModes.REQUEST}/>
                <GlobalModal></GlobalModal>
            </Provider>
        </ConfigProvider>,
        document.getElementById('root') as HTMLElement
    )

    // 设置浏览器顶部ico图标
    if (stores.configStore.baseConfig && stores.configStore.baseConfig.icoLogo){
        const shortcut = $("link[rel='shortcut icon']");
        if (shortcut) {
            shortcut.attr('href', process.env.DEV_SERVER + stores.configStore.baseConfig.icoLogo);
        }
    }

    // 全局样式注入
    if (stores.configStore.baseConfig && stores.configStore.baseConfig.styleInject){
        commonUtil.setCustomStyle(stores.configStore.baseConfig.styleInject)
    }
});

// 莫名其妙会生成一个iframe遮挡页面，影响调试效率，本地调试模式下样式隐藏
if (commonUtil.isDebug()){
    commonUtil.setCustomStyle('body>iframe{display:none;}')
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
