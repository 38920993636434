import { action, observable } from "mobx";
import mmsApi from 'src/apis/mmsApi';
import { Stores } from 'src/stores';
import {BaseStore} from "mam-core-react";
import _ from 'lodash';
import iSearchTypes from 'src/types/iSearchTypes';

export default class IOutputExcelStore extends BaseStore<Stores>{
    // 控制下载弹窗
    @observable
    public showOutputExcelModal:boolean = false

    @observable
    public req: iSearchTypes.IFullSearchDataReq

    @observable
    public isSelectItem:any

    public constructor(stores: Stores){
        super(stores);
    }

    @action
    public setShowOutputExcelModal(show:boolean){
        this.showOutputExcelModal = show
    }

    @action
    public setReq(req: iSearchTypes.IFullSearchDataReq){
        this.req =req
    }

    @action
    public outputExcel(isSelectItem:any[]){
        this.isSelectItem = isSelectItem;
        this.setShowOutputExcelModal(true)
        const size: number = this.stores.configStore.config.config.excelExportPageSize || 2000;
        this.getQueryRequest(size)
        if(isSelectItem.length > 0){
            //清空列表选中
            this.stores.iSearchStore.selectAllStore.switchAllSelected(false);
            this.stores.iSearchStore.selectAllStore.switchListItemSelected(undefined, false);
        }
    }

    @action 
    public getQueryRequest(size:number){
        let param = this.stores.iSearchStore.getFullSearchParam(1);
        param.pageSize = size;
        this.setReq(param)
    }

    @action
    public keywordCheck(keyword:string[]){
        return true
    }
}